import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";
import aboutHeroBg from "assets/images/about-bg.jpg";

const Styles = styled.div`
  .about {
    &__hero {
      min-height: 300px;
      ${respondTo.xl`
        height: calc(100vh - var(--top-header-height) - var(--nav-menu-height));
    `};

      background-image: url(${aboutHeroBg});
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      &__content {
        width: 80%;
        ${respondTo.md`
            width: 60%;
        `}
        ${respondTo.lg`
            width: 40%;
        `}
      }
    }

    &__container {
      width: 90%;
      ${respondTo.md`
        width: 80%;
      `}
      ${respondTo.xl`
        width: 70%;
      `}
      margin: auto;
    }

    &__content {
      display: grid;
      column-gap: 4rem;
      row-gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
      ${respondTo.md`
            grid-template-columns: repeat(2, 1fr);
        `}
    }

    &__page-list {
      &__link {
        display: flex;
        font-size: 1rem;
        font-weight: 500;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: solid 1px var(--greyLight);
        padding-left: 0;
        padding-right: 0;

        .title {
          flex: 1;
        }

        .icon {
          flex: 1;
        }
      }
    }

    &__call-to-action {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
`;

export const CallToActionSection = styled.div`
  width: 100%;
  padding: 20% 10%;
  color: white;
  background-color: ${(props) => props.bgColor || `var(--primary)`};

  .title {
    font-size: 2.5rem;
    font-weight: 600;
  }

  .sub-title {
    font-size: 1rem;
    font-weight: 500;
  }

  .desc {
    font-size: 14px;
  }
`;
export default Styles;
