import { SocialLink } from "@atoms";
import { socialLinksData } from "@constants";
import { useFetchSocialLinks } from "@hooks";
import React from "react";
import Styles from "./style";

const SocialLinks = ({ border }) => {
  const { socialLinksData: contactInfo, isSocialLinksLoading } = useFetchSocialLinks();

  if (!isSocialLinksLoading) {
    const data = contactInfo?.data;
    socialLinksData.linkedin.href = data?.linkedinPage;
    socialLinksData.twitter.href = data?.twitterPage;
    socialLinksData.instagram.href = data?.instagramPage;
    socialLinksData.facebook.href = data?.facebookPage;
    socialLinksData.youtube.href = data?.youtubeChannel;
  }

  const links = Object.values(socialLinksData).filter((link) => !!link.href);

  return (
    <Styles border={border}>
      {links.length > 0 && (
        <div className="social-links px-2 px-lg-3 d-flex justify-content-between align-items-center">
          {links.map((link, i) => (
            <SocialLink key={i} href={link.href}>
              {link.icon}
            </SocialLink>
          ))}
        </div>
      )}
    </Styles>
  );
};

export default SocialLinks;
