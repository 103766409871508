import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Styles, { Title } from "./styles";
import { StyledButton } from "@atoms";
import { pageRoutes, theme } from "@constants";
import { HTMLComponent, LocalizedLink } from "@particles";
import { useQuery } from "react-query";
import { keyTopicsApi } from "api";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Alert } from "react-bootstrap";
import { BlogCard } from "@molecules";
import SendBlogModal from "../send-blog-modal";

const KeyTopicDetails = () => {
  const { id } = useParams();

  const [sendBlogModalOpen, setSendBlogModalOpen] = useState(false);

  const handleSendYourBlogClick = () => {
    setSendBlogModalOpen(true);
  };

  const {
    isLoading: isSelectedKeyTopicLoading,
    data: selectedKeyTopic,
    isError: isDetailsError
  } = useQuery(["key-topics", "details", id], () => keyTopicsApi.getDetails(id));

  const {
    name,
    description,
    datahighlights,
    datahighlightsImageUrl,
    links,
    records: blogs
  } = selectedKeyTopic?.data || {};

  const isNotFound = selectedKeyTopic?.succeeded === false;

  return (
    <Styles>
      <div className="key-topic-details">
        {isSelectedKeyTopicLoading && (
          <div className="w-100 d-flex justify-content-center align-items-center details-loader">
            <Loader color={theme.colors.primary} type="ThreeDots" />
          </div>
        )}

        {isDetailsError && !isSelectedKeyTopicLoading && (
          <div className="container my-5 px25">
            <Alert>
              <FormattedMessage defaultMessage="Something went wrong fetching the key topic" />
            </Alert>
          </div>
        )}

        {isNotFound && !isSelectedKeyTopicLoading && (
          <div className="container my-5 px25">
            <Alert>
              <FormattedMessage defaultMessage="Key topic is not found" />
            </Alert>
          </div>
        )}

        {!isDetailsError && !isSelectedKeyTopicLoading && !isNotFound && (
          <>
            <section className="mt-3 mt-lg-5">
              <div className="container">
                <div className="d-flex justify-content-between mb-3">
                  <h1>{name}</h1>
                </div>

                <HTMLComponent className="description mb-3 mb-lg-5" html={description} />
              </div>
            </section>
            {(datahighlights || datahighlightsImageUrl || links?.length > 0) && (
              <section className="data-highligts mt-3">
                <div className="container">
                  <div className="d-flex justify-content-between mb-3">
                    <Title>
                      <FormattedMessage defaultMessage="Data Highlights" />
                    </Title>
                    {links?.length > 0 && (
                      <LocalizedLink to={`${pageRoutes.dataHighlights}?keyTopicId=${id}`}>
                        <StyledButton
                          variant="outline-primary"
                          className="rounded-0 px-4">
                          <FormattedMessage defaultMessage="View All" />
                        </StyledButton>
                      </LocalizedLink>
                    )}
                  </div>
                  <div className="row justify-content-between">
                    <div className="px-0 px-md-2 col-sm-6 mb-3 mb-lg-0">
                      <div className="w-75">
                        <p>{datahighlights}</p>
                      </div>
                    </div>
                    <div className="px-0 px-md-2 col-sm-3">
                      {datahighlightsImageUrl && (
                        <img
                          src={datahighlightsImageUrl}
                          alt="data-highligts"
                          className="d-block w-100"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            )}
            {blogs?.length > 0 && (
              <section className="blogs my-3 my-lg-5">
                <div className="container">
                  <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                    <h4 className="text-primary my-2">
                      <FormattedMessage defaultMessage="Related Blogs" />
                    </h4>

                    <div className="d-flex align-items-center">
                      <LocalizedLink to={`${pageRoutes.blogs}`}>
                        <StyledButton
                          variant="outline-primary"
                          className="rounded-0 px-4">
                          <FormattedMessage defaultMessage="View All" />
                        </StyledButton>
                      </LocalizedLink>

                      <StyledButton
                        className="rounded-0 px-4 uppercase ms-3"
                        onClick={handleSendYourBlogClick}>
                        <FormattedMessage defaultMessage="Send us your blog" />
                      </StyledButton>
                    </div>
                  </div>
                  <div className="blogs__grid">
                    {blogs?.map?.((item, i) => {
                      return <BlogCard key={i} {...item} />;
                    })}
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </div>

      <SendBlogModal
        sendBlogModalOpen={sendBlogModalOpen}
        setSendBlogModalOpen={setSendBlogModalOpen}
      />
    </Styles>
  );
};

export default KeyTopicDetails;
