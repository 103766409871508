import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import { isNumber } from "@utils";
import isArabic from "helpers/isArabic";
import getSeriesColor from "helpers/getColor";

// methods naming convention:
// create: inittate instance and handle setup
// setup: receive instance as param and handle setup
// initiate: create amchart component instance and return it

class BaseChart {
  chart;
  chartType;
  xAxis;
  legendContainer;
  seriesDataFieldXProp;
  seriesDataList;
  seriesDataGroupedByUnit;
  config = {
    containerId: "",
    chartId: "",
    multiFrequency: false,
    multipleYAxes: false,
    isMobileView: false,
    xAxisProp: "year",
    singleYAxisUnitName: "",
    legendDivId: "legenddiv",
    exportMenuContainerId: "",
    showDataLabels: false,
    yAxesRanges: {},
    isLogarithmic: false,
    getExportingItems: () => {}
  };

  constructor({
    data,
    seriesDataList,
    seriesDataGroupedByUnit,
    config: {
      containerId,
      chartId,
      multiFrequency,
      multipleYAxes,
      isMobileView,
      xAxisProp,
      singleYAxisUnitName,
      legendDivId,
      exportMenuContainerId,
      showDataLabels,
      yAxesRanges,
      isLogarithmic,
      getExportingItems
    }
  }) {
    am4core.useTheme(am4themesAnimated);
    this.config = {
      containerId,
      chartId,
      multiFrequency,
      multipleYAxes,
      isMobileView,
      xAxisProp,
      singleYAxisUnitName,
      legendDivId,
      exportMenuContainerId,
      showDataLabels,
      yAxesRanges,
      isLogarithmic,
      getExportingItems
    };

    this.data = data;
    this.seriesDataList = seriesDataList;
    this.seriesDataGroupedByUnit = seriesDataGroupedByUnit;

    this.bootstrapChart();
  }

  initiateChart() {
    // this is abstract method should be implemented by inheriting class
    // this method should create proper amchart instance and set it to "chart" field
    // and set the proper value for chartType field
    throw new Error("this is abstract method should be implemented by inheriting class");
  }

  createXDateAxis() {
    this.xAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    this.xAxis.baseInterval = {
      timeUnit: "year",
      count: 1
    };

    this.xAxis.dataFields.date = this.config.xAxisProp;
    this.seriesDataFieldXProp = "dateX";

    // make sure series bullets aligned with x axis labels
    // this is needed in date axis type only
    this.xAxis.renderer.labels.template.location = 0.5;
    this.xAxis.renderer.grid.template.location = 0.5;
  }

  createXCategoryAxis() {
    this.xAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    this.xAxis.dataFields.category = this.config.xAxisProp;
    this.seriesDataFieldXProp = "categoryX";
  }

  setupXAxis() {
    this.xAxis.renderer.minGridDistance = this.isMobileView ? 30 : 60;
    this.xAxis.keepSelection = true;

    const scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 40;
    this.chart.scrollbarX = scrollbarX;

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.xAxis = this.xAxis;
  }

  initiateXAxis() {
    // abstract methor should be implemented by inheriting class
    // should create proper xaxis instance and set it to xaxis field
    // in most cases it will call either createXcategoryAxis or createXDateAxis methods
    throw new Error(
      "This is an abstract method that should be implemented by inheriting class"
    );
  }

  createXAxis() {
    this.initiateXAxis();
    this.setupXAxis();
  }

  createValueAxis({ title, range, opposite, color }) {
    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

    valueAxis.title.text = title;

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.opposite = opposite;

    valueAxis.title.dx = opposite ? -10 : 10;
    if (color) {
      valueAxis.renderer.line.stroke = color;
      valueAxis.renderer.labels.template.fill = color;
      valueAxis.title.fill = color;
    }

    this.setAxisRange(valueAxis, range);

    return valueAxis;
  }

  setAxisRange(axis, range) {
    if (isNumber(range.min) && isNumber(range.max)) {
      if (!(range.min <= 0 && this.config.isLogarithmic)) {
        // prevent set axis min if logarithmic is enable and target min <= 0 as the chart screws up in this case
        axis.min = range.min;
      }
      axis.max = range.max;
    } else {
      if (this.config.isLogarithmic) {
        // add as less as possible padding to prevent chart screwing up
        // when logarithmic is enabled
        axis.extraMin = 0.05;
      } else {
        axis.extraMin = 0.1;
      }

      axis.extraMax = 0.1;
    }

    axis.logarithmic = this.config.isLogarithmic;
  }

  setupSeries(series, { name, color, unitName, yAxis }) {
    // do the commont setup for series between all charts
    series.name = name;
    series.dataFields[this.seriesDataFieldXProp] = this.config.xAxisProp;
    series.dataFields.valueY = name;

    series.stroke = color;
    series.showOnInit = true;

    if (yAxis) {
      series.yAxis = yAxis;
    }

    series.tooltipText = `{valueY} ${unitName}`;
    series.tooltip.background.fill = color;
    series.tooltip.getStrokeFromObject = true;
    series.tooltip.background.strokeWidth = 1;
    series.tooltip.getFillFromObject = false;

    if (this.config.showDataLabels) {
      this.showSeriesDataLabels(series);
    }

    return series;
  }

  initiateSeries() {
    // abstract method should be overide by inheriting class
    // this method should create proper amchart series instance and return it
    throw new Error("This is abstract method should be overided by inheriting class");
  }

  createSeries({ name, color, unitName, yAxis }) {
    const config = { name, color, unitName, yAxis };
    const series = this.initiateSeries();
    this.setupSeries(series, config);
  }

  createLegend() {
    // add legends in auto sized external container
    this.legendContainer = am4core.create(this.config.legendDivId, am4core.Container);
    this.legendContainer.width = am4core.percent(100);
    this.legendContainer.height = am4core.percent(100);

    this.chart.legend = new am4charts.Legend();
    this.chart.legend.scrollable = false;
    this.chart.legend.parent = this.legendContainer;

    // resize legends based on date or window size change
    this.chart.events.on("datavalidated", () => {
      this.resizeLegend();
    });
    this.chart.events.on("maxsizechanged", () => {
      this.resizeLegend();
    });

    this.chart.legend.events.on("datavalidated", () => {
      this.resizeLegend();
    });
    this.chart.legend.events.on("maxsizechanged", () => {
      this.resizeLegend();
    });

    this.chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    this.chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

    this.chart.legend.itemContainers.template.events.on("over", (event) => {
      this.processOver(event.target.dataItem.dataContext);
    });

    this.chart.legend.itemContainers.template.events.on("out", (event) => {
      this.processOut(event.target.dataItem.dataContext);
    });
  }

  createExporting() {
    this.chart.exporting.menu = new am4core.ExportMenu();
    this.chart.exporting.filePrefix = `${this.config.chartId}-${this.chartType}`;
    this.chart.exporting.getFormatOptions("pdf").addURL = false;
    this.chart.exporting.menu.items = this.config.getExportingItems(
      this.chart,
      `${this.config.chartId}-${this.chartType}`
    );
    this.chart.exporting.menu.container = document.getElementById(
      this.config.exportMenuContainerId
    );
    this.chart.exporting.extraSprites.push({
      sprite: this.legendContainer,
      position: "bottom",
      marginTop: 20
    });
  }

  handleRtl() {
    const isRtl = isArabic();
    this.chart.rtl = isRtl;
  }

  showSeriesDataLabels(series, getYOffset) {
    const labelBullet = series.bullets.push(new am4charts.LabelBullet());

    labelBullet.label.text = "{valueY}";
    labelBullet.label.dy = getYOffset ? getYOffset() : -20;
    labelBullet.label.dx = 10;
    labelBullet.label.truncate = false;
    labelBullet.label.hideOversized = false;
    labelBullet.label.fill = series.stroke;
    // prevent cut of labels positioned on the edge of the chart container
    this.chart.maskBullets = false;
    this.chart.paddingRight = 25;

    return labelBullet;
  }

  processOver(hoveredSeries) {
    hoveredSeries.toFront();

    hoveredSeries.segments.each((segment) => {
      segment.setState("hover");
    });

    hoveredSeries.legendDataItem.marker.setState("default");
    hoveredSeries.legendDataItem.label.setState("default");

    this.chart.series.each((series) => {
      if (series != hoveredSeries) {
        series.segments.each((segment) => {
          segment.setState("dimmed");
        });
        series.bulletsContainer.setState("dimmed");
        series.legendDataItem.marker.setState("dimmed");
        series.legendDataItem.label.setState("dimmed");
      }
    });
  }

  processOut() {
    this.chart.series.each(function (series) {
      series.segments.each(function (segment) {
        segment.setState("default");
      });
      series.bulletsContainer.setState("default");
      series.legendDataItem.marker.setState("default");
      series.legendDataItem.label.setState("default");
    });
  }

  setupSeriesOverMultipleYAxes(seriesDataGroupedByUnit) {
    let colorIndex = 0;

    Object.entries(seriesDataGroupedByUnit).forEach(
      ([unitName, seriesDataList], index) => {
        const yAxis = this.createValueAxis({
          title: unitName,
          range: this.config.yAxesRanges?.[unitName],
          color: getSeriesColor(seriesDataList?.length - 1 + colorIndex),
          opposite: (index + 1) % 2 === 0
        });

        this.createSeriesList(seriesDataList, {
          unitName,
          yAxis,
          colorIndexOffset: colorIndex
        });

        colorIndex = seriesDataList.length;
      }
    );
  }

  createSeriesList(seriesDataList = [], { unitName, yAxis, colorIndexOffset = 0 }) {
    seriesDataList.forEach((s, index) => {
      this.createSeries({
        name: s.name,
        unitName,
        yAxis,
        color: getSeriesColor(index + colorIndexOffset)
      });
    });
  }

  setupAllSeriesAndValueAxes() {
    if (this.config.multipleYAxes) {
      this.setupSeriesOverMultipleYAxes(this.seriesDataGroupedByUnit);
    } else {
      this.createValueAxis({
        title: this.config.singleYAxisUnitName,
        range: this.config.yAxesRanges
      });

      this.createSeriesList(this.seriesDataList, {
        unitName: this.config.singleYAxisUnitName
      });
    }
  }

  resizeLegend() {
    document.getElementById(this.config.legendDivId).style.height =
      this.chart.legend.contentHeight + "px";
  }

  dispose() {
    am4core.disposeAllCharts();
  }

  bootstrapChart() {
    this.initiateChart();
    this.createXAxis();
    this.setupAllSeriesAndValueAxes();
    this.createLegend();
    this.createExporting();
    this.handleRtl();
    this.chart.data = this.data;
  }
}

export default BaseChart;
