import styled from "styled-components";

const Styles = styled.div`
  .key-topics-carousel {
    .swiper {
      height: 100%;
      padding-bottom: 50px;
    }
    .swiper-slide {
      height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: var(--primary);
    }
  }
`;
export default Styles;
