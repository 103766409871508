import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .home {
    &__hero {
      ${respondTo.xl`
        height: calc(100vh - var(--top-header-height) - var(--nav-menu-height));
    `};
    }

    &__portal-carousels {
      &__inner {
        width: 90%;
        ${respondTo.md`
            width: 80%
        `};

        ${respondTo.lg`
            width: 75%
        `};

        ${respondTo.xl`
            width : 65%
        `}
      }
    }

    &__help-btn {
      position: fixed;
      top: calc(var(--top-header-height) + var(--nav-menu-height) + 20px);
      right: 10px;
      z-index: 20;
    }

    // override bootstrap container on home page
    .container {
      ${respondTo.xl`
        max-width: 960px;
      `};
      ${respondTo.xxl`
        max-width: 1200px;
      `}
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
      ${respondTo.lg`
        display: block;
      `}
      top: 0;
      height: 100%;
      width: 30px;

      &::after {
        transition: opacity 0.2s ease-in-out;
        visibility: hidden;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      &:hover {
        &::after {
          opacity: 1;
          padding: 5px;
          visibility: visible;
        }
      }
    }
  }
`;

export default Styles;
