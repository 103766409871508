import { ExternalLink, StyledButton } from "@atoms";
import { MAX_PUB_CARD_SUMMARY_CHARS, pageRoutes } from "@constants";
import { RightArrowIcon } from "@icons";
import { LocalizedLink } from "@particles";
import { getDateLocale } from "@utils";
import { format } from "date-fns";
import truncate from "lodash/truncate";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ARABIC, ENGLISH } from "types/languages";
import Styles from "./styles";

const PublicationCard = ({
  image,
  title,
  summary,
  id,
  author,
  displayDate,
  downloadLink,
  publicationEnglishFilesCount,
  publicationArabicFilesCount
}) => {
  const dateLocale = getDateLocale();
  const pubLink = `${id}`;

  return (
    <Styles>
      <div className="publication-card p-3 p-lg-4 d-flex flex-column">
        {author && (
          <h6 className="text-14px font-md text-primary">
            <FormattedMessage
              defaultMessage="Authors: {author}"
              values={{ author: author }}
            />
          </h6>
        )}
        {title && <h2 className="text-25px text-dark font-md mb-3">{title}</h2>}
        <div className="publication-card__img-container">
          <div className="publication-card__img mb-3">
            {image && <img className="w-100" src={image} alt={title || "Publication"} />}
          </div>
        </div>

        {displayDate && (
          <h6 className="text-14px text-dark font-md">
            {format(new Date(displayDate), "PP", { locale: dateLocale })}
          </h6>
        )}
        {summary && (
          <p className="text-16px text-dark font-light">
            {truncate(summary, { length: MAX_PUB_CARD_SUMMARY_CHARS })}
          </p>
        )}

        <div className="d-flex align-items-center justify-content-between my-2">
          <LocalizedLink to={pubLink} className="cursor-pointer">
            <span className="text-14px font-bold text-dark me-3">
              <FormattedMessage defaultMessage="Read more" />
            </span>
            <StyledButton size="lg" circle>
              <RightArrowIcon />
            </StyledButton>
          </LocalizedLink>

          <div className="uppercase ">
            {publicationEnglishFilesCount > 0 && (
              <LocalizedLink
                to={`${pageRoutes.publications}/${pubLink}`}
                lang={ENGLISH}
                target="_blank"
                rel="noopener noreferrer"
                className="font-regular font-rubik">
                En
              </LocalizedLink>
            )}

            {publicationEnglishFilesCount > 0 && publicationArabicFilesCount > 0 && (
              <span className="text-dark">{" | "}</span>
            )}

            {publicationArabicFilesCount > 0 && (
              <LocalizedLink
                to={`${pageRoutes.publications}/${pubLink}`}
                lang={ARABIC}
                target="_blank"
                rel="noopener noreferrer"
                className="font-regular font-rubik">
                Ar
              </LocalizedLink>
            )}
          </div>
        </div>

        {downloadLink && (
          <ExternalLink className="uppercase mt-2">
            {<FormattedMessage defaultMessage="Download PDF" />}
          </ExternalLink>
        )}
      </div>
    </Styles>
  );
};

export default PublicationCard;
