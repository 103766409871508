import styled from "styled-components";

const Styles = styled.div`
  .nav-menu-link {
    text-decoration: none;
    color: ${({ color }) => color || `var(--black)`};
    display: block;
    white-space: ${({ wrap }) => (wrap ? "normal" : "noWrap")};
    &:hover {
      color: ${({ hoverColor }) => hoverColor || `var(--primary)`};
    }
    &.active {
      color: ${({ activeColor, color }) =>
        activeColor === false ? color || `var(--black)` : activeColor || `var(--primary)`};
    }
  }
`;
export default Styles;
