import { toggleLang } from "@utils";
import React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { ENGLISH } from "types/languages";
import Styles from "./styles";

const LanguageButton = () => {
  const { locale: lang } = useIntl();
  const { pathname, search } = useLocation();
  const handleToggleLang = () => toggleLang(lang, pathname + search);
  return (
    <Styles lang={lang}>
      <button
        className="language-button btn btn-link text-white text-12px ps-1 ms-0"
        onClick={handleToggleLang}>
        {lang === ENGLISH ? "عربي" : "English"}
      </button>
    </Styles>
  );
};

export default LanguageButton;
