import edpLogo from "assets/images/edp-logo.png";

import React from "react";
import stylesFactory from "./styles";
import { getCurrentLang } from "@utils";
import { ARABIC } from "types/languages";
import translatedStrings from "./i18n";
import { Document, Page, View, Text, Image, Html } from "@particles";

const AuthorCard = ({ imageUrl, name, bio, style }) => (
  <View style={style.container}>
    {imageUrl && <Image src={imageUrl} alt={name} style={style.image} />}
    <View style={{ flex: 1 }}>
      {name && <Text style={style.name}>{name}</Text>}
      {bio && <Text style={style.bio}>{bio}</Text>}
    </View>
  </View>
);

function getAuthorsSectionTitle(authors) {
  const lang = getCurrentLang();
  if (authors?.length > 1) {
    return translatedStrings.authors[lang];
  }

  return translatedStrings.author[lang];
}

const BlogPDFReport = ({
  image,
  title,
  publishDate,
  description,
  topics,
  authors,
  source
}) => {
  const lang = getCurrentLang();
  const styles = stylesFactory(lang === ARABIC);
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image src={edpLogo} alt="EDP Logo" style={styles.logo} />
        </View>
        <View style={styles.content}>
          <View style={styles.topics}>
            {topics?.map?.((topic, index) => (
              <Text style={styles.topic} key={index}>
                {topic}
              </Text>
            ))}
          </View>
          <Text style={styles.title}>{title}</Text>
          <View>
            <View style={{ ...styles.flexSection, ...styles.meta }}>
              <Text>
                {authors?.map?.((a) => a.name)?.join(lang === ARABIC ? "، " : ", ")}
              </Text>
              {!!authors && !!publishDate && <Text>{", "}</Text>}
              <Text>{publishDate}</Text>
            </View>
          </View>

          <Image src={image} alt={title} style={styles.mainImage} />
          <View>
            <Html style={styles.description} html={description} />

            {source && (
              <>
                <Text style={{ margin: "5pt 0", fontSize: "12pt", fontWeight: 500 }}>
                  {translatedStrings.source[lang]}
                </Text>
                <Html style={styles.description} html={source} />
              </>
            )}
          </View>
          <View wrap={false} style={{ marginTop: 10 }}>
            <Text style={{ margin: "5pt 0", fontSize: "12pt", fontWeight: 500 }}>
              {getAuthorsSectionTitle(authors)}
            </Text>
            {authors?.map?.((author) => (
              <AuthorCard key={author.id} {...author} style={styles.authorCard} />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BlogPDFReport;
