import React, { useMemo, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import Tippy from "@tippyjs/react";

import { CheckButton } from "@atoms";

import Styles from "./styles";

import "tippy.js/dist/tippy.css";
import { checkArrayIncludes } from "@utils";

const SdgOptionsList = ({
  id,
  title,
  tooltip,
  labelProp = "name",
  value: selectedOption,
  values: selectedOptions = [],
  options: defaultOptions = [],
  multi,
  enableSelectAll,
  onChange,
  grouped,
  groupLabelProp,
  search = true,
  disabled
}) => {
  const intl = useIntl();

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (e) => {
    setSearchText(e?.target?.value);
  };

  const options = useMemo(() => {
    if (!search || !searchText) {
      return defaultOptions;
    }

    const searchOption = (option, searchText) =>
      option[labelProp]
        ?.toLocaleUpperCase?.()
        .includes?.(searchText?.toLocaleUpperCase());

    if (searchText) {
      if (!grouped) {
        return defaultOptions?.filter((option) => searchOption(option, searchText));
      } else {
        return defaultOptions?.map((group) => ({
          ...group,
          options: group.options?.filter((option) => searchOption(option, searchText))
        }));
      }
    }
  }, [searchText, search, defaultOptions, labelProp, grouped]);

  const handleMultiChange = (option) => {
    const newSelectedOptions = [...selectedOptions];

    const index = selectedOptions?.findIndex((o) => o.id === option.id);

    const exist = index >= 0;

    if (exist) {
      newSelectedOptions.splice(index, 1);
    } else {
      newSelectedOptions.push(option);
    }

    onChange && onChange(newSelectedOptions);
  };

  const handleSingleSelect = (option) => {
    onChange && onChange(option);
  };

  const handleChange = (option) => {
    if (multi) {
      handleMultiChange(option);
    } else {
      handleSingleSelect(option);
    }
  };

  const isSelected = (optionId) => {
    if (multi) {
      const index = selectedOptions?.findIndex((o) => o.id === optionId);

      return index >= 0;
    } else {
      if (selectedOption) {
        return optionId === selectedOption.id;
      }
    }

    return false;
  };

  const flattenOptions = useMemo(() => {
    if (!grouped) {
      return [].concat(defaultOptions || []);
    }

    return defaultOptions?.flatMap?.((group) => group.options);
  }, [grouped, defaultOptions]);

  const isAllSelected = useMemo(() => {
    if (enableSelectAll) {
      return checkArrayIncludes(selectedOptions, flattenOptions, (option) => option?.id);
    }

    return false;
  }, [enableSelectAll, selectedOptions, flattenOptions]);

  const handleSelectAllChange = () => {
    if (!onChange) return;

    if (isAllSelected) {
      onChange([]);
    } else {
      onChange([...flattenOptions]);
    }
  };

  const noAvailableOptions = useMemo(() => {
    if (!grouped) {
      return !options?.length > 0;
    }

    return (
      !options?.length > 0 ||
      options?.reduce((prev, item) => prev && !item?.options?.length > 0, true)
    );
  }, [grouped, options]);

  return (
    <Styles>
      <div className="sdg-options-list">
        {title && (
          <div className="w-100 p-3 bg-dark text-light uppercase text-14px">{title}</div>
        )}

        {search && (
          <div className="sdg-options-list__search">
            <input
              className="w-100 p-3 bg-white"
              onChange={handleSearchChange}
              placeholder={intl.formatMessage({ defaultMessage: "Search" })}
              type="search"
              value={searchText}
            />
          </div>
        )}

        <div className="py-3 pe-3 bg-white d-flex flex-column flex-1">
          <div className="sdg-options-list__options ps-3 ">
            {enableSelectAll && !noAvailableOptions && (
              <CheckButton
                checked={isAllSelected}
                id={`select-all`}
                label={<FormattedMessage defaultMessage="Select All" />}
                disabled={disabled}
                onChange={handleSelectAllChange}
                type={"checkbox"}
              />
            )}
            <div className={enableSelectAll ? "ps-3" : ""}>
              {noAvailableOptions && (
                <p className="sdg-options-list__empty-msg">
                  <FormattedMessage defaultMessage="No data found" />
                </p>
              )}
              {grouped &&
                options?.map?.(
                  (group, i) =>
                    group?.options?.length > 0 && (
                      <div key={i}>
                        <div className="text-14px uppercase mb-1">
                          {group?.[groupLabelProp]}
                        </div>
                        <div>
                          {group?.options?.map?.((option) => (
                            <Tippy
                              key={option.id}
                              disabled={!tooltip || !tooltip(option)}
                              content={tooltip && tooltip(option)}>
                              <CheckButton
                                checked={isSelected(option.id)}
                                id={`${id}-${option.id}`}
                                key={option.id}
                                label={option[labelProp]}
                                disabled={disabled}
                                onChange={() => handleChange(option)}
                                type={multi ? "checkbox" : "radio"}
                              />
                            </Tippy>
                          ))}
                        </div>
                      </div>
                    )
                )}

              {!grouped &&
                options?.map?.((option) => (
                  <Tippy
                    key={option.id}
                    disabled={!tooltip || !tooltip(option)}
                    content={tooltip && tooltip(option)}>
                    <CheckButton
                      checked={isSelected(option.id)}
                      id={`${id}-${option.id}`}
                      label={option[labelProp]}
                      disabled={disabled}
                      onChange={() => handleChange(option)}
                      type={multi ? "checkbox" : "radio"}
                    />
                  </Tippy>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default React.memo(SdgOptionsList);
