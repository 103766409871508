import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#F26A2B";

const StatisticalSnapshot9 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={{ ...styles.section, padding: "0 10pt" }}>
            <Image
              src={sdgInfo?.snapshotImageURL}
              alt={sdgInfo?.name}
              style={{ width: "100%" }}
            />
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[0] && <Indicator {...indicators[0]} />}
              {indicators[1] && <Indicator {...indicators[1]} />}
            </View>
          </View>

          <View style={{ ...styles.section, ...styles.sectionWithBg, width: "100%" }}>
            <View style={styles.indicatorsContainer}>
              {indicators[2] && <Indicator {...indicators[2]} />}
              {indicators[3] && <Indicator {...indicators[3]} />}
              {indicators[4] && <Indicator {...indicators[4]} />}
              {indicators[5] && <Indicator {...indicators[5]} />}
            </View>
          </View>

          <View style={styles.main}>
            <View style={{ ...styles.section, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[6] && <Indicator {...indicators[6]} iconWidth="2.34cm" />}
                {indicators[7] && <Indicator {...indicators[7]} iconWidth="2.34cm" />}
                {indicators[8] && <Indicator {...indicators[8]} iconWidth="2.34cm" />}
              </View>
            </View>

            <View style={{ ...styles.section, ...styles.sectionWithBg, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[11] && <Indicator {...indicators[11]} />}
                {indicators[12] && <Indicator {...indicators[12]} />}
                {indicators[13] && <Indicator {...indicators[13]} />}
              </View>
            </View>
          </View>

          <View style={styles.side}>
            {indicators[9] && <Indicator {...indicators[9]} stretch={false} />}
            <View style={styles.hr} />
            {indicators[10] && <Indicator {...indicators[10]} stretch={false} />}
            <View style={styles.hr} />
            {indicators[14] && <Indicator {...indicators[14]} stretch={false} />}
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot9;
