import React from "react";

import { FormattedMessage } from "react-intl";

import { SdgPageTemplate } from "@templates";
import { LocalizationCharts, LocalizationDetails } from "@organisms";
import { SdgButton } from "@atoms";

import { ChartIcon, GovernorateIcon } from "@icons";
import { useTabNavigation } from "@hooks";

const contentType = {
  snapshots: "snapshots",
  charts: "charts"
};

const tabs = Object.values(contentType);

const TabsComponent = ({ tab, handleTabClick }) => (
  <>
    <SdgButton
      variant="light"
      active={tab !== contentType.charts}
      onClick={() => handleTabClick(contentType.snapshots)}>
      <GovernorateIcon className="me-2 text-28px" />
      <FormattedMessage defaultMessage="Explore Governorate Snapshots" />
    </SdgButton>

    <SdgButton
      variant="light"
      active={tab === contentType.charts}
      onClick={() => handleTabClick(contentType.charts)}>
      <ChartIcon className="me-2 text-28px" />
      <FormattedMessage defaultMessage="Build Chart" />
    </SdgButton>
  </>
);
const Localization = () => {
  const { tab, handleTabClick } = useTabNavigation(tabs);

  return (
    <SdgPageTemplate
      seoTitle="Local Development"
      title={<FormattedMessage defaultMessage="Local Development" />}
      tabsComponent={() => <TabsComponent tab={tab} handleTabClick={handleTabClick} />}>
      {tab === contentType.snapshots ? <LocalizationDetails /> : <LocalizationCharts />}
    </SdgPageTemplate>
  );
};

export default Localization;
