import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#DE1767";

const StatisticalSnapshot10 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.main}>
            <View style={{ ...styles.section, padding: "0 10pt" }}>
              <Image
                src={sdgInfo?.snapshotImageURL}
                alt={sdgInfo?.name}
                style={{ width: "100%" }}
              />
            </View>

            <View style={styles.section}>
              <View style={{ ...styles.indicatorsContainer, padding: "0 20pt" }}>
                {indicators[0] && <Indicator {...indicators[0]} />}
              </View>
            </View>

            <View style={{ ...styles.section, ...styles.sectionWithBg, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[3] && <Indicator {...indicators[3]} />}
                {indicators[4] && <Indicator {...indicators[4]} />}
                {indicators[5] && <Indicator {...indicators[5]} />}
              </View>
            </View>

            <View style={{ ...styles.section, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[7] && <Indicator {...indicators[7]} />}
                {indicators[8] && <Indicator {...indicators[8]} />}
                {indicators[9] && <Indicator {...indicators[9]} />}
              </View>
            </View>

            <View style={{ ...styles.section, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[11] && (
                  <View
                    style={{ ...styles.sectionWithBg, display: "block", width: "62%" }}>
                    <Image
                      src={indicators[11].imgUrl}
                      style={{ width: "6.4cm", height: "3.2cm", objectFit: "cover" }}
                    />
                    <View style={styles.indicatorsContainer}>
                      <Indicator {...indicators[11]} imgUrl={null} />
                    </View>
                  </View>
                )}
                {indicators[12] && (
                  <View style={{ width: "35%" }}>
                    <Indicator {...indicators[12]} iconWidth="2.34cm" />
                  </View>
                )}
              </View>
            </View>
          </View>

          <View style={styles.side}>
            {indicators[1] && <Indicator {...indicators[1]} stretch={false} />}
            <View style={styles.hr} />
            {indicators[2] && <Indicator {...indicators[2]} stretch={false} />}
            <View style={styles.hr} />
            {indicators[6] && <Indicator {...indicators[6]} stretch={false} />}
            {indicators[10] && <Indicator {...indicators[10]} stretch={false} />}
            {indicators[13] && <Indicator {...indicators[13]} stretch={false} />}
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot10;
