import React, { useContext } from "react";
import stylesFactory from "./styles";

import defaultEdpLogo from "assets/images/edp-logo-white.png";
import { ThemeContext } from "../theme-provider";
import SocialLinks from "../social-links";
import Sources from "../sources";
import { Document, Image, Link, Page, Text, View } from "@particles";

const borderColors = [
  "#fe0025",
  "#d29f29",
  "#32934c",
  "#e31486",
  "#f03f2b",
  "#01aed8",
  "#fcb712",
  "#8f1739",
  "#f36d27",
  "#e21385",
  "#fa9d24",
  "#ce8c2b",
  "#48743f",
  "#007cba",
  "#3db049",
  "#03548c",
  "#193567"
];

const GovSnapshotTemplate = ({
  name,
  subTitle,
  iconURL,
  code,
  children,
  socialLinks,
  sources,
  iconWidth = "1.5cm",
  edpLogo = defaultEdpLogo,
  isShowHeaderColoredBorder,
  styles: customStyles = {}
}) => {
  const { themeColor } = useContext(ThemeContext);
  const styles = stylesFactory(themeColor, customStyles);

  return (
    <Document>
      <Page size={"A4"} style={styles.page}>
        <View style={styles.header}>
          <Image src={edpLogo} alt="EDP Logo" style={{ width: "4.16cm" }} />
          <View
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}>
            <View
              style={{
                display: "flex",
                width: "75%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <Image
                src={iconURL}
                alt={name}
                style={{
                  width: iconWidth,
                  marginRight: 10,
                  marginLeft: 20,
                  flexShrink: 0
                }}
              />
              <View
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}>
                {code && (
                  <Text
                    style={{ fontSize: "42pt", fontWeight: "semibold", marginBottom: 0 }}>
                    {code}
                  </Text>
                )}
                <View>
                  <Text style={styles.mainTitle}>{name}</Text>
                  {subTitle && <Text style={styles.subTitle}>{subTitle}</Text>}
                </View>
              </View>
            </View>
          </View>
        </View>

        {isShowHeaderColoredBorder && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%"
            }}>
            {borderColors.map((color, i) => (
              <View
                key={i}
                style={{
                  backgroundColor: color,
                  height: "5pt",
                  flex: 1
                }}
              />
            ))}
          </View>
        )}

        <View style={styles.container}>
          <View style={styles.body}>{children}</View>

          <View>
            <View style={styles.hr} />

            <Sources sources={sources} />
          </View>
        </View>

        <View style={styles.footer}>
          <Image src={defaultEdpLogo} alt="EDP Logo" style={{ width: "2.17cm" }} />
          <Link
            href={`${window.location.protocol}//${window.location.host}`}
            style={{ color: "white", fontSize: "7pt", fontWeight: "bold" }}>
            {window.location.host}
          </Link>

          <SocialLinks links={socialLinks} />
        </View>
      </Page>
    </Document>
  );
};

export default GovSnapshotTemplate;
