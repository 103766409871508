import React from "react";
import Styles from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { HeroItem } from "@molecules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";

const swiperProps = {
  modules: [Autoplay, Navigation, Pagination],
  navigation: true,
  pagination: {
    clickable: true
  },
  autoplay: {
    delay: 5000
  }
};

const Hero = ({ dir = "ltr", items }) => {
  swiperProps.pagination.renderBullet = (index, className) => {
    return `<span class=${className}><span class="title">${items?.[index]?.title}</span></span>`;
  };

  return (
    <Styles>
      <div className="hero">
        <Swiper {...swiperProps} dir={dir}>
          {items?.map?.((item, index) => (
            <SwiperSlide key={index}>
              <HeroItem {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Styles>
  );
};

export default Hero;
