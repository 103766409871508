import React from "react";
import { StyledSvg } from "@atoms";

const ChartArrowDownIcon = (props) => {
  return (
    <StyledSvg
      {...props}
      strokeWidth="0"
      viewBox="0 0 255.118 198.425"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="1em"
      height="0.77em"
      enableBackground="new 0 0 255.118 198.425"
      xmlXpace="preserve">
      <g>
        <polygon
          fill="currentColor"
          points="53.827,127.392 47.56,133.782 53.827,127.487 	"
        />
        <polygon fill="none" points="129.132,153.345 128.662,153.817 128.668,153.823 	" />
        <polygon
          fill="currentColor"
          points="128.64,153.8 128.661,153.817 128.662,153.817 	"
        />
        <polyline
          fill="currentColor"
          points="201.542,152.61 228.283,157.624 228.283,197.378 201.542,197.378 	"
        />
        <polygon
          fill="currentColor"
          points="53.828,197.378 27.087,197.378 27.087,54.317 53.828,81.093 	"
        />
        <polyline
          fill="currentColor"
          points="131.046,56.734 141.056,67.426 141.056,197.378 114.314,197.378 114.314,165.085 114.314,73.5 	
		"
        />
        <polyline
          fill="currentColor"
          points="184.669,197.378 157.928,197.378 157.928,84.505 184.669,110.867 	"
        />
        <polygon
          fill="currentColor"
          points="70.701,197.378 97.442,197.378 97.442,90.59 80.537,107.393 70.701,97.683 	"
        />
        <g>
          <rect
            x="-7.354"
            y="37.266"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 46.5836 -20.1043)"
            fill="currentColor"
            width="109.829"
            height="17.827"
          />

          <rect
            x="61.032"
            y="44.205"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.6149 90.2663)"
            fill="currentColor"
            width="89.245"
            height="17.827"
          />

          <rect
            x="104.126"
            y="62.13"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 101.2532 -102.3591)"
            fill="currentColor"
            width="140.117"
            height="17.827"
          />
          <polyline
            fill="currentColor"
            points="203.038,141.19 252.691,150.134 243.749,100.483 		"
          />
        </g>
      </g>
    </StyledSvg>
  );
};

export default ChartArrowDownIcon;
