import isArabic from "helpers/isArabic";

const stylesFactory = function (themeColor) {
  const isAr = isArabic();
  const paddingEnd = isAr ? "paddingLeft" : "paddingRight";
  const borderStart = isAr ? "borderRight" : "borderLeft";
  return {
    main: {
      // width: "75%",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      [paddingEnd]: "0.5cm"
    },
    side: {
      width: "22%",
      display: "flex",
      padding: "0 0.5cm",
      [borderStart]: "1pt solid #c0c0c0",
      marginBottom: 0,
      position: "relative"
    },
    section: {
      justifyContent: "center",
      // marginBottom: "0.5cm",
      paddingBottom: "0.5cm",
      width: "48%",
      flexDirection: "column",
      display: "flex"
    },
    indicatorsContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between"
    },
    sectionWithBg: {
      backgroundColor: "#EDEDED",
      padding: "20pt",
      display: "flex",
      alignItems: "center"
    },
    hr: {
      position: "absolute",
      bottom: 0,
      width: "90%",
      margin: "0 5%",
      height: 1,
      backgroundColor: "#C4C4C3",
      opacity: "0.3"
    }
  };
};

export default stylesFactory;
