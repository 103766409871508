import React from "react";
import { Image, Link, View } from "@particles";

const SocialLinks = ({ links }) => {
  return (
    <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      {links?.length > 0 &&
        links.map((link, i) => (
          <Link key={i} href={link.href} style={{ margin: "0 3pt", color: "white" }}>
            <Image
              src={link.snapshotIcon}
              style={{
                width: "0.5cm",
                height: "0.5cm",
                objectFit: "contain"
              }}
            />
          </Link>
        ))}
    </View>
  );
};

export default SocialLinks;
