import styled from "styled-components";

const Styles = styled.div`
  .share-component {
    &__btn {
      border: solid 1px var(--greyLight) !important;
      border-radius: 50%;
      padding: 0;
      width: 3rem;
      height: 3rem;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
export default Styles;
