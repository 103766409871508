import { breakpoints } from "@constants";
import styled from "styled-components";

const Styles = styled.div`
  .impact-counter {
    .text-50px {
      @media (max-width: ${breakpoints.sm}) {
        font-size: 2rem;
      }
      @media (max-width: ${breakpoints.md}) {
        font-size: 2.5rem;
      }
    }
  }
`;
export default Styles;
