import React, { useContext } from "react";
import stylesFactory from "./styles";

import edpLogo from "assets/images/edp-logo-white.png";
import { ThemeContext } from "../theme-provider";
import SocialLinks from "../social-links";
import Sources from "../sources";
import { Document, Image, Link, Page, Text, View } from "@particles";
import { FormattedMessage } from "react-intl";

const GovSnapshotTemplate = ({
  name,
  iconURL,
  snapshotHeaderImgUrl,
  children,
  socialLinks,
  sources,
  iconWidth = "1.2cm"
}) => {
  const { themeColor } = useContext(ThemeContext);
  const styles = stylesFactory(themeColor);

  return (
    <Document>
      <Page size={"A4"} style={styles.page}>
        <View style={styles.header}>
          {snapshotHeaderImgUrl && (
            <Image src={snapshotHeaderImgUrl} alt={name} style={styles.headerBg} />
          )}
          {snapshotHeaderImgUrl && <View style={{ ...styles.headerBg }} />}

          <View style={styles.headerContent}>
            <Image src={edpLogo} alt="EDP Logo" style={{ width: "4.16cm" }} />
            <View
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "0 20pt"
              }}>
              {/* <Image
                src={iconURL}
                alt={name}
                style={{
                  width: iconWidth,
                  marginRight: 10,
                  marginLeft: 20,
                  flexShrink: 0
                }}
              /> */}
              <View style={styles.titleContainer}>
                {name && <Text style={styles.mainTitle}>{name}</Text>}

                <Text style={styles.mainTitle}>
                  <FormattedMessage defaultMessage="governorate" />
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.container}>
          <View style={styles.body}>{children}</View>

          <View>
            <View style={styles.hr} />

            <Sources sources={sources} />
          </View>
        </View>

        <View style={styles.footer}>
          <Image src={edpLogo} alt="EDP Logo" style={{ width: "2.17cm" }} />
          <Link
            href={`${window.location.protocol}//${window.location.host}`}
            style={{ color: "white", fontSize: "7pt", fontWeight: "bold" }}>
            {window.location.host}
          </Link>

          <SocialLinks links={socialLinks} />
        </View>
      </Page>
    </Document>
  );
};

export default GovSnapshotTemplate;
