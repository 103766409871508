import { breakpoints } from "@constants";
import { useMediaQuery } from "@hooks";
import React from "react";
import IconLoader from "../icon-loader";
import Styles from "./styles";

const GovsSliderLoader = () => {
  // const isXl = useMediaQuery(`min-width: ${breakpoints.xl}`);
  const isLg = useMediaQuery(`max-width: ${breakpoints.lg}`);
  const isSm = useMediaQuery(`max-width: ${breakpoints.sm}`);

  let cardsCount = 6;

  if (isSm) {
    cardsCount = 2;
  } else if (isLg) {
    cardsCount = 4;
  }

  const govLoaders = Array(cardsCount).fill(0);

  return (
    <Styles>
      {govLoaders.map((_, i) => (
        <IconLoader key={i} />
      ))}
    </Styles>
  );
};

export default GovsSliderLoader;
