import React from "react";
import styled from "styled-components";
import {
  Document as DocumentPdf,
  Page as PagePdf,
  View as ViewPdf,
  Image as ImagePdf,
  Text as TextPdf,
  Link as LinkPdf,
  Font
} from "@react-pdf/renderer";
import { getCurrentLang } from "@utils";
import { ARABIC } from "types/languages";
import HTMLComponent from "../html-component";
import { default as HtmlPdf } from "react-pdf-html";

import rubik_regular from "assets/fonts/Rubik-Regular.ttf";
import rubik_light from "assets/fonts/Rubik-Light.ttf";
import rubik_medium from "assets/fonts/Rubik-Medium.ttf";
import rubik_italic from "assets/fonts/Rubik-Italic.ttf";
import rubik_semibold from "assets/fonts/Rubik-SemiBold.ttf";
import rubik_bold from "assets/fonts/Rubik-Bold.ttf";
import rubik_black from "assets/fonts/Rubik-Black.ttf";

Font.register({
  family: "Rubik",
  fonts: [
    { src: rubik_italic, fontStyle: "italic" },
    { src: rubik_light, fontWeight: 300 },
    { src: rubik_regular, fontWeight: 400 },
    { src: rubik_medium, fontWeight: 500 },
    { src: rubik_semibold, fontWeight: 600 },
    { src: rubik_bold, fontWeight: 700 },
    { src: rubik_black, fontWeight: 800 }
  ]
});

const DocumentPdfAr = styled.div``;

const PagePdfAr = styled.div``;

const ViewPdfAr = styled.div``;

const ImagePdfAr = styled.img``;

const TextPdfAr = styled.p`
  margin-bottom: 0;
`;

const LinkPdfAr = styled.a``;

export const Document = (props) => {
  const lang = getCurrentLang();
  return lang === ARABIC ? <DocumentPdfAr {...props} /> : <DocumentPdf {...props} />;
};

export const Page = (props) => {
  const lang = getCurrentLang();
  return lang === ARABIC ? <PagePdfAr {...props} /> : <PagePdf {...props} />;
};

export const View = (props) => {
  const lang = getCurrentLang();
  return lang === ARABIC ? <ViewPdfAr {...props} /> : <ViewPdf {...props} />;
};

export const Image = (props) => {
  const lang = getCurrentLang();
  return lang === ARABIC ? (
    <ImagePdfAr {...props} />
  ) : props.src || props.source ? (
    <ImagePdf {...props} />
  ) : (
    <></>
  );
};

export const Text = (props) => {
  const lang = getCurrentLang();
  return lang === ARABIC ? <TextPdfAr {...props} /> : <TextPdf {...props} />;
};

export const Link = (props) => {
  const lang = getCurrentLang();
  return lang === ARABIC ? (
    <LinkPdfAr {...props} />
  ) : (
    <LinkPdf {...props} src={props.href} />
  );
};

export const Html = (props) => {
  const lang = getCurrentLang();
  return lang === ARABIC ? (
    <HTMLComponent {...props} />
  ) : (
    <HtmlPdf {...props}>{props.html}</HtmlPdf>
  );
};
