import { TRANSITION_DURATION } from "@constants";
import styled, { css } from "styled-components";

const transitionStyles = {
  entering: css`
    transform: scale(1);
  `,
  entered: css`
    transform: scale(1);
  `,
  exiting: css`
    transform: scale(0);
  `,
  exited: css`
    transform: scale(0);
  `
};

const Styles = styled.div`
  width: 100%;
  height: 100%;
  transition: transform ${(props) => props.duration || TRANSITION_DURATION}ms ease-in-out;
  transform: scale(0);
  ${(props) => transitionStyles[props.state]}
`;
export default Styles;
