import LanguageProvider from "./i18n";
import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { DEFAULT_LANG } from "types/languages";

const LocalizedRouter = ({ children, defaultLanguage = DEFAULT_LANG }) => {
  return (
    <Router>
      <Routes>
        <Route
          path="/:lang/*"
          element={<LanguageProvider>{children}</LanguageProvider>}
        />

        <Route path="*" element={<Navigate to={`/${defaultLanguage}/`} />} />
      </Routes>
    </Router>
  );
};

export default LocalizedRouter;
