import instance from "./instance";

export const endpoints = {
  allSdgs: `Web/SDGS/All`,
  sdgsData: `Web/SDGS/GetSDGsData`,
  indicatorData: `IndicatorValues/GetIndicatorData`,
  covidSdgs: `Web/SDGS/GetCovidSDGs`,
  covidData: `Web/SDGS/GetCovidData`,
  textVar: `Web/TextVars/GetTextVar`,
  sdgReport: `Web/SDGS/GetSdgReport`,
  availabilityReport: `Web/SDGS/GetAvailabilityReport`,
  snapshotData: `Web/Snapshotindicators/GetSDGSnapshotIndicators`
};

export const sdgsApi = {
  getAllSdgs: () => instance.get(endpoints.allSdgs),
  getSdgsData: () => instance.get(endpoints.sdgsData),
  getIndicatorData: (data) => instance.post(endpoints.indicatorData, data),
  getCovidSdgs: () => instance.get(endpoints.covidSdgs),
  getCovidData: () => instance.get(endpoints.covidData),
  getTextVar: (key) =>
    instance.get(endpoints.textVar, {
      params: {
        key
      }
    }),
  getSdgReport: (SDGId) =>
    instance.get(endpoints.sdgReport, {
      params: {
        SDGId
      }
    }),
  getAvailabilityReport: (SDGId) =>
    instance.get(endpoints.availabilityReport, {
      params: {
        SDGId
      }
    }),
  getStatisticalSnapshotData: (SDGId) =>
    instance.get(endpoints.snapshotData, {
      params: {
        SDGId
      }
    })
};

export default sdgsApi;
