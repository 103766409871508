/* eslint-disable jsx-a11y/img-redundant-alt */
import { AppTemplate } from "@templates";
import React from "react";
import { format } from "date-fns";
import Styles from "./styles";
import { FormattedMessage } from "react-intl";
import { ShareComponent } from "@molecules";
import { HTMLComponent, SpinnerLoader } from "@particles";
import { ExternalLink, BibliographicDetails } from "@atoms";
// import { ARABIC } from "types/languages";
import { getDateLocale } from "@utils";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { publicationsApi } from "api";
import isEmpty from "lodash/isEmpty";
import { Alert } from "react-bootstrap";
import MediaType from "types/media-type";
import { theme } from "@constants";

const PublicationDetails = () => {
  const { id } = useParams();

  const { isLoading, data } = useQuery(["publication", "details", id], () =>
    publicationsApi.getDetails(id)
  );

  const publication = data?.data ?? {};

  const {
    mediaFiles,
    // authors,
    title,
    secondaryTitle,
    publishDate,
    description,
    topics,
    sDGDs,
    source,
    hasOtherlanguageFiles
  } = publication;

  const image = mediaFiles?.find?.((file) => file.mediaType === MediaType.image);

  const downloadableFiles = mediaFiles?.filter?.(
    (file) => file.mediaType === MediaType.file
  );

  // const locale = useIntl().locale;

  const isEmptyPublication = isEmpty(publication);

  return (
    <AppTemplate title={title || "Publication Detail"}>
      <Styles>
        <div className="publication-details bg-white">
          <div className="publication-details__content mx-auto py-5">
            {isLoading && <SpinnerLoader />}
            {!isLoading && isEmptyPublication && (
              <Alert>
                <FormattedMessage defaultMessage="something went wrong fetching this publication" />
              </Alert>
            )}
            {!isLoading && !isEmptyPublication && (
              <div className="w-100 row">
                <div className="col-12 col-md-6">
                  {image?.filePath && (
                    <div className="publication-details__img mb-3">
                      <img
                        className="w-100 h-100"
                        src={image?.filePath}
                        alt={image?.caption}
                      />
                    </div>
                  )}

                  <div className="w-100 d-flex justify-content-between">
                    <div>
                      <h6 className="font-md text-dark text-14px mb-2">
                        <FormattedMessage defaultMessage="Share" />
                      </h6>
                      <ShareComponent title={title} />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  {/* {authors?.length > 0 && (
                    <h6 className="text-14px font-md text-primary">
                      <FormattedMessage
                        defaultMessage="Authors: {authors}"
                        values={{
                          authors: authors
                            ?.map?.((a) => a.name)
                            ?.join(locale === ARABIC ? "، " : ", ")
                        }}
                      />
                    </h6>
                  )} */}

                  {title && <h2 className="text-50px text-dark font-md mb-3">{title}</h2>}

                  {secondaryTitle && (
                    <p className="text-16px text-dark font-light mb-3">
                      {secondaryTitle}
                    </p>
                  )}

                  {description && (
                    <HTMLComponent
                      className="publication-details__description text-16px text-dark font-light mb-4"
                      html={description}
                    />
                  )}

                  {(downloadableFiles?.length > 0 || hasOtherlanguageFiles) && (
                    <div>
                      <h4 className="text-22px font-md text-primary mb-3">
                        <FormattedMessage defaultMessage="Download Files" />
                      </h4>
                      {downloadableFiles?.length > 0 && (
                        <ul>
                          {downloadableFiles.map?.((file, i) => (
                            <li key={i}>
                              <ExternalLink
                                color={theme.colors.dark}
                                href={file.filePath}
                                className="font-md">
                                {file.caption || (
                                  <FormattedMessage
                                    defaultMessage="File {index}"
                                    values={{ index: i + 1 }}
                                  />
                                )}
                              </ExternalLink>
                            </li>
                          ))}
                        </ul>
                      )}

                      {downloadableFiles?.length === 0 && (
                        <p className="mb-2 font-md text-greyLight">
                          <FormattedMessage defaultMessage="This report is only available in Arabic version" />
                        </p>
                      )}
                    </div>
                  )}

                  <h4 className="text-22px font-md text-primary mb-3">
                    <FormattedMessage defaultMessage="Bibliographic information" />
                  </h4>

                  {publishDate && (
                    <BibliographicDetails
                      title={<FormattedMessage defaultMessage="Publish Date" />}
                      data={format(new Date(publishDate), "PP", {
                        locale: getDateLocale()
                      })}
                    />
                  )}

                  {topics?.length > 0 && (
                    <BibliographicDetails
                      title={<FormattedMessage defaultMessage="Topics" />}
                      data={topics?.map?.((t) => t.name)}
                    />
                  )}

                  {sDGDs?.length > 0 && (
                    <BibliographicDetails
                      title={<FormattedMessage defaultMessage="SDG" />}
                      data={sDGDs?.map?.((s) => s.name)}
                    />
                  )}

                  {source && (
                    <BibliographicDetails
                      title={<FormattedMessage defaultMessage="Source" />}
                      data={source}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default PublicationDetails;
