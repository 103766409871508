import React from "react";
import Styles from "./styles";
import { ExternalLink } from "@atoms";
import { theme } from "@constants";

import cardPlaceholder from "assets/images/card-placeholder.png";
import { HTMLComponent } from "@particles";

const RelatedLinkCard = ({ id, name, summary, link, linkName, imageUrl }) => {
  let urlHostName;
  if (link) {
    try {
      urlHostName = new URL(link).hostname;
    } catch (error) {
      urlHostName = link;
    }
  }

  return (
    <Styles>
      <div className="related-link-card d-flex flex-column w-100 h-100 bg-white p-3 p-lg-4">
        <div className="related-link-card__img-container">
          <div className="related-link-card__img mb-3">
            <img src={imageUrl || cardPlaceholder} alt={name || "Article"} />
          </div>
        </div>
        <div className="flex-grow-1 d-flex flex-column">
          {name && (
            <h2 className="related-link-card__title text-dark font-md text-25px mb-1">
              {name}
            </h2>
          )}
          {summary && (
            <HTMLComponent
              className="text-16px flex-grow-1 font-light text-dark mb-1"
              html={summary}
            />
          )}
          <hr className="my-2 my-xxl-4" />
          <div className="w-100 mb-2">
            {link && (
              <ExternalLink
                className="related-link-card__link italic text-14px font-semibold w-100"
                href={link}
                color={theme.colors.light}>
                {linkName || urlHostName}
              </ExternalLink>
            )}
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default RelatedLinkCard;
