import { ExternalLink } from "@atoms";
import { LocalizedLink } from "@particles";
import React from "react";

const GenericLink = ({ link, children, className = "" }) => {
  if (link?.startsWith("http")) {
    return (
      <ExternalLink className={className} href={link}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <LocalizedLink className={className} to={link}>
      {children}
    </LocalizedLink>
  );
};

export default GenericLink;
