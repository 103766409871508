import React, { useEffect, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import { AppTemplate } from "@templates";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import Styles, { Hero } from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { keyTopicsApi } from "api";
import { KeyTopicDetails } from "@organisms";
import { SpinnerLoader } from "@particles";
import { ARABIC } from "types/languages";
import { Route, Routes, useParams } from "react-router-dom";
import { useLocalizedNavigate } from "@hooks";
import { theme } from "@constants";

const swiperProps = {
  modules: [Navigation],
  navigation: true,
  slidesPerView: 2,
  spaceBetween: 10,
  loop: false,
  breakpoints: {
    576: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 10
    },
    1200: {
      slidesPerView: 6,
      spaceBetween: 10
    }
  }
};

const KeyTopics = () => {
  const navigate = useLocalizedNavigate();
  const params = useParams();

  const id = params["*"];

  const { isLoading, data } = useQuery(["key-topics"], keyTopicsApi.getAll);

  const keyTopics = useMemo(() => data?.data || [], [data]);

  const handleChangeSelectedTopic = (id) => {
    id && navigate(String(id));
  };

  useEffect(() => {
    if (keyTopics?.length > 0 && !id) {
      handleChangeSelectedTopic(keyTopics?.[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyTopics, id]);

  const locale = useIntl().locale;
  const dir = locale === ARABIC ? "rtl" : "ltr";

  return (
    <AppTemplate title={"Key topics"}>
      <Styles>
        <div className="key-topics">
          {isLoading && <SpinnerLoader />}
          {!isLoading && (
            <>
              <Hero>
                <div className="swiper-container position-relative">
                  <h2>
                    <FormattedMessage defaultMessage="Key Topics" />
                  </h2>
                  {keyTopics?.length > 0 && (
                    <Swiper {...swiperProps} dir={dir}>
                      {keyTopics?.map?.((topic, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div
                              className="cursor-pointer"
                              role="button"
                              tabIndex={0}
                              onClick={() => handleChangeSelectedTopic(topic.id)}
                              onKeyPress={() => handleChangeSelectedTopic(topic.id)}>
                              <div className="w-100 key-topics__slider-icon">
                                <div
                                  lang={locale}
                                  className="w-100 h-100"
                                  style={{
                                    backgroundColor: topic.color || theme.colors.primary
                                  }}>
                                  {topic.iconUrl && (
                                    <img
                                      className="w-50 mb-3"
                                      src={topic.iconUrl}
                                      alt={topic.name}
                                    />
                                  )}
                                  <span className="text-center uppercase font-md text-white topic-title">
                                    {topic.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  )}
                </div>
              </Hero>
              <div className="details">
                <Routes>
                  <Route path=":id" element={<KeyTopicDetails />} />
                </Routes>
              </div>
            </>
          )}
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default KeyTopics;
