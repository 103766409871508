import { ExternalLink } from "@atoms";
import styled from "styled-components";
import { ARABIC, ENGLISH } from "types/languages";

const Styles = styled.div`
  width: 100%;
  .covid-impact-card {
    position: relative;
    width: 100%;
    border-bottom: solid 5px var(--primary);
    background-color: white;
    &__img {
      width: 100%;
      padding: 0;
      padding-bottom: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        position: absolute;
        top: 0;
      }
    }

    &__img-placeholder {
      background-color: ${(props) => props.iconColor};
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 10px;
    }

    &__close-btn {
      outline: none;
      font-size: 1.6rem;
      color: var(--black);
      border: none;
      appearance: none;
      position: absolute;
      background: transparent;
      top: 5px;

      &:focus {
        outline: none;
      }

      &:lang(${ENGLISH}) {
        right: 5px;
      }
      &:lang(${ARABIC}) {
        left: 5px;
      }
    }

    .row {
      margin: 0;
    }

    &__desc {
      font-style: italic;
    }

    ${ExternalLink} {
      padding: 0;
      font-style: italic;
      font-weight: 600;
    }
  }
`;
export default Styles;
