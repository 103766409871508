const stylesFactory = function (themeColor) {
  return {
    section: {
      justifyContent: "center",
      marginBottom: "0.5cm",
      width: "48%",
      flexDirection: "column",
      display: "flex"
    },
    indicatorsContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between"
    },
    sectionWithBg: {
      backgroundColor: "#EDEDED",
      padding: "10pt",
      display: "flex",
      alignItems: "center"
    }
  };
};

export default stylesFactory;
