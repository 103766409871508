import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import uniqueId from "lodash/uniqueId";
import { ChartsViewer, SdgSummary, SdgOptionsList } from "@organisms";
import Styles from "./styles";
import { sdgsApi } from "api";
import { getChartData, loadChartsDataWithProp } from "../sdg-charts/helpers";
import { useQuery } from "react-query";
import { SpinnerLoader, toastError, toastInfo } from "@particles";
import isArabic from "helpers/isArabic";

import uniq from "lodash/uniq";
import { mergeExcelRowCellsWithSameValue } from "../charts-viewer/helpers";
import cloneDeep from "lodash/cloneDeep";

function checkIsValidValue(value) {
  return !!value || value === 0;
}

const getCovidScenarios = (isArabic) => [
  {
    scenId: 1,
    label: isArabic ? "فعلي" : "Actual",
    key: "value"
  },
  {
    scenId: 2,
    label: isArabic ? "بدون تأثير كوفيد" : "No Covid",
    key: "noCovidValue"
  },
  { scenId: 3, label: isArabic ? "مع تأثير كوفيد" : "Covid", key: "covidValue" },
  {
    scenId: 4,
    label: isArabic ? "دفعة أهداف التنمية المستدامة" : "SDG Push",
    key: "sdgpushValue"
  }
];

const delimiter = " - ";

const getMappedScenarios = (entity, idKey) => {
  const covidScenarios = getCovidScenarios(isArabic());
  return {
    name: entity.name,
    options: covidScenarios.map((scenario) => ({
      ...scenario,
      name: `${entity.name}${delimiter}${scenario.label}`,
      id: uniqueId("s-"),
      [idKey]: entity.id
    }))
  };
};

const summaryTextVarKey = "Covid";
const guidelinesTextVarKey = "CovidGuidelines";
const disclaimerTextVarKey = "CovidDisclaimer";
const scenariosNoteTextVarKey = "ScenariosNote";

const Covid19ImpactCharts = () => {
  const [selectedSdgGoal, setSelectedSdgGoal] = useState();
  const [selectedIndicator, setSelectedIndicator] = useState();
  const [selectedScenarios, setSelectedScenarios] = useState([]);

  const [chartsDataYears, setChartsDataYears] = useState([]);

  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  const { isLoading, data } = useQuery("covid-data", sdgsApi.getCovidData);

  const sdgOptions = data?.data?.data || [];

  const scenarioOptions = useMemo(() => {
    const countries = data?.data?.countries;
    const regions = data?.data?.regions;

    if (!selectedIndicator || (!countries && !regions)) {
      return [];
    }

    const options = [];

    if (countries?.length > 0) {
      options.push(
        ...countries.map((country) => getMappedScenarios(country, "countryId"))
      );
    }

    if (regions?.length > 0) {
      options.push(...regions.map((region) => getMappedScenarios(region, "regionId")));
    }

    return options;
  }, [data, selectedIndicator]);

  useEffect(() => {
    const addData = () => {
      if (!selectedScenarios?.length > 0) return;

      const isAnyScenarioWithoutData = selectedScenarios.some(
        (scenario) => !scenario.data
      );

      if (!isAnyScenarioWithoutData) {
        return;
      }

      const selectedCountries = new Set(),
        selectedRegions = new Set();

      selectedScenarios.forEach((scenario) => {
        if (scenario.countryId) {
          selectedCountries.add(scenario.countryId);
        } else if (scenario.regionId) {
          selectedRegions.add(scenario.regionId);
        }
      });

      if (selectedCountries?.size > 0 || selectedRegions?.size > 0) {
        const body = {
          IndicatorId: selectedIndicator.id,
          Countries: Array.from(selectedCountries),
          Regions: Array.from(selectedRegions)
        };

        setIsChartDataLoading(true);
        sdgsApi
          .getIndicatorData(body)
          .then((res) => {
            const years = res?.data?.years || [];
            if (years.length > 0) {
              setChartsDataYears(years);
            }

            const data = res?.data?.values || [];

            if (data?.length > 0) {
              let newSelectedScenarios = cloneDeep(selectedScenarios);

              newSelectedScenarios = newSelectedScenarios.map((scenario) => {
                const newScenario = {
                  ...scenario,
                  data: []
                };

                // get scenario data
                data.forEach((element) => {
                  if (
                    (element.countryId === newScenario.countryId ||
                      element.regionId === newScenario.regionId) &&
                    checkIsValidValue(element[newScenario.key])
                  ) {
                    newScenario.data.push({
                      year: element.year,
                      value: element[newScenario.key],
                      note: element.note,
                      targetValue: element.targetValue
                    });
                  }
                });

                return newScenario;
              });

              newSelectedScenarios = newSelectedScenarios.filter((scenario) => {
                if (!scenario.data?.length > 0) {
                  toastInfo(
                    <FormattedMessage
                      defaultMessage="There are no data found for {name}"
                      values={{
                        name: scenario.name
                      }}
                    />
                  );

                  return false;
                }

                return true;
              });

              setSelectedScenarios(newSelectedScenarios);
            } else {
              setSelectedScenarios((prev) => (prev.length > 0 ? [] : prev));
              toastInfo(
                <FormattedMessage defaultMessage="There are no data found for the selected scenarios" />
              );
            }
          })
          .catch(() => {
            setSelectedScenarios((prev) => (prev.length > 0 ? [] : prev));
            toastError(<FormattedMessage defaultMessage="Something went wrong!" />);
          })
          .finally(() => setIsChartDataLoading(false));
      }
    };

    addData();
  }, [selectedScenarios, selectedIndicator]);

  useEffect(() => {
    if (!selectedScenarios.length > 0) {
      setChartsDataYears((prev) => (prev?.length > 0 ? [] : prev));
    }
  }, [selectedScenarios]);

  const onResetChart = useCallback(() => {
    setSelectedSdgGoal(null);
    setSelectedIndicator(null);
    setSelectedScenarios([]);
  }, []);

  const intl = useIntl();

  const handleExcelDataSheet = useCallback(
    (sheet, chartsData, series) => {
      const keys = Object.keys(chartsData?.[0]);
      const seriesNames = series.map((s) => s.name).sort();

      const countriesAndRegions = [];
      const scenarios = [];

      seriesNames.forEach((name) => {
        const [countryRegion, scenario] = name ? name.split(delimiter) : [];
        countriesAndRegions.push(countryRegion);
        scenarios.push(scenario);
      });

      const dataColumns = seriesNames.map((name, index) => ({
        key: name,
        name: scenarios[index]
      }));

      const uniqueCountriesAndRegions = uniq(countriesAndRegions);
      uniqueCountriesAndRegions.forEach((cr) => {
        const index = dataColumns
          .map((col) => col.key.split(delimiter)[0])
          .lastIndexOf(cr);

        dataColumns.splice(index + 1, 0, {
          key: `${cr}-note`,
          name: intl.formatMessage({ defaultMessage: "Comment" })
        });
      });

      sheet.columns = [{ key: keys[0] }, ...dataColumns];

      const countriesAndRegionsRowData = [...countriesAndRegions];
      uniqueCountriesAndRegions.forEach((cr) => {
        const lastIndex = countriesAndRegionsRowData.lastIndexOf(cr);
        countriesAndRegionsRowData.splice(lastIndex + 1, 0, cr);
      });

      const row = sheet.addRow([
        intl.formatMessage({ defaultMessage: "Country/Region" }),
        ...countriesAndRegionsRowData
      ]);

      const indicatorsRow = sheet.addRow([
        intl.formatMessage({
          defaultMessage: "Indicator"
        }),
        ...Array(countriesAndRegionsRowData.length).fill(selectedIndicator.name)
      ]);

      // merge columns with same country or region
      mergeExcelRowCellsWithSameValue(sheet, row, indicatorsRow);

      const lastHeaderRow = sheet.addRow([
        intl.formatMessage({ defaultMessage: "Scenario" }),
        ...dataColumns.map(({ name }) => name)
      ]);

      const chartsDataWithNotes = loadChartsDataWithProp(
        "note",
        chartsData,
        series,
        (serieName) => `${serieName.split(delimiter)[0]}-note`
      );

      sheet.addRows(chartsDataWithNotes);

      return lastHeaderRow.number;
    },
    [intl, selectedIndicator?.name]
  );

  const indicatorsMetadata = useMemo(
    () => (selectedIndicator ? [selectedIndicator] : []),
    [selectedIndicator]
  );

  const handleChangeSdgGoal = useCallback((newSdgGoal) => {
    setSelectedSdgGoal(newSdgGoal);
    setSelectedIndicator(null);
    setSelectedScenarios([]);
  }, []);

  const handleChangeIndicator = useCallback((newInd) => {
    setSelectedIndicator(newInd);
    setSelectedScenarios([]);
  }, []);

  return (
    <Styles>
      <div className="covid19-impact-charts w-100">
        {isLoading && <SpinnerLoader />}

        <SdgSummary textVarKey={summaryTextVarKey} />

        <div className="w-100 pt-5">
          <div className="d-block">
            <div className="covid19-impact-charts__options">
              <SdgOptionsList
                id="select-sdg"
                title={intl.formatMessage({
                  defaultMessage: "Select an SDG"
                })}
                options={sdgOptions}
                search={false}
                value={selectedSdgGoal}
                onChange={handleChangeSdgGoal}
              />

              {selectedSdgGoal && (
                <SdgOptionsList
                  id="select-indicator"
                  title={intl.formatMessage({ defaultMessage: "Select an indicator" })}
                  options={selectedSdgGoal?.indicators}
                  labelProp="name"
                  search={false}
                  value={selectedIndicator}
                  onChange={handleChangeIndicator}
                />
              )}

              {selectedIndicator && (
                <SdgOptionsList
                  id="select-scenario"
                  title={intl.formatMessage({ defaultMessage: "Select scenarios" })}
                  options={scenarioOptions}
                  search={false}
                  values={selectedScenarios}
                  onChange={setSelectedScenarios}
                  multi
                  grouped
                  groupLabelProp={"name"}
                  labelProp="label"
                  disabled={isChartDataLoading}
                />
              )}
            </div>

            <div className="covid19-impact-charts__chart-container">
              <div className="covid19-impact-charts__chart">
                <ChartsViewer
                  id="covide19"
                  indicators={selectedScenarios}
                  isLoading={isChartDataLoading}
                  title={selectedSdgGoal?.name}
                  emptyChartMsg={intl.formatMessage({
                    defaultMessage:
                      "Please select the goal, indicator,  and the scenarios to show thier charts"
                  })}
                  onResetChart={onResetChart}
                  indicatorsMetadata={indicatorsMetadata}
                  guidelinesTextVarKey={guidelinesTextVarKey}
                  disclaimerTextVarKey={disclaimerTextVarKey}
                  scenariosNoteTextVarKey={scenariosNoteTextVarKey}
                  handleExcelDataSheet={handleExcelDataSheet}
                  chartsDataYears={chartsDataYears}
                  getChartData={getChartData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default Covid19ImpactCharts;
