export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_COUNTRY_ID = "SET_COUNTRYID";
export const SET_CHECK_ERROR = "SET_CHECK_ERROR";

export const MenusTypes = Object.freeze({
  FETCH_UPPER_MENUS_SUCCESS: "FETCH_UPPER_MENUS_SUCCESS",
  FETCH_BOTTOM_MENUS_SUCCESS: "FETCH_BOTTOM_MENUS_SUCCESS",
  FETCH_MENUS_SUCCESS: "FETCH_MENUS_SUCCESS"
});
export const SearchTypes = Object.freeze({
  SET_SEARCH: "SET_SEARCH"
});
