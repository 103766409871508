import React from "react";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon
} from "@icons";

const socialLinksData = {
  linkedin: { href: "", icon: <LinkedinIcon /> },
  instagram: { href: "", icon: <InstagramIcon /> },
  facebook: { href: "", icon: <FacebookIcon /> },
  twitter: { href: "", icon: <TwitterIcon /> },
  youtube: { href: "", icon: <YoutubeIcon /> }
};

export default socialLinksData;
