import { breakpoints, theme } from "@constants";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";
import { GovernorateButtonStatus } from ".";

const Styles = styled.div`
  .localization-details {
    &__slider {
      .swiper-container {
        position: relative;

        ${respondTo.md`
          margin: 0 -20px;
          padding: 0 20px;
          `}
      }

      .swiper {
        background: white;
        height: 100%;
        padding: 1rem;
        padding-bottom: 60px;
        position: unset;
      }
      .swiper-slide {
        height: auto;
      }

      .swiper-button-next,
      .swiper-rtl .swiper-button-prev {
        right: 20%;
        left: auto;

        ${respondTo.md`
          right: -12px;
        `}
      }

      .swiper-button-prev,
      .swiper-rtl .swiper-button-next {
        left: 20%;
        ${respondTo.md`
          left: -12px;
        `}
        right: auto;
      }

      .swiper-pagination {
        bottom: 20px;

        &-bullet-active {
          background-color: var(--primary);
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        color: var(--primary);
        border: none;
        appearance: none;
        background-color: transparent;

        @media (max-width: ${breakpoints.md}) {
          top: unset;
          bottom: 0px;
        }

        &:after {
          font-size: 2rem;

          ${respondTo.xl`
            font-size: 3rem;
          `}
        }
      }
    }
  }
`;

export const GovernorateButton = styled.button`
  width: 100%;
  padding-top: 100%;
  border: none;
  opacity: ${({ status }) => (status === GovernorateButtonStatus.inactive ? "0.4" : "1")};
  position: relative;
  transition: opacity 0.3s;
  background-color: ${(props) => (props.color ? props.color : theme.colors.greyLight)};

  img,
  span {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    object-fit: cover;
  }
`;

export default Styles;
