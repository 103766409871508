import React from "react";
import { sanitizeHtml } from "./helpers";
import Styles from "./styles";

const HTMLComponent = ({ html, className, ...props }) => {
  const cleanHtml = sanitizeHtml(html);

  return (
    <Styles
      {...props}
      dangerouslySetInnerHTML={{ __html: cleanHtml }}
      className={className}></Styles>
  );
};

export default HTMLComponent;
