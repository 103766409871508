import React from "react";
import ReactDomServer from "react-dom/server";
import { generatePdfFromHtml } from "@utils";
import { BlogPDFReport } from "@particles";

function generateBlogReport(blog, cb) {
  const el = ReactDomServer.renderToStaticMarkup(<BlogPDFReport {...blog} />);

  generatePdfFromHtml(el, blog?.title, {
    cb,
    useJsPdf: false
  });
}

export default generateBlogReport;
