import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#F99E26";

const StatisticalSnapshot11 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.main}>
            <View style={{ ...styles.topSection, ...styles.mainContainer }}>
              <View style={{ ...styles.section, width: "100%" }}>
                <Image
                  src={sdgInfo?.snapshotImageURL}
                  alt={sdgInfo?.name}
                  style={{ width: "100%" }}
                />
              </View>

              <View style={{ ...styles.section, width: "100%" }}>
                <View style={styles.indicatorsContainer}>
                  {indicators[2] && <Indicator {...indicators[2]} />}
                  {indicators[3] && <Indicator {...indicators[3]} />}
                  {indicators[4] && <Indicator {...indicators[4]} />}
                </View>
              </View>
            </View>

            <View style={styles.mainContainer}>
              <View
                style={{
                  ...styles.section,
                  ...styles.sectionWithBg,
                  padding: "15pt",
                  width: "100%"
                }}>
                <View style={styles.indicatorsContainer}>
                  {indicators[8] && (
                    <Image
                      src={indicators[8].imgUrl}
                      style={{ width: "50%", flexShrink: 0 }}
                    />
                  )}
                  <View
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 15pt",
                      flexDirection: "column"
                    }}>
                    {indicators[6] && (
                      <Indicator {...indicators[6]} imgGrow stretch={false} />
                    )}
                    {indicators[8] && (
                      <Indicator {...indicators[8]} imgUrl={null} stretch={false} />
                    )}
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.side}>
            {indicators[0] && (
              <View style={{ marginBottom: "1cm" }}>
                <Indicator {...indicators[0]} stretch={false} />
              </View>
            )}
            {indicators[1] && (
              <View style={{ marginBottom: "1cm" }}>
                <Indicator {...indicators[1]} stretch={false} />
              </View>
            )}
            {indicators[5] && (
              <View style={{ marginBottom: "1cm" }}>
                <Indicator {...indicators[5]} stretch={false} iconWidth="2.34cm" />
              </View>
            )}
            {indicators[7] && (
              <View style={{ marginBottom: "1cm" }}>
                <Indicator {...indicators[7]} stretch={false} iconWidth="2.34cm" />
              </View>
            )}
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot11;
