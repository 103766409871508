import { MenusTypes } from "../actions/types";

const initialState = {
  menusLoading: true,
  upperMenus: [],
  bottomMenus: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MenusTypes.FETCH_UPPER_MENUS_SUCCESS:
      return {
        ...state,
        upperMenus: action.payload.upperMenus
      };

    case MenusTypes.FETCH_BOTTOM_MENUS_SUCCESS:
      return {
        ...state,
        bottomMenus: action.payload.bottomMenus
      };

    case MenusTypes.FETCH_MENUS_SUCCESS:
      return {
        ...state,
        menusLoading: false
      };

    default:
      return state;
  }
}
