import styled from "styled-components";
import dataVisBg from "assets/images/data-vis-bg.jpg";

const Styles = styled.div`
  .data-visulization {
    background-color: white;
    background-image: url(${dataVisBg});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export default Styles;
