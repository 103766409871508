import styled from "styled-components";

const Styles = styled.button`
  width: 100%;
  height: 100%;
  appearance: none;
  border: none;
  background-color: ${(props) => props.color || `var(--secondary)`};
  padding: 0;
`;
export default Styles;
