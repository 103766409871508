import http from "api/instance";

import { endpoints } from "api/menus-api";

import { MenusTypes } from "./types";

export const fetchUpperMenusSuccess = (upperMenus) => ({
  type: MenusTypes.FETCH_UPPER_MENUS_SUCCESS,
  payload: { upperMenus }
});

export const fetchBottomMenusSuccess = (bottomMenus) => ({
  type: MenusTypes.FETCH_BOTTOM_MENUS_SUCCESS,
  payload: { bottomMenus }
});

export const fetchMenusSuccess = () => ({
  type: MenusTypes.FETCH_MENUS_SUCCESS
});

export const fetchMenus = () => {
  return (dispatch) => {
    (async () => {
      const [firstResponse, secondResponse] = await Promise.all([
        http.get(endpoints.menu, { params: { location: 1 } }),
        http.get(endpoints.menu, { params: { location: 2 } })
      ]);

      await dispatch(fetchUpperMenusSuccess(firstResponse.data));
      await dispatch(fetchBottomMenusSuccess(secondResponse.data));
      await dispatch(fetchMenusSuccess());
    })();
  };
};
