import React from "react";
import { StyledSvg } from "@atoms";

const TargetIcon = (props) => {
  return (
    <StyledSvg
      {...props}
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10"></circle>
      <circle cx="12" cy="12" r="6"></circle>
      <circle cx="12" cy="12" r="2"></circle>
    </StyledSvg>
  );
};

export default TargetIcon;
