import { CovidImpactCard } from "@molecules";
import { IconLoader, ScaleTransition } from "@particles";
import { sdgsApi } from "api";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import { useQuery } from "react-query";
import Styles, {
  gridResponsiveColumnsCount,
  ImpactButton,
  ImpactDetails
} from "./styles";

export const impactButtonStatus = {
  idle: 0,
  active: 1,
  inactive: 2
};

const getGridColumnsCount = () => {
  let columnsCount = gridResponsiveColumnsCount[0].value;
  gridResponsiveColumnsCount
    .slice()
    .reverse()
    .some((count) => {
      const breakpoint = Number(count.breakpoint.replace("px", ""));
      if (window.innerWidth >= breakpoint) {
        columnsCount = count.value;
        return true;
      }
      return false;
    });
  return columnsCount;
};

const sdgsLoaders = Array(17)
  .fill(0)
  .map((_, i) => <IconLoader key={i} />);

const Covid19ImpactDetails = () => {
  const [activeButtonIndex, setActiveButtonIndex] = useState();

  const { isLoading, data, isError } = useQuery("sdgs-covid", sdgsApi.getCovidSdgs);
  const covidSdgs = data?.data || [];

  const activeItem = covidSdgs?.[activeButtonIndex] || {};

  const getButtonStatus = (i) => {
    if (typeof activeButtonIndex !== "number") {
      return impactButtonStatus.idle;
    }
    if (activeButtonIndex === i) {
      return impactButtonStatus.active;
    }
    return impactButtonStatus.inactive;
  };

  let impactDetailsCardGridRowNumber = 0;
  if (typeof activeButtonIndex === "number") {
    // place the details card in the row next to the clicked item
    impactDetailsCardGridRowNumber =
      Math.ceil((activeButtonIndex + 1) / getGridColumnsCount()) + 1;
  }

  const handleActivateSdg = (i) => {
    // retrigger the transition effect
    setActiveButtonIndex(() => {
      setTimeout(() => {
        setActiveButtonIndex(i);
      }, 100);
      return null;
    });
  };

  return (
    <Styles>
      <div className="covid19-impact-details w-100 py-3 py-xxl-5">
        {isError && (
          <div className="container my-5">
            <Alert>
              <FormattedMessage defaultMessage="Something went wrong. Please try again in a few moments" />
            </Alert>
          </div>
        )}
        {!isError && (
          <div className="covid19-impact-details__grid w-100">
            {!isLoading &&
              covidSdgs?.map?.((sdg, i) => (
                <ImpactButton
                  key={i}
                  color={sdg.color}
                  onClick={() => handleActivateSdg(i)}
                  status={getButtonStatus(i)}>
                  {sdg.iconUrl && (
                    <img className="w-100" src={sdg.iconUrl} alt={sdg.name} />
                  )}

                  {!sdg.iconUrl && <span>{sdg.name}</span>}
                </ImpactButton>
              ))}

            {isLoading && sdgsLoaders}

            <ImpactDetails rowNumber={impactDetailsCardGridRowNumber}>
              <ScaleTransition inProp={typeof activeButtonIndex === "number"}>
                <OutsideClickHandler onOutsideClick={() => setActiveButtonIndex(null)}>
                  <CovidImpactCard
                    {...activeItem}
                    onClose={() => setActiveButtonIndex(null)}
                  />
                </OutsideClickHandler>
              </ScaleTransition>
            </ImpactDetails>
          </div>
        )}
      </div>
    </Styles>
  );
};

export default Covid19ImpactDetails;
