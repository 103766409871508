import styled from "styled-components";
import edpLogo from "assets/images/edp-logo.png";

const EdpLogo = styled.img.attrs((props) => ({
  src: edpLogo,
  alt: "EDP Logo",
  width: "100%"
}))``;

export default EdpLogo;
