import { HTMLComponent } from "@particles";
import { sdgsApi } from "api";
import React from "react";
import { useQuery } from "react-query";
import Styles from "./styles";
import ContentLoader from "react-content-loader";
import { theme } from "@constants";

const TextLoader = () => (
  <div className="w-50 d-flex mx-auto">
    <ContentLoader
      speed={2}
      width={"100%"}
      foregroundColor={theme.colors.primary}
      viewBox="0 0 200 40">
      <rect x="0" y="0" rx="3" ry="3" width="410" height="4" />
      <rect x="0" y="10" rx="3" ry="3" width="380" height="4" />
      <rect x="0" y="20" rx="3" ry="3" width="178" height="4" />
    </ContentLoader>
  </div>
);

const SdgSummary = ({ textVarKey }) => {
  const { isLoading, data: text } = useQuery(["text-var", textVarKey], () =>
    sdgsApi.getTextVar(textVarKey)
  );

  return (
    <Styles>
      {isLoading ? (
        <TextLoader />
      ) : (
        <HTMLComponent
          className={"sdg-summary px-lg-5 text-center text-dark font-light text-16px"}
          html={text?.data?.data}
        />
      )}
    </Styles>
  );
};

export default React.memo(SdgSummary);
