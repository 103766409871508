import isArabic from "helpers/isArabic";

const stylesFactory = function (themeColor) {
  const isAr = isArabic();
  const paddingEnd = isAr ? "paddingLeft" : "paddingRight";
  const borderEnd = isAr ? "borderLeft" : "borderRight";

  return {
    main: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "70%"
    },
    mainContainer: {
      [paddingEnd]: "0.5cm",
      width: "100%"
    },
    side: {
      width: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "0 0.5cm"
    },
    section: {
      justifyContent: "center",
      marginBottom: "1.5cm",
      width: "48%",
      flexDirection: "column",
      display: "flex"
    },
    indicatorsContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between"
    },
    sectionWithBg: {
      backgroundColor: "#EDEDED",
      padding: "10pt",
      display: "flex",
      alignItems: "center"
    },
    topSection: {
      [borderEnd]: "1pt solid #c0c0c0"
    }
  };
};

export default stylesFactory;
