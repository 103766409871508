import { StyledButton, Tag } from "@atoms";
import { useSearch } from "@hooks";
import { RightArrowIcon, SearchIcon } from "@icons";
import { SpinnerLoader } from "@particles";
import { GenericeNewTabLink } from "@molecules";
import { AppTemplate } from "@templates";
import { homeApi } from "api";
import React, { useEffect, useRef } from "react";
import { Alert, FormControl, InputGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Content, Hero } from "./styles";
import debounce from "lodash/debounce";

const debounceTime = 700;

export default function SearchResults() {
  const inputRef = useRef();
  const intl = useIntl();
  const { search, searchParam, handleSearchSubmit, handleSearchChange, setSearchState } =
    useSearch();

  const [searchParams] = useSearchParams();
  const keyword = searchParams.get(searchParam);

  useEffect(() => {
    const handleSearch = debounce(handleSearchSubmit, debounceTime);

    search && handleSearch();

    return handleSearch.cancel;
  }, [handleSearchSubmit, search]);

  useEffect(() => {
    if (search !== keyword) {
      setSearchState(keyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    return () => {
      setSearchState("");
    };
  }, [setSearchState]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const { isLoading, data } = useQuery(["search", keyword], () =>
    homeApi.searchAll(keyword)
  );

  const searchResults = data?.data;

  return (
    <AppTemplate title={`Search: ${keyword}`} searchBar={false}>
      <div className="data-highlights d-flex flex-column">
        <Hero>
          <div className="container">
            <h2>
              {keyword ? (
                <FormattedMessage
                  defaultMessage="Search results for: {keyword}"
                  values={{ keyword }}
                />
              ) : (
                <FormattedMessage defaultMessage="Search" />
              )}
            </h2>
            <InputGroup className="mb-3">
              <FormControl
                ref={inputRef}
                value={search}
                onChange={handleSearchChange}
                placeholder={intl.formatMessage({
                  defaultMessage: "Search for"
                })}
                aria-label="Search for"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text itemType="search" id="basic-addon2">
                <div className="search-icon">
                  <SearchIcon width="1.5rem" />
                </div>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Hero>
        <Content>
          {isLoading && <SpinnerLoader />}
          {!isLoading && searchResults?.length > 0 && (
            <ul className="flex-1 d-flex flex-column justify-content-center">
              {searchResults?.map?.((item, i) => {
                return (
                  <li
                    key={i}
                    className={`py-4 d-flex flex-column justify-content-center ${
                      searchResults.length > 1 ? "flex-1" : ""
                    }`}>
                    <div className="container text-dark">
                      <Tag>{item.type}</Tag>
                      <h3 className="my-4">{item.title}</h3>

                      {item.link && (
                        <GenericeNewTabLink link={item.link}>
                          <span className="text-14px font-bold text-dark me-3">
                            <FormattedMessage defaultMessage="Read more" />
                          </span>
                          <StyledButton circle>
                            <RightArrowIcon />
                          </StyledButton>
                        </GenericeNewTabLink>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}

          {!isLoading && searchResults?.length === 0 && (
            <div className="container my-5">
              <Alert>
                <FormattedMessage
                  defaultMessage="No search results for: {keyword}"
                  values={{ keyword }}
                />
              </Alert>
            </div>
          )}
        </Content>
      </div>
    </AppTemplate>
  );
}
