export const colors = [
  "#0000ff",
  "#00ff00",
  "#ff0000",
  "#ff00ff",
  "#556b2f",
  "#a0522d",
  "#2e8b57",
  "#8b0000",
  "#808000",
  "#483d8b",
  "#5f9ea0",
  "#4682b4",
  "#d2691e",
  "#9acd32",
  "#00008b",
  "#32cd32",
  "#daa520",
  "#8fbc8f",
  "#800080",
  "#b03060",
  "#9932cc",
  "#ff4500",
  "#ff8c00",
  "#ffd700",
  "#ffff00",
  "#c71585",
  "#0000cd",
  "#deb887",
  "#00fa9a",
  "#dc143c",
  "#00ffff",
  "#f4a460",
  "#a020f0",
  "#f08080",
  "#adff2f",
  "#ff6347",
  "#f0e68c",
  "#6495ed",
  "#dda0dd",
  "#90ee90",
  "#7b68ee",
  "#afeeee",
  "#ee82ee",
  "#87cefa",
  "#7fffd4",
  "#ff69b4",
  "#ffb6c1"
];
export const fallbackColor = "#000000";
