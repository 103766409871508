import styled from "styled-components";

const Styles = styled.div`
  .charts-years-filter {
    width: 300px;
    padding: 1rem;
    background-color: var(--light);
    border-radius: 0.5rem;

    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
      gap: 1rem;
    }
  }
`;

export const YearButton = styled.button`
  width: 100%;
  appearance: none;
  border: none;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  padding: 0.3rem;
  background-color: ${(props) => (props.active ? "var(--primary)" : "var(--light)")};
  color: ${(props) => (props.active ? "white" : "var(--dark)")};

  &:hover {
    color: ${(props) => (props.active ? "white" : "var(--primary)")};
  }
  transition-property: background-color, color;
  transition: 0.2s;
`;

export const CustomYearButton = styled(YearButton)`
  background-color: ${(props) => (props.active ? "var(--primary)" : "white")};
`;

export const FrequenciesListStyles = styled.div`
  box-shadow: -2px 0px 17px 0px rgba(0, 0, 0, 0.49);
  width: 100px;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
`;
export default Styles;
