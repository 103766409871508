import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const LocalizedLink = ({ to, lang, ...props }) => {
  const intl = useIntl();
  const locale = lang ?? intl.locale;

  if (!to) return null;

  let link = to;
  if (to?.startsWith("/")) {
    link = `/${locale + to}`;
  }
  return <Link {...props} to={link} />;
};

export default LocalizedLink;
