import React from "react";
import { StyledSvg } from "@atoms";

const SdgsCircleIcon = (props) => {
  return (
    <StyledSvg
      {...props}
      strokeWidth="0"
      viewBox="0 0 152.3 152.3"
      x="0px"
      y="0px"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M41.32,56.17,12.24,41.77l.28-.48A74.47,74.47,0,0,1,26.07,23.65l.38-.38L48.28,47.21l-.28.28a44.23,44.23,0,0,0-6.39,8.2ZM13.57,41.29,40.94,54.93a42.64,42.64,0,0,1,6-7.72L26.35,24.7A72.32,72.32,0,0,0,13.57,41.29Z" />
      <path d="M41.8,58,10.33,42.34l1.05-1.71A75.3,75.3,0,0,1,25.11,22.7l1.34-1.34L50.1,47.3l-1.24,1.24a43.69,43.69,0,0,0-6.2,7.92ZM15.48,40.72l25,12.49a38.75,38.75,0,0,1,4.68-6L26.26,26.61A74.43,74.43,0,0,0,15.48,40.72Z" />
      <path d="M102.07,45.39l-.38-.28a39.72,39.72,0,0,0-8.78-5.53l-.38-.19L104.17,9.06l.47.19a75.21,75.21,0,0,1,19,11.83l.38.28Zm-8.39-6.48a42.79,42.79,0,0,1,8.2,5.15l20.6-22.51A71.62,71.62,0,0,0,104.55,10.4Z" />
      <path d="M102.26,47.21l-1.34-1.05a38.79,38.79,0,0,0-8.48-5.34l-1.53-.77L103.4,7.34l1.72.67a77.25,77.25,0,0,1,19.36,12L126,21.17Zm-6.87-9a39.94,39.94,0,0,1,6.39,4l18.69-20.6a67.11,67.11,0,0,0-15.26-9.44Z" />
      <path d="M116.09,70.66l-.1-.47a38.73,38.73,0,0,0-2.76-9.82L113,60l29-14.49.19.48a70.55,70.55,0,0,1,6,21.17l.1.47Zm-1.82-10.2a38.23,38.23,0,0,1,2.58,9.25l30.42-2.86a71.3,71.3,0,0,0-5.72-19.93Z" />
      <path d="M115,72.09l-.29-1.71a38.53,38.53,0,0,0-2.67-9.54l-.76-1.52,31.47-15.74.76,1.81a73.21,73.21,0,0,1,6.1,21.46l.38,1.91Zm.95-11a43.13,43.13,0,0,1,2,7.15l27.75-2.57a71.29,71.29,0,0,0-4.86-16.88Z" />
      <path d="M111.41,56.74l-.19-.38a42.5,42.5,0,0,0-6.2-8.2l-.28-.29,21.93-23.93.38.38A70.83,70.83,0,0,1,140.31,42l.19.48Zm-5.43-9a45.63,45.63,0,0,1,5.81,7.72l27.37-13.63a70.62,70.62,0,0,0-12.49-16.6Z" />
      <path d="M110.84,58.55l-.76-1.62a39.31,39.31,0,0,0-6-7.82L102.93,48,126.67,22,128,23.37a71.49,71.49,0,0,1,13.44,17.92l.1.1.66,1.62Zm-3-10.77a55.85,55.85,0,0,1,4.48,5.91l25-12.49a67,67,0,0,0-10.59-14Z" />
      <path d="M5.85,94.22l-.1-.48A72.47,72.47,0,0,1,3.56,76.2c0-1.53.1-3.06.19-4.77V71l32.33,2.86v.48c0,.67-.1,1.24-.1,1.91a38.21,38.21,0,0,0,1,8.67l.09.48ZM4.61,72c-.1,1.43-.1,2.86-.1,4.2A71.23,71.23,0,0,0,6.52,93l29.37-8.39A42,42,0,0,1,35,76.2V74.76Z" />
      <path d="M4.8,95.94,4.42,94A76.05,76.05,0,0,1,2.23,76.2c0-1.44.09-2.87.19-4.3V69.52l35,3.15v1.71a4.15,4.15,0,0,1-.09,1.05v.86a34.15,34.15,0,0,0,1,8.39l.38,1.72Zm1-22.51V76.2A72.78,72.78,0,0,0,7.47,91.26l26.8-7.63a39,39,0,0,1-.67-7.43V76Z" />
      <path d="M116.47,136.65,99.4,109l.38-.29a38.12,38.12,0,0,0,7.53-7.15l.29-.38,25.84,19.55-.28.38a72.46,72.46,0,0,1-16.31,15.16Zm-15.83-27.36,16,26A70.49,70.49,0,0,0,132,121l-24.41-18.4A38.78,38.78,0,0,1,100.64,109.29Z" />
      <path d="M116.18,138.85,97.59,108.71l1.33-1a35.19,35.19,0,0,0,7.34-7l1-1.43,28,21.17-1,1.43a74.1,74.1,0,0,1-16.5,15.36Zm-13.73-29.28L117,133.32a75.23,75.23,0,0,0,13.06-12.11l-22.31-16.79A33.79,33.79,0,0,1,102.45,109.57Z" />
      <path d="M146.6,94.12l-31.18-9,.09-.48a41.61,41.61,0,0,0,1-8.58V74.19l32.32-3v.48c.1,1.52.1,2.95.1,4.48a76.35,76.35,0,0,1-2.1,17.45Zm-30.13-9.63L145.84,93a70.57,70.57,0,0,0,2-16.78c0-1.24,0-2.58-.09-3.91l-30.42,2.86v1A41,41,0,0,1,116.47,84.49Z" />
      <path d="M147.46,95.74,113.8,86.11l.38-1.71a38.16,38.16,0,0,0,.95-8.3V73l35-3.33v2c.1,1.52.1,2.95.1,4.48a82.88,82.88,0,0,1-2.1,17.74v.09ZM118,83.54l26.8,7.72a71.41,71.41,0,0,0,1.62-15.06V73.72l-27.75,2.57A36.43,36.43,0,0,1,118,83.54Z" />
      <path d="M36.75,137.13l-.39-.29a74.63,74.63,0,0,1-16.49-15l-.29-.38,25.84-19.64.29.38a42,42,0,0,0,7.72,7.06l.39.28ZM20.82,121.68A72.89,72.89,0,0,0,36.36,135.8l16-25.94a39.51,39.51,0,0,1-7.25-6.58Z" />
      <path d="M37.13,139.13,35.6,138a76.27,76.27,0,0,1-16.69-15.16l-1.14-1.53,28-21.26L47,101.47a41.73,41.73,0,0,0,7.44,6.77l1.43,1.05Zm-14.4-17.26a71,71,0,0,0,13.16,12l14.59-23.65a49.23,49.23,0,0,1-5.63-5Z" />
      <path d="M36.46,70.28,4.13,67.42l.1-.47a70.31,70.31,0,0,1,6.29-21.46l.19-.48,29,14.5-.28.28a40.29,40.29,0,0,0-3,10ZM5.18,66.47,35.6,69.23a43.78,43.78,0,0,1,2.77-9.34L11.09,46.25A71.85,71.85,0,0,0,5.18,66.47Z" />
      <path d="M37.79,71.71,2.51,68.57l.38-2A71,71,0,0,1,9.28,44.82L10,43.11l32,16-1.44,1.43A38.53,38.53,0,0,0,37.7,69.9v1.81ZM6.71,65.23l27.75,2.48a47.27,47.27,0,0,1,2.1-7.25l-24.8-12.4A72.46,72.46,0,0,0,6.71,65.23Z" />
      <path d="M92,147.05l-6-32,.48-.09A39.62,39.62,0,0,0,96,111.1l.39-.19,17.07,27.56-.39.28a71.63,71.63,0,0,1-20.5,8.2ZM87.1,115.87l5.62,30a72,72,0,0,0,19.36-7.72L96,112.24A42,42,0,0,1,87.1,115.87Z" />
      <path d="M90.91,148.57l-6.48-34.52,1.81-.38A38.13,38.13,0,0,0,95.39,110l1.53-.76,18.4,29.66L113.8,140a76,76,0,0,1-20.89,8.39Zm-2.29-31.75,5.15,27.46a71.77,71.77,0,0,0,16.4-6.58L95.49,114.05A53.85,53.85,0,0,1,88.62,116.82Z" />
      <path d="M135.64,117.87,109.7,98.32l.28-.38a43.38,43.38,0,0,0,4.39-9l.19-.47,31.18,9-.19.48A73.71,73.71,0,0,1,136,117.39ZM111,98.13l24.41,18.4a72.54,72.54,0,0,0,9.06-18.31l-29.37-8.39A51.63,51.63,0,0,1,111,98.13Z" />
      <path d="M135.83,119.78l-28-21.17,1-1.44A42.32,42.32,0,0,0,113,88.59L113.7,87l33.86,9.63-.77,1.81a74.28,74.28,0,0,1-9.72,19.74l-.48.58Zm-23-22,22.32,16.78a71.06,71.06,0,0,0,7.63-15.45L116,91.45A63.61,63.61,0,0,1,112.84,97.75Z" />
      <path d="M76.23,148.76A80.05,80.05,0,0,1,65.54,148l-.47-.09,6-31.95.47.1A38.81,38.81,0,0,0,82,115.87l.48-.1,6,31.85-.48.1A62.85,62.85,0,0,1,76.23,148.76Zm-10-1.62A71.17,71.17,0,0,0,87.38,147l-5.62-30a37.58,37.58,0,0,1-9.83.1Z" />
      <path d="M76.23,150.1a89.64,89.64,0,0,1-10.88-.76l-1.9-.38,6.48-34.62,1.72.38a39.46,39.46,0,0,0,10.11-.19l1.81-.38,6.48,34.52-1.81.38A66.87,66.87,0,0,1,76.23,150.1Zm-8.4-4.1a67,67,0,0,0,18-.19l-5.15-27.37a40.31,40.31,0,0,1-7.63.1Z" />
      <path d="M89.19,38.05,88.72,38a39.56,39.56,0,0,0-9.92-1.91h-.48V3.53h.48a72.59,72.59,0,0,1,21.55,4.1l.48.19Zm-9.91-3a39.69,39.69,0,0,1,9.34,1.82l11-28.52A70.64,70.64,0,0,0,79.28,4.58Z" />
      <path d="M90.05,39.58l-1.71-.38a39.07,39.07,0,0,0-9.54-1.82H77.08V2.2H78.9a73.73,73.73,0,0,1,22,4.19l1.72.67Zm-9.44-5.72a33.41,33.41,0,0,1,7.15,1.43l10-26A69.24,69.24,0,0,0,80.61,6Z" />
      <path d="M61.54,147.33l-.48-.09a72.39,72.39,0,0,1-20.88-8l-.38-.29,17.07-27.56.38.19a36.44,36.44,0,0,0,9.92,3.72l.47.1Zm-20.41-8.67a73.67,73.67,0,0,0,19.65,7.53l5.62-30a40,40,0,0,1-9.25-3.53Z" />
      <path d="M62.59,149l-1.81-.39a72.73,72.73,0,0,1-21.27-8.2l-.19-.09L38,139.23l18.4-29.66,1.53.76A36.62,36.62,0,0,0,67.55,114l1.81.38ZM43,138.18a70.33,70.33,0,0,0,16.69,6.39l5.15-27.37a39.73,39.73,0,0,1-7.15-2.76Z" />
      <path d="M63.73,38,52,7.63l.48-.19a71.24,71.24,0,0,1,21.84-4h.47V35.86h-.47a41.71,41.71,0,0,0-10.21,1.81ZM53.24,8.3,64.31,36.81a40.65,40.65,0,0,1,9.53-1.72V4.48A71.62,71.62,0,0,0,53.24,8.3Z" />
      <path d="M63.16,40.15,50.29,6.87,52,6.2A71.7,71.7,0,0,1,74.32,2.1h1.91V37.19H74.41a38.48,38.48,0,0,0-9.53,1.72Zm-8.1-31,10.1,26a38.17,38.17,0,0,1,7.35-1.33V5.91A68.89,68.89,0,0,0,55.06,9.16Z" />
      <path d="M17.29,118.63l-.28-.38A73.55,73.55,0,0,1,7,98.13l-.19-.48,31.19-9,.19.47a40.52,40.52,0,0,0,4.76,9.54l.29.38-.38.29ZM8,98.32a71.21,71.21,0,0,0,9.54,19L41.9,98.8a43.75,43.75,0,0,1-4.49-9Z" />
      <path d="M17,120.54l-1.15-1.43a78,78,0,0,1-10.2-20.5L4.9,96.79l33.75-9.72.67,1.62A36.56,36.56,0,0,0,44,97.94l1,1.43ZM9.76,99.27a71,71,0,0,0,8.1,16.12L40.08,98.51a47.81,47.81,0,0,1-3.52-7Z" />
      <path d="M51,44.92l-21.83-24,.38-.29A72.21,72.21,0,0,1,48.19,9.16L48.67,9,60.4,39.2l-.39.19a37.52,37.52,0,0,0-8.58,5.24ZM30.45,21l20.6,22.6a40.17,40.17,0,0,1,8.11-4.86L48.09,10.21A73.82,73.82,0,0,0,30.45,21Z" />
      <path d="M50.67,46.63,27.11,20.7l1.53-1.15a73.93,73.93,0,0,1,19-11.63l1.81-.67L62.11,39.86l-1.52.77a38.44,38.44,0,0,0-8.3,5l-.19.1ZM32.45,21.17l18.79,20.6a41.22,41.22,0,0,1,6.2-3.72L47.33,12A70.21,70.21,0,0,0,32.45,21.17Z" />
    </StyledSvg>
  );
};

export default SdgsCircleIcon;
