import instance from "./instance";

export const endpoints = {
  governoratesData: `/Web/Governorates/All`,
  localizationData: `/Web/Topics/GetLocalizationData`,
  indicatorData: `/IndicatorValues/GetLocalizationIndicatorData`,
  govSnapshotData: `Web/Snapshotindicators/GetGovernoratesSnapshotIndicators`,
  govReport: `/Web/Governorates/GetGovernorateReport`
};

export const localizationApi = {
  getGovernoratesData: () => instance.get(endpoints.governoratesData),
  getLocalizationData: () => instance.get(endpoints.localizationData),
  getIndicatorData: (data) => instance.post(endpoints.indicatorData, data),

  getGovStatisticalSnapshotData: (GovernorateId) =>
    instance.get(endpoints.govSnapshotData, {
      params: {
        GovernorateId
      }
    }),

  getGovReport: (GovernorateId) =>
    instance.get(endpoints.govReport, {
      params: {
        GovernorateId
      }
    })
};

export default localizationApi;
