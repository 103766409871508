import React from "react";

import { useIntl } from "react-intl";

import joinWithExtraction from "helpers/joinWithExtraction";

import { ARABIC } from "types/languages";

import Styles from "./styles";
import { HTMLComponent } from "@particles";

import { decode } from "he";
import { getCurrentLang } from "@utils";

export const getMetaDataMapping = (formatMessage) => {
  const lang = getCurrentLang();
  return [
    {
      name: formatMessage({ defaultMessage: "Indicator" }),
      prop: "name"
    },
    {
      name: formatMessage({ defaultMessage: "Frequency" }),
      prop: "frequencies",
      getValue: (value) => joinWithExtraction(value, (item) => item.name)
    },
    { name: formatMessage({ defaultMessage: "Source" }), prop: "sourceName" },
    {
      name: formatMessage({ defaultMessage: "Unit" }),
      prop: lang === ARABIC ? "unitNameAr" : "unitNameEn",
      getValue: (value, item) => (lang === ARABIC ? value || item["unitNameEn"] : value)
    },
    {
      name: formatMessage({ defaultMessage: "Last Updated" }),
      prop: "lastUpdatedDate",
      getValue: (value) => (value ? new Date(value).toLocaleDateString() : "N/A")
    },
    {
      name: formatMessage({ defaultMessage: "Definition" }),
      prop: "description",
      render: (value) => (value ? <HTMLComponent html={value} /> : "N/A"),
      getValue: (value) => (value ? decode(value) : "N/A")
    },
    {
      name: formatMessage({ defaultMessage: "Comment" }),
      prop: "comment",
      render: (value) => (value ? <HTMLComponent html={value} /> : "N/A"),
      getValue: (value) => (value ? decode(value) : "N/A")
    }
  ];
};

const MetadataTable = ({ indicatorsMetadata }) => {
  const intl = useIntl();
  const mappingData = getMetaDataMapping(intl.formatMessage);

  return (
    <Styles>
      <div className="metadata-table">
        {indicatorsMetadata?.map?.((item, index) => (
          <table key={index} className="table table-bordered">
            <tbody>
              {mappingData?.map(({ name, prop, render, getValue }, index) => {
                let content = item[prop] || "N/A";
                if (render) {
                  content = render(item[prop], item);
                } else if (getValue) {
                  content = getValue(item[prop], item);
                }

                return (
                  <tr key={index}>
                    <td className="name">{name}</td>

                    <td className="value">{content}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ))}
      </div>
    </Styles>
  );
};

export default MetadataTable;
