import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#BE8C2C";

const StatisticalSnapshot12 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={{ ...styles.section, width: "60%" }}>
            <Image
              src={sdgInfo?.snapshotImageURL}
              alt={sdgInfo?.name}
              style={{ width: "100%" }}
            />
          </View>

          <View style={{ ...styles.section, width: "30%" }}>
            <View style={styles.indicatorsContainer}>
              {indicators[0] && <Indicator {...indicators[0]} />}
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[1] && <Indicator {...indicators[1]} iconWidth="2.34cm" />}
              {indicators[2] && <Indicator {...indicators[2]} iconWidth="2.34cm" />}
            </View>
          </View>

          <View style={{ ...styles.section, ...styles.sectionWithBg }}>
            <View style={styles.indicatorsContainer}>
              {indicators[3] && <Indicator {...indicators[3]} />}
              {indicators[4] && <Indicator {...indicators[4]} />}
            </View>
          </View>

          <View style={{ ...styles.section, ...styles.sectionWithBg }}>
            <View style={styles.indicatorsContainer}>
              {indicators[5] && <Indicator {...indicators[5]} horiz iconWidth="3cm" />}
            </View>
          </View>

          <View style={{ ...styles.section }}>
            <View style={styles.indicatorsContainer}>
              {indicators[6] && <Indicator {...indicators[6]} />}
              {indicators[7] && <Indicator {...indicators[7]} />}
            </View>
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot12;
