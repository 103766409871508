import { StyledSvg } from "@atoms";
import React from "react";

const ArrowDropUpIcon = (props) => {
  return (
    <StyledSvg {...props} strokeWidth="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M7 14l5-5 5 5z"></path>
    </StyledSvg>
  );
};

export default ArrowDropUpIcon;
