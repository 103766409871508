import styled from "styled-components";

import { Modal } from "react-bootstrap";

export const StyledModal = styled(Modal)`
  font-family: Rubik;
  [dir="rtl"] {
    font-family: ArbFonts;
  }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: unset;
  }
`;
