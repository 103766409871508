import React from "react";
import Styles from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { EventCard } from "@molecules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";

const swiperProps = {
  modules: [Autoplay, Navigation, Pagination],
  navigation: true,
  pagination: {
    type: "bullets"
  },
  autoplay: {
    delay: 5000
  },
  slidesPerView: 1,
  spaceBetween: 20,
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 40
    },
    1400: {
      slidesPerView: 3,
      spaceBetween: 65
    }
  }
};

const EventsCarousel = ({ items, dir = "ltr" }) => {
  return (
    <Styles>
      {items?.length > 0 && (
        <div className="events-carousel w-100">
          <Swiper {...swiperProps} dir={dir}>
            {items?.map?.((item, index) => (
              <SwiperSlide key={index}>
                <EventCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </Styles>
  );
};

export default EventsCarousel;
