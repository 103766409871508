import { pageRoutes } from "@constants";
import useLocalizedNavigate from "./useLocalizedNavigate";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "redux/actions/searchActions";
import { useCallback } from "react";

const searchParam = "keyword";

function useSearch() {
  const dispatch = useDispatch();

  const navigate = useLocalizedNavigate();

  const search = useSelector((state) => state.search?.search);

  const handleSearchChange = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const setSearchState = useCallback(
    (value) => {
      dispatch(setSearch(value));
    },
    [dispatch]
  );

  const handleSearchSubmit = useCallback(
    () => search && navigate(`${pageRoutes.search}?${searchParam}=${search}`),
    [navigate, search]
  );

  return {
    search,
    handleSearchChange,
    handleSearchSubmit,
    setSearchState,
    searchParam
  };
}

export default useSearch;
