import { FilterOptionButton } from "@atoms";
import { SearchIcon } from "@icons";
import { FilerSelect } from "@molecules";
import React, { useEffect, useRef, useState } from "react";
import Styles from "./styles";

const Filters = ({
  filters,
  placeholder,
  activeFilters,
  onFilterSelect,
  onFilterDeselect,
  onSearchChange
}) => {
  const handleSelectFilterOption = (option) => {
    onFilterSelect(option);
  };

  const handleDeselectFilterOption = (option) => {
    onFilterDeselect(option);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const searchRef = useRef();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(searchRef.current?.value);
  };

  const handleSearchInputChange = (e) => {
    if (!e?.target?.value) {
      setSearchTerm("");
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      searchRef.current.value = searchTerm;
    }

    onSearchChange && onSearchChange(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <Styles isAnyActiveFilter={activeFilters?.length > 0 || searchTerm}>
      <div className="filters">
        <div className="filters__list w-100 d-flex flex-column flex-lg-row">
          <div className="filters__titles flex-grow-1 w-100 d-flex">
            {filters?.map?.((filter, i) => (
              <FilerSelect
                key={i}
                name={filter?.name}
                options={filter?.options}
                onOptionClick={handleSelectFilterOption}>
                {filter.name}
              </FilerSelect>
            ))}
          </div>
          <form className="filters__search d-flex" onSubmit={handleSearchSubmit}>
            <input
              ref={searchRef}
              type={"search"}
              placeholder={placeholder}
              className="ps-2"
              name="search"
              onChange={handleSearchInputChange}
            />
            <button type="submit" className="filters__search__icon ms-1">
              <SearchIcon />
            </button>
          </form>
        </div>
        <div className="filters__options">
          <div className="filters__options__grid">
            {activeFilters?.map((option, i) => (
              <FilterOptionButton
                key={i}
                onCancelClick={() => handleDeselectFilterOption(option)}>
                {option.name}
              </FilterOptionButton>
            ))}
            {searchTerm && (
              <FilterOptionButton onCancelClick={() => setSearchTerm("")}>
                {searchTerm}
              </FilterOptionButton>
            )}
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default Filters;
