import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import BaseChart from "./base-chart";

class SdgBarChart extends BaseChart {
  initiateChart() {
    this.chart = am4core.create(this.config.containerId, am4charts.XYChart);
    this.chartType = "Bar-Chart";
  }

  initiateXAxis() {
    this.createXCategoryAxis();
  }

  createLegend() {
    super.createLegend();
    this.chart.legend.paddingBottom = 20;
    this.chart.legend.labels.template.maxWidth = 95;
  }

  initiateSeries() {
    return this.chart.series.push(new am4charts.ColumnSeries());
  }

  setupXAxis() {
    super.setupXAxis();
    this.xAxis.renderer.cellStartLocation = 0.1;
    this.xAxis.renderer.cellEndLocation = 0.9;
    this.xAxis.renderer.grid.template.location = 0;
    this.xAxis.renderer.maxGridDistance = 200;
  }

  setupSeries(series, config = {}) {
    super.setupSeries(series, config);
    series.fill = config.color;

    series.events.on("hidden", () => {
      this.arrangeColumns();
    });
    series.events.on("shown", () => {
      this.arrangeColumns();
    });
  }

  arrangeColumns() {
    var series = this.chart.series.getIndex(0);

    var w =
      1 -
      this.xAxis.renderer.cellStartLocation -
      (1 - this.xAxis.renderer.cellEndLocation);

    if (series.dataItems.length > 1) {
      var x0 = this.xAxis.getX(series.dataItems.getIndex(0), "categoryX");
      var x1 = this.xAxis.getX(series.dataItems.getIndex(1), "categoryX");
      var delta = ((x1 - x0) / this.chart.series.length) * w;
      if (am4core.isNumber(delta)) {
        var middle = this.chart.series.length / 2;

        var newIndex = 0;
        this.chart.series.each((series) => {
          if (!series.isHidden && !series.isHiding) {
            series.dummyData = newIndex;
            newIndex++;
          } else {
            series.dummyData = this.chart.series.indexOf(series);
          }
        });
        var visibleCount = newIndex;
        var newMiddle = visibleCount / 2;

        this.chart.series.each((series) => {
          var trueIndex = this.chart.series.indexOf(series);
          var newIndex = series.dummyData;

          var dx = (newIndex - trueIndex + middle - newMiddle) * delta;

          series.animate(
            { property: "dx", to: dx },
            series.interpolationDuration,
            series.interpolationEasing
          );
          series.bulletsContainer.animate(
            { property: "dx", to: dx },
            series.interpolationDuration,
            series.interpolationEasing
          );
        });
      }
    }
  }
}

export default SdgBarChart;
