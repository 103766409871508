import React from "react";
import { StyledSvg } from "@atoms";

const EventTimeIcon = (props) => {
  return (
    <StyledSvg {...props} strokeWidth="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path>
    </StyledSvg>
  );
};

export default EventTimeIcon;
