import styled from "styled-components";

const Styles = styled.div`
  .table {
    margin-bottom: 2rem;

    tbody {
      tr {
        vertical-align: baseline;
      }
    }
  }

  .name {
    width: 120px;
  }
`;

export default Styles;
