import { Filters } from "@organisms";
import { AppTemplate } from "@templates";
import { PublicationCard, Pagination } from "@molecules";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Styles from "./styles";
import { useQuery } from "react-query";
import { publicationsApi } from "api";
import { CardsLoader } from "@particles";
import { Alert } from "react-bootstrap";

const filtersIds = {
  topics: "topics",
  types: "types",
  authors: "authors",
  years: "years"
};
const filters = [
  {
    id: filtersIds.authors,
    name: <FormattedMessage defaultMessage="Author" />,
    options: [],
    showProp: "authorsShow"
  },
  {
    id: filtersIds.types,
    name: <FormattedMessage defaultMessage="Type" />,
    options: [],
    showProp: "typesShow"
  },
  {
    id: filtersIds.topics,
    name: <FormattedMessage defaultMessage="Topic" />,
    options: []
  },
  {
    id: filtersIds.years,
    name: <FormattedMessage defaultMessage="Year" />,
    single: true,
    options: []
  }
];

const Publications = () => {
  const [page, setPage] = useState(0);

  const [params, setParams] = useState({
    keyword: "",
    pageSize: 6,
    orderBy: "name",
    [filtersIds.years]: [],
    [filtersIds.types]: [],
    [filtersIds.topics]: [],
    [filtersIds.authors]: []
  });

  const { data, isLoading, isError } = useQuery(["publications", params, page], () =>
    publicationsApi.getAll({
      keyword: params.keyword,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      page,
      year: params[filtersIds.years]?.[0]?.id,
      [filtersIds.types]: params[filtersIds.types].map((o) => o.id),
      [filtersIds.topics]: params[filtersIds.topics].map((o) => o.id),
      [filtersIds.authors]: params[filtersIds.authors].map((o) => o.id)
    })
  );

  const { records: items, pagingInfo } = data?.data ?? {};

  const {
    data: filtersData,
    isLoading: isFiltersLoading,
    isError: isFiltersError
  } = useQuery(["publications-filters"], () => publicationsApi.getFiltersList());

  const filtersList = useMemo(() => {
    if (isFiltersLoading) {
      return [...filters];
    }

    if (isFiltersError) {
      return [];
    }

    return filters
      .filter(
        (filter) =>
          !filter.showProp || (filter.showProp && filtersData?.data?.[filter.showProp])
      )
      .map((filter) => ({
        ...filter,
        options:
          filtersData?.data?.[filter.id]?.map((o) =>
            typeof o === "object"
              ? {
                  ...o,
                  filterId: filter.id,
                  single: filter.single
                }
              : {
                  id: o,
                  name: o,
                  filterId: filter.id,
                  single: filter.single
                }
          ) || []
      }));
  }, [filtersData, isFiltersLoading, isFiltersError]);

  useEffect(() => {
    setPage(0);
  }, [params]);

  const onFilterSelect = (option) => {
    const found = params[option.filterId].some((o) => o.id === option.id);

    if (found) {
      return;
    }
    setParams({
      ...params,
      [option.filterId]: option.single ? [option] : [...params[option.filterId], option]
    });
  };

  const onFilterDeselect = (option) => {
    setParams({
      ...params,
      [option.filterId]: params?.[option.filterId]?.filter((o) => o.id !== option.id)
    });
  };

  const onSearchChange = (searchTerm) => {
    setParams({
      ...params,
      keyword: searchTerm
    });
  };

  const activeFilters = useMemo(
    () => [
      ...params[filtersIds.types],
      ...params[filtersIds.topics],
      ...params[filtersIds.authors],
      ...params[filtersIds.years]
    ],
    [params]
  );

  const Items = () => {
    if (items?.length) {
      return (
        <div className="publications__grid w-100">
          {items?.map?.((item, i) => (
            <PublicationCard key={i} {...item} />
          ))}
        </div>
      );
    }
    return (
      <div className={`w-100 py-5 text-center`}>
        {!isError && (
          <Alert>
            <FormattedMessage defaultMessage="There are no publications " />
          </Alert>
        )}
        {isError && (
          <Alert>
            <FormattedMessage defaultMessage="Something went wrong fetching the publications" />
          </Alert>
        )}
      </div>
    );
  };

  const intl = useIntl();
  return (
    <AppTemplate title={"Latest Publications"}>
      <Styles>
        <div className="publications">
          <div className="publications__top-section bg-black">
            <div className="container mx-auto py-5">
              <h1 className="text-50px font-md text-white mb-2 mb-xxl-3">
                <FormattedMessage defaultMessage="Latest Publications" />
              </h1>
              <div className="publications__filters">
                <Filters
                  filters={filtersList}
                  activeFilters={activeFilters}
                  onFilterSelect={onFilterSelect}
                  onFilterDeselect={onFilterDeselect}
                  onSearchChange={onSearchChange}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Search for Publications"
                  })}
                />
              </div>
            </div>
          </div>
          <div className="publications__content my-5">
            <div className="container mx-auto pt-3 pt-xxl-5">
              {isLoading && <CardsLoader count={6} dir="vert" />}
              {!isLoading && <Items />}

              <div className="w-100 my-4">
                <Pagination
                  page={page}
                  pageCount={pagingInfo?.totalPages}
                  onPageChange={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default Publications;
