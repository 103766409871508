import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .footer {
    &__container {
      margin: 0 auto;
      width: 95%;
      ${respondTo.md`
        width: 92%;
      `}
      ${respondTo.xl`
        width: 85%;
      `}
      ${respondTo.xxl`
        width: 80%;
      `}
    }
    &__social-links {
      border-width: 0px 1px;
      border-style: solid;
      border-color: rgb(27, 131, 65);
      margin: -0.5rem 0;
    }
    &__logo {
      width: 8rem;
      ${respondTo.md`
        width: 12rem;
      `}
    }
  }
`;
export default Styles;
