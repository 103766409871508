import React from "react";
import { NavMenuLinkContainer } from "@molecules";

import { connect } from "react-redux";

import Styles from "./styles";

const NavMenuLinks = ({ upperMenus, color, hoverColor, activeColor, className = "" }) => {
  return (
    <Styles>
      <div
        className={`nav-menu-links w-100 d-flex flex-wrap flex-column flex-sm-row align-items-center ${className}`}>
        {upperMenus?.map?.((link) => (
          <NavMenuLinkContainer
            {...link}
            color={color}
            hoverColor={hoverColor}
            activeColor={activeColor}
            key={link.id}
          />
        ))}
      </div>
    </Styles>
  );
};

const mapStateToProps = (state) => ({
  upperMenus: state.menus.upperMenus
});

export default connect(mapStateToProps)(NavMenuLinks);
