import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";

import bottomDraw from "assets/snapshots/gender-equality-draw.png";
import { Image, View } from "@particles";

const themeColor = "#EF4027";

const StatisticalSnapshot5 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.section}>
            <Image
              src={sdgInfo?.snapshotImageURL}
              alt={sdgInfo?.name}
              style={{ width: "90%" }}
            />
          </View>
          <View style={{ ...styles.section, padding: "0 10pt" }}>
            <View style={{ ...styles.indicatorsContainer }}>
              {indicators[0] && <Indicator {...indicators[0]} />}
              {indicators[1] && <Indicator {...indicators[1]} />}
            </View>
          </View>

          <View style={{ ...styles.sectionWithBg, ...styles.section, width: "70%" }}>
            <View style={styles.indicatorsContainer}>
              {indicators[2] && <Indicator {...indicators[2]} />}
              {indicators[3] && <Indicator {...indicators[3]} />}
              {indicators[4] && <Indicator {...indicators[4]} />}
            </View>
          </View>

          <View
            style={{
              ...styles.section,
              width: "30%",
              padding: "0 20pt"
            }}>
            {indicators[5] && (
              <Indicator {...indicators[5]} iconWidth="2.34cm" iconHeight="2.34cm" />
            )}
          </View>

          <View style={{ ...styles.section, width: "70%" }}>
            <View
              style={{ ...styles.indicatorsContainer, justifyContent: "space-between" }}>
              <View style={{ width: "30%", justifyContent: "flex-end" }}>
                <Image src={bottomDraw} style={{ width: "100%", height: "auto" }} />
              </View>
              <View style={{ width: "65%" }}>
                <View style={{ ...styles.indicatorsContainer, marginBottom: "0.5cm" }}>
                  {indicators[6] && <Indicator {...indicators[6]} />}
                </View>

                <View style={styles.indicatorsContainer}>
                  {indicators[8] && <Indicator {...indicators[8]} />}
                  {indicators[9] && <Indicator {...indicators[9]} />}
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              ...styles.side
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[7] && <Indicator {...indicators[7]} iconWidth="2.8cm" />}
            </View>
            <View style={{ margin: "0.5cm 0" }} />

            <View style={styles.indicatorsContainer}>
              {indicators[10] && <Indicator {...indicators[10]} />}
            </View>
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot5;
