import instance from "./instance";

export const keyTopicsApi = {
  getAll: (config = {}) =>
    instance.get(`Web/Topics/All`, {
      ...config
    }),

  getDetails: (id) =>
    id &&
    instance.get(`Web/Topics/Details`, {
      params: {
        topicId: id
      }
    }),

  getInternationalData: () => instance.get(`Web/Topics/GetInternationalData`)
};

export default keyTopicsApi;
