import React from "react";

import { FormattedMessage } from "react-intl";

import { Modal as BtModal } from "react-bootstrap";

import { StyledModal } from "./styles";

import isArabic from "helpers/isArabic";
import { StyledButton } from "@atoms";

const Modal = ({ show, setShow, title, children }) => {
  const handleClose = () => setShow(false);

  return (
    <StyledModal show={show} onHide={handleClose} dir={isArabic() ? "rtl" : "ltr"}>
      <BtModal.Header closeButton>
        <BtModal.Title>{title}</BtModal.Title>
      </BtModal.Header>

      <BtModal.Body>{children}</BtModal.Body>

      <BtModal.Footer>
        <StyledButton onClick={handleClose}>
          <FormattedMessage defaultMessage="Close" />
        </StyledButton>
      </BtModal.Footer>
    </StyledModal>
  );
};

export default Modal;
