import React from "react";
import { connect } from "react-redux";
import NavMenuLinkContainer from "../nav-menu-link-container";
import Styles from "./styles";

const FooterLinks = ({ bottomMenus, color, hoverColor, activeColor, className }) => {
  return (
    <Styles>
      <div
        className={`footer-links w-100 d-flex flex-wrap flex-column flex-sm-row align-items-sm-center ${className}`}>
        {bottomMenus?.map?.((link) => (
          <NavMenuLinkContainer
            {...link}
            color={color}
            hoverColor={hoverColor}
            activeColor={activeColor}
            key={link.id}
          />
        ))}
      </div>
    </Styles>
  );
};

const mapStateToProps = (state) => ({
  bottomMenus: state.menus.bottomMenus
});

export default connect(mapStateToProps)(FooterLinks);
