import { StyledButton } from "@atoms";
import { MAX_DATA_VIS_CARD_CHARS } from "@constants";
import { RightArrowIcon } from "@icons";
import truncate from "lodash/truncate";
import React from "react";
import { FormattedMessage } from "react-intl";
import { GenericLink } from "@molecules";
import Styles from "./styles";

const DataVisualizationCard = ({ name, imagePath, summary, link }) => {
  return (
    <Styles>
      <div className="data-visualiztion-card w-100 bg-light p-3 p-lg-4 p-xxl-5 d-flex flex-column">
        {name && (
          <h4 className="text-primary font-md text-25px mb-3 d-flex align-items-center">
            {name}
          </h4>
        )}

        <div className="data-visualiztion-card__img mb-3">
          {imagePath && (
            <img
              className="w-100"
              src={imagePath}
              alt={name || "Data Visualization Page"}
            />
          )}
        </div>
        <div className="data-visualiztion-card__body flex-grow-1 d-flex flex-column">
          {summary && (
            <p className="text-dark font-light text-16px mb-3 flex-grow-1">
              {truncate(summary, { length: MAX_DATA_VIS_CARD_CHARS })}
            </p>
          )}
          {link && (
            <div className="d-flex align-items-center">
              <GenericLink link={link} className="cursor-pointer">
                <span className="text-14px font-bold text-dark me-3">
                  {<FormattedMessage defaultMessage="Explore Data" />}
                </span>
                <StyledButton size="lg" circle>
                  <RightArrowIcon />
                </StyledButton>
              </GenericLink>
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
};

export default DataVisualizationCard;
