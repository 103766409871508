import styled from "styled-components";
import mpedLogo from "assets/images/mped-logo.png";

const MpedLogo = styled.img.attrs((props) => ({
  src: mpedLogo,
  alt: "MPED Logo",
  width: "100%"
}))``;

export default MpedLogo;
