import React from "react";
import { FormattedMessage } from "react-intl";
import { SdgPageTemplate } from "@templates";
import { Covid19ImpactCharts, Covid19ImpactDetails } from "@organisms";
import { SdgButton } from "@atoms";
import { ChartIcon, Covid19Icon } from "@icons";
import { useTabNavigation } from "@hooks";

const contentType = {
  impact: "impact",
  charts: "charts"
};

const tabs = Object.values(contentType);

const TabsComponent = ({ tab, handleTabClick }) => (
  <>
    <SdgButton
      variant="light"
      active={tab !== contentType.charts}
      onClick={() => handleTabClick(contentType.impact)}>
      <Covid19Icon className="me-2 text-28px" />
      <FormattedMessage defaultMessage="Impact" />
    </SdgButton>

    <SdgButton
      variant="light"
      active={tab === contentType.charts}
      onClick={() => handleTabClick(contentType.charts)}>
      <ChartIcon className="me-2 text-28px" />
      <FormattedMessage defaultMessage="Build Chart" />
    </SdgButton>
  </>
);

const Covid19Impact = () => {
  const { tab, handleTabClick } = useTabNavigation(tabs);

  return (
    <SdgPageTemplate
      seoTitle="Covid 19 Impact"
      title={<FormattedMessage defaultMessage="COVID-19 Impact" />}
      tabsComponent={() => <TabsComponent tab={tab} handleTabClick={handleTabClick} />}>
      {tab === contentType.impact ? <Covid19ImpactDetails /> : <Covid19ImpactCharts />}
    </SdgPageTemplate>
  );
};

export default Covid19Impact;
