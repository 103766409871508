import instance from "./instance";

export const publicationsApi = {
  getAll: (filters, config = {}) =>
    instance.post(`Web/Publications/FilterAll`, filters, {
      ...config
    }),

  getFiltersList: () => instance.get(`Web/Publications/FilterAllLists`),

  getDetails: (id) =>
    id &&
    instance.get(`Web/Publications/Details`, {
      params: {
        publicationId: id
      }
    })
};

export default publicationsApi;
