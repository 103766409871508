import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#4B9E45";

const StatisticalSnapshot3 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.main}>
            <View style={{ ...styles.section, width: "65%", padding: "0 10pt" }}>
              <Image
                src={sdgInfo?.snapshotImageURL}
                alt={sdgInfo?.name}
                style={{ width: "100%" }}
              />
            </View>
            <View style={{ ...styles.section, width: "30%", padding: "0 5pt" }}>
              <View
                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {indicators[0] && <Indicator {...indicators[0]} />}
              </View>
            </View>

            <View style={{ ...styles.sectionWithBg, marginBottom: "0.5cm" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[2] && <Indicator {...indicators[2]} />}
                {indicators[3] && <Indicator {...indicators[3]} />}
                {indicators[4] && <Indicator {...indicators[4]} />}
              </View>
            </View>

            <View style={{ ...styles.indicatorsContainer, marginBottom: "0.5cm" }}>
              {indicators[6] && <Indicator {...indicators[6]} />}
              {indicators[7] && <Indicator {...indicators[7]} />}
              {indicators[8] && <Indicator {...indicators[8]} />}
            </View>
          </View>

          <View style={styles.side}>
            {indicators[1] && <Indicator {...indicators[1]} stretch={false} />}
            {indicators[5] && <Indicator {...indicators[5]} stretch={false} />}
            {indicators[8] && <Indicator {...indicators[9]} stretch={false} />}
          </View>

          <View style={{ ...styles.hr, backgroundColor: "#c0c0c0" }} />

          <View
            style={{
              ...styles.section,
              ...styles.sectionWithBg
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[10] && <Indicator {...indicators[10]} />}
              {indicators[11] && <Indicator {...indicators[11]} />}
            </View>
          </View>

          <View style={{ ...styles.section, padding: "0 10pt" }}>
            <View style={styles.indicatorsContainer}>
              {indicators[12] && <Indicator {...indicators[12]} iconWidth="2.34cm" />}
              {indicators[13] && <Indicator {...indicators[13]} iconWidth="2.34cm" />}
            </View>
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot3;
