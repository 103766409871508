import instance from "./instance";

export const eventsApi = {
  getAll: (config = {}) =>
    instance.get(`Web/ContentRecord/AllEvents`, {
      ...config
    }),

  getDetails: (id) =>
    id &&
    instance.get(`Web/ContentRecord/Details`, {
      params: {
        recordId: id
      }
    })
};

export default eventsApi;
