import { StyledButton } from "@atoms";
import { FadeTransition } from "@particles";
import Tippy from "@tippyjs/react";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Styles, { YearButton, FrequenciesListStyles, CustomYearButton } from "./styles";

const FrequenciesList = ({ popperAttrs, freqs, filterdYears, handleFilterFreq }) => (
  <FrequenciesListStyles {...popperAttrs}>
    {freqs?.map((freq, i) => {
      const isActive = filterdYears?.includes(freq);

      return (
        <CustomYearButton
          key={i}
          active={isActive}
          onClick={handleFilterFreq(freq, isActive)}>
          {freq}
        </CustomYearButton>
      );
    })}
  </FrequenciesListStyles>
);

const ChartsYearsFilter = ({
  years,
  filterdYears,
  setFilterdYears,
  onApplyFilter,
  popperAttrs = {},
  ...props
}) => {
  const [selectedYear, setSelectedYear] = useState();
  const [frequencyListRef, setFrequencyListRef] = useState();

  const handleFilterYear = (year, isActive) => () => {
    if (isActive) {
      setFilterdYears(filterdYears?.filter((y) => y !== year));
      return;
    }
    setFilterdYears([...filterdYears, year]);
  };

  const handleReset = () => setFilterdYears([]);

  const handleShowFreqList = (ref, yearWithItems) => {
    setSelectedYear(yearWithItems);
    setFrequencyListRef(ref);
  };

  const handleHideFreqList = () => {
    setSelectedYear(null);
    setFrequencyListRef(null);
  };

  const handleToggleFreqList = (ref, yearWithItems) => {
    if (selectedYear && selectedYear.year === yearWithItems?.year) {
      handleHideFreqList();
      return;
    }

    handleShowFreqList(ref, yearWithItems);
  };

  const sortedYears = useMemo(
    () => [].concat(years).sort((a, b) => a.year - b.year),
    [years]
  );
  return (
    <Styles {...popperAttrs}>
      <div className="charts-years-filter" {...props}>
        <div className="charts-years-filter__grid">
          {sortedYears.map(({ year, items }, i) => {
            if (items?.length > 0) {
              const isActive = items?.every((freq) => filterdYears?.includes(freq));
              return (
                <div key={i}>
                  <YearButton
                    key={i}
                    active={isActive}
                    onClick={(e) => {
                      handleToggleFreqList(e.target, { years, items });
                    }}>
                    {year}
                  </YearButton>
                </div>
              );
            } else {
              const yearStr = year?.toString();
              const isActive = filterdYears?.includes(yearStr);
              return (
                <YearButton
                  key={i}
                  active={isActive}
                  onClick={handleFilterYear(yearStr, isActive)}>
                  {year}
                </YearButton>
              );
            }
          })}
        </div>

        <div className="mt-4 d-flex justify-content-center">
          <StyledButton
            className="me-2"
            disabled={!filterdYears?.length}
            variant="greyDark"
            onClick={handleReset}>
            <FormattedMessage defaultMessage="Reset" />
          </StyledButton>

          <StyledButton variant="greyDark" onClick={onApplyFilter}>
            <FormattedMessage defaultMessage="Apply" />
          </StyledButton>
        </div>

        <Tippy
          interactive
          visible={!!frequencyListRef}
          onClickOutside={handleHideFreqList}
          placement="left"
          offset={[0, -10]}
          render={(attrs) => (
            <FadeTransition inProp={!!frequencyListRef}>
              <FrequenciesList
                popperAttrs={attrs}
                freqs={selectedYear?.items}
                handleFilterFreq={handleFilterYear}
                filterdYears={filterdYears}
              />
            </FadeTransition>
          )}
          reference={frequencyListRef}
        />
      </div>
    </Styles>
  );
};

export default ChartsYearsFilter;
