import React from "react";
import ReactDomServer from "react-dom/server";
import { generatePdfFromHtml } from "@utils";

export function exportStatisticalSnapshotToPdf(Snapshot, exportName, cb) {
  if (!Snapshot) {
    cb && cb();
    return;
  }

  const el = ReactDomServer.renderToStaticMarkup(<Snapshot />);

  generatePdfFromHtml(el, exportName, {
    useJsPdf: false,
    margin: 0,
    cb
  });
}
