import styled from "styled-components";

const Styles = styled.div`
  .nav-sub-menu {
    .nav-link {
      font-weight: 500;
      font-size: 0.875rem;
      text-decoration: none;
      color: ${({ color }) => color || `var(--black)`};
      display: block;
      white-space: ${({ wrap }) => (wrap ? "normal" : "noWrap")};
      &:hover {
        color: ${({ hoverColor }) => hoverColor || `var(--primary)`};
      }
    }

    .dropdown-menu {
      background-color: ${(props) => props.bgColor || `var(--light)`};
    }
  }
`;
export default Styles;
