import styled from "styled-components";
import edpLogoWhite from "assets/images/edp-logo-white.png";

const EdpLogoWhite = styled.img.attrs((props) => ({
  src: edpLogoWhite,
  alt: "EDP Logo",
  width: "100%"
}))``;

export default EdpLogoWhite;
