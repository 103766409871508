import { HomeIndicatorCard } from "@molecules";
import { CounterLoader } from "@particles";
import React from "react";
import { FormattedMessage } from "react-intl";
import Styles from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useMediaQuery } from "@hooks";
import { breakpoints } from "@constants";

const LoadingIndicator = () => {
  const isMaxMd = useMediaQuery(`max-width:${breakpoints.md}`);
  const isMaxXs = useMediaQuery(`max-width:380px`);
  const loaderCount = isMaxXs ? 1 : isMaxMd ? 2 : 4;
  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      {Array(loaderCount)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="flex-1 mx-3 mx-lg-5">
            <CounterLoader />
          </div>
        ))}
    </div>
  );
};
const swiperProps = {
  modules: [Autoplay, Navigation, Pagination],
  navigation: true,
  pagination: {
    clickable: true,
    type: "bullets"
  },
  autoplay: {
    delay: 5000
  },
  breakpoints: {
    380: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 30
    }
  }
};

const HomeIndicators = ({ indicators, loading, dir = "ltr" }) => {
  return (
    <Styles>
      <section className="home-indicators w-100">
        <div className="container mx-auto">
          <div className="w-100 mb-5">
            <h1 className="text-dark text-center font-md text-50px">
              <FormattedMessage defaultMessage="Egypt" />
            </h1>
            <p className="text-primary text-center font-md text-25px">
              <FormattedMessage defaultMessage="in numbers" />
            </p>
          </div>
          <div className="swiper-container w-100">
            {!loading && (
              <Swiper {...swiperProps} dir={dir}>
                {indicators?.map?.((indicator, index) => (
                  <SwiperSlide key={index}>
                    <HomeIndicatorCard key={index} {...indicator} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}

            {loading && <LoadingIndicator />}
          </div>
        </div>
      </section>
    </Styles>
  );
};

export default HomeIndicators;
