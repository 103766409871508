import React from "react";
import { Modal } from "@organisms";
import { FormattedMessage } from "react-intl";
import { HTMLComponent } from "@particles";
import { sdgsApi } from "api";
import { useQuery } from "react-query";

const SendBlogModal = ({ sendBlogModalOpen, setSendBlogModalOpen }) => {
  const { data: sendBlogcontent } = useQuery(["text-var", "Blog"], () =>
    sdgsApi.getTextVar("Blog")
  );

  return (
    <Modal
      show={sendBlogModalOpen}
      setShow={setSendBlogModalOpen}
      title={<FormattedMessage defaultMessage="Submission Guidelines" />}>
      <HTMLComponent html={sendBlogcontent?.data?.data} />
    </Modal>
  );
};

export default SendBlogModal;
