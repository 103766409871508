import instance from "./instance";

export const homeApi = {
  getSlider: () => instance.get(`Web/Slider/All`),
  getPortalCounters: () => instance.get(`Web/PortalCounters/GetPortalCounters`),
  getTopPublications: () =>
    instance.get(`Web/Publications/TopPublications`, { params: { typeId: 3 } }),
  getDataVisualationPages: () => instance.get(`Web/DataVisualizationPages/All`),
  getLatestEvents: () => instance.get(`Web/ContentRecord/GetLatestEvents`),
  getKeyTopics: () => instance.get(`Web/Topics/GetHomeTopics`),
  getRelatedLinks: () => instance.get(`Web/RelatedLinks/All`),
  getMenuLinks: () =>
    instance.get(`Web/Menu/All`, {
      params: {
        location: 1
      }
    }),
  getAboutPage: () => instance.get(`Web/AboutUs`),
  getContactInfo: () => instance.get(`Web/Companies/GetContactInfo`),
  getIndicators: () => instance.get(`HomeIndicators/GetHomeIndicators`),
  searchAll: (keyword) =>
    instance.get(`Web/Search`, {
      params: {
        keyword
      }
    })
};

export default homeApi;
