import React, { useEffect, useMemo, useRef, useState } from "react";

import { GovernorateCard } from "@molecules";
import { GovsSliderLoader } from "@particles";

import Styles, { GovernorateButton } from "./styles";
import { useQuery } from "react-query";
import { localizationApi } from "api";
import { Alert } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { ARABIC } from "types/languages";
import classNames from "classnames";

const swiperProps = {
  modules: [Navigation, Pagination],
  navigation: false,
  pagination: {
    dynamicBullets: true
  },
  loop: false,
  slidesPerView: 2,
  spaceBetween: 10,
  breakpoints: {
    576: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 10
    },
    1200: {
      slidesPerView: 6,
      spaceBetween: 10
    }
  }
};

export const GovernorateButtonStatus = {
  idle: 0,
  active: 1,
  inactive: 2
};

const LocalizationDetails = () => {
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const { isLoading, data, isError } = useQuery(
    "governorates-data",
    localizationApi.getGovernoratesData
  );

  const swiperRef = useRef();
  const governorates = useMemo(() => data?.data || [], [data]);

  const activeItem = governorates?.[activeButtonIndex] || {};

  const getButtonStatus = (i) => {
    if (typeof activeButtonIndex !== "number") {
      return GovernorateButtonStatus.idle;
    }

    if (activeButtonIndex === i) {
      return GovernorateButtonStatus.active;
    }

    return GovernorateButtonStatus.inactive;
  };

  const handleActivateGov = (i) => {
    setActiveButtonIndex(i);
  };

  const handleGoToNextSlide = () => {
    setActiveButtonIndex((prev) => Math.min(governorates?.length - 1, prev + 1));
  };

  const handleGoToPrevSlide = () => {
    setActiveButtonIndex((prev) => Math.max(0, prev - 1));
  };

  useEffect(() => {
    swiperRef?.current?.swiper?.slideTo(activeButtonIndex);
  }, [activeButtonIndex]);

  const intl = useIntl();

  const dir = intl.locale === ARABIC ? "RTL" : "LTR";

  return (
    <Styles>
      <div className="localization-details w-100 py-3 py-xxl-5">
        {isError && (
          <div className="container my-5">
            <Alert>
              <FormattedMessage defaultMessage="Something went wrong. Please try again in a few moments" />
            </Alert>
          </div>
        )}
        {!isError && (
          <div className="localization-details__slider w-100">
            {!isLoading && (
              <>
                <div className="swiper-container">
                  <Swiper {...swiperProps} ref={swiperRef} dir={dir}>
                    <button
                      onClick={handleGoToNextSlide}
                      className={classNames([
                        "swiper-button-next",
                        {
                          "swiper-button-disabled":
                            activeButtonIndex === governorates?.length - 1
                        }
                      ])}
                    />
                    <button
                      onClick={handleGoToPrevSlide}
                      className={classNames([
                        "swiper-button-prev",
                        { "swiper-button-disabled": activeButtonIndex === 0 }
                      ])}
                    />

                    {governorates?.map?.((gov, i) => (
                      <SwiperSlide key={i}>
                        <GovernorateButton
                          iconUrl={gov.flagUrl}
                          color={gov.color}
                          onClick={() => handleActivateGov(i)}
                          status={getButtonStatus(i)}>
                          {gov.flagUrl && (
                            <img
                              className="w-100"
                              src={gov.flagUrl}
                              alt={gov.name || "Governorate"}
                            />
                          )}

                          {!gov.flagUrl && <span />}
                        </GovernorateButton>

                        <div className="text mt-2 pt-1 font-bold">{gov.name}</div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                <div className="mt-4">
                  <GovernorateCard {...activeItem} />
                </div>
              </>
            )}

            {isLoading && <GovsSliderLoader />}
          </div>
        )}
      </div>
    </Styles>
  );
};

export default LocalizationDetails;
