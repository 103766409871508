import React from "react";
import { FormattedMessage } from "react-intl";

export const pageRoutes = {
  about: "/about",
  sdg: "/sdg",
  localization: "/local-development",
  covid: "/covid19-impact",
  keyTopics: "/key-topics",
  publications: "/publications",
  intIndicators: "/international-indicators",
  events: "/events-news",
  dataHighlights: "/data-highlights",
  blogs: "/blogs",
  search: "/search",
  dynamic: "/pages"
};

const navMenuLinksData = [
  { to: pageRoutes.about, title: <FormattedMessage defaultMessage="About" /> },
  { to: pageRoutes.sdg, title: <FormattedMessage defaultMessage="SDGs Data Explorer" /> },
  {
    to: pageRoutes.localization,
    title: <FormattedMessage defaultMessage="Local Development" />
  },
  { to: pageRoutes.covid, title: <FormattedMessage defaultMessage="Covid 19 Impact" /> },
  { to: pageRoutes.keyTopics, title: <FormattedMessage defaultMessage="Key Topics" /> },
  {
    to: pageRoutes.publications,
    title: <FormattedMessage defaultMessage="Publications" />
  },
  { to: pageRoutes.events, title: <FormattedMessage defaultMessage="Events & News" /> },
  {
    to: pageRoutes.intIndicators,
    title: <FormattedMessage defaultMessage="International Indicators" />
  }
];

export default navMenuLinksData;
