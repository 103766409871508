import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  position: relative;
  height: 100%;

  .event-carousel {
    .carousel {
      .event-image {
        width: 100%;
        ${respondTo.md`
          height: 450px;
        `}

        ${respondTo.xxl`
          height: 500px;
        `}

        object-fit: cover;
      }
    }

    .controller-summary {
      display: flex;

      @media (max-width: 768px) {
        display: block;
      }

      .controller {
        flex-basis: 19.5%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 1rem 2rem;

        background-color: #1d1d1d;
        color: white;

        .arrows {
          .disabled {
            opacity: 0.5;
            cursor: unset;
          }
        }
      }

      .summary {
        padding: 1rem 4rem;
        font-style: italic;

        flex-basis: 80.5%;

        background-color: var(--primary);
      }
    }
  }
`;
export default Styles;
