import styled from "styled-components";

const Styles = styled.div`
  .form-check {
    padding: 0;
    color: #4a4a49;

    display: flex;

    &-input {
      float: none;
      margin-left: 0;
      background-color: var(--greyLight);
      border-color: var(--greyLight);
      &:checked {
        background-color: var(--primary);
        border-color: var(--primary);
      }
    }
  }
`;
export default Styles;
