import edpLogoB64 from "assets/images/edpLogoB64";
import isArabic from "helpers/isArabic";
import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

// local images should be base64 formatted

const ChartExportTemplate = ({
  exportElId,
  chartExportImageId,
  indicatorsMetadata,
  uniqueSources,
  lastUpdateDate
}) => {
  const isAr = isArabic();
  return (
    <div
      id={exportElId}
      style={{
        fontFamily: isAr ? "ArbFonts" : "Rubik",
        display: "block",
        padding: "10pt 20pt",
        position: "fixed",
        top: -5000
      }}
      dir={isAr ? "RTL" : "LTR"}>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginBottom: "10pt" }}>
        {indicatorsMetadata?.length === 1 && (
          <h5 style={{ fontSize: "12pt" }}>{indicatorsMetadata?.[0]?.name}</h5>
        )}
        <img
          src={edpLogoB64}
          style={{
            width: "100pt"
            // height: "42pt"
          }}
          alt="EDP Logo"
        />
      </div>
      {uniqueSources?.length > 0 && (
        <p style={{ fontSize: "8pt" }}>
          <span>
            <FormattedMessage defaultMessage="Source(s)" />
            {": "}
          </span>
          <span className="font-md">{uniqueSources?.join(isAr ? "، " : ", ")}</span>
        </p>
      )}
      {lastUpdateDate && (
        <p style={{ fontSize: "8pt" }}>
          <span>
            <FormattedMessage defaultMessage="Last Updated" />
            {": "}
          </span>
          <span className="font-md">{<FormattedDate value={lastUpdateDate} />}</span>
        </p>
      )}

      <p style={{ fontSize: "8pt" }}>
        <span>
          <FormattedMessage defaultMessage="Extracted on" />
          {": "}
        </span>
        <span className="font-md">{<FormattedDate value={new Date()} />}</span>
      </p>

      <div id={chartExportImageId}></div>
    </div>
  );
};

export default ChartExportTemplate;
