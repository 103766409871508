import styled from "styled-components";

const Styles = styled.div`
  position: relative;
  .cancel-btn {
    position: absolute;
    right: 2px;
    top: -5px;
    border-radius: 50%;
    border: none;
    appearance: none;
    line-height: 1;
    font-size: 10px;
    padding: 0.1rem;
  }
`;
export default Styles;
