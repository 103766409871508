import { Transition } from "react-transition-group";
import React from "react";
import Styles from "./styles";
import { TRANSITION_DURATION } from "@constants";

const ScaleTransition = ({ inProp, duration = TRANSITION_DURATION, children }) => {
  return (
    <Transition in={inProp} timeout={duration}>
      {(state) => (
        <Styles duration={duration} state={state}>
          {children}
        </Styles>
      )}
    </Transition>
  );
};

export default ScaleTransition;
