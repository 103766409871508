import { StyledButton } from "@atoms";
import { LocalizedLink } from "@particles";
import { AppTemplate } from "@templates";
import React from "react";
import { FormattedMessage } from "react-intl";
import Styles from "./styles";

const Page404 = () => {
  return (
    <AppTemplate title={"Page Not Found"}>
      <Styles>
        <div className="page-404">
          <div className="text-center">
            <h1 className="page-404__title text-primary">404</h1>
            <h4 className="uppercase">
              <FormattedMessage defaultMessage="The page you requested can't be found" />
            </h4>
            <LocalizedLink to={"/"}>
              <StyledButton>
                <FormattedMessage defaultMessage="Go to home page" />
              </StyledButton>
            </LocalizedLink>
          </div>
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default Page404;
