import {
  DataVisualization,
  Hero,
  PortalCounters,
  PublicationsCarousel,
  KeyTopicsCarousel,
  RelatedLinks,
  HomeIndicators
} from "@organisms";
import { AppTemplate } from "@templates";
import React from "react";
import Styles from "./styles";
import { ARABIC } from "types/languages";
import { useIntl } from "react-intl";
import { HeroLoader, PubCarouselLoader } from "@particles";
import { useQuery } from "react-query";
import { homeApi } from "api";

const Home = () => {
  const lang = useIntl().locale;
  const dir = lang === ARABIC ? "rtl" : "ltr";

  const { isLoading: isHeroLoading, data: heroItems } = useQuery(
    "home-home-slider",
    homeApi.getSlider
  );

  const { isPortalLoading, data: portalCounters } = useQuery(
    "home-portal-counters",
    homeApi.getPortalCounters
  );

  const { isLoading: isTopPubLoading, data: topPublications } = useQuery(
    "home-top-publications",
    homeApi.getTopPublications
  );

  const { isLoading: isRelatedLinksLoading, data: relatedLinks } = useQuery(
    "home-related-links",
    homeApi.getRelatedLinks
  );

  const { isLoading: isDataVisLoading, data: dataVisPages } = useQuery(
    "home-data-visualization",
    homeApi.getDataVisualationPages
  );

  const { isLoading: isKeyTopicsLoading, data: keyTopics } = useQuery(
    "home-key-topics",
    homeApi.getKeyTopics
  );

  const { isLoading: isHomeIndicatorsLoading, data: homeIndicators } = useQuery(
    "home-indicators",
    homeApi.getIndicators
  );

  return (
    <AppTemplate title="Home">
      <Styles>
        <div className="home">
          {(isHeroLoading || (heroItems && heroItems?.data?.length > 0)) && (
            <div className="home__hero">
              {!isHeroLoading && <Hero dir={dir} items={heroItems?.data} />}
              {isHeroLoading && <HeroLoader />}
            </div>
          )}

          <div className="home__home-indicators">
            <HomeIndicators
              indicators={homeIndicators?.data}
              loading={isHomeIndicatorsLoading}
              dir={dir}
            />
          </div>

          <div className="home__data-visualization">
            <DataVisualization
              dataVisItems={dataVisPages?.data}
              loading={isDataVisLoading}
              dir={dir}
            />
          </div>

          <div className="home__portal-counters">
            <PortalCounters counters={portalCounters} loading={isPortalLoading} />
          </div>

          <div className="home__portal-carousels bg-white py-3 py-lg-5">
            <div className="home__portal-carousels__inner mx-auto">
              <div className="home__latest_publications">
                {!isTopPubLoading && (
                  <PublicationsCarousel dir={dir} items={topPublications?.data} />
                )}

                {isTopPubLoading && <PubCarouselLoader />}
              </div>

              <div className="home__key-topics mt-5">
                <KeyTopicsCarousel
                  dir={dir}
                  items={keyTopics?.data}
                  loading={isKeyTopicsLoading}
                />
              </div>
            </div>
          </div>

          <div className="home__related-links">
            <RelatedLinks
              dir={dir}
              relatedLinks={relatedLinks?.data}
              loading={isRelatedLinksLoading}
            />
          </div>

          {/* <div className="home__help-btn">
            <HelpButton size="lg">
              <FormattedMessage defaultMessage="Help" />
            </HelpButton>
          </div> */}
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default Home;
