import { EdpLogoWhite } from "@atoms";
import { theme } from "@constants";
import { FooterLinks, SocialLinks } from "@molecules";
import React from "react";
import { FormattedMessage } from "react-intl";
import Styles from "./styles";

const Footer = () => {
  return (
    <Styles>
      <footer className="footer">
        <div className=" bg-primary py-4">
          <div className="footer__container d-flex justify-content-between align-items-center align-items-md-end">
            <div className="flex-1 me-3 me-lg-5">
              <FooterLinks
                color={theme.colors.light}
                hoverColor={theme.colors.dark}
                activeColor={false}
              />
            </div>
            <div className="footer__logo">
              <EdpLogoWhite />
            </div>
          </div>
        </div>

        <div className="footer__bar bg-dark py-2">
          <div className="container mx-auto d-flex flex-wrap justify-content-between align-items-center">
            <p className="text-14px ps-2 ps-lg-3 text-white mb-2 mb-sm-0">
              <FormattedMessage defaultMessage="All copyrights reserved to Egypt Development Portal 2021 © " />
            </p>
            <div>
              <SocialLinks />
            </div>
          </div>
        </div>
      </footer>
    </Styles>
  );
};

export default Footer;
