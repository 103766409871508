import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  height: 100%;
  .blog-card {
    background-color: var(--light);
    height: 100%;
    &__img {
      width: 100%;
      ${respondTo.md`
            width: 20%;
        `}
    }

    &__meta {
      color: var(--grey);
    }

    &__body {
      p {
        text-align: justify;
      }
    }

    &__link {
      &:hover {
        * {
          color: var(--primaryDark) !important;
        }
      }
    }
  }
`;
export default Styles;
