import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .blogs {
    flex: 1;

    &__content {
      .container {
        ${respondTo.xl`
        max-width: 960px;
    `};
        ${respondTo.xxl`
        max-width: 1200px;
    `}
      }
    }
    &__grid {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
      ${respondTo.xl`
      gap: 3rem;
  `}
    }
  }
`;
export default Styles;
