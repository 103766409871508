export const metaInformation = [
  ["SDGs version date: 4-Feb-2022"],
  ["Extraction date: 25-6-2022"],
  ["Source Type: International and national"],
  ["Goal 1: No poverty"],
  [],
  []
];

export const tableRows = [
  [
    "A",
    "Normal value",
    "This code is used as default code if no value is provided or when no special coded qualification is assumed. Usually, it can be assumed that the source agency assigns sufficient "
  ],
  [
    "B",
    "Time series break",
    "Different content exists or a different methodology has been applied to this observation as compared with the preceding one (the one given for the previous period)."
  ],
  [
    "D",
    "Definition differs",
    "Used to indicate slight deviations from the established methodology (footnote-type information); these divergences do not imply a break in time series."
  ],
  [
    "E",
    "Estimated value",
    "Observation obtained through an estimation methodology (e.g. to produce back-casts) or based on the use of a limited amount of data or ad hoc sampling and through "
  ],
  [
    "F",
    "Forecast value",
    'Value deemed to assess the magnitude which a quantity will assume at some future point of time (as distinct from "estimated value" which attempts to assess the magnitude '
  ],
  [
    "G",
    "Experimental value",
    "Data collected on the basis of definitions or (alternative) collection methods under development. Data not of guaranteed quality as normally expected from provider."
  ],
  [
    "I",
    "Value imputed",
    "Observation imputed by receiving agency to replace or fill gaps in data series. This code is intended to cover all cases where a receiving agency publishes data about a sending "
  ],
  [
    "K",
    "Data included in another category",
    "This code is used when data for a given category are missing and are included in another category, sub-total or total. Generally where code “K” is used there should be a "
  ],
  [
    "W",
    "Includes data from another category",
    'This code is used when data include another category, or go beyond the scope of the data collection and are therefore over-covered. Generally, where code "W" is used there '
  ],
  [
    "O",
    "Missing value",
    "This code is to be used when no breakdown is made between the reasons why data are missing."
  ],
  [
    "M",
    "Missing value; data cannot exist",
    "This code is used to denote empty cells resulting from the impossibility to collect a statistical value (e.g. a particular education level or type of institution may be not "
  ],
  [
    "P",
    "Provisional value",
    'An observation is characterised as "provisional" when the source agency – while it bases its calculations on its standard production methodology – considers that the data, almost certainly, are expected to be revised.'
  ],
  [
    "S",
    "Strike and other special events",
    "Special circumstances (e.g. strike) affecting the observation or causing a missing value."
  ]
];
