import { ExternalLink } from "@atoms";
import { LocalizedLink } from "@particles";
import React from "react";

const GenericeNewTabLink = ({ link, children, className = "" }) => {
  if (link?.startsWith("http")) {
    return (
      <ExternalLink className={className} href={link}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <LocalizedLink
      className={className}
      to={link?.startsWith("/") ? link : `/${link}`}
      target="_blank">
      {children}
    </LocalizedLink>
  );
};

export default GenericeNewTabLink;
