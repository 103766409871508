import { DataVisualizationCarousel } from "@organisms";
import { CardsLoader } from "@particles";
import React from "react";
import { FormattedMessage } from "react-intl";
import Styles from "./styles";

const DataVisualization = ({ dir, dataVisItems, loading }) => {
  return (
    <Styles>
      <section className="data-visulization w-100 py-3 py-lg-5">
        <div className="container mx-auto">
          <h1 className="font-md text-50px text-white text-center mb-3">
            <FormattedMessage defaultMessage="Data Visualization" />
          </h1>
          <div className="mt-3">
            {!loading && (
              <DataVisualizationCarousel dir={dir} dataVisItems={dataVisItems} />
            )}

            {loading && <CardsLoader />}
          </div>
        </div>
      </section>
    </Styles>
  );
};

export default DataVisualization;
