import { StyledButton } from "@atoms";
import { pageRoutes } from "@constants";
import { useLocalizedNavigate } from "@hooks";
import { RightArrowIcon, SearchIcon } from "@icons";
import { SpinnerLoader } from "@particles";
import { GenericeNewTabLink } from "@molecules";
import { AppTemplate } from "@templates";
import { keyTopicsApi } from "api";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, FormControl, InputGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Content, Hero } from "./styles";

export default function DataHighlights() {
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const [searchParams] = useSearchParams();
  const keyTopicId = searchParams.get("keyTopicId");
  const navigate = useLocalizedNavigate();

  useEffect(() => {
    if (!keyTopicId) {
      navigate(pageRoutes.keyTopics);
    }
  }, [keyTopicId, navigate]);

  const { isLoading, data } = useQuery(["key-topics", "details", keyTopicId], () =>
    keyTopicsApi.getDetails(keyTopicId)
  );

  const datahighlights = useMemo(() => {
    let newDatahighlights = data?.data?.links || [];

    if (search) {
      newDatahighlights = newDatahighlights?.filter((data) =>
        data.name?.toLocaleUpperCase()?.includes(search?.toLocaleUpperCase())
      );
    }

    return newDatahighlights;
  }, [search, data]);

  return (
    <AppTemplate title={"Data highlights"}>
      <div className="data-highlights d-flex flex-column">
        <Hero>
          <div className="container">
            <h2>
              <FormattedMessage defaultMessage="Data Highlights" />
            </h2>
            <InputGroup className="mb-3" onChange={onSearchChange}>
              <FormControl
                placeholder={intl.formatMessage({
                  defaultMessage: "Search for Data"
                })}
                aria-label="Search for Data"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text itemType="search" id="basic-addon2">
                <div className="search-icon">
                  <SearchIcon width="2rem" />
                </div>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Hero>
        <Content>
          {isLoading && <SpinnerLoader />}
          {!isLoading && datahighlights.length > 0 && (
            <ul className="flex-1 d-flex flex-column justify-content-center">
              {datahighlights?.map?.((item, i) => {
                return (
                  <li
                    key={i}
                    className={`py-4 d-flex flex-column justify-content-center ${
                      datahighlights.length > 1 ? "flex-1" : ""
                    }`}>
                    <div className="container text-dark">
                      {item.date && <span>{item.date}</span>}
                      <h3 className="mt-2">{item.name}</h3>
                      <p className="mb-3">{item.summary}</p>

                      {item.link && (
                        <GenericeNewTabLink link={item.link}>
                          <span className="text-14px font-bold text-dark me-3">
                            <FormattedMessage defaultMessage="Read more" />
                          </span>
                          <StyledButton circle>
                            <RightArrowIcon />
                          </StyledButton>
                        </GenericeNewTabLink>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}

          {!isLoading && datahighlights.length === 0 && (
            <div className="container my-5">
              <Alert>
                <FormattedMessage defaultMessage="No datahighlights found" />
              </Alert>
            </div>
          )}
        </Content>
      </div>
    </AppTemplate>
  );
}
