import { breakpoints } from "@constants";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .header {
    height: var(--top-header-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &__container {
      margin: 0 auto;
      width: 98%;
      ${respondTo.md`
        width: 92%;
      `}
      ${respondTo.xl`
        width: 85%;
      `}
      ${respondTo.xxl`
        width: 80%;
      `}
    }

    &__social-links {
      margin: -0.5rem 0;
    }

    &__logo {
      width: 8rem;
      ${respondTo.md`
        width: 12rem;
      `}
    }
    &__search {
      width: 10rem;
      ${respondTo.md`
        width: auto;
        min-width: 180px;
      `}
      &:focus-within {
        @media (max-width: ${breakpoints.sm}) {
          position: absolute;
          left: 0;
          width: 100%;
          padding: 0 5px;
        }
        background-color: var(--primary);
      }
    }
  }
`;
export default Styles;
