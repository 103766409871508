const delimiter = "___";

export function generateBlogUrlParam(blogTitle, blogId) {
  if (!blogId) return "";
  const urlizedTitle = blogTitle
    ? blogTitle
        ?.split?.(" ")
        .filter((word) => !!word)
        .join("-")
        .replaceAll(/[!*'();:@&=+$,/?%#[\]؟]+/g, "")
        .toLowerCase()
    : "";
  return [urlizedTitle, blogId].join(delimiter);
}

export function getBlogIdFromUrlParam(param) {
  if (!param) return null;

  return Number(param.split?.(delimiter)?.[1]);
}
