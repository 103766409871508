import { LanguageButton, MpedLogo } from "@atoms";
import { useSearch } from "@hooks";
import { SearchInput, SocialLinks } from "@molecules";
import classNames from "classnames";
import React from "react";

import Styles from "./styles";

const Header = ({ searchBar }) => {
  const { search, handleSearchSubmit, handleSearchChange } = useSearch();

  const onSubmit = (e) => {
    e.preventDefault();
    handleSearchSubmit();
  };
  return (
    <Styles>
      <header className="header w-100 bg-primary py-2">
        <div className="header__container mx-auto d-flex">
          <div className="mx-md-2 d-flex align-items-center">
            <LanguageButton />
          </div>
          <div className="header__social-links d-none d-md-flex align-items-center">
            <SocialLinks border />
          </div>
          <div
            className={classNames([
              "flex-grow-1 d-flex  align-items-center",
              searchBar ? "justify-content-between" : "justify-content-end"
            ])}>
            {searchBar && (
              <form
                className="header__search ms-2 ms-lg-4 d-flex h-100 align-items-center transition-all"
                onSubmit={onSubmit}>
                <SearchInput
                  inputClassName="w-100"
                  value={search}
                  onChange={handleSearchChange}
                />
              </form>
            )}
            <div className="header__logo">
              <MpedLogo />
            </div>
          </div>
        </div>
      </header>
    </Styles>
  );
};

export default Header;
