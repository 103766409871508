import styled from "styled-components";

const Styles = styled.div`
  .page-404 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      font-size: 10rem;
      font-family: Rubik;
    }
  }
`;
export default Styles;
