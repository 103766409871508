import { ChartTypeButton } from "@atoms";
import { breakpoints } from "@constants";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";
import { ARABIC, ENGLISH } from "types/languages";

const Styles = styled.div`
  .charts-viewer {
    margin: 0 -5%;

    ${respondTo.lg`
      margin: 0;
    `}

    &__chart-type {
      border-bottom: solid 1px var(--grey);

      ${respondTo.lg`
           border-bottom : none;

           &:lang(${ENGLISH}) {
                border-right: solid 1px var(--grey);
            }
            &:lang(${ARABIC}) {
                border-left: solid 1px var(--grey);
            }
        `}
    }

    &__chart-type-options {
      @media (max-width: ${breakpoints.lg}) {
        ${ChartTypeButton} {
          flex: 1;
        }

        > * + * {
          &:lang(${ENGLISH}) {
            border-left: solid 1px var(--grey);
          }
          &:lang(${ARABIC}) {
            border-right: solid 1px var(--grey);
          }
        }
      }

      ${respondTo.lg`
            > * + * {
                border-top : solid 1px var(--grey);
                border-left: none;
                border-right: none;
            }
        `}
    }

    &__empty-chart {
      height: 370px;

      ${respondTo.md`
        height: 450px;
      `}
    }

    &__chart {
      min-height: 320px;
      overflow-x: hidden;

      ${respondTo.md`
        min-height: 400px;
      `}
    }

    &__selected-sdg {
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .empty-space {
      width: 90.2px;
    }

    &__export-menu-btn {
      position: relative;
    }
    .amcharts-amexport-menu {
      top: 0;
      left: 0;
      bottom: 30px;
      right: 0;
      width: 100%;
      height: 100%;

      &.amcharts-amexport-menu-level-1 {
        top: 100%;
        right: -100%;
      }

      li {
        height: 100%;
        width: 100%;
        background-color: var(--primary);
        opacity: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 0;
      }

      li:hover {
        color: #181818;
        background-color: #d3ab5f;
        border-color: #cea14b;
        border-top-color: rgb(206, 161, 75) !important;
        border-right-color: rgb(206, 161, 75) !important;
        border-bottom-color: rgb(206, 161, 75) !important;
        border-left-color: rgb(206, 161, 75) !important;
        opacity: 1 !important;
      }

      a {
        color: var(--light);
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 400;
      }
    }

    &__filter-years-popup {
      width: 100%;
      max-height: 400px;
      background-color: var(--primary);
    }

    .guidelines-btn {
      position: absolute;
      right: 10px;
    }

    .chart-options-btn {
      width: 17rem;
    }

    .chart-options-menu {
      width: 17rem;
      padding: 0.5rem;
      background-color: var(--greyDark);

      & > button {
        margin: 0.25rem 0;
        border: none;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
`;
export default Styles;
