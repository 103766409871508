import { FormattedMessage } from "react-intl";
import React from "react";

export { default as breakpoints } from "./breakpoints";
export { default as theme } from "./theme";
export { default as navMenuLinksData, pageRoutes } from "./menu-links-data";
export { default as socialLinksData } from "./social-links-data";
export { default as PUB_CAROUSEL_COLORS } from "./pub-carousel-colors";
export const MAX_HERO_CHARS = 500;
export const MAX_PUB_SUMMARY_CHARS = 150;
export const MAX_PUB_CARD_SUMMARY_CHARS = 250;
export const MAX_EVENT_CARD_CHARS = 200;
export const MAX_EVENT_CARD_TITLE_CHARS = 30;
export const MAX_TOPIC_CARD_CHARS = 300;
export const MAX_DATA_VIS_CARD_CHARS = 200;
export const MAX_ARTICLE_CARD_CHARS = 200;
export const MAX_BLOG_CARD_CHARS = 600;
export const MAX_ARTICLE_CARD_TITLE_CHARS = 30;
export const HOME_CAROUSEL_TITLE_MAX_CHARS = 33;
export const LANG_LOCAL_STORAGE_KEY = "lang";
export const TRANSITION_DURATION = 300;
export const toastTime = 3000;
export const DRAW_CHART_DEBOUNCE_TIME = 500;
export const GEN_ERR_MSG = (
  <FormattedMessage defaultMessage="Oops! Something went wrong" />
);
