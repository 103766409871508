import styled from "styled-components";

export const Hero = styled.div`
  background: #1d1d1d;
  padding-top: 4rem;
  padding-bottom: 4rem;

  h2 {
    margin-bottom: 2rem;
    color: white;
  }
  input,
  input:focus,
  input:active {
    background: transparent;
    border: 0;
    color: white;
    border-radius: 0;
    border-bottom: 1px solid white;
    outline: unset;
    box-shadow: unset;
  }

  .input-group-text {
    background: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid white;
    color: white;
  }

  .search-icon {
    border: 1px solid white;
    padding: 0.5rem;
    color: white;
    border-radius: 50%;
  }

  .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white !important;
    text-transform: capitalize;
  }

  .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: white !important;
    text-transform: capitalize;
  }

  .form-control:-ml-input-placeholder {
    /* IE 10+ */
    color: white !important;
    text-transform: capitalize;
  }

  .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: white !important;
    text-transform: capitalize;
  }
`;

export const Content = styled.div`
  /* min-height: 300px; */
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--light);

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  li:nth-child(even) {
    background: #eeee;
  }

  li:nth-child(odd) {
    background: #fff;
  }

  .results {
    min-height: 300px;
  }
`;
