import React, { useLayoutEffect } from "react";

import { SdgAreaChartCreator } from "classes/charts";

import Styles, { containerId } from "./styles";

const legendDivId = "legenddiv";

const SdgAreaChart = ({
  id,
  indicators,
  data,
  getExportingItems,
  xAxisProp = "year",
  multiFrequency,
  pdfmakeDocument,
  multipleYAxes,
  groupedIndicators,
  exportMenuContainerId,
  singleYAxisUnitName,
  showDataLabels,
  isMobileView,
  yAxesRanges,
  isLogarithmic
}) => {
  useLayoutEffect(() => {
    const areaChart = new SdgAreaChartCreator({
      data,
      seriesDataList: indicators,
      seriesDataGroupedByUnit: groupedIndicators,
      config: {
        containerId,
        chartId: id,
        multiFrequency,
        multipleYAxes,
        isMobileView,
        singleYAxisUnitName,
        legendDivId,
        exportMenuContainerId,
        showDataLabels,
        yAxesRanges,
        isLogarithmic,
        getExportingItems,
        xAxisProp
      }
    });

    return () => {
      areaChart.dispose();
    };
  }, [
    id,
    indicators,
    data,
    getExportingItems,
    pdfmakeDocument,
    multiFrequency,
    xAxisProp,
    multipleYAxes,
    groupedIndicators,
    exportMenuContainerId,
    singleYAxisUnitName,
    showDataLabels,
    isMobileView,
    yAxesRanges,
    isLogarithmic
  ]);

  return (
    <Styles>
      <div className="sdg-area-chart">
        <div dir="ltr" id={containerId} />
        <div className="legendContainer">
          <div id={legendDivId}></div>
        </div>
      </div>
    </Styles>
  );
};

export default SdgAreaChart;
