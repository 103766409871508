import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ChartsViewer, SdgOptionsList, SdgSummary } from "@organisms";
import Styles from "./styles";
import { localizationApi } from "api";
import { useQuery } from "react-query";
import { SpinnerLoader, toastError } from "@particles";
import {
  localizationChartOptions,
  mergeExcelRowCellsWithSameValue
} from "../charts-viewer/helpers";
import { getLocalizationChartData } from "./helpers";
import { toastInfo } from "components/particles/toasts";
import { loadChartsDataWithProp } from "../sdg-charts/helpers";
import uniqueId from "lodash/uniqueId";
import { useSearchParams } from "react-router-dom";

const idleEmptyMsg = (
  <FormattedMessage defaultMessage="Please select the governorates and thier respective data to show the charts" />
);

const summaryTextVarKey = "Localization";
const guidelinesTextVarKey = "LocalizationGuidelines";
const disclaimerTextVarKey = "LocalizationDisclaimer";

const getGroupedOptions = (name, data, idKey) => ({
  name: name,
  options: data?.map((element) => ({
    ...element,
    id: uniqueId("o-"),
    [idKey]: element?.id
  }))
});

const optionRegionIdKey = "regId";
const optionGovIdKey = "govId";

const dateProp = "date";
const topicIdSearchParamKey = "topicId";
const indicatorIdSearchParamKey = "indicatorId";

const LocalizationCharts = () => {
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedGovernoratesAndRegions, setSelectedGovernoratesAndRegions] = useState(
    []
  );
  const [series, setSeries] = useState([]);

  const chartsContainerRef = useRef();

  const [selectedIndicator, setSelectedIndicator] = useState();
  const [selectedFrequency, setSelectedFrequency] = useState();

  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  const [emptyChartMsg, setEmptyChartMsg] = useState(idleEmptyMsg);

  const [chartsDataYears, setChartsDataYears] = useState([]);

  const { isLoading, data: localizationData } = useQuery(
    "localization-data",
    localizationApi.getLocalizationData
  );

  const frequenciesOptions = useMemo(
    () => selectedIndicator?.frequencies,
    [selectedIndicator]
  );

  const governoratesAndRegionsOptions = useMemo(() => {
    const govs = localizationData?.data?.governorates || [];

    const govsOptions = getGroupedOptions(
      <FormattedMessage defaultMessage="Governorates" />,
      govs,
      optionGovIdKey
    );

    const options = [govsOptions];

    const regions = localizationData?.data?.regions;

    if (regions?.length > 0) {
      const regionsWithGovs = localizationData?.data?.regions?.map((reg) => ({
        ...reg,
        govs: localizationData?.data?.governorates?.filter(
          (gov) => gov.regionId === reg.id
        )
      }));

      const regionsOptions = getGroupedOptions(
        <FormattedMessage defaultMessage="Regions" />,
        regionsWithGovs,
        optionRegionIdKey
      );

      options.unshift(regionsOptions);
    }

    return options;
  }, [localizationData]);

  const sdgsOptions = localizationData?.data?.data;

  useEffect(() => {
    // initially select all governorates
    const govsOptions =
      governoratesAndRegionsOptions?.[governoratesAndRegionsOptions.length - 1]?.options;
    if (govsOptions) {
      setSelectedGovernoratesAndRegions([].concat(govsOptions));
    } else {
      setSelectedGovernoratesAndRegions([]);
    }
  }, [governoratesAndRegionsOptions]);

  // ===========================================
  // handle the case of topic and indicator id from url
  const [searchParams] = useSearchParams();
  const initialSelectedTopicId = Number(searchParams.get(topicIdSearchParamKey));

  const initialSelectedIndicatorId = Number(searchParams.get(indicatorIdSearchParamKey));

  useEffect(() => {
    if (initialSelectedTopicId) {
      const newTopic = localizationData?.data?.data?.find(
        (topic) => topic.id === initialSelectedTopicId
      );
      setSelectedTopic(newTopic);
    } else {
      setSelectedTopic((prev) => (prev ? null : prev));
    }
  }, [initialSelectedTopicId, localizationData?.data?.data]);

  useEffect(() => {
    if (initialSelectedIndicatorId && !!selectedTopic?.id) {
      setSelectedIndicator(
        selectedTopic?.indicators?.find((ind) => initialSelectedIndicatorId === ind.id)
      );
    } else {
      setSelectedIndicator();
    }
  }, [initialSelectedIndicatorId, selectedTopic]);

  useEffect(() => {
    if (frequenciesOptions?.length > 0 && initialSelectedIndicatorId) {
      setSelectedFrequency(frequenciesOptions?.[0]);
      chartsContainerRef.current?.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [frequenciesOptions, initialSelectedIndicatorId]);
  // ============================================

  useEffect(() => {
    const addData = () => {
      if (!selectedGovernoratesAndRegions?.length > 0 || !selectedFrequency) {
        setSeries((data) => (data.length > 0 ? [] : data));
        setChartsDataYears([]);
        return;
      }

      const body = {
        IndicatorId: selectedIndicator.id,
        FrequencyId: selectedFrequency.id,
        Governorates: selectedGovernoratesAndRegions
          .filter((g) => g[optionGovIdKey])
          .map((g) => g[optionGovIdKey]),
        Regions: selectedGovernoratesAndRegions
          .filter((r) => r[optionRegionIdKey])
          .map((r) => r[optionRegionIdKey])
      };

      setIsChartDataLoading(true);

      localizationApi
        .getIndicatorData(body)
        .then((res) => {
          const years = res?.data?.years || [];
          if (years.length > 0) {
            setChartsDataYears(years);
          }

          const data = res?.data?.values || [];

          if (data.length > 0) {
            let seriesData = selectedGovernoratesAndRegions.map((govReg) => ({
              ...govReg,
              data: data
                .filter(
                  (rec) =>
                    rec.governorateId === govReg[optionGovIdKey] ||
                    rec.regionId === govReg[optionRegionIdKey]
                )
                .map((record) => ({
                  value: record.value,
                  [dateProp]: record.timeSeries,
                  note: record.note,
                  targetValue: record.targetValue
                }))
            }));

            seriesData = seriesData.filter((govReg) => {
              if (!govReg.data?.length > 0) {
                toastInfo(
                  <FormattedMessage
                    defaultMessage="There are no data found for {name}"
                    values={{
                      name: govReg.name
                    }}
                  />
                );

                return false;
              }

              return true;
            });

            setSeries(seriesData);
          } else {
            setSeries([]);
            setEmptyChartMsg(
              <FormattedMessage defaultMessage="No data found. Please try other options" />
            );
            toastInfo(
              <FormattedMessage defaultMessage="There are no data found for the selected options" />
            );
          }
        })
        .catch((err) => {
          setSeries([]);
          setEmptyChartMsg(
            <FormattedMessage defaultMessage="Something went wrong. Please try again in a few moments" />
          );
          toastError(<FormattedMessage defaultMessage="Something went wrong!" />);
        })
        .finally(() => setIsChartDataLoading(false));
    };
    addData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGovernoratesAndRegions, selectedFrequency]);

  useEffect(() => {
    setEmptyChartMsg(idleEmptyMsg);
  }, [
    selectedGovernoratesAndRegions,
    selectedFrequency,
    selectedIndicator,
    selectedFrequency
  ]);

  const getChartData = useCallback(
    (seriesData) => getLocalizationChartData(seriesData, dateProp),
    []
  );

  const onResetChart = useCallback(() => {
    setSelectedTopic(null);
    setSelectedIndicator(null);
    setSelectedFrequency(null);
    setSeries([]);
  }, []);

  const getHeatmapData = useCallback((timeSeries, series) => {
    if (!timeSeries) {
      return {};
    }
    return {
      govs: series
        ?.filter((gov) => gov[optionGovIdKey])
        ?.map?.((gov) => ({
          id: gov.geoId, // this id should be the same as id of the gov in the map geo data
          name: gov.name,
          value: gov?.data?.find((record) => record[dateProp] === timeSeries)?.value || 0
        })),

      regions: series
        ?.filter((reg) => reg[optionRegionIdKey])
        ?.map?.((reg) => {
          const value =
            reg?.data?.find((record) => record[dateProp] === timeSeries)?.value || 0;

          return {
            name: reg.name,
            govs: reg.govs?.map((gov) => ({ ...gov, id: gov.geoId, value })), // map them to use in the map chart
            value
          };
        })
    };
  }, []);

  const intl = useIntl();

  const handleExcelDataSheet = useCallback(
    (sheet, chartsData, series) => {
      const keys = Object.keys(chartsData?.[0]);
      const governoratesNames = series.map((s) => s.name);

      const dataColumns = governoratesNames.flatMap((name) => [
        {
          key: name,
          title: selectedIndicator?.name
        },
        {
          key: `${name}-note`,
          title: intl.formatMessage({
            defaultMessage: "Comment"
          })
        }
      ]);

      sheet.columns = [{ key: keys[0] }, ...dataColumns];

      const row = sheet.addRow([
        intl.formatMessage({ defaultMessage: "Governorate" }),
        ...governoratesNames.flatMap((name) => [name, name])
      ]);

      mergeExcelRowCellsWithSameValue(sheet, row);

      const lastHeaderRow = sheet.addRow([
        intl.formatMessage({ defaultMessage: "Indicator" }),
        ...dataColumns.map((el) => el.title)
      ]);

      const chartsDataWithNotes = loadChartsDataWithProp("note", chartsData, series);

      sheet.addRows(chartsDataWithNotes);

      return lastHeaderRow.number;
    },
    [intl, selectedIndicator?.name]
  );

  const getGovOptionTooltip = useCallback(
    (option) => {
      const checkIsRegionOfGovSelected = (gov) => {
        if (!gov[optionGovIdKey]) return false;
        return selectedGovernoratesAndRegions?.some(
          (reg) => reg[optionRegionIdKey] && reg[optionRegionIdKey] === gov.regionId
        );
      };

      if (checkIsRegionOfGovSelected(option)) {
        return (
          <FormattedMessage defaultMessage="Selected governorates belong to selected region won't be shown on the map" />
        );
      }

      return null;
    },
    [selectedGovernoratesAndRegions]
  );

  const chartsViewerTitle = useMemo(
    () =>
      selectedTopic?.name && selectedIndicator?.name ? (
        <>
          {selectedTopic?.name}
          {" - "}
          <em>{selectedIndicator?.name}</em>
        </>
      ) : (
        ""
      ),
    [selectedTopic?.name, selectedIndicator?.name]
  );

  const indicatorsMetadata = useMemo(
    () => (selectedIndicator ? [selectedIndicator] : []),
    [selectedIndicator]
  );

  const handleChangeTopic = useCallback((newTopic) => {
    setSelectedTopic(newTopic);
    setSelectedIndicator(null);
    setSelectedFrequency(null);
  }, []);

  const handleChangeIndicator = useCallback((newInd) => {
    setSelectedIndicator(newInd);
    setSelectedFrequency(null);
  }, []);

  return (
    <Styles>
      <div className="localization-charts w-100">
        {isLoading && <SpinnerLoader />}

        <SdgSummary textVarKey={summaryTextVarKey} />

        <div className="w-100 pt-5">
          <div className="d-block">
            <div className="localization-charts__options">
              <SdgOptionsList
                id="select-governorate"
                title={intl.formatMessage({
                  defaultMessage: "Select Governorates & Regions"
                })}
                options={governoratesAndRegionsOptions}
                search={true}
                values={selectedGovernoratesAndRegions}
                onChange={setSelectedGovernoratesAndRegions}
                multi
                grouped
                groupLabelProp={"name"}
                labelProp="name"
                enableSelectAll
                disabled={isChartDataLoading}
                tooltip={getGovOptionTooltip}
              />

              {selectedGovernoratesAndRegions?.length > 0 && (
                <SdgOptionsList
                  id="select-topic"
                  title={intl.formatMessage({ defaultMessage: "Select a topic" })}
                  options={sdgsOptions}
                  search={false}
                  value={selectedTopic}
                  onChange={handleChangeTopic}
                  disabled={isChartDataLoading}
                />
              )}

              {selectedTopic && (
                <SdgOptionsList
                  id="select-indicator"
                  title={intl.formatMessage({ defaultMessage: "Select Indicator" })}
                  options={selectedTopic?.indicators}
                  search={false}
                  value={selectedIndicator}
                  onChange={handleChangeIndicator}
                  disabled={isChartDataLoading}
                />
              )}

              {selectedIndicator && (
                <SdgOptionsList
                  id="select-frequency"
                  title={intl.formatMessage({ defaultMessage: "Select Frequency" })}
                  options={frequenciesOptions}
                  search={false}
                  value={selectedFrequency}
                  onChange={setSelectedFrequency}
                  disabled={isChartDataLoading}
                />
              )}
            </div>

            <div className="localization-charts__chart-container">
              <div ref={chartsContainerRef} className="localization-charts__chart">
                <ChartsViewer
                  chartOptions={localizationChartOptions}
                  id="localization"
                  indicators={series}
                  isLoading={isChartDataLoading}
                  title={chartsViewerTitle}
                  emptyChartMsg={emptyChartMsg}
                  onResetChart={onResetChart}
                  multiFrequency
                  xAxisProp={dateProp}
                  getHeatmapData={getHeatmapData}
                  indicatorsMetadata={indicatorsMetadata}
                  guidelinesTextVarKey={guidelinesTextVarKey}
                  disclaimerTextVarKey={disclaimerTextVarKey}
                  handleExcelDataSheet={handleExcelDataSheet}
                  selectedFrequency={selectedFrequency?.id}
                  chartsDataYears={chartsDataYears}
                  isSortHeaders
                  getChartData={getChartData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default LocalizationCharts;
