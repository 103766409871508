import styled from "styled-components";
import { respondTo } from "helpers/style/respond-to";
import { ARABIC, ENGLISH } from "types/languages";

const Styles = styled.div`
  .home-indicators {
    background-color: #efebdf;
    padding: 5rem 0;
    ${respondTo.xxl`
        padding: 6rem 0;
    `}

    .container {
      ${respondTo.lg`
        max-width: 990px !important;
        padding: 0 !important;
     `}

      ${respondTo.xl`
        max-width: 1040px !important;
        padding: 0 !important;
     `}

      ${respondTo.xxl`
        max-width: 1300px !important;
        padding: 0 !important;
     `}
    }

    .swiper-container {
      position: relative;
    }

    .swiper {
      height: 100%;
      padding-bottom: 2.5rem;
      position: unset;

      ${respondTo.lg`
        width: 920px;
     `}

      ${respondTo.xl`
        width: 960px;
     `}

      ${respondTo.xxl`
         width: 1200px;
     `}
    }

    .swiper-pagination {
      ${respondTo.lg`
        width: 960px;
        right: 15px;
        left: 15px;
     `}

      ${respondTo.xl`
        width: 960px;
        right: 40px;
        left: 40px;
     `}

      ${respondTo.xxl`
         width: 1200px;
         right: 50px;
         left: 50px;
     `}
    }

    .swiper-slide {
      height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: var(--primary);

      &::after {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }

    .swiper-button-disabled {
      display: none !important;
    }

    .swiper-button-next:lang(${ENGLISH}),
    .swiper-button-prev:lang(${ARABIC}) {
      right: 2px !important;
    }

    .swiper-button-prev:lang(${ENGLISH}),
    .swiper-button-next:lang(${ARABIC}) {
      left: 2px !important;
    }

    .swiper-pagination-bullets {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .swiper-pagination-bullet {
      margin: 0;
      flex: 1;
      height: 2px;
      border-radius: 0;
      background-color: var(--dark);
      opacity: 1;
      &-active {
        height: 4px;
        background-color: var(--primary);
      }
    }
  }
`;
export default Styles;
