import { FormattedMessage } from "react-intl";
import React from "react";

export const getLocalizationChartData = (governorates, dateProp = "date") => {
  const data = [];

  const found = governorates.find((governorate) => !governorate?.data);

  if (!found) {
    governorates?.forEach((governorate) => {
      governorate?.data?.forEach((element) => {
        const dataIndex = data.findIndex((e) => e[dateProp] == element[dateProp]);

        const isExist = dataIndex >= 0;

        if (isExist) {
          data[dataIndex][governorate.name] = element.value;
        } else {
          data.push({ [dateProp]: element[dateProp], [governorate.name]: element.value });
        }
      });
    });

    const getTimeSeriesParts = (date) => {
      if (!date || typeof date !== "string") return [];
      const [year, frequency] = date.split(" - ");
      return { year: Number(year), freq: Number(frequency?.slice(1)) };
    };

    data?.sort((a, b) => {
      const aParts = getTimeSeriesParts(a?.[dateProp]);
      const bParts = getTimeSeriesParts(b?.[dateProp]);

      if (aParts.year !== bParts.year) {
        return aParts.year - bParts.year;
      }
      return aParts.freq - bParts.freq;
    });

    return data;
  }

  return [];
};

const IndicatorFrequencyEnum = {
  Annual: 1,
  Semi_annual: 2,
  Monthly: 3,
  Quarterly: 4
};

const frequenciesTitles = {
  [IndicatorFrequencyEnum.Annual]: {
    name: <FormattedMessage defaultMessage="Year" />,
    plural: <FormattedMessage defaultMessage="Years" />
  },
  [IndicatorFrequencyEnum.Semi_annual]: {
    name: <FormattedMessage defaultMessage="Semester" />,
    plural: <FormattedMessage defaultMessage="Semesters" />
  },
  [IndicatorFrequencyEnum.Monthly]: {
    name: <FormattedMessage defaultMessage="Month" />,
    plural: <FormattedMessage defaultMessage="Months" />
  },
  [IndicatorFrequencyEnum.Quarterly]: {
    name: <FormattedMessage defaultMessage="Quarter" />,
    plural: <FormattedMessage defaultMessage="Quarters" />
  }
};

export function getFrequencyName(freq) {
  const freqTitles =
    frequenciesTitles[freq] || frequenciesTitles[IndicatorFrequencyEnum.Annual];

  return [freqTitles.name, freqTitles.plural];
}
