import React from "react";
import ContentLoader from "react-content-loader";

const ImageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      backgroundColor="#a9a7a7"
      foregroundColor="#969292">
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
    </ContentLoader>
  );
};

export default ImageLoader;
