import React from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

const LocalizedNavLink = ({ to, ...props }) => {
  const { locale } = useIntl();

  if (!to) {
    return null;
  }

  let link = to;
  if (to?.startsWith("/")) {
    link = `/${locale + to}`;
  }

  return <NavLink {...props} to={link} />;
};

export default LocalizedNavLink;
