import { StyledButton } from "@atoms";
import { RightArrowIcon } from "@icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import truncate from "lodash/truncate";
import Styles from "./styles";
import {
  HOME_CAROUSEL_TITLE_MAX_CHARS,
  MAX_TOPIC_CARD_CHARS,
  pageRoutes,
  theme
} from "@constants";
import GenericLink from "../generic-link";

const KeyTopicCard = ({ name, iconUrl, summary, id, color }) => {
  const link = `${pageRoutes.keyTopics}/${id}`;

  return (
    <Styles>
      <div className="key-topic-card w-100 p-4">
        <div className="clearfix">
          {iconUrl && (
            <div className="key-topic-card__img-container float-start me-3 mb-1">
              <div className="key-topic-card__img">
                <div
                  className="w-100 h-100"
                  style={{
                    backgroundColor: color || theme.colors.primary
                  }}>
                  {iconUrl && <img src={iconUrl} alt={name} />}
                </div>
              </div>
            </div>
          )}
          <div className="key-topic-card__body">
            {name && (
              <h4 className="text-black font-md text-25px mb-3">
                {truncate(name, { length: HOME_CAROUSEL_TITLE_MAX_CHARS })}
              </h4>
            )}
            {summary && (
              <p className="text-black text-16px">
                {truncate(summary, { length: MAX_TOPIC_CARD_CHARS })}
              </p>
            )}

            {id && (
              <GenericLink link={link}>
                <span className="font-bold text-black text-11px uppercase me-2">
                  <FormattedMessage defaultMessage="read more" />
                </span>
                <StyledButton circle size="lg">
                  <RightArrowIcon />
                </StyledButton>
              </GenericLink>
            )}
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default KeyTopicCard;
