import styled from "styled-components";

const Styles = styled.div`
  .articles-carousel {
    .swiper {
      height: 100%;
      padding-bottom: 50px;
      padding-left: 2.8rem;
      padding-right: 2.8rem;
    }
    .swiper-slide {
      height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: var(--primary);
    }

    .swiper-pagination-bullets {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .swiper-pagination-bullet {
      margin: 0;
      flex: 1;
      height: 2px;
      border-radius: 0;
      background-color: var(--dark);
      opacity: 1;
      &-active {
        height: 4px;
        background-color: var(--primary);
      }
    }
  }
`;
export default Styles;
