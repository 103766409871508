import { breakpoints } from "@constants";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.button`
  height: 100%;
  font-size: 3.8rem;
  font-weight: 500;
  padding: 1rem;
  color: var(--light);
  background-color: ${(props) => props.bgColor || `var(--primary)`};
  appearance: none;
  display: flex;
  flex-direction: row;
  border: none;
  flex: 1;
  align-items: center;

  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.4) inset;
  -webkit-box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.4) inset;
  -moz-box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.4) inset;

  &:focus {
    outline: none;
  }
  .index {
    opacity: 0.5;
    line-height: 1;
  }

  .text {
    flex: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.5rem;
  }

  @media (min-width: ${(props) => props.vertModeBreakPoint || breakpoints.lg}) {
    flex-direction: column;
    padding: 1rem 0;
    .text {
      flex: 1;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      ${respondTo.xl`
        font-size: 2rem;
    `}
    }
  }
`;
export default Styles;
