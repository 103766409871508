import React from "react";
import ReactDOM from "react-dom";

import { Provider as StoreProvider } from "react-redux";

import { QueryClient, QueryClientProvider } from "react-query";

import LocalizedRouter from "localized-router";

import store from "./redux/store";

import App from "./App";
import { ToastContainer } from "react-toastify";
import { toastTime } from "@constants";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: "always"
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <LocalizedRouter>
          <ToastContainer
            theme="colored"
            position="top-right"
            autoClose={toastTime}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover={false}
            pauseOnFocusLoss={false}
          />
          <App />
        </LocalizedRouter>
      </QueryClientProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
