import styled from "styled-components";
import { ENGLISH } from "types/languages";

const Styles = styled.div`
  .language-button {
    font-weight: 500;
    font-family: ${(props) => (props.lang === ENGLISH ? "ArbFONTS" : "Rubik")};
    &.btn-link {
      text-decoration: none;
    }
  }
`;
export default Styles;
