import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/gov-snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#C79537";

const GovStatisticalSnapshot = ({
  governorateInfo,
  indicators = [],
  sources,
  socialLinks
}) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...governorateInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.main}>
            <View style={{ ...styles.section, width: "30%" }}>
              <Image
                src={governorateInfo?.snapshotMapImgUrl}
                alt={governorateInfo?.name}
                style={{ width: "100%" }}
              />
            </View>

            <View style={{ ...styles.section, width: "45%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[0] && <Indicator {...indicators[0]} />}
                {indicators[1] && <Indicator {...indicators[1]} />}
              </View>
            </View>

            {indicators[2] && (
              <View style={{ ...styles.section, ...styles.side }}>
                <Indicator {...indicators[2]} stretch={false} iconWidth="1.5cm" />
                <View style={styles.hr} />
              </View>
            )}

            <View
              style={{
                ...styles.section,
                ...styles.sectionWithBg,
                width: "75%"
              }}>
              <View style={styles.indicatorsContainer}>
                {indicators[3] && <Indicator {...indicators[3]} />}
                {indicators[4] && <Indicator {...indicators[4]} />}
                {indicators[5] && (
                  <Indicator {...indicators[5]} iconHeight="27pt" iconWidth="21.6pt" />
                )}
              </View>
            </View>

            {indicators[6] && (
              <View style={{ ...styles.section, ...styles.side }}>
                <Indicator {...indicators[6]} stretch={false} />
                <View style={styles.hr} />
              </View>
            )}

            <View style={{ ...styles.section, paddingTop: "0.5cm", width: "75%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[7] && <Indicator {...indicators[7]} iconWidth="2.34cm" />}
                {indicators[8] && <Indicator {...indicators[8]} iconWidth="2.34cm" />}
                {indicators[9] && <Indicator {...indicators[9]} iconWidth="2.34cm" />}
              </View>
            </View>

            {indicators[10] && (
              <View style={{ ...styles.section, ...styles.side, borderBottom: "none" }}>
                <Indicator {...indicators[10]} stretch={false} />
              </View>
            )}
          </View>

          <View
            style={{
              ...styles.section,
              ...styles.sectionWithBg,
              marginTop: "0.5cm",
              width: "100%"
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[11] && <Indicator {...indicators[11]} />}
              {indicators[12] && <Indicator {...indicators[12]} />}
              {indicators[13] && <Indicator {...indicators[13]} />}
              {/* {indicators[14] && <Indicator {...indicators[14]} />} */}
              <View style={{ width: "23%" }} />
            </View>
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default GovStatisticalSnapshot;
