import React from "react";

import { DateIcon, LocationIcon } from "@icons";
import { AppTemplate } from "@templates";
import { EventCarousel } from "@organisms";
import { ShareComponent } from "@molecules";
import { Tag } from "@atoms";
import { HTMLComponent, SpinnerLoader } from "@particles";

import Styles from "./styles";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { eventsApi } from "api";
import { FormattedMessage, useIntl } from "react-intl";
import isEmpty from "lodash/isEmpty";
import { Alert } from "react-bootstrap";
import { format } from "date-fns";
import { getDateLocale } from "@utils";
import { ARABIC } from "types/languages";

const getFormattedDate = (date) => {
  if (!date) return "";
  return format(new Date(date), "PP", { locale: getDateLocale() });
};

const EventDetails = () => {
  const { id } = useParams();

  const { isLoading, data } = useQuery(["event", "details", id], () =>
    eventsApi.getDetails(id)
  );

  const event = data?.data ?? {};

  const {
    mediaFiles,
    title,
    summary,
    publishDate,
    description,
    location,
    eventType,
    dates
  } = event;

  const locale = useIntl().locale;
  const dir = locale === ARABIC ? "rtl" : "ltr";

  const isEmptyEvent = isEmpty(event);

  return (
    <AppTemplate title={title || "Event"}>
      <Styles>
        <div className="event">
          {isLoading && <SpinnerLoader />}
          {!isLoading && isEmptyEvent && (
            <div className="container">
              <div className="w-100 py-5">
                <Alert>
                  <FormattedMessage defaultMessage="something went wrong fetching this event" />
                </Alert>
              </div>
            </div>
          )}
          {!isLoading && !isEmptyEvent && (
            <>
              <div className="container">
                <header>
                  {eventType && (
                    <div className="event__type">
                      <Tag>{eventType}</Tag>
                    </div>
                  )}

                  <div className="event__title">
                    <h1 className="text-50px">{title}</h1>
                  </div>

                  <div className="event__summary">
                    <p className="text-20px">{summary}</p>
                  </div>
                </header>

                <div className="event__date-location d-flex align-items-center">
                  {publishDate && (
                    <div className="date">
                      <div className="d-flex align-items-center">
                        <div className="date__icon me-2">
                          <DateIcon width="1.5rem" />
                        </div>

                        <div className="date__text">{getFormattedDate(publishDate)}</div>
                      </div>
                    </div>
                  )}

                  {location && (
                    <div className="location">
                      <div className="d-flex align-items-center">
                        <div className="location-icon me-2">
                          <LocationIcon />
                        </div>

                        <div className="location-text">{location}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="events__carousel">
                <EventCarousel
                  items={mediaFiles}
                  imageProp="filePath"
                  captionProp="caption"
                  dir={dir}
                />
              </div>

              <div className="container">
                <div className="d-md-flex">
                  <HTMLComponent
                    className="event__des text-18px me-md-4 mb-5 mb-md-0"
                    html={description}
                  />

                  <div className="event__info-share ms-md-4">
                    <div className="info">
                      <div className="event__date-location mb-5">
                        <div className="date mb-4">
                          {dates?.map?.((date, i) => (
                            <div key={i} className="d-flex align-items-center my-2">
                              <div className="date__icon me-2">
                                <DateIcon width="1.5rem" />
                              </div>

                              <div className="date__text">{getFormattedDate(date)}</div>
                            </div>
                          ))}
                        </div>

                        {location && (
                          <div className="location mb-4">
                            <div className="d-flex align-items-center">
                              <div className="location__icon me-2">
                                <LocationIcon />
                              </div>

                              <div className="location__text">{location}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="share">
                      <div className="share__text mb-2">
                        <FormattedMessage defaultMessage="Share" />
                      </div>
                      <div className="share__icons">
                        <ShareComponent title={title} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default EventDetails;
