import React from "react";
import { StyledButton } from "@atoms";
import Styles from "./styles";
import { CloseIcon } from "@icons";

const FilterOptionButton = ({ children, active, onClick, onCancelClick }) => {
  return (
    <Styles>
      <StyledButton
        variant={active ? "light" : "outline-light"}
        className="rounded-0 w-100"
        onClick={onClick}>
        {children}
      </StyledButton>
      {onCancelClick && (
        <button className="cancel-btn" onClick={onCancelClick}>
          <CloseIcon />
        </button>
      )}
    </Styles>
  );
};

export default FilterOptionButton;
