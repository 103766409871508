import React from "react";

import LoadableComponent from "@loadable/component";

import SpinnerLoader from "../spinner-loader";

const Loadable = (component) =>
  LoadableComponent(component, { fallback: <SpinnerLoader /> });

export default Loadable;
