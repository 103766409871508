export const metaInformation = [
  ["تاريخ اصدار الأهداف:  4-Feb-2022"],
  ["تاريخ الاستخراج: 25-6-2022"],
  ["Source Type: International and national"],
  ["الهدف 1: الهدف 1: لا فقر"],
  [],
  []
];

export const tableRows = [
  [
    "A",
    "قيمة عادية",
    "يتم استخدام هذا الرمز كرمز افتراضي إذا لم يتم توفير قيمة أو في حالة عدم افتراض مؤهل مشفر خاص. عادة ، يمكن افتراض أن وكالة المصدر تقوم بتعيين ما يكفي"
  ],
  [
    "B",
    "كسر السلسلة الزمنية",
    "يوجد محتوى مختلف أو تم تطبيق منهجية مختلفة على هذه الملاحظة مقارنة بالمحتوى السابق (الذي تم تقديمه للفترة السابقة)."
  ],
  [
    "D",
    "التعريف يختلف",
    "تُستخدم للإشارة إلى انحرافات طفيفة عن المنهجية المعمول بها (معلومات من نوع الحاشية السفلية) ؛ هذه الاختلافات لا تعني انقطاع السلاسل الزمنية."
  ],
  [
    "E",
    "القيمة المقدرة",
    "الملاحظة التي يتم الحصول عليها من خلال منهجية تقدير (على سبيل المثال لإنتاج قوالب خلفية) أو بناءً على استخدام كمية محدودة من البيانات أو أخذ عينات مخصصة ومن خلال"
  ],
  [
    "F",
    "القيمة المتوقعة",
    'تعتبر القيمة لتقييم الحجم الذي ستفترضه الكمية في وقت ما في المستقبل (على عكس "القيمة المقدرة" التي تحاول تقييم الحجم'
  ],
  [
    "G",
    "القيمة التجريبية",
    "تم جمع البيانات على أساس التعاريف أو طرق الجمع (البديلة) قيد التطوير. البيانات ليست مضمونة الجودة كما هو متوقع عادة من المزود."
  ],
  [
    "I",
    "القيمة المنسوبة",
    "تنسب الملاحظة من قبل الوكالة المستقبلة لاستبدال أو سد الثغرات في سلسلة البيانات. يهدف هذا الرمز إلى تغطية جميع الحالات التي تنشر فيها الوكالة المستقبلة بيانات حول الإرسال"
  ],
  [
    "K",
    "البيانات المدرجة في فئة أخرى",
    'يتم استخدام هذا الرمز عندما تكون البيانات الخاصة بفئة معينة مفقودة ويتم تضمينها في فئة أخرى أو إجمالي فرعي أو إجمالي. بشكل عام عند استخدام الكود "K" ، يجب أن يكون هناك ملف'
  ],
  [
    "W",
    "يتضمن بيانات من فئة أخرى",
    'يتم استخدام هذا الرمز عندما تتضمن البيانات فئة أخرى ، أو تتجاوز نطاق جمع البيانات وبالتالي يتم تغطيتها بشكل مبالغ فيه. بشكل عام ، حيث يتم استخدام الرمز "W" هناك'
  ],
  [
    "O",
    "قيمة مفقودة",
    "يتم استخدام هذا الرمز في حالة عدم وجود تفصيل بين أسباب فقدان البيانات."
  ],
  [
    "M",
    "قيمة مفقودة؛ لا يمكن أن توجد البيانات",
    "يستخدم هذا الرمز للإشارة إلى الخلايا الفارغة الناتجة عن استحالة جمع قيمة إحصائية (على سبيل المثال ، قد لا يكون مستوى تعليمي معين أو نوع مؤسسة"
  ],
  [
    "P",
    "القيمة المؤقتة",
    'توصف الملاحظة بأنها "مؤقتة" عندما ترى الوكالة المصدر - بينما تبني حساباتها على منهجية الإنتاج المعيارية - أن البيانات ، من شبه المؤكد ، من المتوقع مراجعتها.'
  ],
  [
    "S",
    "الإضراب والمناسبات الخاصة الأخرى",
    "الظروف الخاصة (مثل الإضراب) التي تؤثر على الملاحظة أو تسبب قيمة مفقودة."
  ]
];
