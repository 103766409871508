import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";
import { ARABIC, ENGLISH } from "types/languages";

const Styles = styled.div`
  .blog {
    margin-top: 2.8rem;
    margin-bottom: 6rem;

    header {
      margin-bottom: 2rem;
    }

    &__title {
      h1 {
        font-size: 2rem;
      }
    }

    &__topics {
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      > * {
        margin-bottom: 1rem;

        &:lang(${ARABIC}) {
          margin-left: 1rem;
        }

        &:lang(${ENGLISH}) {
          margin-right: 1rem;
        }
      }
    }

    &__date-location {
      .date {
        margin-right: 5rem;
      }

      [dir="rtl"] & .date {
        margin-right: 0;
        margin-left: 5rem;
      }
    }

    &__carousel {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
      /* @media (max-width: 768px) {
        margin-top: 3.5rem;
        margin-bottom: 4rem;
      } */
    }

    &__des {
      flex-basis: 70%;

      > p:first-of-type:first-letter {
        font-size: 5.25rem;
        margin-right: 6px;
        line-height: 1;
        float: left;
        color: var(--primary);
      }

      [dir="rtl"] & > p:first-of-type:first-letter {
        margin-right: 0;
        margin-left: 6px;
        float: right;
      }

      .pillars {
        color: #319f8f;
      }

      a {
        color: var(--primary);
      }
    }

    &__sources {
      flex-basis: 70%;

      .pillars {
        color: #319f8f;
      }

      a {
        color: var(--primary);
      }
    }

    &__info-share {
      flex-basis: 30%;
    }

    .date__text {
      margin-top: 2px;
    }

    &__authors-list {
      display: grid;
      grid-template-columns: 1;
      gap: 1rem;

      > * {
        border-bottom: solid 1px var(--greyLight);
      }
    }

    .border-md-end:lang(${ENGLISH}) {
      ${respondTo.md`
          border-right: solid 1px var(--greyLight);
      `}
    }

    .border-md-end:lang(${ARABIC}) {
      ${respondTo.md`
          border-left: solid 1px var(--greyLight);
      `}
    }
  }
`;

export default Styles;
