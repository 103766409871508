import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .publication-details {
    &__content {
      width: 90%;
      ${respondTo.md`
        width: 80%;
      `}

      ${respondTo.xl`
        width: 60%;
      `}
      ${respondTo.xxl`
        width: 60%;
      `}
    }

    &__description {
      * {
        font-family: "Rubik" !important;
        font-size: 1rem !important;
        line-height: 150% !important;
      }
    }

    &__img {
      position: relative;
      width: 100%;
      padding-top: 120%;
      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`;
export default Styles;
