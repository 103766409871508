import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#A31D43";

const StatisticalSnapshot8 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={{ ...styles.section, padding: "0 10pt" }}>
            <Image
              src={sdgInfo?.snapshotImageURL}
              alt={sdgInfo?.name}
              style={{ width: "100%" }}
            />
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[0] && <Indicator {...indicators[0]} />}
              {indicators[1] && <Indicator {...indicators[1]} />}
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[2] && <Indicator {...indicators[2]} />}
              {indicators[3] && <Indicator {...indicators[3]} />}
              {indicators[4] && <Indicator {...indicators[4]} />}
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[5] && <Indicator {...indicators[5]} />}
              {indicators[6] && <Indicator {...indicators[6]} />}
              {indicators[7] && <Indicator {...indicators[7]} />}
            </View>
          </View>

          <View style={{ ...styles.section, ...styles.sectionWithBg }}>
            <View style={styles.indicatorsContainer}>
              {indicators[8] && <Indicator {...indicators[8]} />}
              {indicators[9] && <Indicator {...indicators[9]} />}
              {indicators[10] && <Indicator {...indicators[10]} />}
            </View>
          </View>

          <View style={{ ...styles.section, ...styles.sectionWithBg }}>
            <View style={styles.indicatorsContainer}>
              {indicators[11] && <Indicator {...indicators[11]} />}
              {indicators[12] && <Indicator {...indicators[12]} />}
              {indicators[13] && <Indicator {...indicators[13]} />}
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[14] && (
                <Indicator {...indicators[14]} horiz iconWidth="2.34cm" />
              )}
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[15] && <Indicator {...indicators[15]} />}
              {indicators[16] && <Indicator {...indicators[16]} />}
            </View>
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot8;
