import Tippy from "@tippyjs/react";
import React from "react";
import Styles from "./styles";

const SdgCircleButton = ({ tooltip, ...props }) => {
  return (
    <Tippy content={tooltip} disabled={!tooltip}>
      <Styles {...props}>
        {props.iconUrl && (
          <img className="w-50" src={props.iconUrl} alt="SDGs principle" />
        )}
      </Styles>
    </Tippy>
  );
};

export default SdgCircleButton;
