import styled from "styled-components";

const Styles = styled.div`
  .event {
    margin-top: 3.5rem;
    margin-bottom: 6rem;

    header {
      margin-bottom: 4.5rem;
    }

    ul {
      list-style-type: none;

      font-size: 1.5rem;
    }

    .event__type {
      margin-bottom: 0.7rem;
    }

    .event__summary {
    }

    .event__date-location {
      .date {
        margin-right: 5rem;
      }

      [dir="rtl"] & .date {
        margin-right: 0;
        margin-left: 5rem;
      }
    }

    .events__carousel {
      margin-top: 4.5rem;
      margin-bottom: 5rem;

      @media (max-width: 768px) {
        margin-top: 3.5rem;
        margin-bottom: 4rem;
      }
    }

    .event__des {
      flex-basis: 70%;

      > p:first-of-type:first-letter {
        font-size: 5.25rem;
        margin-right: 6px;
        line-height: 1;
        float: left;
        color: var(--primary);
      }

      [dir="rtl"] & > p:first-of-type:first-letter {
        margin-right: 0;
        margin-left: 6px;
        float: right;
      }

      .pillars {
        color: #319f8f;
      }
    }

    .event__info-share {
      flex-basis: 30%;
    }

    .date__text {
      margin-top: 2px;
    }
  }
`;

export default Styles;
