import React, { useMemo } from "react";

import Styles from "./styles";

const SdgTable = ({ id, indicators, data, xAxisProp = "year", isSortHeaders }) => {
  // id is very important for the exporting to excel functionality

  const headers = useMemo(() => {
    if (!indicators?.length > 0) return [];

    const indicatorsNames = indicators.map(({ name }) => name);
    if (isSortHeaders) {
      indicatorsNames.sort();
    }
    return indicatorsNames;
  }, [indicators, isSortHeaders]);

  return (
    <Styles>
      <div className="top-improvers">
        <table id={id} className="top-improvers__table table">
          <thead>
            <tr>
              <th width="20%" className="capitalized">
                {xAxisProp}
              </th>

              {headers.map((name, index) => (
                <th width="10%" key={index}>
                  {name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data?.map((props, index) => (
              <tr key={index}>
                <td>{props?.[xAxisProp]}</td>
                {headers.map((name, index) => (
                  <td key={index}>{props[name]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Styles>
  );
};

export default SdgTable;
