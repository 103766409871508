import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import Styles from "./styles";

const FilerSelect = ({ value, onOptionClick, options = [], name, ...props }) => {
  return (
    <Styles>
      <Dropdown as={ButtonGroup} className="filter-select">
        <Dropdown.Toggle as={"button"} variant="outline-light" className="filter-select__btn">
          {name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options?.map?.((option, i) => (
            <Dropdown.Item
              key={i}
              as={"button"}
              onClick={() => onOptionClick && onOptionClick(option)}>
              {option?.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Styles>
  );
};

export default FilerSelect;
