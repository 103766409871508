import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#1A3667";

const StatisticalSnapshot17 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={{ ...styles.section, padding: "0 10pt" }}>
            <Image
              src={sdgInfo?.snapshotImageURL}
              alt={sdgInfo?.name}
              style={{ width: "100%" }}
            />
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[0] && <Indicator {...indicators[0]} />}
              {indicators[1] && <Indicator {...indicators[1]} />}
            </View>
          </View>

          <View style={{ ...styles.section, width: "100%" }}>
            <View style={styles.indicatorsContainer}>
              <View
                style={{
                  padding: "0 10pt",
                  width: "30%"
                }}>
                {indicators[2] && <Indicator {...indicators[2]} stretch={false} />}
                <View style={styles.hr} />
                {indicators[6] && <Indicator {...indicators[6]} stretch={false} />}
              </View>

              <View style={{ ...styles.sectionWithBg, width: "65%" }}>
                <View style={styles.indicatorsContainer}>
                  {indicators[3] && <Indicator {...indicators[3]} iconWidth="2.34cm" />}
                  {indicators[4] && <Indicator {...indicators[4]} iconWidth="2.34cm" />}
                  {indicators[5] && <Indicator {...indicators[5]} iconWidth="2.34cm" />}
                </View>
              </View>
            </View>
          </View>

          <View style={{ ...styles.section, width: "100%" }}>
            <View style={styles.indicatorsContainer}>
              {indicators[7] && <Indicator {...indicators[7]} />}
              {indicators[8] && <Indicator {...indicators[8]} />}
              {indicators[9] && <Indicator {...indicators[9]} />}
              {indicators[10] && <Indicator {...indicators[10]} />}
            </View>
          </View>

          <View style={{ ...styles.section, width: "100%" }}>
            <View style={{ ...styles.indicatorsContainer, alignItems: "center" }}>
              <View style={{ padding: "0 10pt", width: "30%" }}>
                {indicators[11] && <Indicator {...indicators[11]} stretch={false} />}
              </View>

              <View style={{ ...styles.indicatorsContainer, width: "65%" }}>
                <View
                  style={{
                    ...styles.sectionWithBg,
                    flex: 1,
                    backgroundColor: "#0A5393"
                  }}>
                  {indicators[12] && (
                    <Indicator
                      {...indicators[12]}
                      iconWidth="4cm"
                      iconHeight="1cm"
                      textColor="#FFFFFF"
                    />
                  )}
                </View>
                <View
                  style={{
                    ...styles.sectionWithBg,
                    flex: 1,
                    backgroundColor: "#1A3667"
                  }}>
                  {indicators[13] && (
                    <Indicator
                      {...indicators[13]}
                      iconWidth="4cm"
                      iconHeight="1cm"
                      textColor="#FFFFFF"
                    />
                  )}
                </View>
              </View>
            </View>
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot17;
