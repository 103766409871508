import React, { useCallback, useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { SpinnerLoader, toastError, toastInfo } from "@particles";
import { SdgOptionsList, ChartsViewer, SdgSummary } from "@organisms";

import { getChartData, indicatorTooltip, loadChartsDataWithProp } from "./helpers";

import Styles from "./styles";
import { sdgsApi } from "api";

import { combineChartsDataYears } from "../charts-viewer/helpers";
import cloneDeep from "lodash/cloneDeep";

const summaryTextVarKey = "SDG";
const guidelinesTextVarKey = "SDGGuidelines";
const disclaimerTextVarKey = "SDGDisclaimer";

const SdgCharts = ({ sdgs, isSdgsLoading }) => {
  const [selectedSdgGoal, setSelectedSdgGoal] = useState();
  const [selectedParent, setSelectedParent] = useState();
  const [selectedIndicators, setSelectedIndicators] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [chartsDataYears, setChartsDataYears] = useState([]);

  useEffect(() => {
    const indicatorWithoutData = selectedIndicators.find((indicator) => !indicator?.data);

    if (indicatorWithoutData) {
      setIsLoading(true);

      const index = selectedIndicators.findIndex((indicator) => !indicator?.data);

      const addData = async () => {
        const newSelectedIndicators = cloneDeep(selectedIndicators);

        const body = {
          IndicatorId: indicatorWithoutData.id,
          Governorates: [],
          Countries: [8]
        };

        const updateChartsDataYears = (newYears) => {
          setChartsDataYears((prev) => {
            return combineChartsDataYears(newYears, prev);
          });
        };

        try {
          const res = await sdgsApi.getIndicatorData(body);

          const years = res?.data?.years || [];
          if (years.length > 0) {
            updateChartsDataYears(years);
          }

          const data = res?.data?.values || [];

          if (data?.length > 0) {
            newSelectedIndicators[index] = {
              ...indicatorWithoutData,
              data
            };
          } else {
            newSelectedIndicators.splice(index, 1);
            toastInfo(
              <FormattedMessage defaultMessage="There are no data found for the selected indicator" />
            );
          }
        } catch (error) {
          newSelectedIndicators.splice(index, 1);
          toastError();
        } finally {
          setSelectedIndicators(newSelectedIndicators);
          setIsLoading(false);
        }
      };

      addData();
    }
  }, [selectedIndicators]);

  const handleResetChart = useCallback(() => {
    setSelectedSdgGoal(null);
    setSelectedParent(null);
    setSelectedIndicators([]);
  }, []);

  const intl = useIntl();

  const handleExcelDataSheet = useCallback(
    (sheet, chartsData, series) => {
      const keys = Object.keys(chartsData[0]);
      const indicatorsNames = series?.map?.((i) => i.name);

      const dataColumns = indicatorsNames.flatMap((name) => [name, `${name}-note`]);

      sheet.columns = [keys[0], ...dataColumns].map((key) => ({ key }));

      const row = sheet.addRow([
        intl.formatMessage({
          defaultMessage: "Indicator"
        }),
        ...indicatorsNames.flatMap((name) => [
          name,
          intl.formatMessage({ defaultMessage: "Comment" })
        ])
      ]);

      const chartsDataWithNotes = loadChartsDataWithProp("note", chartsData, series);

      sheet.addRows(chartsDataWithNotes);

      // return last header row number to style the header in charts viewer
      return row.number;
    },
    [intl]
  );

  const getIndicatorTooltip = useCallback(
    (indicator) => indicatorTooltip(indicator, selectedSdgGoal),
    [selectedSdgGoal]
  );

  const handleChangeSdgGoal = useCallback((newSdgGoal) => {
    setSelectedSdgGoal(newSdgGoal);
    setSelectedParent(null);
    setSelectedIndicators([]);
  }, []);

  const handleChangeParent = useCallback((newParent) => {
    setSelectedParent(newParent);
    setSelectedIndicators([]);
  }, []);

  return (
    <Styles>
      <div className="sdg-charts w-100">
        {isSdgsLoading && <SpinnerLoader />}

        <SdgSummary textVarKey={summaryTextVarKey} />

        <div className="w-100 pt-5">
          <div className="sdg-charts__options">
            <SdgOptionsList
              id="sdg-goal"
              onChange={handleChangeSdgGoal}
              options={sdgs}
              title={intl.formatMessage({
                defaultMessage: "SDG Goal"
              })}
              value={selectedSdgGoal}
            />

            {selectedSdgGoal && (
              <SdgOptionsList
                id="sdg-indicator"
                tooltip={getIndicatorTooltip}
                onChange={handleChangeParent}
                options={selectedSdgGoal.parents}
                title={intl.formatMessage({
                  defaultMessage: "Indicator"
                })}
                value={selectedParent}
              />
            )}

            {selectedParent && (
              <SdgOptionsList
                multi
                disabled={isLoading}
                id="sdg-indicators-list"
                onChange={setSelectedIndicators}
                options={selectedParent.indicators}
                title={intl.formatMessage({
                  defaultMessage: "Indicators List"
                })}
                values={selectedIndicators}
              />
            )}
          </div>

          <ChartsViewer
            id="sdg"
            multipleYAxes
            isLoading={isLoading}
            indicators={selectedIndicators}
            title={selectedSdgGoal?.name}
            onResetChart={handleResetChart}
            indicatorsMetadata={selectedIndicators}
            guidelinesTextVarKey={guidelinesTextVarKey}
            disclaimerTextVarKey={disclaimerTextVarKey}
            handleExcelDataSheet={handleExcelDataSheet}
            chartsDataYears={chartsDataYears}
            getChartData={getChartData}
            handleExcelCodeDefinitionSheet={true}
          />
        </div>
      </div>
    </Styles>
  );
};

export default SdgCharts;
