const vAlignEnum = {
  top: 1,
  middle: 2,
  bottom: 3
};

export function getVertAlign(vAlign) {
  const vAlignOptions = {
    [vAlignEnum.top]: "start",
    [vAlignEnum.middle]: "center",
    [vAlignEnum.bottom]: "end"
  };

  return vAlignOptions[vAlign] || vAlignOptions[vAlignEnum.middle];
}

const hAlignEnum = {
  left: 1,
  middle: 2,
  right: 3
};

export function getHorizAlign(hAlign) {
  const hAlignOptions = {
    [hAlignEnum.left]: "start",
    [hAlignEnum.middle]: "center",
    [hAlignEnum.right]: "end"
  };

  return hAlignOptions[hAlign] || hAlignOptions[hAlignEnum.left];
}
