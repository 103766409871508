import { PageTitle } from "@atoms";
import React from "react";
import AppTemplate from "../app-template";
import Styles from "./styles";

const SdgPageTemplate = ({ seoTitle, title, tabsComponent: TabsComponent, children }) => {
  return (
    <AppTemplate title={seoTitle}>
      <Styles>
        <div className="sdg-page-template">
          <div className="sdg-page-template__header py-5 bg-black">
            <div className="sdg-page-template__container">
              <PageTitle>{title}</PageTitle>

              {TabsComponent && (
                <div className="w-100 d-flex mt-4">
                  <TabsComponent />
                </div>
              )}
            </div>
          </div>

          <div className="sdg-page-template__content py-3 py-lg-5 bg-light">
            <div className="sdg-page-template__container">{children}</div>
          </div>
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default SdgPageTemplate;
