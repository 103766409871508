import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import messages_en from "./compiled-locales/en.json";
import messages_ar from "./compiled-locales/ar.json";
import { appLangs, ARABIC, ENGLISH } from "types/languages";
import { getCurrentLang, setCurrentLang } from "@utils";
import { useLocation, Navigate, useParams } from "react-router-dom";

const messages = {
  [ARABIC]: messages_ar,
  [ENGLISH]: messages_en
};

const LanguageProvider = ({ children }) => {
  const { pathname } = useLocation();
  const { lang } = useParams();

  const currentLang = getCurrentLang();

  let newLang = lang;
  if (!appLangs.includes(lang)) {
    newLang = currentLang;
  }

  useEffect(() => {
    setCurrentLang(newLang);
  }, [newLang]);

  if (!pathname.includes(`/${newLang}/`)) {
    return <Navigate to={`/${newLang}/`} />;
  }

  return (
    <IntlProvider messages={messages[newLang]} locale={newLang}>
      <div className="body" dir={newLang === ARABIC ? "rtl" : "ltr"} lang={newLang}>
        {children}
      </div>
    </IntlProvider>
  );
};

export default LanguageProvider;
