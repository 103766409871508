import { ImageLoader } from "@particles";
import React, { useState } from "react";

const ImageComponent = ({ src, ...props }) => {
  const [loading, setLoading] = useState(true);

  const handleOnLoad = () => {
    setLoading(false);
  };

  const handleOnError = () => {
    setLoading(false);
  };

  return src ? (
    <>
      {loading && (
        <div className={props.className}>
          <ImageLoader />
        </div>
      )}
      <img
        {...props}
        alt={props.alt}
        src={src}
        onLoad={handleOnLoad}
        onError={handleOnError}
        style={{
          display: loading ? "none" : "block"
        }}
      />
    </>
  ) : null;
};

export default ImageComponent;
