import { TRANSITION_DURATION } from "@constants";
import styled, { css } from "styled-components";

const transitionStyles = {
  entering: css`
    opacity: 1;
  `,
  entered: css`
    opacity: 1;
  `,
  exiting: css`
    opacity: 0;
  `,
  exited: css`
    opacity: 0;
  `
};

const Styles = styled.div`
  width: 100%;
  height: 100%;
  transition: opacity ${(props) => props.duration || TRANSITION_DURATION}ms ease-in-out;
  opacity: 0;
  ${(props) => transitionStyles[props.state]}
`;
export default Styles;
