import styled from "styled-components";
import { respondTo } from "helpers/style/respond-to";

const Styles = styled.div`
  .nav-menu {
    min-height: var(--nav-menu-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__container {
      margin: 0 auto;
      width: 95%;
      ${respondTo.md`
        width: 92%;
      `}
      ${respondTo.xl`
        width: 85%;
      `}
      ${respondTo.xxl`
        width: 80%;
      `}
    }

    &__brand {
      display: block;
      width: 8rem;
      ${respondTo.md`
         width: 12rem;
      `}
    }
    &__btn {
      align-self: center;
    }
    &__expandable {
      transition: all 0.6s ease-in-out;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      &.--expanded {
        max-height: 100vh;
        opacity: 1;
      }
    }
  }
`;
export default Styles;
