import styled from "styled-components";

const Styles = styled.div`
  .author-card {
    &__img {
      width: 30%;
      border: solid 1px #ccc;
    }

    &__body {
      p {
        text-align: justify;
        line-height: 1.3;
      }
    }
  }
`;
export default Styles;
