import React from "react";
import { FormattedMessage } from "react-intl";

import { sdgsStatisticalSnapshots } from "@particles";
import ReportButtonComponent from "./report-button-component";
import { sdgsApi } from "api";
import { useSnapshotPdf } from "@hooks";

const DownloadSnapshotBtn = ({ exportName, activeSdg }) => {
  const sdgId = activeSdg?.id;

  const { generateSnapshot, isLoading, isDisabled } = useSnapshotPdf({
    exportName,
    infoPropName: "sdgInfo",
    component: sdgsStatisticalSnapshots[activeSdg?.code],
    query: {
      queryKey: ["sdg-snapshot-data", sdgId],
      queryFn: () => sdgsApi.getStatisticalSnapshotData(sdgId),
      enabled: !!sdgId
    }
  });

  return (
    <ReportButtonComponent
      disabled={isDisabled}
      onClick={generateSnapshot}
      isLoading={isLoading}>
      <FormattedMessage defaultMessage="Statistical Snapshots" />
    </ReportButtonComponent>
  );
};

export default DownloadSnapshotBtn;
