import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .localization-charts {
    &__options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;

      ${respondTo.sm`
             grid-template-columns: repeat(2, 1fr);
        `}

      ${respondTo.lg`
             grid-template-columns: repeat(4, 1fr);
             gap: 3rem;
        `}
    }

    &__indicators,
    &__disclaimer {
      width: 100%;
      ${respondTo.md`
        width: 280px
      `}
    }

    &__chart-container,
    &__extra {
      width: 100%;
      ${respondTo.md`
        width: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
      `}
    }

    &__disclaimer {
      order: 2;
      ${respondTo.md`
        order: 1;
      `}
    }

    &__extra {
      order: 1;
      ${respondTo.md`
        order: 2;
      `}
    }
  }
`;
export default Styles;
