import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { ChartTypeButton, StyledButton } from "@atoms";
import { ChartsYearsFilter, StyledSelect } from "@molecules";
import {
  SdgLineChart,
  SdgBarChart,
  SdgAreaChart,
  SdgTable,
  EgyptGeoHeatmap,
  Modal,
  MetadataTable
} from "@organisms";

import {
  ChartExportTemplate,
  FadeTransition,
  HTMLComponent,
  toastInfo
} from "@particles";

import { findMaxDate, isNumber } from "@utils";

import sdgChartTypes from "types/sdg-chart";

import {
  sdgChartOptions,
  getExportingItems,
  groupByUnit,
  exportElId,
  chartExportImageId,
  exportToExcel
} from "./helpers";

import Styles from "./styles";
import isArabic from "helpers/isArabic";
import Tippy from "@tippyjs/react";
import {
  ArrowDropDownIcon,
  CalculatorIcon,
  ClearIcon,
  FilterIcon,
  InfoIcon,
  LabelIcon,
  NoteIcon,
  TargetIcon
} from "@icons";
import { useQueries } from "react-query";
import { sdgsApi } from "api";
import uniq from "lodash/uniq";
import { useMediaQuery } from "@hooks";
import { breakpoints, theme } from "@constants";
import { getMetaDataMapping } from "../metadata-table";
import classNames from "classnames";
import { loadChartsDataWithProp } from "../sdg-charts/helpers";
import { getFrequencyName } from "../localization-charts/helpers";

const tableId = "chart-table";
const exportMenuContainerId = "export-menu";

const targetPropName = "targetValue";

function getTargetSeriesName(name, intl) {
  return `${name} - ${intl.formatMessage({ defaultMessage: "target" })}`;
}

const ChartOptionsContainer = ({ children, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchor = useRef();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = (e) => {
    setIsOpen(true);
  };

  return (
    <>
      <Tippy
        interactive
        visible={isOpen}
        onClickOutside={handleClose}
        placement="bottom"
        offset={[0, 0]}
        render={(attrs) => (
          <FadeTransition inProp={isOpen}>
            <div {...attrs} className="chart-options-menu">
              {children}
            </div>
          </FadeTransition>
        )}
        reference={anchor.current}
      />
      <div>
        <StyledButton
          disabled={disabled}
          variant="greyDark"
          className="chart-options-btn rounded-0 uppercase p-0 me-2"
          onClick={handleOpen}>
          <div ref={anchor} className="d-flex align-items-center py-2 px-4">
            <ArrowDropDownIcon className="me-lg-2 text-20px" />
            <div className="flex-1">
              <FormattedMessage defaultMessage="Chart Options" />
            </div>
          </div>
        </StyledButton>
      </div>
    </>
  );
};

const defaultEmptyChartMsg = (
  <FormattedMessage defaultMessage="Please choose an indicator." />
);

const ChartsViewer = ({
  chartOptions = sdgChartOptions,
  id,
  isLoading,
  indicators = [],
  onResetChart,
  title,
  emptyChartMsg = defaultEmptyChartMsg,
  multiFrequency,
  xAxisProp = "year",
  multipleYAxes,
  indicatorsMetadata,
  guidelinesTextVarKey,
  disclaimerTextVarKey,
  scenariosNoteTextVarKey,
  handleExcelDataSheet,
  handleExcelCodeDefinitionSheet,
  isSortHeaders,
  chartsDataYears,
  selectedFrequency,
  getChartData,
  getHeatmapData
}) => {
  const [isShowTarget, setIsShowTarget] = useState(false);

  const handleToggleIsShowTarget = () => {
    setIsShowTarget((prev) => !prev);
  };

  const intl = useIntl();

  const [chartsData, series] = useMemo(() => {
    const chartsData = getChartData(indicators);
    const isDataReady = indicators.every((ind) => ind.data?.length > 0);

    if (
      !isShowTarget ||
      isLoading ||
      !isDataReady ||
      chartsData?.length <= 0 ||
      indicators.length <= 0
    ) {
      return [chartsData, [].concat(indicators)];
    }

    const getWithTargetData = () => {
      // load target data for the original charts data and series (indicators)
      const withTargetChartsData = loadChartsDataWithProp(
        targetPropName,
        chartsData,
        indicators,
        (seriesName) => getTargetSeriesName(seriesName, intl),
        false
      );

      // create series for the target values
      const withTargetSeries = [];

      indicators.forEach((ind) => {
        const targetSeriesName = getTargetSeriesName(ind.name, intl);
        const targetData = withTargetChartsData.filter((rec) => !!rec[targetSeriesName]);

        if (targetData.length > 0) {
          withTargetSeries.push({
            ...ind,
            name: targetSeriesName,
            isTarget: true,
            data: targetData.map((rec) => ({
              [xAxisProp]: rec[xAxisProp],
              value: rec[targetSeriesName]
            }))
          });
        }
      });

      if (withTargetSeries.length < indicators.length) {
        // some series don't have target values
        toastInfo(
          intl.formatMessage({ defaultMessage: "Some series don't have target values" }),
          {
            toastId: "targetCaution"
          }
        );
      }

      return [withTargetChartsData, indicators.concat(withTargetSeries)];
    };

    const [withTargetChartsData, withTargetSeries] = getWithTargetData();
    return [withTargetChartsData, withTargetSeries];
  }, [isShowTarget, getChartData, indicators, isLoading, intl, xAxisProp]);

  const [displayedChartsData, setDisplayedChartsData] = useState([]);
  const prevData = useRef();

  if (prevData.current !== chartsData) {
    // this is the recommended pattern for update this kind of state without causing extra renders
    setDisplayedChartsData(chartsData);
    prevData.current = chartsData;
  }

  const [chartType, setChartType] = useState(chartOptions?.[0]?.id || sdgChartTypes.line);

  const [selectedTimeseries, setSelectedTimeseries] = useState();

  const [modalTitle, setShowModalTitle] = useState();
  const [modalContent, setShowModalContent] = useState();

  const [filteredYears, setFilteredYears] = useState([]);

  const [filterYearPopupOpen, setFilterYearPopupOpen] = useState(false);
  const filterYearsBtnRef = useRef();

  const handleCloseFilterYearPopup = () => setFilterYearPopupOpen(false);
  const handleOpenFilterYearPopup = () => setFilterYearPopupOpen(true);

  const handleFilterYears = () => {
    let filteredData = [].concat(chartsData);
    if (filteredYears?.length > 0) {
      filteredData = chartsData?.filter((item) =>
        filteredYears?.includes(item?.[xAxisProp])
      );
    }
    setDisplayedChartsData(filteredData);
    handleCloseFilterYearPopup();
  };

  const [showDataLabels, setShowDataLabels] = useState(false);
  const toggleShowDataLabels = () => setShowDataLabels((prev) => !prev);

  const [showLogarithmicScale, setShowLogarithmicScale] = useState(false);
  const toggleShowLogarithmicScale = () => setShowLogarithmicScale((prev) => !prev);

  // Grouped by unit indicators
  const groupedIndicators = useMemo(() => {
    if (multipleYAxes && series.length) {
      return groupByUnit(series);
    }

    return {};
  }, [multipleYAxes, series]);

  const [timeSeriesOptions, defaultSelectedTimeSeries] = useMemo(() => {
    if (chartType !== sdgChartTypes.heatmap || !chartsData?.length > 0) {
      return [[], undefined];
    }
    const options = chartsData?.map?.((record) => ({
      value: record?.[xAxisProp],
      label: record?.[xAxisProp]
    }));

    return [options, options?.[options?.length - 1]];
  }, [chartsData, chartType, xAxisProp]);

  const heatmapData = useMemo(() => {
    if (chartType !== sdgChartTypes.heatmap) {
      return {};
    }
    // we passed indicators cuz we don't wanna show the target values in the map
    return getHeatmapData(selectedTimeseries?.value, indicators);
  }, [selectedTimeseries, getHeatmapData, chartType, indicators]);

  useEffect(() => {
    setSelectedTimeseries(defaultSelectedTimeSeries);
  }, [defaultSelectedTimeSeries]);

  const singleYAxisUnitName = isArabic()
    ? indicatorsMetadata?.[0]?.unitNameAr || indicatorsMetadata?.[0]?.unitNameEn
    : indicatorsMetadata?.[0]?.unitNameEn;

  const uniqueSources = uniq(
    indicatorsMetadata?.filter((i) => i?.sourceName).map((i) => i.sourceName)
  );

  const [{ data: guidelines }, { data: disclaimer }, { data: scenariosNote }] =
    useQueries(
      [guidelinesTextVarKey, disclaimerTextVarKey, scenariosNoteTextVarKey].map(
        (key) => ({
          queryKey: ["text-var", key],
          queryFn: () => sdgsApi.getTextVar(key),
          enabled: !!key
        })
      )
    );

  const handleShowMetadata = () => {
    setShowModalTitle(<FormattedMessage defaultMessage="Indicators metadata" />);
    setShowModalContent(<MetadataTable indicatorsMetadata={indicatorsMetadata} />);
  };

  const handleShowGuidelines = () => {
    setShowModalTitle(<FormattedMessage defaultMessage="Guidelines" />);
    setShowModalContent(<HTMLComponent html={guidelines?.data?.data} />);
  };

  const handleShowDisclaimer = () => {
    setShowModalTitle(<FormattedMessage defaultMessage="Disclaimer" />);
    setShowModalContent(<HTMLComponent html={disclaimer?.data?.data} />);
  };

  const handleShowScenariosNote = () => {
    setShowModalTitle(<FormattedMessage defaultMessage="Scenarios Note" />);
    setShowModalContent(<HTMLComponent html={scenariosNote?.data?.data} />);
  };

  const lastUpdateDate = useMemo(
    () =>
      findMaxDate(
        indicatorsMetadata
          ?.filter((i) => i?.lastUpdatedDate)
          .map((i) => i?.lastUpdatedDate)
      ),
    [indicatorsMetadata]
  );

  const yAxesRanges = useMemo(() => {
    let ranges = {};
    if (multipleYAxes) {
      const keys = Object.keys(groupedIndicators);
      keys.forEach((key) => {
        const ind = indicatorsMetadata?.find(
          (i) => i.unitNameEn === key && isNumber(i.minValue) && isNumber(i.maxValue)
        );
        ranges[key] = {
          min: ind?.minValue,
          max: ind?.maxValue
        };
      });
    } else {
      const ind = indicatorsMetadata?.[0];
      ranges = {
        min: ind?.minValue,
        max: ind?.maxValue
      };
    }

    return ranges;
  }, [groupedIndicators, indicatorsMetadata, multipleYAxes]);

  const isMobileView = useMediaQuery(`max-width:${breakpoints.sm}`);

  const handleExcelExport = useCallback(() => {
    const mappingData = getMetaDataMapping(intl.formatMessage);

    const columnStyle = {
      alignment: {
        wrapText: true,
        vertical: "top",
        readingOrder: isArabic() ? "rtl" : "ltr"
      }
    };

    const columnsWidths = [30, 30, 20, 20, 20, 60, 40];

    // add metadata to meta sheet
    const handleMetaDataExcelExport = function (sheet) {
      sheet.columns = mappingData.map(({ name }, i) => ({
        header: name,
        width: columnsWidths[i],
        style: columnStyle
      }));

      const metaData = indicatorsMetadata?.map?.((ind) =>
        mappingData.map(({ prop, getValue }) =>
          getValue ? getValue(ind[prop], ind) : ind[prop] || "N/A"
        )
      );

      sheet.addRows(metaData);
    };

    // add charts data to data sheet
    const handleDataExcelExport = function (sheet, wb) {
      if (lastUpdateDate) {
        sheet.addRow([
          intl.formatMessage(
            { defaultMessage: "Last Updated on: {date}" },
            { date: new Date(lastUpdateDate).toLocaleDateString() }
          )
        ]);
      }

      const dataStartRowNumber = sheet.lastRow?.number + 1;

      if (handleExcelDataSheet) {
        const lastDataHeaderRow = handleExcelDataSheet(
          sheet,
          displayedChartsData,
          series,
          wb
        );

        // style the data header rows
        const headerRows = sheet.findRows(
          dataStartRowNumber,
          lastDataHeaderRow - dataStartRowNumber + 1
        );

        const fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: `FF${theme.colors.primary.slice(1)}`
          }
        };
        headerRows?.forEach((row) => {
          row?.eachCell((cell) => {
            cell.font = {
              bold: true
            };
            if (cell.col > 1) {
              cell.fill = fill;
            }
          });
        });
      }

      return dataStartRowNumber;
    };

    exportToExcel({
      exportName: id,
      handleDataSheet: handleDataExcelExport,
      handleMetaDataSheet: handleMetaDataExcelExport,
      handleCodeDefinitionSheet: handleExcelCodeDefinitionSheet,
      formatMessage: intl.formatMessage
    });
  }, [
    indicatorsMetadata,
    id,
    lastUpdateDate,
    handleExcelDataSheet,
    displayedChartsData,
    series,
    handleExcelCodeDefinitionSheet,
    intl
  ]);

  const handleGetExportingItems = useMemo(
    () => getExportingItems(handleExcelExport),
    [handleExcelExport]
  );

  const isReversed = indicatorsMetadata?.[0]?.reversed;

  const isTable = chartType === sdgChartTypes.table;

  const [frequencyName, pluralFrequencyName] = getFrequencyName(selectedFrequency);

  return (
    <Styles isHeatmap={chartType === sdgChartTypes.heatmap}>
      <div className="charts-viewer">
        <div className="px-3 px-md-0 w-100 d-flex justify-content-end my-4">
          <StyledButton
            className="rounded-0 uppercase me-2 py-2 px-4"
            onClick={handleShowMetadata}
            disabled={isLoading || series?.length <= 0}>
            <FormattedMessage defaultMessage="metadata" />
          </StyledButton>

          <StyledButton
            id={exportMenuContainerId}
            disabled={isLoading || series?.length <= 0}
            className="charts-viewer__export-menu-btn rounded-0 uppercase py-2 px-4"
            onClick={isTable ? handleExcelExport : undefined}>
            <FormattedMessage defaultMessage="export" />
          </StyledButton>
        </div>

        <div className="charts-viewer__selected-sdg position-relative d-flex w-100 bg-black text-center text-light uppercase py-3">
          <div className="flex-1">{title}</div>

          {guidelines?.data?.data && (
            <StyledButton
              className="guidelines-btn rounded-0 mx-2"
              onClick={handleShowGuidelines}>
              <FormattedMessage defaultMessage="Guidelines" />
            </StyledButton>
          )}
        </div>

        <div className="w-100 bg-white rounded-sm py-3 p-lg-4">
          <div className="w-100 d-flex flex-column flex-lg-row">
            <div className="charts-viewer__chart-type w-lg-15">
              <h4 className="ms-2 ms-lg-0 text-20px mt-2 mt-lg-5 text-dark font-md mb-lg-2 ps-lg-2 mb-3 mb-lg-0">
                <FormattedMessage defaultMessage="Chart Type" />
              </h4>

              <div className="charts-viewer__chart-type-options d-flex flex-row flex-lg-column">
                {chartOptions?.map?.(({ id, label }) => (
                  <ChartTypeButton
                    className="px-2"
                    key={id}
                    selected={chartType === id}
                    onClick={() => setChartType(id)}>
                    {label}
                  </ChartTypeButton>
                ))}
              </div>
            </div>

            {isLoading && (
              <div className="charts-viewer__empty-chart ps-3">
                <FormattedMessage defaultMessage="Loading..." />
              </div>
            )}

            {!series?.length > 0 && !isLoading && (
              <div className="charts-viewer__empty-chart ps-3 py-3">{emptyChartMsg}</div>
            )}

            {!isLoading && series.length !== 0 && (
              <div className="charts-viewer__chart ps-lg-3 h-100 w-lg-85">
                {chartType === sdgChartTypes.line && (
                  <SdgLineChart
                    id={id}
                    data={displayedChartsData}
                    indicators={series}
                    getExportingItems={handleGetExportingItems}
                    xAxisProp={xAxisProp}
                    multiFrequency={multiFrequency}
                    groupedIndicators={groupedIndicators}
                    multipleYAxes={multipleYAxes}
                    exportMenuContainerId={exportMenuContainerId}
                    singleYAxisUnitName={singleYAxisUnitName}
                    showDataLabels={showDataLabels}
                    isMobileView={isMobileView}
                    yAxesRanges={yAxesRanges}
                    isLogarithmic={showLogarithmicScale}
                  />
                )}

                {chartType === sdgChartTypes.bar && (
                  <SdgBarChart
                    id={id}
                    indicators={series}
                    data={displayedChartsData}
                    getExportingItems={handleGetExportingItems}
                    xAxisProp={xAxisProp}
                    multiFrequency={multiFrequency}
                    groupedIndicators={groupedIndicators}
                    multipleYAxes={multipleYAxes}
                    singleYAxisUnitName={singleYAxisUnitName}
                    exportMenuContainerId={exportMenuContainerId}
                    showDataLabels={showDataLabels}
                    isMobileView={isMobileView}
                    yAxesRanges={yAxesRanges}
                    isLogarithmic={showLogarithmicScale}
                  />
                )}

                {chartType === sdgChartTypes.area && (
                  <SdgAreaChart
                    id={id}
                    indicators={series}
                    data={displayedChartsData}
                    getExportingItems={handleGetExportingItems}
                    xAxisProp={xAxisProp}
                    multiFrequency={multiFrequency}
                    groupedIndicators={groupedIndicators}
                    multipleYAxes={multipleYAxes}
                    singleYAxisUnitName={singleYAxisUnitName}
                    exportMenuContainerId={exportMenuContainerId}
                    showDataLabels={showDataLabels}
                    isMobileView={isMobileView}
                    yAxesRanges={yAxesRanges}
                    isLogarithmic={showLogarithmicScale}
                  />
                )}

                {chartType === sdgChartTypes.table && (
                  <SdgTable
                    id={tableId}
                    indicators={series}
                    data={displayedChartsData}
                    xAxisProp={xAxisProp}
                    isSortHeaders={isSortHeaders}
                  />
                )}

                {chartType === sdgChartTypes.heatmap && (
                  <div>
                    <div className="px-3 my-3 w-100 w-md-50 d-flex align-items-center">
                      <div className="me-3">
                        <FormattedMessage
                          defaultMessage="Select {freq}"
                          values={{ freq: frequencyName }}
                        />
                      </div>
                      <StyledSelect
                        placeholder={intl.formatMessage(
                          {
                            defaultMessage: "Select {freq}"
                          },
                          { freq: frequencyName }
                        )}
                        options={timeSeriesOptions}
                        value={selectedTimeseries}
                        onChange={setSelectedTimeseries}
                        defaultValue={defaultSelectedTimeSeries}
                      />
                    </div>

                    <EgyptGeoHeatmap
                      indicators={series}
                      getExportingItems={handleGetExportingItems}
                      data={heatmapData}
                      yAxesRanges={yAxesRanges}
                      exportMenuContainerId={exportMenuContainerId}
                      isReversed={isReversed}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="w-100 px-3 px-lg-0 d-flex flex-wrap justify-content-between align-items-start my-4">
            <div>
              {disclaimer?.data?.data && (
                <StyledButton
                  className="rounded-0 uppercase d-flex align-items-center py-2 px-4 w-100"
                  onClick={handleShowDisclaimer}>
                  <InfoIcon className="me-lg-2 text-20px" />
                  <div className="d-none d-lg-block flex-1">
                    <FormattedMessage defaultMessage="Disclaimer" />
                  </div>
                </StyledButton>
              )}

              {scenariosNote?.data?.data && (
                <StyledButton
                  className={classNames([
                    "rounded-0 uppercase d-flex align-items-center py-2 px-4 w-100",
                    {
                      "mt-2": !!disclaimer?.data?.data
                    }
                  ])}
                  onClick={handleShowScenariosNote}>
                  <NoteIcon className="me-lg-2 text-20px" />
                  <div className="d-none d-lg-block flex-1">
                    <FormattedMessage defaultMessage="Scenarios Note" />
                  </div>
                </StyledButton>
              )}
            </div>

            <div className="d-flex flex-wrap">
              {![sdgChartTypes.heatmap].includes(chartType) && (
                <ChartOptionsContainer disabled={!series?.length}>
                  <>
                    {sdgChartTypes.table !== chartType && (
                      <>
                        <StyledButton
                          disabled={!series?.length}
                          variant={showLogarithmicScale ? "primary" : "outline-light"}
                          className="rounded-0 uppercase px-4 d-flex align-items-center py-3 w-100 border-b"
                          onClick={toggleShowLogarithmicScale}
                          title={intl.formatMessage({
                            defaultMessage: "Logarithmic Scale"
                          })}>
                          <CalculatorIcon className="me-lg-2 text-20px" />

                          <div className="flex-1">
                            <FormattedMessage defaultMessage="Logarithmic Scale" />
                          </div>
                        </StyledButton>

                        <StyledButton
                          disabled={!series?.length}
                          variant={showDataLabels ? "primary" : "outline-light"}
                          className="rounded-0 uppercase px-4 d-flex align-items-center py-3 w-100 border-b"
                          onClick={toggleShowDataLabels}
                          title={intl.formatMessage({ defaultMessage: "Data Labels" })}>
                          <LabelIcon className="me-lg-2 text-20px" />

                          <div className="flex-1">
                            {showDataLabels ? (
                              <FormattedMessage defaultMessage="Hide Data Labels" />
                            ) : (
                              <FormattedMessage defaultMessage="Show Data Labels" />
                            )}
                          </div>
                        </StyledButton>
                      </>
                    )}

                    <StyledButton
                      disabled={!series?.length}
                      variant={isShowTarget ? "primary" : "outline-light"}
                      className="rounded-0 uppercase px-4 d-flex align-items-center py-3 w-100 border-b"
                      onClick={handleToggleIsShowTarget}
                      title={intl.formatMessage({ defaultMessage: "Target Values" })}>
                      <TargetIcon className="me-lg-2 text-20px" />
                      <div className="flex-1">
                        {isShowTarget ? (
                          <FormattedMessage defaultMessage="Hide Target Values" />
                        ) : (
                          <FormattedMessage defaultMessage="Show Target Values" />
                        )}
                      </div>
                    </StyledButton>
                  </>
                </ChartOptionsContainer>
              )}

              {chartType !== sdgChartTypes.heatmap && (
                <>
                  <Tippy
                    interactive
                    visible={filterYearPopupOpen}
                    onClickOutside={handleCloseFilterYearPopup}
                    render={(attrs) => (
                      <FadeTransition inProp={filterYearPopupOpen}>
                        <ChartsYearsFilter
                          filterdYears={filteredYears}
                          setFilterdYears={setFilteredYears}
                          popperAttrs={attrs}
                          years={chartsDataYears}
                          onApplyFilter={handleFilterYears}
                        />
                      </FadeTransition>
                    )}
                    reference={filterYearsBtnRef.current}
                  />
                  <StyledButton
                    disabled={!chartsDataYears?.length}
                    variant="greyDark"
                    className="rounded-0 uppercase p-0 me-2"
                    onClick={handleOpenFilterYearPopup}>
                    <div
                      className="w-100 h-100 d-flex align-items-center py-2 px-4"
                      ref={filterYearsBtnRef}>
                      <FilterIcon className="me-lg-2 text-20px" />
                      <div className="d-none d-lg-block flex-1">
                        <FormattedMessage
                          defaultMessage="Filter {freq}"
                          values={{ freq: pluralFrequencyName }}
                        />
                      </div>
                    </div>
                  </StyledButton>
                </>
              )}

              <StyledButton
                variant="greyDark"
                className="rounded-0 d-flex align-items-center uppercase py-2 px-4"
                disabled={!chartsData?.length}
                onClick={onResetChart}>
                <ClearIcon className="me-lg-2 text-20px" />
                <div className="d-none d-lg-block flex-1">
                  <FormattedMessage defaultMessage="Reset Chart" />
                </div>
              </StyledButton>
            </div>
          </div>

          {uniqueSources?.length > 0 && chartsData?.length > 0 && (
            <div className="w-100 px-3 px-lg-0 my-4">
              <h5>
                <FormattedMessage defaultMessage="Source(s)" />
              </h5>
              <ul className="w-100">
                {uniqueSources?.map?.((source) => (
                  <li key={source}>{source}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <Modal show={!!modalContent} setShow={setShowModalContent} title={modalTitle}>
        {modalContent}
      </Modal>

      {/* use as template for pdf/image exporting */}
      <ChartExportTemplate
        exportElId={exportElId}
        chartExportImageId={chartExportImageId}
        indicatorsMetadata={indicatorsMetadata}
        uniqueSources={uniqueSources}
        lastUpdateDate={lastUpdateDate}
      />
    </Styles>
  );
};

export default React.memo(ChartsViewer);
