import styled from "styled-components";

const Styles = styled.div`
  padding: 1rem;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
`;
export default Styles;
