import styled from "styled-components";
import StyledButton from "../styled-button";

const Styles = styled.div`
  width: 100%;
  ${StyledButton} {
    width: 100%;
    -webkit-clip-path: ${({ active }) =>
      active ? `polygon(0% 0%, 100% 0%, 100% 80%, 52% 80%, 50% 100%, 48% 80%, 0% 80%)` : "none"};
    clip-path: ${({ active }) =>
      active ? `polygon(0% 0%, 100% 0%, 100% 80%, 52% 80%, 50% 100%, 48% 80%, 0% 80%)` : "none"};
  }
`;
export default Styles;
