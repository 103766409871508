import { heroItemContainerStyle } from "components/molecules/hero-item/styles";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  height: 100%;
  .hero {
    height: 100%;
    .swiper {
      height: 100%;
    }
    .swiper-slide {
      height: auto;
      ${respondTo.xl`
            height: 100%
        `}
    }
    .swiper-button-next,
    .swiper-button-prev {
      color: var(--light);
    }
    .swiper-pagination-bullets {
      ${respondTo.lg`
        display: flex
      `}
      bottom: 2rem;
      justify-content: space-between;
      ${heroItemContainerStyle}
    }
    .swiper-pagination-bullet {
      &-active {
        background-color: var(--primary);
      }
      .title {
        display: none;
      }
      ${respondTo.lg`
        color: var(--light);
        width: max-content;
        height: auto;
        border-radius: 0;
        background-color: unset;
        text-align: left;
        border-top: solid 1px;
        border-top-color: rgba(242, 249, 242, 0.2);
        padding-top: 5px;
        opacity: 1;
        .title {
          display: inline;
        }
        &-active {
          border-top-color: var(--light);
          display: list-item;
          list-style-type: disc;
          list-style-position: inside;
          &::marker {
            font-size: 1rem;
            color: var(--primary);
            margin-right: 0.2rem;
          }
        }
      `}
    }
  }
`;
export default Styles;
