import React from "react";
import { FormattedMessage } from "react-intl";
import { SdgPageTemplate } from "@templates";
import { InternationalIndicatorsCharts } from "@organisms";

const InternationalIndicators = () => {
  return (
    <SdgPageTemplate
      seoTitle="International Indicators"
      title={<FormattedMessage defaultMessage="International Indicators" />}>
      <InternationalIndicatorsCharts />
    </SdgPageTemplate>
  );
};

export default InternationalIndicators;
