import styled from "styled-components";

const ChartTypeButton = styled.button`
  appearance: none;
  border: none;
  background-color: ${({ selected }) => (selected ? `var(--greyDark)` : "transparent")};
  color: ${({ selected }) => (selected ? `white` : "`var(--dark)")};
  font-size: 0.875rem;
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ selected }) =>
      selected ? `var(--greyDark)` : "var(--greyLight)"};
  }
`;

export default ChartTypeButton;
