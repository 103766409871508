import { respondTo } from "helpers/style/respond-to";

const { default: styled } = require("styled-components");
const { ENGLISH, ARABIC } = require("types/languages");

const FilterTabBtn = styled.button`
  border: none;
  appearance: none;
  border-bottom: solid 2px ${(props) => (props.active ? `var(--primary)` : `white`)};
  color: ${(props) => (props.active ? `var(--primary)` : `white`)};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  &:lang(${ENGLISH}) {
    padding-left: 0;
    padding-right: 2rem;
  }
  &:lang(${ARABIC}) {
    padding-right: 0;
    padding-left: 2rem;
  }

  font-size: 1rem;
  ${respondTo.lg`
    font-size: 1.1rem;
  `}

  ${respondTo.xxl`
    font-size: 1.375;
  `}
`;

export default FilterTabBtn;
