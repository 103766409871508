import React, { useEffect, useMemo, useState } from "react";
import { RawIntlProvider, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { saveAs } from "file-saver";
import { usePDF } from "@react-pdf/renderer";

import { socialLinksData } from "@constants";
import { imgToPng, isSvg } from "@utils";
import { useFetchSocialLinks } from "@hooks";
import { ENGLISH } from "types/languages";
import { exportStatisticalSnapshotToPdf } from "components/organisms/sdg-reports/helpers";

import fbSnapshotIcon from "assets/snapshots/snapshot-fb.png";
import twitterSnapshotIcon from "assets/snapshots/snapshot-twitter.png";
import emailSnapshotIcon from "assets/snapshots/snapshot-email.png";

function useSnapshotPdf({
  query,
  component: SnapshotComponent,
  infoPropName,
  exportName
}) {
  const intl = useIntl();
  const lang = intl.locale;

  const isEnglish = lang === ENGLISH;

  const [reportLoading, setReportLoading] = useState();
  const [exportReport, setExportReport] = useState();
  const [iconURL, setIconURL] = useState();

  const { isLoading: isSnapshotDataLoading, data: snapshotData } = useQuery(query);

  // pass the social links to statistical snapshot report
  const { socialLinksData: contactInfo, isSocialLinksLoading } = useFetchSocialLinks();

  const iconURLRaw = snapshotData?.data?.[infoPropName]?.iconURL;

  useEffect(() => {
    // convert svg image to png cuz react-pdf doesn't support svg
    if (isEnglish) {
      // we are using react-pdf for english

      if (isSvg(iconURLRaw)) {
        setReportLoading(true);
        imgToPng(iconURLRaw, (png) => {
          setReportLoading(false);
          setIconURL(png);
        });
      } else {
        setIconURL(iconURLRaw);
      }
    }
  }, [iconURLRaw, isEnglish, infoPropName]);

  const socialLinks = useMemo(
    () => ({
      facebook: { ...socialLinksData.facebook, snapshotIcon: fbSnapshotIcon },
      twitter: { ...socialLinksData.twitter, snapshotIcon: twitterSnapshotIcon },
      email: {
        href: "",
        snapshotIcon: emailSnapshotIcon
      }
    }),
    []
  );

  const Snapshot = useMemo(() => {
    const { indicators, sources } = snapshotData?.data || {};

    const info = snapshotData?.data?.[infoPropName] || {};

    if (!isSocialLinksLoading) {
      const data = contactInfo?.data;
      socialLinks.twitter.href = data?.twitterPage;
      socialLinks.facebook.href = data?.facebookPage;
      socialLinks.email.href = `mailto:${data?.email}`;
    }

    const filteredSocialLinks = Object.values(socialLinks).filter((link) => !!link.href);

    const props = {
      [infoPropName]: {
        ...info,
        iconURL: isEnglish ? iconURL : info?.iconURL
      },
      indicators: indicators?.sort?.((a, b) => a.displayOrder - b.displayOrder),
      sources: sources,
      socialLinks: filteredSocialLinks
    };

    return SnapshotComponent && !isSnapshotDataLoading
      ? () => (
          <RawIntlProvider value={intl}>
            <SnapshotComponent {...props} />
          </RawIntlProvider>
        )
      : undefined;
  }, [
    snapshotData?.data,
    infoPropName,
    isSocialLinksLoading,
    socialLinks,
    isEnglish,
    iconURL,
    SnapshotComponent,
    isSnapshotDataLoading,
    contactInfo?.data,
    intl
  ]);

  const [pdfInstance, updateInstance] = usePDF({
    document: Snapshot ? <Snapshot /> : Snapshot
  });

  useEffect(() => {
    // save english snapshot after finsih rendering
    if (exportReport && !pdfInstance.loading) {
      saveAs(pdfInstance.url, `${exportName}.pdf`);
      setReportLoading(false);
      setExportReport(false);
    }
  }, [pdfInstance, exportReport, exportName]);

  const generateArabicSnapshot = () => {
    setReportLoading(true);
    exportStatisticalSnapshotToPdf(Snapshot, exportName, () => setReportLoading(false));
  };

  const generateEnglishSnapshot = () => {
    setReportLoading(true);
    updateInstance();
    setExportReport(true);
  };

  function generateSnapshot() {
    if (isEnglish) {
      generateEnglishSnapshot();
    } else {
      generateArabicSnapshot();
    }
  }

  return {
    generateSnapshot,
    isLoading:
      isSnapshotDataLoading || (isEnglish && pdfInstance.loading) || reportLoading,
    isDisabled: !Snapshot || (isEnglish && pdfInstance.error)
  };
}

export default useSnapshotPdf;
