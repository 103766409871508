import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";
import { FormattedMessage } from "react-intl";

import edpLogo from "assets/images/edp-logo.png";

const themeColor = "#24ABE2";

const subTitle = <FormattedMessage defaultMessage="Egypt's Best Performing Indicators" />;

const StatisticalSnapshot18 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        code={null}
        subTitle={subTitle}
        sources={sources}
        socialLinks={socialLinks}
        edpLogo={edpLogo}
        isShowHeaderColoredBorder
        styles={{
          header: {
            backgroundColor: "#FFFFFF",
            color: "#000000"
          }
        }}>
        <>
          <View
            style={{
              ...styles.section,
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: "0 10pt"
            }}>
            <Image
              src={sdgInfo?.snapshotImageURL}
              alt={sdgInfo?.name}
              style={{ width: "70%" }}
            />
          </View>

          <View
            style={{
              ...styles.section
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[0] && <Indicator {...indicators[0]} />}
              {indicators[1] && <Indicator {...indicators[1]} />}
              {indicators[2] && <Indicator {...indicators[2]} />}
            </View>
          </View>

          <View
            style={{
              ...styles.section
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[3] && <Indicator {...indicators[3]} />}
              {indicators[4] && <Indicator {...indicators[4]} />}
              {indicators[5] && <Indicator {...indicators[5]} />}
            </View>
          </View>

          <View
            style={{
              ...styles.section,
              ...styles.sectionWithBg,
              padding: "10pt"
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[6] && <Indicator {...indicators[6]} />}
              {indicators[7] && <Indicator {...indicators[7]} />}
              {indicators[8] && <Indicator {...indicators[8]} />}
            </View>
          </View>

          <View
            style={{
              ...styles.section,
              ...styles.sectionWithBg,
              padding: "10pt"
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[9] && <Indicator {...indicators[9]} />}
              {indicators[10] && <Indicator {...indicators[10]} />}
              {indicators[11] && <Indicator {...indicators[11]} />}
            </View>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <View style={styles.horizIndicator}>
              {indicators[12] && <Indicator {...indicators[12]} iconWidth="2cm" horiz />}
            </View>

            <View style={styles.horizIndicator}>
              {indicators[13] && <Indicator {...indicators[13]} iconWidth="2cm" horiz />}
            </View>
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot18;
