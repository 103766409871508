import classNames from "classnames";
import styled from "styled-components";
import { ENGLISH } from "types/languages";

const PageTitle = styled.h1.attrs((props) => ({
  className: classNames(["text-50px", "font-md", "uppercase", props.className])
}))`
  &:lang(${ENGLISH}) {
    letter-spacing: 0.4rem;
  }
  text-align: center;
  color: var(--light);
  margin-bottom: 0;
`;

export default PageTitle;
