import styled from "styled-components";

const Styles = styled.div`
  width: 100%;
  height: 100%;
  .home-indicator-card {
    width: 100%;
    height: 100%;
    background-color: white;

    &__year {
      color: var(--grey);
    }

    &__name {
      letter-spacing: -0.5px;
      line-height: 1.2;
    }
  }
`;
export default Styles;
