import styled from "styled-components";
import eventsCalBg from "assets/images/events-bg.jpg";

const Styles = styled.div`
  .related-links {
    min-height: 600px;
    background-image: url(${eventsCalBg});
    background-size: cover;
  }
`;
export default Styles;
