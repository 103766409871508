import styled from "styled-components";
import { ARABIC, ENGLISH } from "types/languages";

const Styles = styled.div`
  .data-visualization-carousel {
    .swiper {
      height: 100%;
      padding-bottom: 2.5rem;
    }
    .swiper-slide {
      height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: var(--primary);

      &::after {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }

    .swiper-button-disabled {
      display: none !important;
    }

    .swiper-button-next:lang(${ENGLISH}),
    .swiper-button-prev:lang(${ARABIC}) {
      right: 2px !important;
    }

    .swiper-button-prev:lang(${ENGLISH}),
    .swiper-button-next:lang(${ARABIC}) {
      left: 2px !important;
    }

    .swiper-pagination-bullets {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .swiper-pagination-bullet {
      margin: 0;
      flex: 1;
      height: 2px;
      border-radius: 0;
      background-color: var(--dark);
      opacity: 1;
      &-active {
        height: 4px;
        background-color: var(--primary);
      }
    }
  }
`;
export default Styles;
