import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg.attrs((props) => ({
  width: props.width || "1em",
  fill: props.fill || "currentColor",
  stroke: props.stroke || "currentColor",
  className: `${props.localized ? "localized" : ""}`
}))``;

export default React.memo(StyledSvg);
