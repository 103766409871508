import React from "react";

import { FormattedMessage } from "react-intl";

import { useQuery } from "react-query";

import { SdgPageTemplate } from "@templates";
import { SdgCharts, SdgReports } from "@organisms";
import { SdgButton } from "@atoms";

import { sdgsApi } from "api";

import { ChartIcon, SdgsCircleIcon } from "@icons";
import { useTabNavigation } from "@hooks";

const contentType = {
  explorer: "explorer",
  chart: "chart"
};

const tabs = Object.values(contentType);

const TabsComponent = ({ tab, handleTabClick }) => (
  <>
    <SdgButton
      variant="light"
      active={tab !== contentType.chart}
      onClick={() => handleTabClick(contentType.explorer)}>
      <SdgsCircleIcon className="me-2 text-28px" />
      <FormattedMessage defaultMessage="Explore SDGs" />
    </SdgButton>

    <SdgButton
      variant="light"
      active={tab === contentType.chart}
      onClick={() => handleTabClick(contentType.chart)}>
      <ChartIcon className="me-2 text-28px" />
      <FormattedMessage defaultMessage="Build Chart" />
    </SdgButton>
  </>
);

const SDG = () => {
  const { isLoading: isSdgsLoading, data: sdgs } = useQuery(
    "sdgs-all",
    sdgsApi.getAllSdgs
  );
  const { isLoading: isSdgsDataLoading, data: sdgsData } = useQuery(
    "sdgs-data",
    sdgsApi.getSdgsData
  );

  const { tab, handleTabClick } = useTabNavigation(tabs);

  return (
    <SdgPageTemplate
      seoTitle="SDG Data Explorer"
      title={<FormattedMessage defaultMessage="SDG data explorer" />}
      tabsComponent={() => <TabsComponent tab={tab} handleTabClick={handleTabClick} />}>
      {tab === contentType.explorer ? (
        <SdgReports sdgs={sdgs} isLoading={isSdgsLoading} />
      ) : (
        <SdgCharts sdgs={sdgsData?.data} isSdgsLoading={isSdgsDataLoading} />
      )}
    </SdgPageTemplate>
  );
};

export default SDG;
