import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#FAC213";

const StatisticalSnapshot7 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.main}>
            <View style={{ ...styles.section, width: "65%", padding: "0 10pt" }}>
              <Image
                src={sdgInfo?.snapshotImageURL}
                alt={sdgInfo?.name}
                style={{ width: "100%" }}
              />
            </View>

            <View style={{ ...styles.section, width: "35%", padding: "0 10pt" }}>
              {indicators[0] && <Indicator {...indicators[0]} />}
            </View>

            <View style={{ ...styles.section, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[2] && <Indicator {...indicators[2]} iconWidth="2.34cm" />}
                {indicators[3] && <Indicator {...indicators[3]} iconWidth="2.34cm" />}
                {indicators[4] && <Indicator {...indicators[4]} iconWidth="2.34cm" />}
              </View>
            </View>
          </View>

          <View style={styles.side}>
            {indicators[1] && <Indicator {...indicators[1]} stretch={false} />}
            {indicators[5] && <Indicator {...indicators[5]} stretch={false} />}
            {indicators[6] && <Indicator {...indicators[6]} stretch={false} />}
          </View>

          <View
            style={{
              ...styles.indicatorsContainer,
              ...styles.sectionWithBg,
              margin: "0.5cm 0"
            }}>
            {indicators[7] && <Indicator {...indicators[7]} />}
            {indicators[8] && <Indicator {...indicators[8]} />}
            {indicators[9] && <Indicator {...indicators[9]} />}
            {indicators[10] && <Indicator {...indicators[10]} />}
          </View>

          <View style={styles.main}>
            <View
              style={{
                ...styles.indicatorsContainer,
                alignItems: "center"
              }}>
              {indicators[11] && (
                <>
                  <View style={{ ...styles.indicatorsContainer, flex: 1 }}>
                    <Indicator {...indicators[11]} imgUrl={null} />
                  </View>

                  <Image
                    src={indicators[11]?.imgUrl}
                    alt={indicators[11]?.name}
                    style={{
                      width: "50%"
                    }}
                  />
                </>
              )}
            </View>
          </View>

          <View style={styles.side}>
            {indicators[12] && <Indicator {...indicators[12]} stretch={false} />}
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot7;
