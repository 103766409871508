import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .publications-carousel {
    .swiper {
      height: 100%;
      /* padding-bottom: 50px; */
    }
    .swiper-slide {
      height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: var(--light);
    }

    &__buttons {
      z-index: 1;
    }

    .swiper-pagination {
      visibility: visible;
      ${respondTo.md`
        visibility: hidden;
      `}
    }
  }
`;
export default Styles;
