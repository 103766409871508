import StatisticalSnapshot1 from "./snapshot-1";
import StatisticalSnapshot2 from "./snapshot-2";
import StatisticalSnapshot3 from "./snapshot-3";
import StatisticalSnapshot4 from "./snapshot-4";
import StatisticalSnapshot5 from "./snapshot-5";
import StatisticalSnapshot6 from "./snapshot-6";
import StatisticalSnapshot7 from "./snapshot-7";
import StatisticalSnapshot8 from "./snapshot-8";
import StatisticalSnapshot9 from "./snapshot-9";
import StatisticalSnapshot10 from "./snapshot-10";
import StatisticalSnapshot11 from "./snapshot-11";
import StatisticalSnapshot12 from "./snapshot-12";
import StatisticalSnapshot13 from "./snapshot-13";
import StatisticalSnapshot14 from "./snapshot-14";
import StatisticalSnapshot15 from "./snapshot-15";
import StatisticalSnapshot16 from "./snapshot-16";
import StatisticalSnapshot17 from "./snapshot-17";
import StatisticalSnapshot18 from "./snapshot-18";

export { default as GovernorateStatisticalSnapshot } from "./gov-snapshot";

const sdgsStatisticalSnapshots = {
  1: StatisticalSnapshot1,
  2: StatisticalSnapshot2,
  3: StatisticalSnapshot3,
  4: StatisticalSnapshot4,
  5: StatisticalSnapshot5,
  6: StatisticalSnapshot6,
  7: StatisticalSnapshot7,
  8: StatisticalSnapshot8,
  9: StatisticalSnapshot9,
  10: StatisticalSnapshot10,
  11: StatisticalSnapshot11,
  12: StatisticalSnapshot12,
  13: StatisticalSnapshot13,
  14: StatisticalSnapshot14,
  15: StatisticalSnapshot15,
  16: StatisticalSnapshot16,
  17: StatisticalSnapshot17,
  18: StatisticalSnapshot18
};

export default sdgsStatisticalSnapshots;
