import styled from "styled-components";

const Styles = styled.div`
  height: 100%;
  .event-card {
    cursor: pointer;
    max-width: 400px;
    margin: auto;
    border: solid 1px var(--light);
    border-bottom: solid 4px ${({ highlightColor }) => highlightColor || `var(--primary)`};
    &__img {
      position: relative;
      padding-top: 60%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    &__date-badge {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-clip-path: polygon(50% 0%, 0 50%, 0 0);
      clip-path: polygon(50% 0%, 0 50%, 0 0);
      padding-left: 0.6rem;
      padding-top: 0.6rem;
      background-color: ${({ highlightColor }) => highlightColor || `var(--primary)`};
      * {
        line-height: 1;
      }
    }

    &__event-type {
      background-color: ${({ highlightColor }) => highlightColor || `var(--primary)`};
    }
  }
`;
export default Styles;
