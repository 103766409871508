import React from "react";
import { StyledSvg } from "@atoms";

const ChevRightIcon = (props) => {
  return (
    <StyledSvg
      {...props}
      localized
      strokeWidth="0"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </StyledSvg>
  );
};

export default ChevRightIcon;
