import React from "react";
import { StyledSvg } from "@atoms";

const ChartArrowUpIcon = (props) => {
  return (
    <StyledSvg
      {...props}
      strokeWidth="0"
      viewBox="0 0 255.118 198.425"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="1em"
      height="0.77em"
      enableBackground="new 0 0 255.118 198.425"
      xmlXpace="preserve">
      <g>
        <polygon
          fill="currentColor"
          points="53.829,127.541 47.561,133.932 53.829,127.637 	"
        />
        <polygon
          fill="currentColor"
          points="129.132,153.495 128.663,153.968 128.669,153.973 	"
        />
        <polygon
          fill="currentColor"
          points="128.64,153.948 128.662,153.968 128.663,153.968 	"
        />
        <polygon
          fill="currentColor"
          points="141.056,141.201 129.132,153.495 141.056,141.522 	"
        />
        <g>
          <g>
            <g>
              <polygon
                fill="currentColor"
                points="53.829,126.445 27.087,153.313 27.087,197.229 53.829,197.229 53.829,126.445 				"
              />
            </g>
            <g>
              <polygon
                fill="currentColor"
                points="201.542,197.229 228.284,197.229 228.284,53.513 201.542,80.287 				"
              />
            </g>
            <g>
              <polygon
                fill="currentColor"
                points="157.928,197.229 184.669,197.229 184.669,97.032 157.928,123.716 				"
              />
            </g>
            <g>
              <polyline
                fill="currentColor"
                points="131.212,150.375 114.315,133.363 114.315,197.229 141.056,197.229 141.056,165.236 
					141.056,140.552 				"
              />
            </g>
            <g>
              <polygon
                fill="currentColor"
                points="70.701,197.229 97.442,197.229 97.442,116.422 80.814,99.526 70.701,109.568 				"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <rect
              x="-7.354"
              y="96.236"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -60.4217 64.4277)"
              fill="currentColor"
              width="109.83"
              height="17.827"
            />

            <rect
              x="61.033"
              y="89.299"
              transform="matrix(0.7071 0.7071 -0.7071 0.7071 100.3925 -45.9442)"
              fill="currentColor"
              width="89.246"
              height="17.828"
            />

            <rect
              x="104.126"
              y="71.373"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.7541 146.6826)"
              fill="currentColor"
              width="140.116"
              height="17.828"
            />
            <polyline
              fill="currentColor"
              points="203.04,10.138 252.691,1.195 243.75,50.848 			"
            />
          </g>
        </g>
      </g>
    </StyledSvg>
  );
};

export default ChartArrowUpIcon;
