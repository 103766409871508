import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#DEA63A";

const StatisticalSnapshot2 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={{ ...styles.section, padding: "0 10pt" }}>
            <Image
              src={sdgInfo?.snapshotImageURL}
              alt={sdgInfo?.name}
              style={{ width: "80%" }}
            />
          </View>
          <View style={{ ...styles.section, padding: "0 1cm" }}>
            <View
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}>
              {indicators[0] && <Indicator {...indicators[0]} imgGrow={false} />}
            </View>
          </View>

          <View
            style={{
              ...styles.section,
              ...styles.sectionWithBg,
              padding: "15pt",
              width: "70%"
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[1] && <Indicator {...indicators[1]} iconWidth="1.5cm" />}
              {indicators[2] && <Indicator {...indicators[2]} iconWidth="1.5cm" />}
              {indicators[3] && <Indicator {...indicators[3]} iconWidth="1.5cm" />}
            </View>
          </View>

          <View
            style={{
              ...styles.section,
              ...styles.indicatorsContainer,
              width: "30%",
              padding: "20pt 10pt"
            }}>
            {indicators[4] && <Indicator {...indicators[4]} iconWidth="2.2cm" />}
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <View style={styles.indictorWithBorder}>
              {indicators[5] && <Indicator {...indicators[5]} iconWidth="2cm" horiz />}
            </View>

            <View style={styles.indictorWithBorder}>
              {indicators[6] && <Indicator {...indicators[6]} iconWidth="2cm" horiz />}
            </View>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <View style={styles.indictorWithBorder}>
              {indicators[7] && (
                <Indicator {...indicators[7]} iconWidth="2cm" horiz justifyBetween />
              )}
            </View>

            <View style={styles.indictorWithBorder}>
              {indicators[8] && (
                <Indicator {...indicators[8]} iconWidth="2cm" horiz justifyBetween />
              )}
            </View>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <View style={{ ...styles.indictorWithBorder, borderBottom: "none" }}>
              {indicators[9] && <Indicator {...indicators[9]} iconWidth="2cm" horiz />}
            </View>

            <View style={{ ...styles.indictorWithBorder, borderBottom: "none" }}>
              {indicators[10] && <Indicator {...indicators[10]} iconWidth="2cm" horiz />}
            </View>
          </View>

          <View
            style={{
              ...styles.sectionWithBg,
              width: "100%"
            }}>
            {indicators[11] && <Indicator {...indicators[11]} horiz />}
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot2;
