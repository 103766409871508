import React, { useEffect } from "react";

import { Routes, Route, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import { SpinnerLoader } from "@particles";

import { checkMenus, addDynamicRoutes } from "utils/menus";

import routes from "./routes";

import "./global-styles/app.scss";
import { scrollTop } from "@utils";
import { Page404 } from "@pages";
import { pageRoutes } from "@constants";

const App = ({ menusLoading, upperMenus, bottomMenus }) => {
  const newRoutes = addDynamicRoutes(routes, upperMenus, bottomMenus);

  useEffect(() => {
    checkMenus();
  }, []);

  // scroll top when navigating
  const location = useLocation();

  useEffect(() => {
    if (
      // prevent the special case in international indicators page when setting
      // the selected topic and redirecting to the same page with only different query param
      location.pathname?.includes(pageRoutes.intIndicators) &&
      location.state?.from?.pathname?.includes(pageRoutes.intIndicators)
    ) {
      return;
    }
    scrollTop();
  }, [location]);

  if (menusLoading) {
    return <SpinnerLoader />;
  }

  return (
    <Routes>
      {newRoutes.map(({ path, component: Component }, index) => (
        <Route key={index} path={path} element={<Component />} />
      ))}

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  menusLoading: state.menus.menusLoading,
  upperMenus: state.menus.upperMenus,
  bottomMenus: state.menus.bottomMenus
});

export default connect(mapStateToProps)(App);
