import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .cards-loader {
    display: grid;
    gap: 1.5rem;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    margin: auto;

    ${respondTo.md`
        grid-template-columns: repeat(2, 1fr);
      `}

    ${respondTo.lg`
        grid-template-columns: repeat(${(props) => Math.min(props?.count, 3)}, 1fr);
      `}
  }
`;
export default Styles;
