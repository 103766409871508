import styled from "styled-components";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Styles = styled.div`
  width: 100;
  height: 100%;
  .spinner-loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5000;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
export default Styles;
