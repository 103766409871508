import React from "react";
import { FormattedMessage } from "react-intl";
import truncate from "lodash/truncate";
import Styles from "./styles";
import { MAX_PUB_SUMMARY_CHARS, pageRoutes } from "@constants";
import GenericLink from "../generic-link";

const PublicationCarouselItem = ({
  image,
  id,
  imageAlt,
  title,
  summary,
  bgColor,
  index = 0
}) => {
  const link = `${pageRoutes.publications}/${id}`;

  return (
    <Styles bgColor={bgColor}>
      <div className="publication-carousel-item row no-gutters h-100">
        <div className="col-sm-6 col-xxl-7">
          <div className="publication-carousel-item__img-container">
            <div className="w-100 h-100 publication-carousel-item__img">
              {image && (
                <img
                  className="w-100 h-100 object-fill"
                  src={image}
                  alt={imageAlt || "Publication cover"}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xxl-5">
          <div className="publication-carousel-item__card w-100 h-100 p-4">
            <div className="w-100 d-flex justify-content-end">
              <div className="uppercase text-light text-18px mb-3 pt-1">
                <div>
                  <FormattedMessage defaultMessage="Publication" />
                </div>
                {typeof index === "number" && (
                  <div className="publication-carousel-item__index">{index}</div>
                )}
              </div>
            </div>

            <div className="pb-5 px-2">
              {title && (
                <h1 className="publication-carousel-item__title mb-3">{title}</h1>
              )}
              {summary && (
                <p className="text-light text-16px mb-4">
                  {truncate(summary, { length: MAX_PUB_SUMMARY_CHARS })}
                </p>
              )}
              {link && (
                <GenericLink
                  link={link}
                  className="publication-carousel-item__read-more text-14px">
                  <FormattedMessage defaultMessage="Read more" />
                </GenericLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default PublicationCarouselItem;
