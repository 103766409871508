import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Styles from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { PublicationCarouselItem } from "@molecules";
import { breakpoints, PUB_CAROUSEL_COLORS } from "@constants";
import { PublicationCarouselButton } from "@atoms";

const swiperProps = {
  modules: [Autoplay, Navigation, Pagination],
  navigation: true,
  pagination: {
    type: "bullets"
  },
  autoplay: {
    delay: 5000
  }
};

const getPubColor = (index) => PUB_CAROUSEL_COLORS[index % PUB_CAROUSEL_COLORS.length];

const PublicationsCarousel = ({ items, dir = "ltr" }) => {
  const swiperRef = useRef();
  const [activeSlide, setActiveSlide] = useState(
    swiperRef?.current?.swiper?.activeIndex || 0
  );

  const handleClickCarousel = (index) => swiperRef?.current?.swiper?.slideTo(index);
  const onSlideChange = (swiper) => setActiveSlide(swiper?.activeIndex);

  return (
    <Styles>
      {items?.length > 0 && (
        <div className="publications-carousel w-100">
          <h1 className="font-md text-50px text-black text-center mb-4">
            <FormattedMessage defaultMessage="Latest Publications" />
          </h1>
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-10 col-xxl-9 h-100 flex-grow-1">
              <Swiper
                {...swiperProps}
                ref={swiperRef}
                onSlideChange={onSlideChange}
                dir={dir}>
                {items?.map?.((item, index) => (
                  <SwiperSlide key={index}>
                    <PublicationCarouselItem
                      {...item}
                      index={index + 1}
                      bgColor={getPubColor(index)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {items?.length > 1 && (
              <div
                className={`publications-carousel__buttons d-none d-md-block col-lg-2 col-xxl-${
                  items?.length > 2 ? "3" : "2"
                }`}>
                <div className="d-flex flex-column flex-lg-row h-100">
                  {items?.map((item, i) =>
                    i !== activeSlide ? (
                      <PublicationCarouselButton
                        key={i}
                        bgColor={getPubColor(i)}
                        title={item.title}
                        index={i + 1}
                        onClick={() => handleClickCarousel(i)}
                        vertModeBreakPoint={breakpoints.lg}
                      />
                    ) : null
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Styles>
  );
};

export default PublicationsCarousel;
