import { CardsLoader } from "@particles";
import React from "react";
import { FormattedMessage } from "react-intl";
import { RelatedLinksCarousel } from "@organisms";
import Styles from "./styles";

const RelatedLinks = ({ loading, relatedLinks, dir }) => {
  return (
    <Styles>
      <section className="related-links py-3 py-xxl-5">
        <div className="container">
          <h1 className="text-white font-md text-50px text-center mb-2 mb-xxl-3">
            <FormattedMessage defaultMessage="Related Links" />
          </h1>
          <div className="related-links__content">
            {!loading && <RelatedLinksCarousel items={relatedLinks} dir={dir} />}

            {!loading && relatedLinks?.length <= 0 && (
              <p className="text-center text-18px font-md text-white py-5">
                <FormattedMessage defaultMessage="There are no related links" />
              </p>
            )}

            {loading && <CardsLoader />}
          </div>
        </div>
      </section>
    </Styles>
  );
};

export default RelatedLinks;
