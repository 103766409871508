import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .events {
    &__top-section {
      transition: all 0.2s ease-in-out;
    }
    &__content {
      .container {
        ${respondTo.xl`
        max-width: 960px;
      `};
        ${respondTo.xxl`
        max-width: 1200px;
      `}
      }
    }

    &__calendar-btns {
      border-bottom: solid 1px var(--dark);
      /* padding: 0 5%; */
      ${respondTo.md`
        padding: 0 25%;
      `}
      &__wrapper {
        margin-bottom: -1rem;
      }
    }

    &__grid {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
      ${respondTo.md`
          grid-template-columns: repeat(2, 1fr);
      `}
      ${respondTo.xl`
          grid-template-columns: repeat(3, 1fr);
          gap: 3rem;
      `}
    }
  }
`;
export default Styles;
