import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  height: 100%;
  .key-topic-card {
    background-color: var(--light);
    height: 100%;

    &__img-container {
      width: 35%;
      ${respondTo.sm`
        width: 25%;
      `}
    }
    &__img {
      position: relative;
      padding-top: 100%;
      & > * {
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem 0.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 65%;
        }
      }
    }

    &__body {
      text-align: justify;
    }
  }
`;
export default Styles;
