import React from "react";
import { StyledButton } from "@atoms";
import Styles from "./styles";

const EventCalendarButton = ({ active, onClick, children, ...props }) => {
  return (
    <Styles active={active}>
      <StyledButton
        {...props}
        size="lg"
        variant={active ? "primary" : "white"}
        className="flex-grow-1 rounded-0 py-3"
        onClick={onClick}>
        {children}
        {active && <div className="py-2" />}
      </StyledButton>
    </Styles>
  );
};

export default EventCalendarButton;
