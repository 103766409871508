import styled from "styled-components";
import eventsCalBg from "assets/images/events-bg.jpg";
import { respondTo } from "helpers/style/respond-to";

const Styles = styled.div`
  .events-calendar {
    min-height: 600px;
    background-image: url(${eventsCalBg});
    background-size: cover;

    &__select {
      margin: auto;
      width: 100%;
      ${respondTo.sm`
            width: 50%
       `};
      ${respondTo.lg`
            width: 25%
       `}
    }
  }
`;
export default Styles;
