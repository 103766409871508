const breakpoints = {
  zero: 0,
  xs: "350px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
  xxxl: "1600px",
  xxxxl: "1800px"
};

export default breakpoints;
