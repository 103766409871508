const theme = {
  colors: {
    primary: "#c89637",
    primaryDark: "#b28735",
    secondary: "#328f54",
    light: "#f2f4f9",
    grey: "#757575",
    dark: "#2e2925",
    black: "#181818",
    greyLight: "#d5d4d5",
    greyDark: "#5a5a5a",
    danger: "#ff3c2a"
  }
};

export default theme;
