import isArabic from "helpers/isArabic";

const stylesFactory = function (themeColor) {
  const isAr = isArabic();

  const marginStart = isAr ? "marginRight" : "marginLeft";
  return {
    page: {
      fontFamily: isAr ? "ArbFONTS" : "Rubik",
      width: "595pt",
      // minHeight: "841pt",
      height: "841pt",
      position: "relative",
      direction: isAr ? "RTL" : "LTR",
      display: "flex",
      flexDirection: "column"
    },
    header: {
      width: "100%",
      height: "2.82cm",
      // maxHeight: "3.2cm",
      position: "relative",
      backgroundColor: themeColor,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    headerContent: {
      position: "relative",
      width: "100%",
      padding: "0.5cm 1.4cm",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      color: "white",
      fontSize: "22pt",
      fontWeight: 700
    },
    headerBg: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: isAr ? "column-reverse" : "column"
    },
    mainTitle: {
      fontSize: "20pt",
      textAlign: "center",
      fontWeight: "semibold",
      marginBottom: 0,
      [marginStart]: "12pt"
    },
    container: {
      padding: "0 1.4cm",
      paddingTop: "0.5cm",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between"
    },
    hr: {
      width: "100%",
      height: 1,
      backgroundColor: "#919191",
      margin: "0.3cm 0"
    },
    footer: {
      maxHeight: "1.5cm",
      padding: "5pt 1.4cm",
      backgroundColor: "#231F20",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
      // position: "absolute",
      // bottom: 0
    }
  };
};

export default stylesFactory;
