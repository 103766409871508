import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import queryString from "query-string";
import uniqueId from "lodash/uniqueId";
import { ChartsViewer, SdgOptionsList } from "@organisms";
import Styles from "./styles";
import { keyTopicsApi, sdgsApi } from "api";
import { getChartData, loadChartsDataWithProp } from "../sdg-charts/helpers";
import { useQuery } from "react-query";
import { SpinnerLoader, toastError, toastInfo } from "@particles";
import SdgSummary from "../sdg-summary";

import {
  combineChartsDataYears,
  mergeExcelRowCellsWithSameValue
} from "../charts-viewer/helpers";
import { useSearchParams } from "react-router-dom";

const getSerieId = (indicator, countryRegion) => {
  if (countryRegion?.countryId) {
    return `i${indicator?.id}-c${countryRegion.countryId}`;
  } else if (countryRegion?.regionId) {
    return `i${indicator?.id}-r${countryRegion.regionId}`;
  }
  return "";
};

const delimiter = " -- ";

const topicIdSearchParamKey = "topicId";
const indicatorIdSearchParamKey = "indicatorId";
const summaryTextVarKey = "international-indicators";
const guidelinesTextVarKey = "InternationalGuidelines";
const disclaimerTextVarKey = "InternationalDisclaimer";
const indUrlSeparator = ",";

function navigateWithoutRendering(route = "") {
  window.history.replaceState(null, null, route);
}

const InternationalIndicatorsCharts = () => {
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedIndicators, setSelectedIndicators] = useState([]);
  const [selectedCountriesAndRegions, setSelectedCountriesAndRegions] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [chartsDataYears, setChartsDataYears] = useState([]);

  const chartsContainerRef = useRef();

  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  const { isLoading, data } = useQuery(
    "international-data",
    keyTopicsApi.getInternationalData
  );

  const internationalData = useMemo(() => data?.data || {}, [data]);

  const countriesAndRegionsOptions = useMemo(() => {
    if (!internationalData?.countries && !internationalData?.regions) {
      return [];
    }

    const options = [];

    const getGroupedOptions = (name, data, idKey) => ({
      name: name,
      options: data.map((element) => ({
        ...element,
        id: uniqueId("o-"),
        [idKey]: element.id
      }))
    });

    if (internationalData.countries?.length > 0) {
      options.push(
        getGroupedOptions(
          <FormattedMessage defaultMessage="Countries" />,
          internationalData.countries,
          "countryId"
        )
      );
    }

    if (internationalData.regions?.length > 0) {
      options.push(
        getGroupedOptions(
          <FormattedMessage defaultMessage="Regions" />,
          internationalData.regions,
          "regionId"
        )
      );
    }

    return options;
  }, [internationalData]);

  // ==================================================

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initialSelectedTopicId = Number(searchParams.get(topicIdSearchParamKey));

    const initialSelectedIndicatorsIds =
      searchParams.get(indicatorIdSearchParamKey)?.split(indUrlSeparator)?.map(Number) ||
      [];

    if (initialSelectedTopicId) {
      const newTopic = internationalData?.data?.find(
        (topic) => topic.id === initialSelectedTopicId
      );
      setSelectedTopic(newTopic);

      if (initialSelectedIndicatorsIds.length > 0) {
        setSelectedIndicators(
          newTopic?.indicators?.filter((ind) =>
            initialSelectedIndicatorsIds.includes(ind.id)
          )
        );

        if (countriesAndRegionsOptions?.length > 0) {
          const newSelectedCountryAndRegions = [
            countriesAndRegionsOptions[0]?.options?.[0]
          ];
          setSelectedCountriesAndRegions(newSelectedCountryAndRegions);

          chartsContainerRef.current?.scrollIntoView({
            behavior: "smooth"
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesAndRegionsOptions, internationalData?.data]);

  // =====================================================

  useEffect(() => {
    const createNewSeries = () => {
      const series = [];
      if (selectedIndicators?.length === 0 && selectedCountriesAndRegions?.length === 0) {
        return series;
      }

      selectedIndicators?.forEach((indicator) => {
        selectedCountriesAndRegions?.forEach((countryRegion) => {
          const serieId = getSerieId(indicator, countryRegion);
          const data = seriesData.find((s) => s.id === serieId)?.data;

          const serie = {
            name: `${indicator.name}${delimiter}${countryRegion.name}`,
            id: serieId,
            unitNameEn: indicator.unitNameEn || "",
            unitNameAr: indicator.unitNameAr || "",
            data: data ? [...data] : null
          };

          series.push(serie);
        });
      });

      return series;
    };

    const addData = () => {
      const series = createNewSeries();

      if (!series?.length > 0 && !seriesData?.length > 0) {
        return;
      }

      const seriesWithoutData = series.some((s) => !s.data);

      if (!seriesWithoutData) {
        if (seriesData?.length > series?.length) {
          setSeriesData([].concat(series));
        }
        return;
      }

      const selectedCountries = selectedCountriesAndRegions
        .filter((s) => s.countryId)
        .map((s) => s.countryId);

      const selectedRegions = selectedCountriesAndRegions
        .filter((s) => s.regionId)
        .map((s) => s.regionId);

      const promises = [];

      setIsChartDataLoading(true);

      let chartsDataYearsTemp = [];

      selectedIndicators?.forEach((indicator) => {
        const body = {
          IndicatorId: indicator.id,
          Countries: selectedCountries,
          Regions: selectedRegions
        };

        promises.push(
          sdgsApi.getIndicatorData(body).then((res) => {
            const years = res?.data?.years || [];
            if (years.length > 0) {
              chartsDataYearsTemp = combineChartsDataYears(years, chartsDataYearsTemp);
            }

            const indicatorData = res?.data?.values || [];

            if (indicatorData?.length > 0) {
              selectedCountriesAndRegions.forEach((countryRegion) => {
                const serie = series.find(
                  (s) => s.id === getSerieId(indicator, countryRegion)
                );

                if (indicatorData?.length > 0) {
                  indicatorData.forEach((element) => {
                    if (
                      countryRegion.countryId === element.countryId ||
                      countryRegion.regionId === element.regionId
                    ) {
                      if (!serie.data) {
                        serie.data = [];
                      }
                      serie.data.push({
                        year: element.year,
                        value: element.value,
                        note: element.note,
                        targetValue: element.targetValue
                      });
                    }
                  });
                }
              });
            }
          })
        );
      });

      Promise.all(promises)
        .then(() => {
          series.forEach((s) => {
            if (!s.data) {
              toastInfo(
                <FormattedMessage
                  defaultMessage="There are no data found for {name}"
                  values={{
                    name: s.name
                  }}
                />
              );
            }
          });

          // use the empty array to differentiate between the new series and old series without data
          setSeriesData(series.map((s) => (s.data ? s : { ...s, data: [] })));

          setChartsDataYears(chartsDataYearsTemp);
        })
        .catch(() => {
          setSeriesData((prev) => (prev.length > 0 ? [] : prev));
          toastError();
        })
        .finally(() => setIsChartDataLoading(false));
    };

    addData();
  }, [selectedCountriesAndRegions, seriesData, selectedIndicators]);

  const datafullSeries = useMemo(
    () => seriesData.filter((s) => s.data?.length > 0),
    [seriesData]
  );

  useEffect(() => {
    if (!datafullSeries.length > 0) {
      setChartsDataYears((prev) => (prev?.length > 0 ? [] : prev));
    }
  }, [datafullSeries]);

  const intl = useIntl();

  const handleExcelDataSheet = useCallback(
    (sheet, chartsData, series) => {
      const keys = Object.keys(chartsData?.[0]);

      const seriesNames = series.map((s) => s.name).sort();

      const countriesAndRegions = [];
      const indicators = [];
      seriesNames.forEach((name) => {
        const [indicator, countryRegion] = name ? name.split(delimiter) : [];
        countriesAndRegions.push(countryRegion);
        indicators.push(indicator);
      });

      const dataColumns = seriesNames.flatMap((name, index) => [
        { key: name, title: indicators[index] },
        { key: `${name}-note`, title: intl.formatMessage({ defaultMessage: "Comment" }) }
      ]);

      sheet.columns = [{ key: keys[0] }, ...dataColumns];

      const countriesAndRegionsRowData = [...countriesAndRegions];

      countriesAndRegions.forEach((cr) => {
        const lastIndex = countriesAndRegionsRowData.lastIndexOf(cr);
        countriesAndRegionsRowData.splice(lastIndex + 1, 0, cr);
      });

      const row = sheet.addRow([
        intl.formatMessage({ defaultMessage: "Country/Region" }),
        ...countriesAndRegionsRowData
      ]);

      // merge columns with same country
      mergeExcelRowCellsWithSameValue(sheet, row);

      const lastHeaderRow = sheet.addRow([
        intl.formatMessage({ defaultMessage: "Indicator" }),
        ...dataColumns.map((el) => el.title)
      ]);

      const chartsDataWithNotes = loadChartsDataWithProp("note", chartsData, series);

      sheet.addRows(chartsDataWithNotes);

      return lastHeaderRow.number;
    },
    [intl]
  );

  const handleTopicChange = useCallback((newTopic) => {
    setSelectedTopic(newTopic);
    setSelectedIndicators([]);
    setSelectedCountriesAndRegions([]);
  }, []);

  const handleOnResetChart = useCallback(() => {
    handleTopicChange();
    setSeriesData([]);
  }, [handleTopicChange]);

  useEffect(() => {
    // let url track state changes without rerendering
    const ids = selectedIndicators?.map((ind) => ind.id);

    const route = !selectedTopic
      ? "?"
      : "?" +
        queryString.stringify(
          {
            [topicIdSearchParamKey]: selectedTopic?.id,
            [indicatorIdSearchParamKey]: ids
          },
          {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: "separator",
            arrayFormatSeparator: indUrlSeparator
          }
        );

    navigateWithoutRendering(route);
  }, [selectedTopic, selectedIndicators]);

  return (
    <Styles>
      <div className="international-indicators-charts w-100">
        {isLoading && <SpinnerLoader />}

        <SdgSummary textVarKey={summaryTextVarKey} />

        <div className="w-100 pt-5">
          <div className="d-block">
            <div className="international-indicators-charts__options">
              <SdgOptionsList
                id="select-topic"
                title={intl.formatMessage({ defaultMessage: "Select Topic" })}
                options={internationalData.data}
                search={false}
                value={selectedTopic}
                onChange={handleTopicChange}
              />

              {selectedTopic && (
                <SdgOptionsList
                  id="select-indicators"
                  title={intl.formatMessage({ defaultMessage: "Select indicators" })}
                  options={selectedTopic?.indicators}
                  labelProp="name"
                  search={false}
                  values={selectedIndicators}
                  onChange={setSelectedIndicators}
                  multi
                  disabled={isChartDataLoading}
                />
              )}

              {selectedIndicators?.length > 0 && (
                <SdgOptionsList
                  id="select-country"
                  title={intl.formatMessage({
                    defaultMessage: "Select country & region"
                  })}
                  options={countriesAndRegionsOptions}
                  search={false}
                  values={selectedCountriesAndRegions}
                  onChange={setSelectedCountriesAndRegions}
                  multi
                  grouped
                  groupLabelProp={"name"}
                  labelProp="name"
                  disabled={isChartDataLoading}
                />
              )}
            </div>

            <div className="international-indicators-charts__chart-container">
              <div
                ref={chartsContainerRef}
                className="international-indicators-charts__chart">
                <ChartsViewer
                  id="international-indicators"
                  indicators={datafullSeries}
                  isLoading={isChartDataLoading}
                  title={selectedTopic?.name}
                  multipleYAxes
                  emptyChartMsg={intl.formatMessage({
                    defaultMessage:
                      "Please select goal, indicators, and the countries and regions to show their charts"
                  })}
                  onResetChart={handleOnResetChart}
                  indicatorsMetadata={selectedIndicators}
                  guidelinesTextVarKey={guidelinesTextVarKey}
                  disclaimerTextVarKey={disclaimerTextVarKey}
                  handleExcelDataSheet={handleExcelDataSheet}
                  chartsDataYears={chartsDataYears}
                  getChartData={getChartData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default InternationalIndicatorsCharts;
