import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .publications {
    flex: 1;

    &__content {
      .container {
        ${respondTo.xl`
        max-width: 960px;
      `};
        ${respondTo.xxl`
        max-width: 1200px;
      `}
      }
    }
    &__grid {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
      ${respondTo.md`
          grid-template-columns: repeat(2, 1fr);
      `}
      ${respondTo.xl`
          grid-template-columns: repeat(3, 1fr);
          gap: 3rem;
      `}
    }
  }
`;
export default Styles;
