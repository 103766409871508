import styled from "styled-components";

const ExternalLink = styled.a.attrs(({ href }) => ({
  target: "_blank",
  rel: "noopener noreferrer",
  href: href?.startsWith("www.") ? `https://${href}` : href
}))`
  color: ${({ color }) => color || `var(--primary)`};
  &:hover {
    color: ${({ hoverColor }) => hoverColor || `var(--primaryDark)`};
  }
  text-decoration: none;
  padding: 0.3rem 0.5rem;
  line-height: 100%;
`;

export default ExternalLink;
