const translatedStrings = {
  source: {
    //todo replace confirmed translation
    en: "Sources and Citations",
    ar: "المصادر والمراجع"
  },
  author: {
    en: "About the author",
    ar: "نبذة عن المؤلف"
  },
  authors: {
    en: "About the authors",
    ar: "نبذة عن المؤلفين"
  }
};

export default translatedStrings;
