import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#5BBA47";

const StatisticalSnapshot15 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.main}>
            <View style={{ ...styles.section, marginBottom: "1cm", width: "100%" }}>
              <Image
                src={sdgInfo?.snapshotImageURL}
                alt={sdgInfo?.name}
                style={{ width: "100%" }}
              />
            </View>

            <View style={{ ...styles.section, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[1] && <Indicator {...indicators[1]} iconWidth="2.34cm" />}
                {indicators[2] && <Indicator {...indicators[2]} iconWidth="2.34cm" />}
                {indicators[3] && <Indicator {...indicators[3]} iconWidth="2.34cm" />}
              </View>
            </View>

            <View
              style={{
                ...styles.section,
                ...styles.sectionWithBg,
                width: "100%"
              }}>
              <View style={styles.indicatorsContainer}>
                {indicators[5] && (
                  <>
                    <Image
                      src={indicators[5].imgUrl}
                      style={{
                        width: "8cm",
                        height: "4cm",
                        padding: "0 10pt",
                        flexShrink: 0,
                        objectFit: "cover"
                      }}
                    />
                    <View style={{ paddingTop: "0.5cm", flex: 1 }}>
                      <Indicator {...indicators[5]} horiz imgUrl={null} />
                    </View>
                  </>
                )}
              </View>
            </View>
          </View>

          <View style={styles.side}>
            {indicators[0] && <Indicator {...indicators[0]} stretch={false} />}
            <View style={styles.hr} />
            {indicators[4] && <Indicator {...indicators[4]} stretch={false} />}
            <View style={styles.hr} />
            {indicators[6] && <Indicator {...indicators[6]} stretch={false} />}
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot15;
