import { StyledButton } from "@atoms";
import { MAX_BLOG_CARD_CHARS, pageRoutes } from "@constants";
import { RightArrowIcon } from "@icons";
import { LocalizedLink } from "@particles";
import { getDateLocale } from "@utils";
import ImageComponent from "components/atoms/image-component";
import { generateBlogUrlParam } from "components/pages/blogs/helpers";
import { format } from "date-fns";
import truncate from "lodash/truncate";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ARABIC } from "types/languages";
import Styles from "./styles";

const BlogCard = ({ id, title, summary, image, imageAlt, authors, publishDate }) => {
  const link = `${pageRoutes.blogs}/${generateBlogUrlParam(title, id)}`;

  const authorsNames = authors?.map?.((author) => author.name);

  const locale = useIntl().locale;

  return (
    <Styles>
      <div className="blog-card w-100 p-4 px-xl-5">
        <div className="w-100">
          {title && (
            <LocalizedLink className="blog-card__link" to={link}>
              <h4 className="text-primary font-md text-28px mb-3">{title}</h4>
            </LocalizedLink>
          )}
          {
            <p className="blog-card__meta uppercase font-md text-14px mb-3">
              {authorsNames?.length > 0 && (
                <span>{authorsNames.join(locale === ARABIC ? "، " : ", ")}</span>
              )}
              {authorsNames?.length > 0 && publishDate && (
                <span>
                  <FormattedMessage defaultMessage="," />{" "}
                </span>
              )}
              {publishDate && (
                <span>
                  {format(new Date(publishDate), "PP", {
                    locale: getDateLocale()
                  })}
                </span>
              )}
            </p>
          }
        </div>
        <div className="clearfix">
          {image && (
            <ImageComponent
              className="blog-card__img float-md-start me-4 mb-4"
              src={image}
              alt={imageAlt || "Blog"}
            />
          )}
          <div className="blog-card__body">
            {summary && (
              <p className="text-black text-18px">
                {truncate(summary, { length: MAX_BLOG_CARD_CHARS })}
              </p>
            )}
          </div>

          {id && (
            <div className="mt-3">
              <LocalizedLink to={link}>
                <span className="font-bold text-black text-14px uppercase me-2">
                  <FormattedMessage defaultMessage="read more" />
                </span>
                <StyledButton circle size="lg">
                  <RightArrowIcon />
                </StyledButton>
              </LocalizedLink>
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
};

export default BlogCard;
