import styled from "styled-components";

const Styles = styled.div`
  .app-template {
    &__header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      min-height: var(--header-height);
    }
    &__content {
      margin-top: calc(var(--top-header-height) + var(--nav-menu-height));
      min-height: calc(100vh - var(--top-header-height) - var(--nav-menu-height) - 125px);
      display: flex;
      flex-direction: column;
      > *:first-child {
        flex: 1;
        display: flex;
        display: flex;
        flex-direction: column;
      }
    }
    &__scroll-btn {
      position: fixed;
      bottom: 2rem;
      right: 1rem;
      z-index: 2000;
      transition: all 0.2s;
      opacity: ${({ showScrollBtn }) => (showScrollBtn ? 1 : 0)};
      visibility: ${({ showScrollBtn }) => (showScrollBtn ? "visible" : "hidden")};
    }
  }
`;
export default Styles;
