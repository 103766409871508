import React, { useContext } from "react";
import { Image, Text, View } from "@particles";
import { ThemeContext } from "../theme-provider";
import stylesFactory from "./styles";
import { useIntl } from "react-intl";
import { isNumber } from "@utils";

function formatValue(value, unit, intl) {
  if (!isNumber(value)) {
    return [value, unit];
  }
  let tempValue = value;
  let formattedUnit = unit;
  // if (value > 100000) {
  //   tempValue = value / 1000;
  //   formattedUnit = unit
  //     ? `${intl.formatMessage({ defaultMessage: "thousands" })} ${unit}`
  //     : "";
  // }

  let maximumFractionDigits = 0;
  if (value < 10) {
    maximumFractionDigits = 2;
  } else if (value < 1000) {
    maximumFractionDigits = 1;
  }

  const formattedValue = value
    ? new Intl.NumberFormat(intl.locale, {
        maximumFractionDigits
      }).format(tempValue)
    : "N/A";

  return [formattedValue, formattedUnit];
}

const Indicator = ({
  imgUrl,
  value,
  name,
  source,
  year,
  unit,
  iconWidth,
  iconHeight,
  horiz,
  justifyBetween,
  stretch = true,
  textColor,
  imgGrow,
  debug
}) => {
  const { themeColor } = useContext(ThemeContext);
  const styles = stylesFactory(themeColor, horiz, stretch, justifyBetween, textColor);

  const isUnitPercent = unit?.toLowerCase() === "percent" || unit === "نسبة مئوية";
  const isUnitUsd = unit === "USD" || unit === "دولار أمريكي";

  const intl = useIntl();
  const [formattedValue, formattedUnit] = formatValue(value, unit, intl);

  // when set this conditionally in styles, things screwd up in react-pdf
  // so went with this way
  if (stretch) {
    styles.body.flex = "1";
  }

  const contentStyle = {};

  if (horiz) {
    contentStyle.flex = 1;
  }
  return (
    <View debug={debug} style={styles.body}>
      <View
        style={{
          flexGrow: !horiz && imgGrow ? 1 : 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: "3pt"
        }}>
        {imgUrl && (
          <Image
            src={imgUrl}
            alt={name}
            style={{
              ...styles.icon,
              width: iconWidth ?? styles.icon.width,
              height: iconHeight ?? "auto",
              objectFit: "cover"
            }}
          />
        )}
      </View>

      <View style={contentStyle}>
        <Text style={styles.value}>
          {isUnitUsd && "$"}
          {formattedValue}
          {isUnitPercent && "%"}
        </Text>
        {unit && !isUnitPercent && !isUnitUsd && (
          <Text style={styles.unit}>{formattedUnit}</Text>
        )}
        <Text style={styles.name}>{name}</Text>
        {(source || year) && (
          <Text style={styles.source}>
            {year}
            {year && source && " / "}
            {source}
          </Text>
        )}
      </View>
    </View>
  );
};

export default Indicator;
