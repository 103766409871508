import { GEN_ERR_MSG } from "@constants";
import { toast } from "react-toastify";

export function toastError(msg = GEN_ERR_MSG, options = {}) {
  msg && toast.error(msg, options);
}

export function toastSuccess(msg, options = {}) {
  msg && toast.success(msg, options);
}

export function toastInfo(msg, options = {}) {
  msg && toast.info(msg, options);
}
