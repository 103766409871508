import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

export const containerId = "sdg-line-chart__chart";

const Styles = styled.div`
  .sdg-line-chart {
    #${containerId} {
      height: 320px;
      ${respondTo.lg`
        height: 400px;
      `}
    }

    .legendContainer {
      max-height: 350px;
      overflow-x: none;
      overflow-y: auto;
      padding: 1rem;
    }

    #legenddiv {
      direction: ltr;
      width: 100%;
      height: 100px;
      border: 1px dotted var(--grey);
    }
  }
`;

export default Styles;
