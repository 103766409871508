import styled from "styled-components";

const Styles = styled.div`
  width: 100%;
  .pagination {
    display: flex;
    justify-content: center;

    &__container {
      list-style: none;
      display: flex;
      justify-content: center;
    }

    &__btn {
      &.disabled {
        cursor: not-allowed;
        * {
          cursor: not-allowed;
          opacity: 0.8;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    &__link {
      &--active {
        background-color: var(--primary);
        color: white;
      }
    }
  }
`;
export default Styles;
