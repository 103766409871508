import Loadable from "components/particles/loadable";

import { pageRoutes } from "constants/menu-links-data";

const Home = Loadable(() => import("components/pages/home"));
const Publications = Loadable(() => import("components/pages/publications"));
const SDG = Loadable(() => import("components/pages/sdg"));
const Events = Loadable(() => import("components/pages/events"));
const PublicationDetails = Loadable(() => import("components/pages/publication-details"));
const KeyTopics = Loadable(() => import("components/pages/key-topics"));
const EventDetails = Loadable(() => import("components/pages/event-details"));
const DataHighlights = Loadable(() => import("components/pages/data-highlights"));
const Localization = Loadable(() => import("components/pages/localization"));
const About = Loadable(() => import("components/pages/about"));
const InternationalIndicators = Loadable(() =>
  import("components/pages/international-indicators")
);
const Covid19Impact = Loadable(() => import("components/pages/covid19-impact"));
const Blogs = Loadable(() => import("components/pages/blogs"));
const BlogDetails = Loadable(() => import("components/pages/blog-details"));
const SearchResults = Loadable(() => import("components/pages/search-results"));

const routes = [
  {
    component: Home,
    path: "/"
  },
  {
    component: SDG,
    path: pageRoutes.sdg
  },
  {
    component: Publications,
    path: pageRoutes.publications
  },
  {
    component: PublicationDetails,
    path: `${pageRoutes.publications}/:id`
  },
  {
    component: Events,
    path: pageRoutes.events
  },
  {
    component: EventDetails,
    path: `${pageRoutes.events}/:id`
  },
  {
    component: KeyTopics,
    path: `${pageRoutes.keyTopics}/*`
  },
  {
    component: DataHighlights,
    path: pageRoutes.dataHighlights
  },
  {
    component: Covid19Impact,
    path: pageRoutes.covid
  },
  {
    component: Localization,
    path: pageRoutes.localization
  },
  {
    component: About,
    path: pageRoutes.about
  },
  {
    component: InternationalIndicators,
    path: pageRoutes.intIndicators
  },
  {
    component: Blogs,
    path: pageRoutes.blogs
  },
  {
    component: BlogDetails,
    path: `${pageRoutes.blogs}/:id`
  },
  {
    component: SearchResults,
    path: pageRoutes.search
  }
];

export default routes;
