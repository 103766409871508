import styled from "styled-components";

const SocialLink = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noopener noreferrer"
}))`
  color: ${({ color }) => color || `var(--light)`};
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.3rem 0.5rem;
  line-height: 100%;
`;

export default SocialLink;
