const stylesFactory = function (themeColor) {
  return {
    section: {
      justifyContent: "center",
      marginBottom: "0.5cm",
      width: "50%"
    },
    indicatorsContainer: {
      display: "flex",
      flexDirection: "row",
      // alignItems: "flex-start",
      width: "100%"
    },
    source: {
      marginTop: 5,
      color: "#919191",
      fontStyle: "italic",
      fontSize: 7
    },
    horizIndicator: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 8
    },
    sectionWithBg: {
      backgroundColor: "#EDEDED",
      padding: "15pt",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },

    hr: {
      width: "100%",
      height: 1,
      backgroundColor: "#919191",
      margin: "0.3cm 0"
    }
  };
};

export default stylesFactory;
