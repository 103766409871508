import React from "react";
import { Text, View } from "@particles";

const Sources = ({ sources }) => {
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        flexDirection: "row",
        marginBottom: "10pt"
      }}>
      {sources?.map?.((source, i) => (
        <Text
          style={{ width: "48%", fontSize: "7pt", margin: "3pt 0", fontWeight: 500 }}
          key={i}>
          {source}
        </Text>
      ))}
    </View>
  );
};

export default Sources;
