const stylesFactory = function (themeColor) {
  return {
    section: {
      justifyContent: "center",
      marginBottom: "0.3cm",
      width: "48%"
    },
    indicatorsContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%"
    },
    sectionWithBg: {
      backgroundColor: "#EDEDED",
      padding: "10pt 20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row"
    },
    horizIndicator: {
      padding: "8pt 0",
      width: "45%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  };
};

export default stylesFactory;
