import React, { useEffect, useState } from "react";

import http from "api/instance";

import { endpoints } from "api/menus-api";
import { HTMLComponent } from "@particles";

const Dynamic = ({ id }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (id) {
      http.get(endpoints.pageDetails, { params: { menuId: id } }).then((response) => {
        setContent(response.data.Content);
      });
    }
  }, [id]);

  if (!content) {
    return null;
  }

  return <HTMLComponent className="container p-0" html={content} />;
};

export default Dynamic;
