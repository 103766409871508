import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

export const Item = styled.li`
  border: 1px solid #b7b7b7;
  padding: 2rem 2.5rem 2rem 2.5rem;

  .header span,
  .header li {
    color: var(--primary);
  }

  .header ul {
    list-style: none;
    padding-left: 0.3rem !important;
  }

  .content {
    order: 2;
    ${respondTo.md`
      order: 1;
    `}
  }

  .image {
    order: 1;
    ${respondTo.md`
      order: 2;
    `}
  }

  .content span,
  .content a {
    font-weight: bold;
  }
`;
