import { breakpoints } from "@constants";
import { useMediaQuery } from "@hooks";
import React from "react";
import { VertCardLoader, HorizCardLoader } from "@particles";
import Styles from "./styles";

const CardsLoader = ({ count = 3, dir = "vert", altClassName }) => {
  const isMobilScreen = useMediaQuery(`max-width: ${breakpoints.sm}`);

  const cardsCount = isMobilScreen ? 1 : count;

  const eventsLoaderIndicator = Array(cardsCount)
    .fill(0)
    .map((_, i) =>
      dir === "horiz" ? <HorizCardLoader key={i} /> : <VertCardLoader key={i} />
    );

  return (
    <Styles count={cardsCount}>
      <div className={altClassName ?? "cards-loader"}>{eventsLoaderIndicator}</div>
    </Styles>
  );
};

export default CardsLoader;
