import { ExternalLink } from "@atoms";
import { ReportButton } from "components/organisms/sdg-reports/styles";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";
import { ARABIC, ENGLISH } from "types/languages";

const Styles = styled.div`
  width: 100%;
  .localization-details-card {
    width: 100;
    border-bottom: solid 5px var(--primary);
    background-color: white;
    position: relative;

    &__close-btn {
      outline: none;
      font-size: 1.6rem;
      color: var(--black);
      border: none;
      appearance: none;
      position: absolute;
      background: transparent;
      top: 5px;
      &:focus {
        outline: none;
      }
      &:lang(${ENGLISH}) {
        right: 5px;
      }
      &:lang(${ARABIC}) {
        left: 5px;
      }
    }

    .row {
      margin: 0;
    }

    &__desc {
      font-style: italic;
    }

    ${ExternalLink} {
      padding: 0;
      font-style: italic;
      font-weight: 600;
    }

    ${ReportButton}, .report-btn {
      padding: 0.6rem 1.2rem;
      border: 1px solid black;
      background-color: transparent;
      font-size: 0.8rem;
      flex: 1 0 40%;
      color: var(--dark);
      border-color: var(--dark);
      transition: all 0.2s ease-in;
      width: unset;

      a {
        height: 100%;
        width: 100%;
        padding: 0.5rem 1rem;
        color: var(--dark);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &:hover {
        background-color: var(--dark);
        color: white;

        a {
          color: white;
        }
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        background-color: transparent;
        color: var(--dark);

        a {
          color: var(--dark);
        }
      }

      ${respondTo.lg`
        padding: 0.75rem 2.5rem;
        font-size: 1.2rem;
        flex: 0 0 30%;
      `}
    }

    ${ReportButton} {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .btn-container {
      flex: 1 0 40%;
      ${respondTo.lg`
        flex: 0 0 30%;
      `}
    }
  }
`;
export default Styles;
