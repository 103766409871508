import React, { useRef, useState } from "react";

import classNames from "classnames";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Styles from "./styles";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { LeftArrowIcon, RightArrowIcon } from "@icons";

const swiperProps = {
  modules: [Autoplay],
  navigation: false,
  autoplay: {
    delay: 5000
  }
};

const EventsCarousel = ({ items, imageProp, captionProp, quote, dir = "ltr" }) => {
  const swiperRef = useRef();
  const [activeSlide, setActiveSlide] = useState(
    swiperRef?.current?.swiper?.activeIndex || 0
  );

  const onSlideChange = (swiper) => setActiveSlide(swiper?.activeIndex);

  const handleGoToNextSlide = () => swiperRef?.current?.swiper?.slideTo(activeSlide + 1);
  const handleGoToPrevSlide = () => swiperRef?.current?.swiper?.slideTo(activeSlide - 1);

  return (
    <Styles>
      {items?.length > 0 && (
        <div className="event-carousel">
          <div className="carousel">
            <Swiper
              {...swiperProps}
              ref={swiperRef}
              onSlideChange={onSlideChange}
              dir={dir}>
              {items?.map?.((item, i) => (
                <SwiperSlide key={i}>
                  <img
                    className="event-image"
                    src={item?.[imageProp]}
                    alt={item?.[captionProp] || "Event"}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="controller-summary">
            <div className="controller">
              <div className="arrows">
                <LeftArrowIcon
                  width="1.25rem"
                  className={classNames("me-5 cursor-pointer localized", {
                    disabled: activeSlide === 0
                  })}
                  onClick={handleGoToPrevSlide}
                />

                <RightArrowIcon
                  width="1.25rem"
                  className={classNames("ms-3 cursor-pointer localized", {
                    disabled: activeSlide === items?.length - 1
                  })}
                  onClick={handleGoToNextSlide}
                />
              </div>
              <div>
                <span className="me-2">{activeSlide + 1}</span>
                <span className="me-2">/</span>
                <span>{items?.length}</span>
              </div>
            </div>

            <div className="summary d-flex align-items-center text-16px font-bold">
              {quote}
            </div>
          </div>
        </div>
      )}
    </Styles>
  );
};

export default EventsCarousel;
