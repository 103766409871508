import { theme } from "@constants";

const styles = (isArabic) => ({
  page: {
    fontFamily: isArabic ? "ArbFONTS" : "Rubik",
    position: "relative",
    direction: isArabic ? "RTL" : "LTR",
    padding: isArabic ? "unset" : "20pt 0" // don't use margin on page component, for arabic we pass the margin y to html2pdf function
  },
  header: {
    width: "100%",
    padding: "0.2cm 1cm",
    backgroundColor: theme.colors.light,
    marginBottom: "10pt",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  logo: { width: "3cm" },
  content: {
    width: "100%",
    padding: "0 1cm",
    flex: 1
  },
  title: { fontSize: "16pt" },
  topics: { flexDirection: "row", flexWrap: "wrap", display: "flex" },
  topic: {
    backgroundColor: theme.colors.primary,
    padding: "2pt 5pt",
    marginRight: "10pt",
    marginBottom: "10pt",
    fontSize: "6pt",
    color: "white"
  },
  flexSection: { flexDirection: "row", display: "flex" },
  icon: { width: "0.6cm", marginRight: "5pt", color: theme.colors.grey },
  meta: { fontSize: "8pt", color: theme.colors.grey, alignItems: "center" },
  mainImage: { width: "100%", margin: "10pt 0", maxHeight: "6cm", objectFit: "cover" },
  description: {
    // let the fontSize at the very begining, without units
    fontSize: isArabic ? 12 : 10,
    paddingBottom: "10pt",
    borderBottom: "1pt solid #ccc",
    marginTop: "5pt"
  },
  authorCard: {
    container: {
      padding: "5pt 0",
      overflow: "auto",
      display: "flex",
      flexDirection: "row"
    },
    image: {
      width: "25%",
      border: "1pt solid #ccc",
      float: isArabic ? "right" : "left",
      marginBottom: "5pt",
      marginRight: isArabic ? 0 : "8pt",
      marginLeft: isArabic ? "8pt" : 0
    },
    name: {
      color: theme.colors.primary,
      marginBottom: "5pt"
    },
    bio: {
      color: theme.colors.black,
      fontSize: "8pt",
      lineHeight: 1.2,
      textAlign: "justify"
    }
  }
});

export default styles;
