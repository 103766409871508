import React from "react";
import { NavDropdown } from "react-bootstrap";
import { NavMenuLinkContainer } from "@molecules";
import Styles from "./styles";

const NavSubMenu = ({ subMenus, name, color, hoverColor, bgColor }) => {
  return (
    <Styles color={color} hoverColor={hoverColor} bgColor={bgColor}>
      <NavDropdown
        className="nav-sub-menu px-2 px-lg-3 py-3 py-md-2"
        role="menu"
        title={name}>
        {subMenus?.map?.((link, i) => (
          <NavMenuLinkContainer key={i} {...link}>
            {link?.name}
          </NavMenuLinkContainer>
        ))}
      </NavDropdown>
    </Styles>
  );
};

export default NavSubMenu;
