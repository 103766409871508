import { ImpactCounters } from "@molecules";
import React from "react";
import { FormattedMessage } from "react-intl";
import Styles from "./styles";

const PortalCounters = ({ counters, loading }) => {
  return (
    <Styles>
      <section className="portal-counters w-100">
        <div className="portal-counters__inner mx-auto">
          <div className="w-100 mb-5">
            <h1 className="text-dark text-center font-md text-50px">
              <FormattedMessage defaultMessage="Portal" />
            </h1>
            <p className="text-primary text-center font-md text-25px">
              <FormattedMessage defaultMessage="Counters" />
            </p>
          </div>
          <div className="w-100">
            <ImpactCounters counters={counters} loading={loading} />
          </div>
        </div>
      </section>
    </Styles>
  );
};

export default PortalCounters;
