import { BlogCard, Pagination } from "@molecules";
import { Filters, SendBlogModal } from "@organisms";
import { CardsLoader } from "@particles";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Styles from "./styles";
import { useQuery } from "react-query";
import { blogsApi } from "api";
import { Alert } from "react-bootstrap";
import { AppTemplate } from "@templates";
import { StyledButton } from "@atoms";

const filtersIds = {
  topics: "topics",
  authors: "authors"
};
const filters = [
  {
    id: filtersIds.authors,
    name: <FormattedMessage defaultMessage="Author" />,
    options: []
  },
  {
    id: filtersIds.topics,
    name: <FormattedMessage defaultMessage="Topic" />,
    options: []
  }
];

const Items = ({ items, isError, handleSendYourBlogClick }) => {
  if (items?.length) {
    return (
      <div className="w-100">
        <div className="w-100 my-3 d-flex justify-content-end">
          <StyledButton
            className="rounded-0 px-4 uppercase ms-3"
            onClick={handleSendYourBlogClick}>
            <FormattedMessage defaultMessage="Send us your blog" />
          </StyledButton>
        </div>

        <div className="blogs__grid w-100">
          {items?.map?.((item, i) => (
            <BlogCard key={i} {...item} />
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className={`w-100 py-5 text-center`}>
      {!isError && (
        <Alert>
          <FormattedMessage defaultMessage="There are no blogs " />
        </Alert>
      )}
      {isError && (
        <Alert>
          <FormattedMessage defaultMessage="Something went wrong fetching the blogs" />
        </Alert>
      )}
    </div>
  );
};

const Blogs = () => {
  const [page, setPage] = useState(0);
  const [sendBlogModalOpen, setSendBlogModalOpen] = useState(false);

  const handleSendYourBlogClick = () => {
    setSendBlogModalOpen(true);
  };

  const intl = useIntl();

  const [params, setParams] = useState({
    keyword: "",
    pageSize: 10,
    orderBy: "name",
    [filtersIds.topics]: [],
    [filtersIds.authors]: []
  });

  const { data, isLoading, isError } = useQuery(["blogs", params, page], () =>
    blogsApi.getAll({
      ...params,
      page,
      [filtersIds.topics]: params[filtersIds.topics]?.map((o) => o.id),
      [filtersIds.authors]: params[filtersIds.authors]?.map((o) => o.id)
    })
  );

  const { records: items, pagingInfo } = data?.data ?? {};

  const {
    data: filtersData,
    isLoading: isFiltersLoading,
    isError: isFiltersError
  } = useQuery(["blogs-filters"], () => blogsApi.getFiltersList());

  const filtersList = useMemo(() => {
    if (isFiltersLoading) {
      return [...filters.filter((f) => f.id !== filtersIds.authors)];
    }

    if (isFiltersError) {
      return [];
    }

    return filters
      .filter((f) => f.id !== filtersIds.authors || filtersData.data?.authorsShow)
      .map((filter) => ({
        ...filter,
        options:
          filtersData?.data?.[filter.id]?.map((o) => ({
            ...o,
            filterId: filter.id
          })) || []
      }));
  }, [filtersData, isFiltersLoading, isFiltersError]);

  useEffect(() => {
    setPage(0);
  }, [params]);

  const onFilterSelect = (option) => {
    const found = params[option.filterId].findIndex((o) => o.id === option.id) > -1;

    if (found) {
      return;
    }
    setParams({
      ...params,
      [option.filterId]: [...params[option.filterId], option]
    });
  };

  const onFilterDeselect = (option) => {
    setParams({
      ...params,
      [option.filterId]: params?.[option.filterId]?.filter((o) => o.id !== option.id)
    });
  };

  const onSearchChange = (searchTerm) => {
    setParams({
      ...params,
      keyword: searchTerm
    });
  };

  const activeFilters = useMemo(
    () => [...params[filtersIds.topics], ...params[filtersIds.authors]],
    [params]
  );

  return (
    <AppTemplate title={"Blogs"}>
      <Styles>
        <div className="blogs">
          <div className="blogs__top-section bg-black">
            <div className="container mx-auto py-5">
              <h1 className="text-50px font-md text-white mb-2 mb-xxl-3">
                <FormattedMessage defaultMessage="Blogs" />
              </h1>
              <div className="blogs__filters">
                <Filters
                  filters={filtersList}
                  activeFilters={activeFilters}
                  onFilterSelect={onFilterSelect}
                  onFilterDeselect={onFilterDeselect}
                  onSearchChange={onSearchChange}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Search for blogs"
                  })}
                />
              </div>
            </div>
          </div>
          <div className="blogs__content">
            <div className="container mx-auto pt-5">
              {isLoading && (
                <CardsLoader count={6} dir="horiz" altClassName={"blogs__grid"} />
              )}
              {!isLoading && (
                <Items
                  items={items}
                  isError={isError}
                  handleSendYourBlogClick={handleSendYourBlogClick}
                />
              )}

              <div className="w-100 my-4">
                <Pagination
                  page={page}
                  pageCount={pagingInfo?.totalPages}
                  onPageChange={setPage}
                />
              </div>
            </div>
          </div>
        </div>

        <SendBlogModal
          sendBlogModalOpen={sendBlogModalOpen}
          setSendBlogModalOpen={setSendBlogModalOpen}
        />
      </Styles>
    </AppTemplate>
  );
};

export default Blogs;
