import React from "react";
import Styles from "./styles";
import truncate from "lodash/truncate";
import { HOME_CAROUSEL_TITLE_MAX_CHARS } from "@constants";

const PublicationCarouselButton = (props) => {
  return (
    <Styles {...props}>
      <div className="index text-50px">{props?.index}</div>
      <div className="text px-2">
        {truncate(props?.title, { length: HOME_CAROUSEL_TITLE_MAX_CHARS })}
      </div>
    </Styles>
  );
};

export default PublicationCarouselButton;
