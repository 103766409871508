import React from "react";
import truncate from "lodash/truncate";
import Styles from "./styles";
import defaultBg from "assets/images/hero-bg.jpg";
import { MAX_HERO_CHARS } from "@constants";
import { RightArrowIcon } from "@icons";
import { StyledButton } from "@atoms";
import { GenericLink } from "@molecules";

const HeroItem = ({
  image = defaultBg,
  title,
  summary,
  link,
  buttonText,
  verticalAlignment,
  horizontalAlignment
}) => {
  return (
    <Styles image={image} vAlign={verticalAlignment} hAlign={horizontalAlignment}>
      <div className="hero-item w-100 py-4 py-lg-5 d-flex justify-content-center">
        <div className="hero-item__inner w-100">
          <div className="hero-item__content py-3 py-lg-0">
            {/* <div className="text-primary italic text-14px mb-2">
              <span>{author}</span>
              <span>
                {author && date && <span className="mx-2">|</span>}{" "}
                {date && format(new Date(date), "d MMMM")}
              </span>
            </div> */}
            <h1 className="text-dark font-bold text-28px">{title}</h1>
            <p className="hero-item__summary text-dark mb-3">
              {truncate(summary, { length: MAX_HERO_CHARS })}
            </p>
            {buttonText && link && (
              <div className="d-flex align-items-center">
                <GenericLink link={link} className="cursor-pointer">
                  <span className="text-14px font-bold text-dark me-3">{buttonText}</span>
                  <StyledButton size="lg" circle>
                    <RightArrowIcon />
                  </StyledButton>
                </GenericLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default HeroItem;
