import styled from "styled-components";

const Styles = styled.div`
  .help-button {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 83% 100%, 57% 76%, 0% 75%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 83% 100%, 57% 76%, 0% 75%);
    .btn {
      border-radius: 0;
    }
  }
`;
export default Styles;
