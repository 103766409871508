import { getCurrentLang } from "@utils";

import { ARABIC } from "types/languages";

const isArabic = () => {
  const lang = getCurrentLang();
  return lang === ARABIC;
};

export default isArabic;
