import { theme } from "@constants";
import React from "react";
import Select from "react-select";

const StyledSelect = ({
  value,
  onChange,
  options = [],
  isRtl,
  color = theme.colors.primary,
  width,
  getOptionLabel,
  getOptionValue,
  ...props
}) => {
  return (
    <Select
      {...props}
      defaultValue={props.defaultValue || options?.[0]}
      value={value}
      onChange={onChange}
      options={options}
      isRtl={isRtl}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      default
      styles={{
        container: (base) => ({
          ...base,
          width: width || base.width
        }),
        control: (base, props) => ({
          ...base,
          borderRadius: 0,
          fontSize: "1rem",
          minWidth: "10rem",
          width: width || base.width,
          borderColor: "#BFC7D2",
          boxShadow: props.isFocused ? `0 0 0 1px ${color}` : "none",
          "&:hover": {
            borderColor: color
          }
        }),
        option: (base, props) => ({
          ...base,
          backgroundColor: props.isSelected ? color : base.backgroundColor
        }),
        valueContainer: (base) => ({
          ...base,
          padding: "2px 20px",
          fontStyle: "italic"
        }),
        menu: (base) => ({
          ...base,
          zIndex: 2
        })
      }}
    />
  );
};

export default StyledSelect;
