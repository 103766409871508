import styled from "styled-components";
import { breakpoints } from "@constants";

const Styles = styled.div`
  width: 100%;
  .sdg-options-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.sm}) {
      max-width: 270px;
      margin-left: auto;
      margin-right: auto;
    }

    &__search {
      input {
        flex: 1;
        border: none;
        outline-color: var(--primary);
        border-bottom: solid 1px var(--light);
        &:focus {
          outline-style: solid;
        }
      }
    }
    &__options {
      flex: 1 0 200px;
      overflow-y: auto;
      > * + * {
        margin-top: 10px;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
    }

    &__empty-msg {
      color: #ccc;
    }
  }
`;
export default Styles;
