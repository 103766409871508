import React from "react";
import { FormattedMessage } from "react-intl";
import Styles from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { KeyTopicCard } from "@molecules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { CardsLoader } from "@particles";

const swiperProps = {
  modules: [Autoplay, Navigation, Pagination],
  navigation: true,
  pagination: {
    type: "bullets"
  },
  autoplay: {
    delay: 5000
  },
  breakpoints: {
    992: {
      slidesPerView: 2,
      spaceBetween: 40
    }
  }
};

const KeyTopicsCarousel = ({ items, dir = "ltr", loading }) => {
  return (
    <Styles>
      {items?.length > 0 && (
        <div className="key-topics-carousel w-100">
          <h1 className="font-md text-50px text-black text-center mb-4">
            <FormattedMessage defaultMessage="Key Topics" />
          </h1>
          <div className="w-100">
            {!loading && (
              <Swiper {...swiperProps} dir={dir}>
                {items?.map?.((item, index) => (
                  <SwiperSlide key={index}>
                    <KeyTopicCard {...item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}

            {loading && <CardsLoader dir="horiz" />}
          </div>
        </div>
      )}
    </Styles>
  );
};

export default KeyTopicsCarousel;
