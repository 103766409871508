import { theme } from "@constants";
import React from "react";
import ContentLoader from "react-content-loader";
import { useIntl } from "react-intl";
import { ARABIC } from "types/languages";
import Styles from "./styles";

const HeroLoader = () => {
  const lang = useIntl().locale;
  const isRtl = lang === ARABIC;
  return (
    <Styles>
      <div className="hero-loader w-100 h-100">
        <div className="hero-loader__content w-100 h-100 d-flex align-items-center">
          <ContentLoader
            speed={2}
            width={400}
            foregroundColor={theme.colors.greyLight}
            viewBox="0 0 200 124"
            rtl={isRtl}>
            <rect x="0" y="8" rx="3" ry="3" width="88" height="6" />
            <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />
            <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
            <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
            <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          </ContentLoader>
        </div>
        <ContentLoader
          speed={2}
          width={"100%"}
          height={"100%"}
          backgroundColor="#a9a7a7"
          foregroundColor="#969292"
          className="hero-loader__bg">
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
        </ContentLoader>
      </div>
    </Styles>
  );
};

export default HeroLoader;
