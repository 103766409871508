import { ChartArrowDownIcon, ChartArrowUpIcon } from "@icons";
import React from "react";
import Styles from "./styles";

const positiveColor = "#00A54F";
const negativeColor = "#ED1C24";

const IndicatorIcon = ({ difference, reversed }) => {
  const Icon = difference >= 0 ? ChartArrowUpIcon : ChartArrowDownIcon;
  let iconColor;

  if (reversed) {
    iconColor = difference >= 0 ? negativeColor : positiveColor;
  } else {
    iconColor = difference >= 0 ? positiveColor : negativeColor;
  }

  return (
    <div
      className="text-center"
      style={{ lineHeight: 1, fontSize: "4rem", color: iconColor }}>
      <Icon />
      <div className="text-14px mt-1 font-md">
        {!isNaN(difference) ? Math.abs(difference) : 0}
      </div>
    </div>
  );
};

const HomeIndicatorCard = ({
  indicatorName,
  unitName,
  year1,
  year2,
  value1,
  value2,
  difference,
  reversed
}) => {
  return (
    <Styles>
      <div className="home-indicator-card px-3 py-4">
        <div className="w-s100 d-flex justify-content-between align-items-end">
          <div>
            <div className="home-indicator-card__year text-12px">{year1}</div>
            <div className="text-primary text-22px font-semibold">{value1}</div>
          </div>

          <IndicatorIcon difference={difference} reversed={reversed} />

          <div>
            <div className="home-indicator-card__year text-12px">{year2}</div>
            <div className="text-primary text-22px font-semibold">{value2}</div>
          </div>
        </div>

        <p className="home-indicator-card__name mt-3 text-dark font-md text-20px">
          {indicatorName}
        </p>
        <p className="text-dark text-14px font-light">{unitName}</p>
      </div>
    </Styles>
  );
};

export default HomeIndicatorCard;
