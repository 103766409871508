import { useCallback, useEffect, useState } from "react";
import throttle from "lodash/throttle";

export default function useDetectScroll(threshold = 0.8, throttleTime = 200) {
  const [isOutThreshold, setIsOutThreshold] = useState(false);

  const rootElement = document.documentElement;
  const handleScroll = throttle(() => {
    const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
    if (rootElement.scrollTop / scrollTotal > threshold) {
      setIsOutThreshold(true);
    } else {
      setIsOutThreshold(false);
    }
  }, throttleTime);

  const memoizedHandleScroll = useCallback(() => handleScroll(), [handleScroll]);

  useEffect(() => {
    document.addEventListener("scroll", memoizedHandleScroll);
    return () => {
      document.removeEventListener("scroll", memoizedHandleScroll);
    };
  }, [memoizedHandleScroll]);

  return isOutThreshold;
}
