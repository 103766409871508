import { AppTemplate } from "@templates";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Styles from "./styles";
import { EventCard, Pagination } from "@molecules";
import { EventCalendarButton } from "@atoms";
import { useQuery } from "react-query";
import { eventsApi } from "api";
import { Alert } from "react-bootstrap";
import { CardsLoader } from "@particles";

const eventsHistoryTypes = {
  upcoming: 1,
  previous: 2
};

const Events = () => {
  const [page, setPage] = useState(0);

  const [eventsHistory, setEventsHistory] = useState(eventsHistoryTypes.upcoming);

  const { data, isLoading, isError } = useQuery(["events", page, eventsHistory], () =>
    eventsApi.getAll({
      params: {
        page: page,
        upcoming: eventsHistory === eventsHistoryTypes.upcoming ? true : false
      }
    })
  );

  const { records: items, pagingInfo } = data ?? {};

  const Items = () => {
    if (items?.length) {
      return (
        <div className="events__grid w-100">
          {items.map((item, i) => (
            <EventCard key={i} {...item} />
          ))}
        </div>
      );
    }
    return (
      <div className={`w-100 py-5 text-center`}>
        {!isError && (
          <Alert variant="info">
            <FormattedMessage defaultMessage="There are no events" />
          </Alert>
        )}
        {isError && (
          <Alert>
            <FormattedMessage defaultMessage="Something went wrong fetching the events" />
          </Alert>
        )}
      </div>
    );
  };
  return (
    <AppTemplate title={"Latest Events"}>
      <Styles>
        <div className="events">
          <div className="events__top-section bg-black">
            <div className="container mx-auto py-3 py-xxl-5">
              <h1 className="text-50px font-md text-white mb-2 mb-xxl-3">
                <FormattedMessage defaultMessage="Latest Events" />
              </h1>
              {/* <div className="events__filters">
                <Filters
                  filters={filters}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Search for Events"
                  })}
                />
              </div> */}
            </div>
          </div>

          <div className="events__calendar-btns mx-auto">
            <div className="events__calendar-btns__wrapper w-100 d-flex">
              <EventCalendarButton
                active={eventsHistory === eventsHistoryTypes.upcoming}
                onClick={() => setEventsHistory(eventsHistoryTypes.upcoming)}>
                <FormattedMessage defaultMessage="Upcoming Events" />
              </EventCalendarButton>

              <EventCalendarButton
                active={eventsHistory === eventsHistoryTypes.previous}
                onClick={() => setEventsHistory(eventsHistoryTypes.previous)}>
                <FormattedMessage defaultMessage="Previous Events" />
              </EventCalendarButton>
            </div>
          </div>

          <div className="events__content my-5">
            <div className="container mx-auto pt-3 pt-xxl-5">
              {isLoading && <CardsLoader count={6} dir="vert" />}
              {!isLoading && <Items />}
              <div className="w-100 my-4">
                <Pagination
                  page={page}
                  pageCount={pagingInfo?.totalPages}
                  onPageChange={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default Events;
