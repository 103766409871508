import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import { SdgCircleButton } from "@atoms";
import Styles from "./styles";
import { ScaleTransition, SpinnerLoader } from "@particles";
import { useQuery } from "react-query";
import { sdgsApi } from "api";
import { isNumber } from "@utils";

import ReportButtonComponent from "./report-button-component";
import DownloadSnapshotBtn from "./download-snapshort-btn";

const SdgReports = ({ sdgs, isLoading }) => {
  const [activeButton, setActiveButton] = useState();
  const activeButtonDetails = sdgs?.data?.[activeButton];
  const sdgId = activeButtonDetails?.id;

  const { isLoading: isSdgReportLoading, data: sdgReport } = useQuery(
    ["sdg-report", sdgId],
    () => sdgsApi.getSdgReport(sdgId),
    {
      enabled: !!sdgId
    }
  );

  const { isLoading: isAvailReportLoading, data: availReport } = useQuery(
    ["availability-report", sdgId],
    () => sdgsApi.getAvailabilityReport(sdgId),
    {
      enabled: !!sdgId
    }
  );

  const handleActivateSdg = (i) => {
    // retrigger the transition effect
    setActiveButton(() => {
      setTimeout(() => {
        setActiveButton(i);
      }, 100);
      return null;
    });
  };

  const intl = useIntl();

  return (
    <Styles>
      <div className="sdg-reports w-100 py-5 d-flex justify-content-center">
        {isLoading && <SpinnerLoader />}

        <ul className="sdg-circle">
          <div className="inner-circle">
            <ScaleTransition inProp={isNumber(activeButton)}>
              <div className="sdg-reports__options w-100 h-100 d-flex justify-content-center align-items-center">
                <OutsideClickHandler onOutsideClick={() => setActiveButton(null)}>
                  <div className="sdg-reports__btns-panel mx-auto bg-white p-3">
                    <p className="text-center text-16px text-secondary">
                      {activeButtonDetails?.code}
                      {activeButtonDetails?.code && "."} {activeButtonDetails?.name}
                    </p>

                    <DownloadSnapshotBtn
                      activeSdg={activeButtonDetails}
                      exportName={intl.formatMessage(
                        { defaultMessage: "{sdg} - statstical snapshots" },
                        { sdg: activeButtonDetails?.name }
                      )}
                    />

                    <ReportButtonComponent
                      disabled={!sdgReport?.data?.path}
                      href={sdgReport?.data?.path}
                      download={sdgReport?.data?.caption}
                      isLoading={isSdgReportLoading}>
                      <FormattedMessage defaultMessage="Generate Report" />
                    </ReportButtonComponent>

                    <ReportButtonComponent
                      disabled={!availReport?.data?.path}
                      href={availReport?.data?.path}
                      download={availReport?.data?.caption}
                      isLoading={isAvailReportLoading}>
                      <FormattedMessage defaultMessage="Generate Availability Report" />
                    </ReportButtonComponent>
                  </div>
                </OutsideClickHandler>
              </div>
            </ScaleTransition>
          </div>

          <div className="outer-circle" />
          {sdgs?.data?.map?.((item, i) => (
            <li className={classNames(["item", { active: i === activeButton }])} key={i}>
              <SdgCircleButton
                {...item}
                tooltip={`${item.code}${item.code && item.name && "."} ${item.name}`}
                onClick={() => handleActivateSdg(i)}
              />
            </li>
          ))}
        </ul>
      </div>
    </Styles>
  );
};

export default SdgReports;
