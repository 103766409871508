import instance from "./instance";

export const blogsApi = {
  getAll: (filters, config = {}) =>
    instance.post(`Web/blogs/All`, filters, {
      ...config
    }),

  getFiltersList: () => instance.get(`Web/blogs/FilterAllLists`),

  getDetails: (id) =>
    id &&
    instance.get(`Web/blogs/Details`, {
      params: {
        RecordId: id
      }
    })
};

export default blogsApi;
