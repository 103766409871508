import axios from "axios";

import { baseUrl, apiKey, prefix } from "./config";

import { toastError } from "@particles";

import { toCamelCase, toPascalCase } from "helpers/caseConverter";
import { getCurrentLang } from "@utils";

const instance = axios.create({
  baseURL: baseUrl + prefix
});

instance.interceptors.request.use((request) => {
  if (!request.params) {
    request.params = {};
  }

  request.params = toPascalCase(request.params);

  request.data = toPascalCase(request?.data);

  request.params.lang = getCurrentLang();

  return request;
});

instance.interceptors.response.use(
  (response) => {
    const data = toCamelCase(response.data);

    return data;
  },
  (error) => {
    if (error?.response?.data?.errorMessage) {
      toastError(error.response.data.errorMessage);
    }

    if (error?.response?.data?.Message) {
      toastError(error.response.data.Message);
    }

    return Promise.reject(error);
  }
);

instance.defaults.headers["Content-Type"] = "application/json";
instance.defaults.headers.common.Accept = "*/*";
instance.defaults.headers.APIKey = apiKey;

export default instance;
