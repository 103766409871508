import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

function useLocalizedNavigate() {
  const navigate = useNavigate();
  const { locale } = useIntl();

  const localizedNavigate = useCallback(
    (to, options) => {
      if (!to) return;

      let link = to;
      if (to?.startsWith?.("/")) {
        link = `/${locale + to}`;
      }

      navigate(link, options);
    },
    [locale, navigate]
  );

  return localizedNavigate;
}

export default useLocalizedNavigate;
