import isArabic from "helpers/isArabic";

const stylesFactory = function (themeColor, horiz, stretch, justifyBetween, textColor) {
  const isAr = isArabic();

  const marginEnd = isAr ? "marginLeft" : "marginRight";
  return {
    body: {
      [marginEnd]: "8pt",
      display: "flex",
      flexDirection: horiz ? "row" : "column",
      alignItems: horiz ? "center" : "start",
      marginBottom: "3pt",
      justifyContent: justifyBetween ? "space-between" : "flex-start"
    },
    icon: {
      width: "1cm",
      [marginEnd]: horiz ? 12 : 0
    },
    value: {
      color: textColor ?? themeColor,
      fontSize: "15pt",
      fontWeight: "600"
    },
    unit: {
      color: textColor ?? themeColor,
      fontSize: "8pt",
      fontWeight: "400"
    },
    name: {
      color: textColor ?? "#231F20",
      fontSize: "8pt",
      fontWeight: "400"
    },
    source: {
      marginTop: 5,
      fontWeight: "400",
      color: "#919191",
      fontStyle: "italic",
      fontSize: "7pt"
    }
  };
};

export default stylesFactory;
