import { StyledButton } from "@atoms";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ARABIC, ENGLISH } from "types/languages";
import generateBlogReport from "./generateReport";
import { BlogPDFReport } from "@particles";

const DownloadPdfBtn = ({ blog }) => {
  const [reportLoading, setReportLoading] = useState(false);
  const lang = useIntl().locale;

  return (
    <div>
      {lang === ARABIC && (
        <StyledButton
          variant="link"
          className="px-0"
          disabled={reportLoading}
          onClick={() => {
            setReportLoading(true);
            generateBlogReport(blog, () => setReportLoading(false));
          }}>
          {reportLoading ? (
            <FormattedMessage defaultMessage="Loading document..." />
          ) : (
            <FormattedMessage defaultMessage="Download as PDF" />
          )}
        </StyledButton>
      )}

      {lang === ENGLISH && (
        <PDFDownloadLink
          document={<BlogPDFReport {...blog} />}
          fileName={`${blog?.title}.pdf`}>
          {({ blob, url, loading, error }) => (
            <StyledButton variant="link" className="px-0" disabled={loading || error}>
              {loading ? (
                <FormattedMessage defaultMessage="Loading document..." />
              ) : (
                <FormattedMessage defaultMessage="Download as PDF" />
              )}
            </StyledButton>
          )}
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default DownloadPdfBtn;
