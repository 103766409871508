import isArabic from "helpers/isArabic";
import merge from "lodash/merge";

const stylesFactory = function (themeColor, customStyles = {}) {
  const isAr = isArabic();
  const marginStart = isAr ? "marginRight" : "marginLeft";

  const defaultStyles = {
    page: {
      fontFamily: isAr ? "ArbFONTS" : "Rubik",
      width: "595pt",
      // minHeight: "841pt",
      height: "841pt",
      position: "relative",
      direction: isAr ? "RTL" : "LTR",
      display: "flex",
      flexDirection: "column"
    },
    header: {
      padding: "0.4cm 1.4cm",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: themeColor,
      color: "white",
      fontSize: "22pt",
      fontWeight: 700,
      maxHeight: "3.2cm"
    },
    mainTitle: {
      fontSize: "18pt",
      textAlign: "center",
      fontWeight: "semibold",
      [marginStart]: "12pt"
    },
    subTitle: {
      fontSize: "12pt",
      textAlign: "center",
      fontWeight: 500,
      marginBottom: 0,
      [marginStart]: "12pt"
    },
    container: {
      padding: "0 1.4cm",
      paddingTop: "0.5cm",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between"
    },
    hr: {
      width: "100%",
      height: 1,
      backgroundColor: "#919191",
      margin: "0.3cm 0"
    },
    footer: {
      maxHeight: "1.5cm",
      padding: "5pt 1.4cm",
      backgroundColor: "#231F20",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
      // position: "absolute",
      // bottom: 0
    }
  };

  return merge(defaultStyles, customStyles);
};

export default stylesFactory;
