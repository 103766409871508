import { LocalizedNavLink } from "@particles";
import React from "react";
import Styles from "./styles";

const NavMenuLink = ({ to, children, color, activeColor, hoverColor, wrap }) => {
  return (
    <Styles color={color} activeColor={activeColor} hoverColor={hoverColor} wrap={wrap}>
      <LocalizedNavLink
        to={to}
        className="nav-menu-link font-md text-14px px-2 px-xl-3 py-3 py-md-2">
        {children}
      </LocalizedNavLink>
    </Styles>
  );
};

export default NavMenuLink;
