import { getCurrentLang } from "@utils";
import React from "react";
import { ARABIC } from "types/languages";
import Styles from "./styles";

const BibliographicDetails = ({ title, data }) => {
  const delimeter = getCurrentLang() === ARABIC ? "، " : ", ";

  const shownData = Array.isArray(data) ? data?.join(delimeter) : data;
  return (
    <Styles>
      <div className="biblio-details my-3">
        <div className="font-light text-dark text-16px mb-1">{title}</div>
        <p className="font-md text-dark text-16px">{shownData}</p>
      </div>
    </Styles>
  );
};

export default BibliographicDetails;
