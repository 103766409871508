import React from "react";
import { StyledSvg } from "@atoms";

const ArrowDropDownIcon = (props) => {
  return (
    <StyledSvg {...props} stroke-width="0" viewBox="0 0 512 512">
      <path d="M128 192l128 128 128-128z"></path>
    </StyledSvg>
  );
};

export default ArrowDropDownIcon;
