import React, { createContext } from "react";

export const ThemeContext = createContext();

export default function ThemeProvider({ children, themeColor }) {
  return (
    <ThemeContext.Provider
      value={{
        themeColor
      }}>
      {children}
    </ThemeContext.Provider>
  );
}
