import styled from "styled-components";

const Styles = styled.div`
  height: 100%;
  .social-links {
    height: 100%;
    border-width: 0px 1px;
    border-style: ${(props) => (props.border ? "solid" : "none")};
    border-color: var(--primaryDark);
  }
`;
export default Styles;
