import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#1C95D3";

const StatisticalSnapshot14 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.main}>
            <View style={{ ...styles.section, marginBottom: "1cm", width: "100%" }}>
              <Image
                src={sdgInfo?.snapshotImageURL}
                alt={sdgInfo?.name}
                style={{ width: "100%" }}
              />
            </View>

            <View style={{ ...styles.section, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[2] && <Indicator {...indicators[2]} iconWidth="2.34cm" />}
                {indicators[3] && <Indicator {...indicators[3]} iconWidth="2.34cm" />}
                {indicators[4] && <Indicator {...indicators[4]} iconWidth="2.34cm" />}
              </View>
            </View>
          </View>

          <View style={styles.side}>
            {indicators[0] && <Indicator {...indicators[0]} stretch={false} />}
            <View style={styles.hr} />
            {indicators[1] && <Indicator {...indicators[1]} stretch={false} />}
            <View style={styles.hr} />
            {indicators[5] && <Indicator {...indicators[5]} stretch={false} />}
          </View>

          <View
            style={{
              ...styles.section,
              ...styles.sectionWithBg,
              marginTop: "0.5cm",
              width: "100%"
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[6] && (
                <View style={{ ...styles.indicatorsContainer, width: "45%" }}>
                  <Indicator {...indicators[6]} />
                </View>
              )}
              {indicators[7] && (
                <View style={{ ...styles.indicatorsContainer, width: "45%" }}>
                  <Indicator {...indicators[7]} />
                </View>
              )}
            </View>
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot14;
