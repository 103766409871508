import { ChartTypeButton } from "@atoms";
import { breakpoints } from "@constants";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";
import { ARABIC, ENGLISH } from "types/languages";

const Styles = styled.div`
  .sdg-charts {
    &__options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;

      ${respondTo.sm`
             grid-template-columns: repeat(2, 1fr);
        `}

      ${respondTo.lg`
            gap: 4rem;
             grid-template-columns: repeat(3, 1fr);
        `}
    }

    &__chart-type {
      border-bottom: solid 1px var(--grey);

      ${respondTo.lg`
           border-bottom : none;

           &:lang(${ENGLISH}) {
                border-right: solid 1px var(--grey);
            }
            &:lang(${ARABIC}) {
                border-left: solid 1px var(--grey);
            }
        `}
    }

    &__chart-type-options {
      @media (max-width: ${breakpoints.lg}) {
        ${ChartTypeButton} {
          flex: 1;
        }

        > * + * {
          &:lang(${ENGLISH}) {
            border-left: solid 1px var(--grey);
          }
          &:lang(${ARABIC}) {
            border-right: solid 1px var(--grey);
          }
        }
      }

      ${respondTo.lg`
            > * + * {
                border-top : solid 1px var(--grey);
                border-left: none;
                border-right: none;
            }
        `}
    }

    .sdg-charts__empty-chart {
      height: 400px;
    }
  }
`;

export default Styles;
