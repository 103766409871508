import styled from "styled-components";

const Styles = styled.div`
  flex: 1;
  .search-input {
    input {
      background-color: transparent;
      padding: 0 0.3rem;
      flex: 1;
      color: ${({ color }) => color || `var(--light)`};
      border: none;
      border-bottom: solid 1px ${({ color }) => color || `var(--light)`};
      &:focus-visible {
        outline: none;
      }
    }
    .btn {
      padding: 0 0.3rem;
      line-height: 100%;
      border-radius: 0;
      font-size: 1.2rem;
      color: ${({ color }) => color || `var(--light)`};
    }
  }
`;
export default Styles;
