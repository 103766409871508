import React, { useLayoutEffect } from "react";

import Styles, { egyptHeatmapContainerId } from "./styles";
import cloneDeep from "lodash/cloneDeep";
import { useIntl } from "react-intl";
import { ARABIC } from "types/languages";
import egyptGovMap from "assets/map/egypt-gov-map.json";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

const defaultHeatColors = {
  min: "#ff0000",
  max: "#00ff00"
};

const EgyptGeoHeatmap = ({
  indicators,
  data = {},
  getExportingItems,
  pdfmakeDocument,
  heatColors = defaultHeatColors,
  yAxesRanges,
  exportMenuContainerId,
  isReversed
}) => {
  const intl = useIntl();
  const isRtl = intl.locale === ARABIC;

  useLayoutEffect(() => {
    // Themes begin
    am4core.useTheme(am4themesAnimated);
    var chart = am4core.create(egyptHeatmapContainerId, am4maps.MapChart);

    // Set map definition
    chart.geodata = egyptGovMap;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // disable tooltip for excluded governorates
    polygonSeries.tooltip.label.adapter.add("text", function (text, target) {
      if (target.dataItem && isNaN(target.dataItem.value)) {
        return "";
      } else {
        return text;
      }
    });

    // Configure series
    var polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}, {value}";
    polygonTemplate.fill = am4core.color("#999");

    const heatRange = {
      min: yAxesRanges?.min,
      max: yAxesRanges?.max
    };

    const actualHeatColors = !isReversed
      ? heatColors
      : {
          min: heatColors.max,
          max: heatColors.min
        };

    // Add heat rule
    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: am4core.color(actualHeatColors.min),
      max: am4core.color(actualHeatColors.max),
      minValue: heatRange.min,
      maxValue: heatRange.max
    });

    // heat legend
    var heatLegend = chart.chartContainer.createChild(am4charts.HeatLegend);
    heatLegend.minValue = heatRange.min;
    heatLegend.maxValue = heatRange.max;
    heatLegend.height = am4core.percent(80);
    heatLegend.series = polygonSeries;
    heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
    heatLegend.valueAxis.renderer.minGridDistance = 30;
    heatLegend.orientation = "vertical";
    heatLegend.valign = "middle";

    // heat legend behavior
    polygonTemplate.events.on("over", (event) => {
      handleHover(event.target);
    });

    polygonTemplate.events.on("hit", (event) => {
      handleHover(event.target);
    });

    function handleHover(target) {
      if (!isNaN(target.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(target.dataItem.value);
      } else {
        heatLegend.valueAxis.hideTooltip();
      }
    }

    polygonTemplate.events.on("out", (event) => {
      heatLegend.valueAxis.hideTooltip();
    });

    // clone the data cuz it's gonna be mutated by the chart
    const mapData = cloneDeep(data.govs);
    polygonSeries.data = mapData;

    // create region series
    function createRegionSeries(name, value, govs) {
      var series = chart.series.push(new am4maps.MapPolygonSeries());
      series.name = name;
      series.useGeodata = true;
      series.include = govs?.map((gov) => gov.id);

      var seriesTemplate = series.mapPolygons.template;
      seriesTemplate.tooltipText = `${name}, ${value}`;
      seriesTemplate.fill = am4core.color("#999");

      // heat legend behavior
      seriesTemplate.events.on("over", (event) => {
        handleHover(event.target);
      });

      seriesTemplate.events.on("hit", (event) => {
        handleHover(event.target);
      });

      seriesTemplate.events.on("out", (event) => {
        heatLegend.valueAxis.hideTooltip();
      });

      // Add heat rule
      series.heatRules.push({
        property: "fill",
        target: series.mapPolygons.template,
        min: am4core.color(heatColors.min),
        max: am4core.color(heatColors.max),
        minValue: heatRange.min,
        maxValue: heatRange.max
      });

      series.data = cloneDeep(govs);

      return series;
    }

    const regions = data?.regions || [];
    regions.forEach(({ name, value, govs }) => createRegionSeries(name, value, govs));

    // add zoom control
    chart.zoomControl = new am4maps.ZoomControl();
    chart.zoomControl.slider.height = 100;

    chart.rtl = isRtl;

    // reset button
    let reset = chart.chartContainer.createChild(am4core.Button);
    const resetTime = 100; // milliseconds
    reset.label.text = intl.formatMessage({
      defaultMessage: "Reset Position"
    });
    reset.align = "right";
    reset.events.on("hit", function (ev) {
      chart.goHome(resetTime);
    });

    // exporting
    chart.exporting.menu = new am4core.ExportMenu();
    // define the exportable data
    chart.exporting.adapter.add("data", function (data) {
      data.data = [];
      for (let i = 0; i < polygonSeries.data.length; i++) {
        let row = polygonSeries.data[i];
        if (row.value) {
          data.data.push({
            name: row.name,
            id: row.id,
            value: row.value
          });
        }
      }
      return data;
    });

    chart.exporting.filePrefix = `egypt-heatmap`;
    chart.exporting.getFormatOptions("pdf").addURL = false;
    chart.exporting.menu.items = getExportingItems(chart, `egypt-heatmap`);
    chart.exporting.menu.container = document.getElementById(exportMenuContainerId);

    return () => {
      chart.dispose();
    };
  }, [
    indicators,
    data,
    getExportingItems,
    pdfmakeDocument,
    heatColors,
    yAxesRanges,
    isRtl,
    exportMenuContainerId,
    intl,
    isReversed
  ]);

  return (
    <Styles>
      <div className="egypt-geo-heatmap">
        <div id={egyptHeatmapContainerId} />
      </div>
    </Styles>
  );
};

export default EgyptGeoHeatmap;
