import styled from "styled-components";

const Styles = styled.div`
  .top-improvers {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 7px;
    }

    .top-improvers__tree-select {
      margin-bottom: 1rem;
    }

    .top-improvers__table {
      margin-bottom: 0;

      thead {
        tr {
          vertical-align: bottom;

          line-height: 1.25;
        }
      }

      tbody {
        tr {
          vertical-align: baseline;
        }
      }
    }
  }
`;

export default Styles;
