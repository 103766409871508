import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#01699E";

const StatisticalSnapshot16 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}>
        <>
          <View style={styles.main}>
            <View style={{ ...styles.section, marginBottom: "1cm", width: "100%" }}>
              <Image
                src={sdgInfo?.snapshotImageURL}
                alt={sdgInfo?.name}
                style={{ width: "100%" }}
              />
            </View>

            <View style={{ ...styles.section, width: "100%" }}>
              <View style={styles.indicatorsContainer}>
                {indicators[2] && <Indicator {...indicators[2]} iconWidth="2.34cm" />}
                {indicators[3] && <Indicator {...indicators[3]} iconWidth="2.34cm" />}
                {indicators[4] && <Indicator {...indicators[4]} iconWidth="2.34cm" />}
              </View>
            </View>

            <View
              style={{
                ...styles.section,
                width: "100%",
                margin: "1cm 0"
              }}>
              <View
                style={{
                  ...styles.indicatorsContainer,
                  justifyContent: "space-between"
                }}>
                {indicators[6] && (
                  <>
                    <Image
                      src={indicators[6].imgUrl}
                      style={{
                        width: "2.34cm",
                        flexShrink: 0
                      }}
                    />
                    <View style={{ width: "60%" }}>
                      <Indicator {...indicators[6]} horiz imgUrl={null} />
                    </View>
                  </>
                )}
              </View>
            </View>
          </View>

          <View style={styles.side}>
            {indicators[0] && <Indicator {...indicators[0]} stretch={false} />}
            {indicators[1] && <Indicator {...indicators[1]} stretch={false} />}
            {indicators[5] && <Indicator {...indicators[5]} stretch={false} />}
            {indicators[7] && <Indicator {...indicators[7]} stretch={false} />}
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot16;
