import Tippy from "@tippyjs/react";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ReportButton } from "./styles";

const ReportButtonComponent = ({
  isLoading,
  disabled,
  children,
  href,
  unavailbleMsg = <FormattedMessage defaultMessage="Unavailable" />,
  download,
  className,
  onClick
}) => (
  <Tippy disabled={!disabled || isLoading} content={unavailbleMsg}>
    <div className={classNames("w-100 btn-container", { [className]: !!className })}>
      <ReportButton disabled={disabled || isLoading} onClick={onClick}>
        {isLoading ? (
          <FormattedMessage defaultMessage="Loading..." />
        ) : (
          <a href={href} download={download}>
            {children}
          </a>
        )}
      </ReportButton>
    </div>
  </Tippy>
);

export default ReportButtonComponent;
