import React from "react";
import Styles from "./styles";
import Loader from "react-loader-spinner";
import { theme } from "@constants";

const SpinnerLoader = () => {
  return (
    <Styles>
      <div className="spinner-loader">
        <Loader
          type="ThreeDots"
          color={theme.colors.primary}
          height={100}
          width={100}
          visible={true}
        />
      </div>
    </Styles>
  );
};

export default SpinnerLoader;
