import React from "react";
import { format } from "date-fns";
import Styles from "./styles";
import { EventLocationIcon, EventTimeIcon } from "@icons";
import { SocialLink } from "@atoms";
import {
  MAX_EVENT_CARD_CHARS,
  MAX_EVENT_CARD_TITLE_CHARS,
  pageRoutes,
  theme
} from "@constants";
import truncate from "lodash/truncate";

import cardPlaceholder from "assets/images/card-placeholder.png";
import { getDateLocale } from "@utils";
import { LocalizedLink } from "@particles";
import { useLocalizedNavigate } from "@hooks";

const EventCard = ({
  id,
  eventType,
  publishDate,
  title,
  summary,
  shareLink,
  location,
  imagePath
}) => {
  const dateInst = publishDate ? new Date(publishDate) : null;

  let urlHostName;
  if (shareLink) {
    try {
      urlHostName = new URL(shareLink).hostname;
    } catch (error) {
      urlHostName = "";
    }
  }

  const highLightColor = theme.colors.primary;

  const navigate = useLocalizedNavigate();
  const handleCardClick = () => navigate(`${pageRoutes.events}/${id}`);

  return (
    <Styles highlightColor={highLightColor} date={publishDate}>
      <div
        className="event-card d-flex flex-column w-100 h-100 bg-white p-3 p-lg-4"
        onClick={handleCardClick}
        onKeyPress={handleCardClick}
        role="button"
        tabIndex={0}>
        <div className="event-card__img mb-3">
          <img
            className="w-100 h-100"
            src={imagePath || cardPlaceholder}
            alt={title || "Event"}
          />

          {dateInst && (
            <div className="event-card__date-badge w-100 h-100">
              <div className="font-md text-25px text-white">{format(dateInst, "d")}</div>
              <div className="font-md text-11px uppercase text-white">
                {format(dateInst, "MMMM")}
              </div>
            </div>
          )}
        </div>
        <div className="flex-grow-1 d-flex flex-column">
          {eventType && (
            <div className="mb-1">
              <span className="event-card__event-type font-light tex-14px italic text-white py-1 px-2">
                {eventType}
              </span>
            </div>
          )}
          {title && (
            <LocalizedLink to={`${pageRoutes.events}/${id}`}>
              <h2 className="text-dark text-25px mb-1">
                {truncate(title, { length: MAX_EVENT_CARD_TITLE_CHARS })}
              </h2>
            </LocalizedLink>
          )}
          {summary && (
            <p className="text-16px flex-grow-1 font-light text-dark mb-1">
              {truncate(summary, { length: MAX_EVENT_CARD_CHARS })}
            </p>
          )}
          <hr className="my-2 my-xxl-4" />
          <div className="d-flex flex-wrap justify-content-between mb-2">
            {location && (
              <span className="text-dark font-md text-14px">
                <EventLocationIcon className="me-2" />
                <span className="text-12px">{location}</span>
              </span>
            )}

            {shareLink && (
              <SocialLink
                className="italic text-12px font-md"
                href={shareLink}
                color={theme.colors.primary}>
                {urlHostName}
              </SocialLink>
            )}
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            {dateInst && (
              <span className="text-dark font-md uppercase text-14px">
                <EventTimeIcon className="me-2 text-14px" />
                <span className="text-12px">
                  {format(dateInst, "hh:mm aaaa", { locale: getDateLocale() })}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default EventCard;
