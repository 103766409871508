import React from "react";
import { StyledSvg } from "@atoms";

const UserIcon = (props) => {
  return (
    <StyledSvg
      {...props}
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>{" "}
    </StyledSvg>
  );
};

export default UserIcon;
