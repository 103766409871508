import { respondTo } from "helpers/style/respond-to";
import styled, { css } from "styled-components";
import { getHorizAlign, getVertAlign } from "./helpers";

export const heroItemContainerStyle = css`
  padding-left: 1rem;
  padding-right: 1rem;

  ${respondTo.lg`
      padding-left: 4rem;
      padding-right: 4rem;
  `}
`;

const Styles = styled.div`
  height: 100%;
  .hero-item {
    /* box-shadow: 3px -131px 30px -15px rgba(0, 0, 0, 0.5) inset;
    -webkit-box-shadow: 3px -131px 30px -15px rgba(0, 0, 0, 0.5) inset;
    -moz-box-shadow: 3px -131px 30px -15px rgba(0, 0, 0, 0.5) inset; */

    box-shadow: -15px -15px 80px 65px rgba(0, 0, 0, 0.4) inset;
    -webkit-box-shadow: -15px -15px 80px 65px rgba(0, 0, 0, 0.4) inset;
    -moz-box-shadow: -15px -15px 80px 65px rgba(0, 0, 0, 0.4) inset;

    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    height: 100%;

    &__summary {
      font-size: 1rem;
      ${respondTo.lg`
        font-size: 1.2rem;
      `}
    }
    &__inner {
      direction: ltr;
      ${heroItemContainerStyle}
      padding-top: 1rem;
      padding-bottom: 1rem;

      ${respondTo.lg`
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
      `}

      display: flex;
      align-items: ${({ vAlign }) => getVertAlign(vAlign)};
      justify-content: ${({ hAlign }) => getHorizAlign(hAlign)};
    }
    &__content {
      width: 70%;
      ${respondTo.md`
            width: 70%;
        `};
      ${respondTo.xl`
            width: 40%;
        `};
    }
  }
`;
export default Styles;
