import styled from "styled-components";

const Styles = styled.div`
  .tag {
    background-color: var(--primary);
    padding: 0.3rem 0.8rem;
    color: white;
    font-size: 0.8rem;
  }
`;

export default Styles;
