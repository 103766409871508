import styled from "styled-components";

export const egyptHeatmapContainerId = "egypt-heatmap-chart";

const Styles = styled.div`
  .egypt-geo-heatmap {
    #${egyptHeatmapContainerId} {
      height: 400px;
      div {
        overflow: visible !important;
      }
  }
`;

export default Styles;
