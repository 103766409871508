import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

export const Title = styled.h2`
  color: var(--primary);
  margin-bottom: 0;
`;

export const ViewAll = styled.a`
  border: 1px solid;
  color: black;
  display: flex;
  align-items: center;
  padding-right: 2rem;
  padding-left: 2rem;
`;

const Styles = styled.div`
  .key-topic-details {
    .description {
      width: 100%;
      ${respondTo.lg`
      width: 75%;
    `}
    }
    .data-highligts {
      background: #f5f6f7;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    ul {
      list-style: none;
    }

    .blogs {
      &__grid {
        display: grid;
        grid-template-columns: repeat(1);
        gap: 2rem;
      }
    }
  }
`;
export default Styles;
