import styled from "styled-components";
import { respondTo } from "helpers/style/respond-to";

const Styles = styled.div`
  width: 100%;
  height: 100%;
  .hero-loader {
    position: relative;
    &__content {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 5rem;
      ${respondTo.lg`
        padding: 0 20rem;
      `}
    }
    &__bg {
      min-height: 300px;
      ${respondTo.lg`
        min-height: 80vh
      `}
    }
  }
`;
export default Styles;
