import React from "react";

import { Dynamic } from "components/pages";

import store from "../../redux/store";
import { fetchMenus } from "../../redux/actions/menusActions";
import { pageRoutes } from "@constants";

export const checkMenus = () => {
  store.dispatch(fetchMenus());
};

const pushMenuItem = (routes, menu) => {
  const index = routes.findIndex((route) => route.id === menu.id);

  if (index === -1) {
    routes.push({
      id: menu.id,
      title: menu.name,
      component: <Dynamic id={menu.id} />,
      path: `${pageRoutes.dynamic}/${menu.id}`
    });
  }
};

export const addDynamicRoutes = (routes, upperMenus = [], bottomMenus = []) => {
  const newRoutes = [...routes];

  [...upperMenus, ...bottomMenus].forEach((menu) => {
    if (!menu.subMenus && menu.inDynamicPage === 1) {
      pushMenuItem(newRoutes, menu);
    }

    if (menu.subMenus && menu.subMenus.length !== 0) {
      menu.subMenus.forEach((subMenu) => {
        if (subMenu.inDynamicPage === 1) {
          pushMenuItem(newRoutes, subMenu);
        }
      });
    }
  });

  return newRoutes;
};
