import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import BaseChart from "./base-chart";

class SdgAreaChart extends BaseChart {
  initiateChart() {
    this.chart = am4core.create(this.config.containerId, am4charts.XYChart);
    this.chartType = "Area-Chart";
  }

  initiateXAxis() {
    if (this.config.multiFrequency) {
      this.createXCategoryAxis();
    } else {
      this.createXDateAxis();
    }
  }

  initiateSeries() {
    return this.chart.series.push(new am4charts.LineSeries());
  }

  setupSeries(series, config = {}) {
    super.setupSeries(series, config);
    const circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.fill = series.stroke;

    series.fillOpacity = 0.6;
    series.strokeWidth = 2;
    series.fill = config.color;

    const segment = series.segments.template;
    segment.interactionsEnabled = true;

    const hoverState = segment.states.create("hover");
    hoverState.properties.strokeWidth = 3;

    const dimmed = segment.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");
  }

  setupXAxis() {
    super.setupXAxis();
    this.xAxis.startLocation = 0.5;
    this.xAxis.endLocation = 0.5;
  }
}

export default SdgAreaChart;
