import { theme } from "@constants";
import React from "react";
import ContentLoader from "react-content-loader";

const CounterLoader = () => {
  return (
    <div className="w-100 d-flex justify-content-center">
      <ContentLoader
        speed={2}
        width={"70%"}
        foregroundColor={theme.colors.greyLight}
        viewBox="0 0 150 100"
        className="d-flex justify-content-center">
        <rect x="0" y="8" rx="3" ry="3" width="100%" height="6" />
        <rect x="0" y="26" rx="3" ry="3" width="100%" height="6" />
      </ContentLoader>
    </div>
  );
};

export default CounterLoader;
