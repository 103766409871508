import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import StyledButton from "../styled-button";
import { breakpoints } from "@constants";

const SdgButton = styled(({ className, size, active, variant, ...props }) => (
  <StyledButton
    {...props}
    className={classNames(
      "flex-1",
      "d-flex",
      "rounded-0",
      "justify-content-center",
      "align-items-center",
      "font-md",
      "py-2 py-lg-3",
      className
    )}
    size={size || "lg"}
    variant={active ? "primary" : variant}
  />
))`
  @media (max-width: ${breakpoints.sm}) {
    font-size: 0.8rem;
  }
`;

export default SdgButton;
