import { homeApi } from "api";
import { useQuery } from "react-query";

const staleTime = 3600000 * 2;

function useFetchSocialLinks() {
  const {
    data: socialLinksData,
    isLoading: isSocialLinksLoading,
    isError: isSocialLinksError
  } = useQuery("contact-info", homeApi.getContactInfo, {
    staleTime
  });

  return { socialLinksData, isSocialLinksLoading, isSocialLinksError };
}

export default useFetchSocialLinks;
