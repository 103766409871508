import React from "react";
import Styles from "./styles";
import ReactPaginate from "react-paginate";
import { ChevLeftIcon, ChevRightIcon } from "@icons";

const Pagination = ({
  page,
  pageCount = 0,
  onPageChange,
  showOnOnePageCount = false
}) => {
  return (
    <Styles>
      <div className="pagination w-100">
        {(pageCount > 1 || (pageCount === 1 && showOnOnePageCount)) && (
          <ReactPaginate
            forcePage={pageCount ? page : -1}
            pageCount={pageCount}
            onPageChange={(page) => onPageChange(page.selected)}
            renderOnZeroPageCount={() => null}
            containerClassName="pagination__container"
            pageClassName="mx-1 pagination__btn"
            activeLinkClassName="pagination__link--active"
            pageLinkClassName="btn pagination__link"
            nextLinkClassName="btn pagination__next"
            previousLinkClassName="btn pagination__prev"
            nextClassName="pagination__btn"
            previousClassName="pagination__btn"
            nextLabel={<ChevRightIcon />}
            previousLabel={<ChevLeftIcon />}
          />
        )}
      </div>
    </Styles>
  );
};

export default Pagination;
