import { DataVisualizationCard } from "@molecules";
import React from "react";
import Styles from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";

const swiperProps = {
  modules: [Autoplay, Navigation, Pagination],
  navigation: true,
  pagination: {
    clickable: true,
    type: "bullets"
  },
  autoplay: {
    delay: 5000
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 50
    }
  }
};

const DataVisualizationCarousel = ({ dir = "ltr", dataVisItems }) => {
  return (
    <Styles>
      {dataVisItems?.length > 0 && (
        <div className="data-visualization-carousel w-100">
          <Swiper {...swiperProps} dir={dir}>
            {dataVisItems?.map?.((item, index) => (
              <SwiperSlide key={index}>
                <DataVisualizationCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </Styles>
  );
};

export default DataVisualizationCarousel;
