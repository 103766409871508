import styled from "styled-components";
import { respondTo } from "helpers/style/respond-to";

const Styles = styled.div`
  .portal-counters {
    background-color: #f1f1f1;
    padding: 2rem 0;
    ${respondTo.xxl`
        padding: 3rem 0;
    `}
    &__inner {
      width: 90%;
      ${respondTo.md`
            width: 75%
        `};
      ${respondTo.lg`
            width : 65%
        `}
      ${respondTo.xl`
            width : 60%
        `}
    }
  }
`;
export default Styles;
