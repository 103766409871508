import isArabic from "helpers/isArabic";

const stylesFactory = function (themeColor) {
  const isAr = isArabic();
  const paddingEnd = isAr ? "paddingLeft" : "paddingRight";
  const borderStart = isAr ? "borderRight" : "borderLeft";
  return {
    main: {
      width: "70%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      [paddingEnd]: "0.5cm"
    },
    side: {
      width: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      padding: "0 0.5cm",
      [borderStart]: "1pt solid #c0c0c0"
    },
    section: {
      justifyContent: "center",
      marginBottom: "1.5cm",
      width: "48%",
      flexDirection: "column",
      display: "flex"
    },
    indicatorsContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between"
    },
    sectionWithBg: {
      backgroundColor: "#EDEDED",
      padding: "10pt",
      display: "flex",
      alignItems: "center"
    }
  };
};

export default stylesFactory;
