import React from "react";
import Styles from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { RelatedLinkCard } from "@molecules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";

const swiperProps = {
  modules: [Autoplay, Navigation, Pagination],
  navigation: true,
  pagination: {
    type: "bullets"
  },
  autoplay: {
    delay: 5000
  },
  slidesPerView: 1,
  spaceBetween: 20
};

let breakpoints = {
  768: {
    slidesPerView: 2,
    spaceBetween: 20
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 30
  },
  1200: {
    slidesPerView: 3,
    spaceBetween: 40
  },
  1400: {
    slidesPerView: 3,
    spaceBetween: 65
  }
};

const RelatedLinksCarousel = ({ items, dir = "ltr" }) => {
  let sliderBreakpoints = { ...breakpoints };

  if (items?.length <= 1) {
    sliderBreakpoints = null;
  } else if (items?.length === 2) {
    sliderBreakpoints = {
      768: {
        slidesPerView: 2,
        spaceBetween: 40
      }
    };
  }
  return (
    <Styles>
      {items?.length > 0 && (
        <div className="articles-carousel w-100">
          <Swiper {...swiperProps} breakpoints={sliderBreakpoints} dir={dir}>
            {items?.map?.((item, index) => (
              <SwiperSlide key={index}>
                <RelatedLinkCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </Styles>
  );
};

export default RelatedLinksCarousel;
