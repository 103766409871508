import { ImpactCounter } from "@atoms";
import { CounterLoader } from "@particles";
import React from "react";
import Styles from "./styles";

const loadingIndicator = Array(3)
  .fill(0)
  .map((_, i) => <CounterLoader key={i} />);

const ImpactCounters = ({ counters, loading }) => {
  return (
    <Styles>
      <div className="impact-counters">
        {!loading &&
          counters?.map?.((counter, index) => <ImpactCounter key={index} {...counter} />)}

        {loading && loadingIndicator}
      </div>
    </Styles>
  );
};

export default ImpactCounters;
