import { breakpoints } from "@constants";
import { respondTo } from "helpers/style/respond-to";
import styled, { css } from "styled-components";
import { impactButtonStatus } from ".";

export const gridResponsiveColumnsCount = [
  {
    breakpoint: "0px",
    value: 3
  },
  {
    breakpoint: breakpoints.sm,
    value: 4
  },
  {
    breakpoint: breakpoints.lg,
    value: 5
  },
  {
    breakpoint: breakpoints.xl,
    value: 6
  }
];

const getGridResponsiveColumnsCountCss = () => {
  let style = [];
  gridResponsiveColumnsCount.forEach((count) => {
    style.push(
      css`
        @media (min-width: ${count.breakpoint}) {
          grid-template-columns: repeat(${count.value}, 1fr);
        }
      `
    );
  });
  return style;
};

const Styles = styled.div`
  .covid19-impact-details {
    &__grid {
      display: grid;
      gap: 2rem;
      ${respondTo.lg`
            gap: 3rem;
       `}

      ${getGridResponsiveColumnsCountCss()}
    }
  }
`;

export const ImpactButton = styled.button`
  width: 100%;
  padding-top: 100%;
  border: none;
  opacity: ${({ status }) => (status === impactButtonStatus.inactive ? "0.4" : "1")};
  position: relative;
  transition: opacity 0.3s;
  background-color: ${(props) => (props.color ? props.color : "unset")};

  img,
  span {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
`;

export const ImpactDetails = styled.div`
  grid-column: 1 / -1;
  grid-row: ${(props) => props.rowNumber ?? "unset"};
  transition: all 0.2s ease-in-out;
`;
export default Styles;
