import React from "react";

import { DateIcon, UserIcon } from "@icons";
import { AppTemplate } from "@templates";
import { EventCarousel } from "@organisms";
import { AuthorCard, ShareComponent } from "@molecules";
import { Tag } from "@atoms";
import { HTMLComponent, LocalizedLink, SpinnerLoader } from "@particles";

import Styles from "./styles";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { blogsApi } from "api";
import { FormattedMessage, useIntl } from "react-intl";
import isEmpty from "lodash/isEmpty";
import { Alert } from "react-bootstrap";
import { format } from "date-fns";
import { getDateLocale } from "@utils";
import { ARABIC } from "types/languages";
import { getBlogIdFromUrlParam } from "../blogs/helpers";
import { pageRoutes } from "@constants";
import DownloadPdfBtn from "./download-pdf-btn";

const getFormattedDate = (date) => {
  if (!date) return "";
  return format(new Date(date), "PP", { locale: getDateLocale() });
};

const BlogDetails = () => {
  const { id: urlId } = useParams();
  const id = getBlogIdFromUrlParam(urlId);

  const { isLoading, data } = useQuery(["blog", "details", id], () =>
    blogsApi.getDetails(id)
  );

  const blog = data?.data ?? {};

  const { mediaFiles, title, publishDate, description, topics, authors, source } = blog;

  const locale = useIntl().locale;
  const dir = locale === ARABIC ? "rtl" : "ltr";

  const isEmptyBlog = isEmpty(blog);

  const authorsNames = authors
    ?.map?.((a) => a.name)
    ?.join(locale === ARABIC ? "، " : ", ");

  return (
    <AppTemplate title={title || "Blog"}>
      <Styles>
        <div className="blog">
          {isLoading && <SpinnerLoader />}
          {!isLoading && isEmptyBlog && (
            <div className="container">
              <div className="w-100 py-5">
                <Alert>
                  <FormattedMessage defaultMessage="something went wrong fetching this blog" />
                </Alert>
              </div>
            </div>
          )}
          {!isLoading && !isEmptyBlog && (
            <>
              <div className="container mb-4">
                <header>
                  {topics?.length > 0 && (
                    <div className="blog__topics">
                      {topics.map((topic) => (
                        <LocalizedLink
                          key={topic.id}
                          to={`${pageRoutes.keyTopics}/${topic.id}`}>
                          <Tag>{topic.name}</Tag>
                        </LocalizedLink>
                      ))}
                    </div>
                  )}

                  <div className="blog__title">
                    <h1>{title}</h1>
                  </div>
                </header>

                <div className="blog__date-location d-block d-lg-flex flex-wrap align-items-center">
                  {authors?.length > 0 && (
                    <h6 className="text-14px mb-3 mb-lg-0 me-lg-5 uppercase font-md d-flex align-items-end">
                      <UserIcon className="me-2 text-20px" />
                      {authorsNames}
                    </h6>
                  )}
                  {publishDate && (
                    <div className="date">
                      <div className="d-flex align-items-center">
                        <div className="date__icon me-2">
                          <DateIcon width="1.5rem" />
                        </div>

                        <div className="date__text">{getFormattedDate(publishDate)}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {mediaFiles?.length > 0 && (
                <div className="blog__carousel">
                  <EventCarousel
                    items={mediaFiles}
                    imageProp="filePath"
                    captionProp="caption"
                    dir={dir}
                  />
                </div>
              )}

              <div className="container">
                <div className="w-100 d-md-flex">
                  <div className="flex-1 border-md-end pe-md-4">
                    <HTMLComponent
                      className="blog__des clearfix text-18px mb-5 mb-md-0"
                      html={description}
                    />

                    <hr className="my-3" />
                    {source && (
                      <>
                        <h5>
                          <FormattedMessage defaultMessage="Sources and Citations" />
                        </h5>
                        <HTMLComponent
                          className="blog__sources clearfix text-18px mb-5 mb-md-0"
                          html={source}
                        />
                      </>
                    )}
                  </div>

                  <div className="blog__info-share ms-md-4">
                    <div className="share">
                      <div className="share__text mb-2">
                        <FormattedMessage defaultMessage="Share" />
                      </div>
                      <div className="share__icons">
                        <ShareComponent title={title} />
                      </div>
                      <div>
                        <DownloadPdfBtn
                          blog={{
                            image: mediaFiles?.[0]?.filePath,
                            title,
                            authors,
                            description,
                            source,
                            topics: topics?.map?.((t) => t.name),
                            publishDate: getFormattedDate(publishDate)
                          }}
                        />
                      </div>

                      {authors?.length > 0 && (
                        <div className="my-3">
                          <h5>
                            {authors.length > 1 ? (
                              <FormattedMessage defaultMessage="About the authors" />
                            ) : (
                              <FormattedMessage defaultMessage="About the author" />
                            )}
                          </h5>
                          <div className="blog__authors-list my-3">
                            {authors?.map?.((author) => (
                              <AuthorCard key={author.id} {...author} />
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Styles>
    </AppTemplate>
  );
};

export default BlogDetails;
