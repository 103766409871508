import styled from "styled-components";

const Styles = styled.div`
  height: 100%;
  .data-visualiztion-card {
    height: 100%;
    max-width: 500px;
    &__img {
      position: relative;
      padding-top: 60%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;
export default Styles;
