import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .filter-select {
    height: 100%;
    &__btn {
      height: 100%;
      border: none;
      appearance: none;
      border-bottom: solid 2px white;
      color: white;
      padding: 0.5rem 1rem;
      background-color: transparent;
      transition: all 0.3s ease-in-out;

      &:focus {
        color: var(--primary);
        border-bottom-color: var(--primary);
      }

      font-size: 1rem;
      ${respondTo.lg`
        font-size: 1.1rem;
      `}

      ${respondTo.xxl`
        font-size: 1.375rem;
      `}

      &:after {
        display: none;
      }
    }
  }
`;
export default Styles;
