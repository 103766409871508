// import { useSnapshotPdf } from "@hooks";
import { useSnapshotPdf } from "@hooks";
import { CloseIcon } from "@icons";
import { HTMLComponent } from "@particles";
import { localizationApi } from "api";
import ReportButtonComponent from "components/organisms/sdg-reports/report-button-component";
import GovStatisticalSnapshot from "components/particles/sdgs-statistical-snapshot/gov-snapshot";
import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";

import Styles from "./styles";

const GovernorateCard = ({ id, name, summary, color, onClose }) => {
  const intl = useIntl();

  const {
    generateSnapshot,
    isLoading: isSnapshotLoading,
    isDisabled: isSnapshotDisabled
  } = useSnapshotPdf({
    exportName: intl.formatMessage(
      { defaultMessage: "{sdg} - statstical snapshots" },
      { sdg: name }
    ),
    infoPropName: "governorateInfo",
    component: GovStatisticalSnapshot,
    query: {
      queryKey: ["governorate-snapshot-data", id],
      queryFn: () => localizationApi.getGovStatisticalSnapshotData(id),
      enabled: !!id
    }
  });

  const { data: govReportData, isLoading: isGovReportLoading } = useQuery({
    queryKey: ["governorate-report", id],
    queryFn: () => localizationApi.getGovReport(id),
    enabled: !!id
  });

  return (
    <Styles iconColor={color}>
      <div className="localization-details-card">
        {onClose && (
          <button className="localization-details-card__close-btn" onClick={onClose}>
            <CloseIcon />
          </button>
        )}
        <div className="h-100 p-3 p-lg-4">
          {name && <h4 className="text-dark font-md text-25px mb-3">{name}</h4>}
          {summary && (
            <HTMLComponent
              className="localization-details-card__desc text-dark text-15px font-light mb-3"
              html={summary}
            />
          )}
          <div className="d-flex pt-3">
            {/* <button
              className="report-btn me-4"
              disabled={isSnapshotDisabled || isSnapshotLoading}
              onClick={generateSnapshot}>
              {isSnapshotLoading ? (
                <FormattedMessage defaultMessage="Loading..." />
              ) : (
                <FormattedMessage defaultMessage="Statistical Snapshot" />
              )}
            </button> */}

            <ReportButtonComponent
              className="me-4"
              disabled={isSnapshotDisabled || isSnapshotLoading}
              onClick={generateSnapshot}>
              {isSnapshotLoading ? (
                <FormattedMessage defaultMessage="Loading..." />
              ) : (
                <FormattedMessage defaultMessage="Statistical Snapshot" />
              )}
            </ReportButtonComponent>

            <ReportButtonComponent
              disabled={!govReportData?.data?.path}
              href={govReportData?.data?.path}
              download={govReportData?.data?.caption}
              isLoading={isGovReportLoading}>
              <FormattedMessage defaultMessage="Generate Data Report" />
            </ReportButtonComponent>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default GovernorateCard;
