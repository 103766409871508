import { breakpoints } from "@constants";
import styled from "styled-components";

const Styles = styled.div`
  width: 100%;
  height: 100%;
  .publication-card {
    width: 100%;
    height: 100%;
    border: solid 1px var(--greyLight);

    .text-25px {
      @media (max-width: ${breakpoints.xxl}) {
        font-size: 1.2rem;
      }
    }

    &__img-container {
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
    }

    &__img {
      position: relative;
      width: 100%;
      padding-top: 115%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }
`;
export default Styles;
