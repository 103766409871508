import React from "react";
import { StyledSvg } from "@atoms";

const ClearIcon = (props) => {
  return (
    <StyledSvg
      {...props}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M5,5 L19,19"></path>
    </StyledSvg>
  );
};

export default ClearIcon;
