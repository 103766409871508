const stylesFactory = function (themeColor) {
  return {
    section: {
      justifyContent: "center",
      marginBottom: "0.5cm",
      width: "50%"
    },
    indicatorsContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%"
    },
    hr: {
      width: "100%",
      height: 1,
      backgroundColor: "#919191",
      margin: "0.5cm 0"
    },
    sectionWithBg: {
      backgroundColor: "#EDEDED",
      padding: "10pt 20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row"
    },
    indictorWithBorder: {
      padding: "8pt 0",
      borderBottom: "1pt solid #c0c0c0",
      width: "45%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  };
};

export default stylesFactory;
