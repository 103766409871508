import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";
import { ARABIC, ENGLISH } from "types/languages";

const Styles = styled.div`
  width: 100%;
  .filters {
    &__list {
      border-bottom: solid 2px white;
    }
    &__titles {
      margin-bottom: -2px;
      order: 2;
      ${respondTo.lg`
        order: 1;
      `}
    }

    &__search {
      font-size: 1.375rem;
      color: white;
      padding-bottom: 0.5rem;
      border: none;
      border-bottom: solid 2px white;
      width: 100%;
      order: 1;
      margin-bottom: 2rem;

      ${respondTo.lg`
        width: 350px;
        order: 2;
        &:lang(${ENGLISH}) {
            border-left: solid 2px white;
        }
        &:lang(${ARABIC}) {
            border-right: solid 2px white;
        }
        margin-bottom: -2px;
      `}
      &:focus-within {
        border-bottom-color: var(--primary);
        &:lang(${ENGLISH}) {
          border-left-color: var(--primary);
        }
        &:lang(${ARABIC}) {
          border-right-color: var(--primary);
        }
      }
      transition: all 0.2s ease-in-out;
      input {
        flex: 1;
        background-color: transparent;
        padding: 0;
        border: none;
        color: white;
        &:focus {
          outline: none;
        }
      }
      &__icon {
        line-height: 1;
        padding: 0.5rem;
        border-radius: 50%;
        border: solid 1px white;
        color: inherit;
        background-color: transparent;
      }
    }

    &__options {
      transition: max-height 0.2s ease-in-out;
      max-height: ${(props) => (props.isAnyActiveFilter ? "15rem" : "0")};
      margin-top: ${(props) => (props.isAnyActiveFilter ? "1rem" : "0")};

      &__grid {
        display: flex;
        flex-wrap: wrap;
        > * {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
`;
export default Styles;
