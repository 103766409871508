import React from "react";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import Styles from "./styles";

const ImpactCounter = ({ counterValue, counterName }) => {
  return (
    <Styles>
      <div className="impact-counter w-100 h-100 p-3 p-xl-4 bg-primary d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-light text-center font-bold text-50px mb-1">
          <CountUp end={parseInt(counterValue)} duration={3} redraw={true} separator=",">
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span className="counter-number" ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
        </h1>
        <p className="text-light text-center font-md text-16px">{counterName}</p>
      </div>
    </Styles>
  );
};

export default ImpactCounter;
