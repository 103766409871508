import { breakpoints } from "@constants";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .sdg-page-template {
    flex: 1;
    display: flex;
    flex-direction: column;
    .text-25px {
      @media (max-width: ${breakpoints.sm}) {
        font-size: 1rem;
      }
    }

    &__container {
      width: 90%;
      ${respondTo.md`
        width: 80%;
      `}
      ${respondTo.xl`
        width: 70%;
      `}
      margin: auto;
    }

    &__content {
      flex: 1;
    }
  }
`;
export default Styles;
