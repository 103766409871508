import React from "react";
import ReactDOMServer from "react-dom/server";
import { FormattedMessage } from "react-intl";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf";
import ar_locale from "date-fns/locale/ar";

import { LANG_LOCAL_STORAGE_KEY } from "@constants";
import { ARABIC, DEFAULT_LANG, ENGLISH } from "types/languages";
import { toastError, toastSuccess } from "@particles";
import "assets/fonts/Rubik-normal";

export function isOdd(num) {
  return num % 2 !== 0;
}

export function isSvg(link) {
  return link?.slice(-3)?.toUpperCase() === "SVG";
}

export function numberWithCommas(x) {
  if (!x || typeof +x !== "number") return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function scrollTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export function getCurrentLang() {
  return (
    localStorage.getItem(LANG_LOCAL_STORAGE_KEY) ||
    navigator.language.split(/[-_]/)[0] ||
    DEFAULT_LANG
  );
}

export function setCurrentLang(lang) {
  localStorage.setItem(LANG_LOCAL_STORAGE_KEY, lang);
  if (lang === ARABIC) {
    import("bootstrap/dist/css/bootstrap-utilities.rtl.min.css");
  } else {
    import("bootstrap/dist/css/bootstrap-utilities.min.css");
  }
}

export function toggleLang(lang, pathname = "") {
  const newLang = lang === ENGLISH ? ARABIC : ENGLISH;
  const newPathname = pathname?.replace(`/${lang}/`, `/${newLang}/`);

  window.location.replace(newPathname);
}

export function getDateLocale() {
  const lang = getCurrentLang();
  return lang === ARABIC ? ar_locale : null;
}

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      toastSuccess(<FormattedMessage defaultMessage="Copying link was successful" />);
    },
    (err) => {
      fallbackCopyTextToClipboard(text);
    }
  );
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? (
      <FormattedMessage defaultMessage="successful" />
    ) : (
      <FormattedMessage defaultMessage="unsuccessful" />
    );
    toastSuccess(
      <FormattedMessage defaultMessage="Copying link was {msg}" values={{ msg }} />
    );
  } catch (err) {
    toastError(err);
  }

  document.body.removeChild(textArea);
}

export function checkArrayIncludes(arr, target, getId = (v) => v) {
  const mappedArr = arr?.map((el) => getId(el));
  return target.every((v) => mappedArr?.includes(getId(v)));
}

export function findMaxDate(arr = []) {
  if (!arr.length) return null;
  const max = Math.max(...arr.map((dateString) => Number(new Date(dateString))));
  return new Date(max);
}

export function generatePdfFromHtml(
  el,
  fileName,
  options = {
    useJsPdf: true,
    cb: null,
    margin: 20
  }
) {
  const marginY = options?.margin ?? 20;
  const lang = getCurrentLang();
  const isArabic = lang === ARABIC;

  const cb = options?.cb;
  const useJsPdf = options?.useJsPdf;

  // gramarly extension cause blank report
  // workaround: remove grammarly tag and reappend it after generating the document
  const grammarly = document.querySelector("grammarly-desktop-integration");
  if (grammarly) {
    grammarly.parentElement.removeChild(grammarly);
  }

  if (isArabic || !useJsPdf) {
    // use html2pdf because it has less issues with Arabic (inverse brackets, lack of fonts...)
    html2pdf()
      .set({
        margin: [marginY, 0],
        image: { type: "png", quality: 0.99 },
        html2canvas: {
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          scale: 2
        },
        enableLinks: true,
        jsPDF: {
          unit: "pt"
        },
        pagebreak: {
          mode: "avoid-all"
        }
      })
      .from(el)
      .toPdf()
      .save(`${fileName}.pdf`)
      .finally(() => {
        cb && cb();
        grammarly && document.querySelector("html").appendChild(grammarly);
      });
  } else {
    // use jsPDF.html because it generates selectable text and has no issue with English
    // LATER EDIT: jsPdf produce not compatible file with adobe reader
    const doc = new jsPDF("p", "pt", "a4");
    const font = "Rubik";

    doc.html(el, {
      margin: [marginY, 0],
      autoPaging: "text",
      windowWidth: 595,
      width: 595,
      jsPDF: doc,
      callback: (doc) => {
        doc.setLanguage(lang);
        doc.setFont(font);

        doc.save(`${fileName}.pdf`);
        cb && cb();
        grammarly && document.querySelector("html").appendChild(grammarly);
      }
    });
  }
}

export function exportImageFromHtml(el, fileName, cb) {
  // grammarly may cause issues with html2canvas
  const grammarly = document.querySelector("grammarly-desktop-integration");
  if (grammarly) {
    grammarly.parentElement.removeChild(grammarly);
  }

  // went with html2canvas becuase it enable generating image from fixed positioned elements
  // we need to position the template in a fixed way to prevent showing it while exporting
  html2canvas(el, {
    useCORS: true,
    scale: 2
  }).then((canvas) => {
    grammarly && document.querySelector("html").appendChild(grammarly);
    cb && cb();
    var link = document.createElement("a");
    link.download = `${fileName}.jpg`;
    link.href = canvas.toDataURL("image/jpeg", 0.9);
    link.click();
  });
}

export function imgToPng(src, cb) {
  const grammarly = document.querySelector("grammarly-desktop-integration");
  if (grammarly) {
    grammarly.parentElement.removeChild(grammarly);
  }

  const image = new Image(500);
  image.style.position = "fixed";
  image.style.top = "-3000px";

  image.onload = () => {
    document.body.appendChild(image);
    html2canvas(image, {
      useCORS: true,
      backgroundColor: "rgba(0, 0, 0, 0)",
      removeContainer: true,
      scale: 2
    }).then((canvas) => {
      grammarly && document.querySelector("html").appendChild(grammarly);
      cb && cb(canvas.toDataURL("image/png"));
      document.body.removeChild(image);
    });
  };

  image.src = src;
}

export function exportChartToPdfFromHtml(exportEl, fileName) {
  // export chart from html template
  let scale = exportEl?.clientHeight / exportEl?.clientWidth;
  if (isNaN(scale)) {
    scale = 0.8;
  }

  const margin = 10;
  const width = 550;

  // grammarly may cause issues with html2canvas
  const grammarly = document.querySelector("grammarly-desktop-integration");
  if (grammarly) {
    grammarly.parentElement.removeChild(grammarly);
  }

  html2canvas(exportEl, {
    useCORS: true,
    scale: 2
  }).then((canvas) => {
    grammarly && document.querySelector("html").appendChild(grammarly);
    const canvasImage = canvas.toDataURL("image/jpeg", 0.9);
    const doc = new jsPDF("p", "pt", "a4");
    doc.addImage(canvasImage, "JPEG", margin, margin, width, width * scale);
    doc.save(`${fileName || "export"}.pdf`);
  });
}

export const isNumber = (num) => typeof num === "number";

export function exportStatisticalSnapshotToPdf(Snapshot, exportName, cb) {
  if (!Snapshot) {
    cb && cb();
    return;
  }

  const el = ReactDOMServer.renderToStaticMarkup(<Snapshot />);

  generatePdfFromHtml(el, exportName, {
    useJsPdf: false,
    margin: 0,
    cb
  });
}
