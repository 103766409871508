import styled from "styled-components";
import { ENGLISH } from "types/languages";

const Styles = styled.div`
  height: 100%;
  .related-link-card {
    margin: auto;
    border: solid 1px var(--light);
    border-bottom: solid 4px var(--primary);

    &__img-container {
      max-width: 200px;
      margin: auto;
      width: 100%;
    }
    &__img {
      width: 100%;
      position: relative;
      padding-top: 100%;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    &__title {
      &:lang(${ENGLISH}) {
        letter-spacing: 2px;
      }
    }

    &__link {
      display: block;
      background-color: var(--primary);
      transition: all 0.2s ease-in-out;
      &:hover {
        color: white;
        background-color: var(--primaryDark);
      }
    }
  }
`;
export default Styles;
