import { PUB_CAROUSEL_COLORS } from "@constants";
import { AppTemplate } from "@templates";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import Styles, { CallToActionSection } from "./styles";
import { homeApi } from "api";
import { HTMLComponent, ImageLoader, SpinnerLoader } from "@particles";
import { Alert } from "react-bootstrap";
import { GenericeNewTabLink } from "@molecules";
import { RightArrowIcon } from "@icons";
import { useLocation } from "react-router-dom";

const getCallToActionColor = (index) =>
  PUB_CAROUSEL_COLORS[index % PUB_CAROUSEL_COLORS.length];

const PageLink = ({ name, link }) => (
  <GenericeNewTabLink className="about__page-list__link" link={link}>
    <span className="title">{name}</span>
    <span className="icon">
      <RightArrowIcon />
    </span>
  </GenericeNewTabLink>
);

const ourPagesId = "pages";

const About = () => {
  const { isLoading, data, isError } = useQuery("about-page", homeApi.getAboutPage);

  const [ourPagesRef, setOurPagesRef] = useState();
  const { hash } = useLocation();

  const {
    title,
    introText,
    introduction,
    dataPagesTitle,
    dataPages,
    knowledgePagesTitle,
    knowledgePages,
    objectiveTitle,
    objectiveSubTitle,
    objectiveText,
    suggestionTitle,
    suggestionSubTitle,
    suggestionText,
    contributionTitle,
    contributionSubTitle,
    contributionText,
    dataLinks,
    knowledgeLinks
  } = data?.data || {};

  const callToActionData = [
    {
      title: objectiveTitle,
      subTitle: objectiveSubTitle,
      text: objectiveText
    },
    {
      title: suggestionTitle,
      subTitle: suggestionSubTitle,
      text: suggestionText
    },
    {
      title: contributionTitle,
      subTitle: contributionSubTitle,
      text: contributionText
    }
  ];

  useEffect(() => {
    if (hash === `#${ourPagesId}` && ourPagesRef) {
      const yOffset = -120;
      const y = ourPagesRef?.getBoundingClientRect().top + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [hash, ourPagesRef]);

  return (
    <AppTemplate title={"About"}>
      <Styles>
        {isLoading && <SpinnerLoader />}

        {isError && (
          <div className="container my-5">
            <Alert>
              <FormattedMessage defaultMessage="Something went wrong. Please try again in a few moments" />
            </Alert>
          </div>
        )}

        {!isError && (
          <div className="about">
            <section className="about__hero text-white text-center">
              {isLoading && <ImageLoader />}
              {!isLoading && (
                <div className="about__hero__content">
                  <h1 className="text-50px font-md mb-4">{title}</h1>
                  <p className="text-20px font-md">{introText}</p>
                </div>
              )}
            </section>
            {!isLoading && (
              <section className="about__container py-5">
                <div className="w-100 px-xl-5">
                  <HTMLComponent className="mb-3" html={introduction} />
                  <h4
                    ref={(ref) => setOurPagesRef(ref)}
                    id={ourPagesId}
                    className="text-dark text-28px font-md mb-4">
                    <FormattedMessage defaultMessage="Our Pages:" />
                  </h4>

                  <div className="about__content mb-5">
                    <div className="w-100">
                      <h4 className="text-dark text-28px font-md mb-2">
                        {dataPagesTitle}
                      </h4>

                      <HTMLComponent className="about__pages-desc" html={dataPages} />

                      <div className="w-100 mt-3">
                        <div className="about__page-list">
                          {dataLinks?.map?.((link) => (
                            <PageLink key={link.id} {...link} />
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="w-100">
                      <h4 className="text-dark text-28px font-md mb-2">
                        {knowledgePagesTitle}
                      </h4>

                      <HTMLComponent
                        className="about__pages-desc"
                        html={knowledgePages}
                      />

                      <div className="w-100 mt-3">
                        <div className="about__page-list">
                          {knowledgeLinks?.map?.((link) => (
                            <PageLink key={link.id} {...link} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="about__call-to-action mt-5">
                  {callToActionData.map(({ title, subTitle, text }, index) => (
                    <CallToActionSection
                      key={index}
                      bgColor={getCallToActionColor(index)}>
                      <div className="sub-title">{subTitle}</div>
                      <h2 className="title mb-3">{title}</h2>
                      <HTMLComponent className="desc" html={text} />
                    </CallToActionSection>
                  ))}
                </div>
              </section>
            )}
          </div>
        )}
      </Styles>
    </AppTemplate>
  );
};

export default About;
