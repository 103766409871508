import { breakpoints } from "@constants";
import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";
import { ARABIC } from "types/languages";

export const Hero = styled.div`
  background: #1d1d1d;
  padding-top: 4rem;
  padding-bottom: 7rem;

  h2 {
    width: 80%;
    margin: auto;
    margin-bottom: 2rem;
    color: white;
  }

  .swiper-container {
    width: 90%;
    margin: auto;
  }

  .swiper {
    width: 65%;
    height: 100%;
    position: unset;
    ${respondTo.md`
        width: 80%;
    `}
  }

  .swiper-slide img {
    object-fit: cover;
    width: 100%;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    color: white;
    top: 70%;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;

    ${respondTo.lg`
        height: 4rem;
        width: 4rem;
    `}
  }

  .swiper-button-prev {
    &:after {
      content: "\\2190";
      font-size: 23px;
    }
    &:lang(${ARABIC}) {
      &:after {
        content: "\\2192";
      }
    }
  }

  .swiper-button-next {
    &:after {
      content: "\\2192";
      font-size: 23px;
    }
    &:lang(${ARABIC}) {
      &:after {
        content: "\\2190";
      }
    }
  }
`;

const Styles = styled.div`
  .key-topics {
    &__slider-icon {
      cursor: pointer;
      position: relative;
      padding-top: 100%;

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem 0.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .text-18px {
        @media (max-width: ${breakpoints.md}) {
          font-size: 0.7rem;
        }
      }

      .topic-title {
        font-size: 0.8rem;
        ${respondTo.md`
          font-size: 1rem;
        `}
        overflow-wrap: anywhere;
        hyphens: auto;
      }
    }

    .details-loader {
      min-height: 300px;
    }
  }
`;
export default Styles;
