import { SearchIcon } from "@icons";
import React from "react";
import Styles from "./styles";

const SearchInput = ({ name, color, inputClassName, ...props }) => {
  return (
    <Styles color={color}>
      <div className="search-input text-14px d-flex align-items-end">
        <button type="submit" className="btn">
          <SearchIcon />
        </button>
        <input {...props} className={inputClassName} name={name} />
      </div>
    </Styles>
  );
};

export default SearchInput;
