import { ImageComponent } from "@atoms";
import React from "react";
import Styles from "./styles";

const AuthorCard = ({ id, name, bio, imageUrl }) => {
  return (
    <Styles>
      <div className="author-card clearfix py-2">
        {imageUrl && (
          <ImageComponent
            className="author-card__img float-start me-2 mb-1"
            src={imageUrl}
            alt={name}
          />
        )}
        <div className="author-card__body">
          {name && <h6 className="text-primary">{name}</h6>}
          {bio && <p className="text-black text-14px">{bio}</p>}
        </div>
      </div>
    </Styles>
  );
};

export default AuthorCard;
