import { Dropdown } from "react-bootstrap";

import styled from "styled-components";

const StyledDropdown = styled(Dropdown)`
  height: 100%;

  &.btn-primary,
  &.btn-secondary,
  &.btn-outline-primary:hover,
  &.btn-outline-secondary:hover {
    color: var(--light);
  }

  .btn {
    border-radius: ${({ circle }) => (circle ? "50%" : "0.25rem")};
    padding: ${({ circle }) => (circle ? "0.75rem 0.75rem" : "0.375rem 0.75rem")};
    line-height: ${({ circle }) => (circle ? "1" : "1.5")};
    &.btn-lg {
      border-radius: ${({ circle }) => (circle ? "50%" : "0.3rem")};
      padding: ${({ circle }) => (circle ? "1rem 1rem" : "0.5rem 1rem")};
    }
  }

  .dropdown-toggle,
  .dropdown-toggle:hover {
    color: var(--light) !important;
    border-color: none;
  }
`;

export default StyledDropdown;
