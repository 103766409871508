import { theme } from "@constants";
import { LinkIcon } from "@icons";
import { copyToClipboard } from "@utils";
import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon
} from "react-share";
import Styles from "./styles";

const ShareComponent = ({ title }) => {
  const sharedLink = window.location.href;

  const handleCopyLink = () => {
    copyToClipboard(sharedLink);
  };
  return (
    <Styles>
      <div className="share-component d-flex mb-3">
        <FacebookShareButton className="mx-1 share-component__btn" url={sharedLink}>
          <FacebookIcon
            iconFillColor={theme.colors.black}
            size={"3rem"}
            bgStyle={{ fill: "none" }}
          />
        </FacebookShareButton>

        <TwitterShareButton
          className="mx-1 share-component__btn"
          url={sharedLink}
          title={title}>
          <TwitterIcon
            iconFillColor={theme.colors.black}
            size={"3rem"}
            bgStyle={{ fill: "none" }}
          />
        </TwitterShareButton>

        <EmailShareButton className="mx-1 share-component__btn" url={sharedLink}>
          <EmailIcon
            iconFillColor={theme.colors.black}
            size={"3rem"}
            bgStyle={{ fill: "none" }}
          />
        </EmailShareButton>

        <button className="mx-1 share-component__btn" onClick={handleCopyLink}>
          <LinkIcon width={"2rem"} />
        </button>
      </div>
    </Styles>
  );
};

export default ShareComponent;
