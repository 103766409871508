import { ExternalLink, NavMenuLink } from "@atoms";
import { pageRoutes, theme } from "@constants";
import React from "react";
import { NavSubMenu } from "@molecules";

const NavMenuLinkContainer = ({
  name,
  subMenus,
  pageUrl,
  inNewWindow,
  inDynamicPage,
  id,
  color = theme.colors.black,
  activeColor = theme.colors.primary,
  hoverColor = theme.colors.primary
}) => {
  if (inDynamicPage === 1 && subMenus?.length === 0) {
    return (
      <NavMenuLink
        color={color}
        hoverColor={hoverColor}
        activeColor={activeColor}
        to={`${pageRoutes.dynamic}/${id}`}>
        {name}
      </NavMenuLink>
    );
  }

  if (subMenus?.length > 0) {
    return (
      <NavSubMenu name={name} subMenus={subMenus} color={color} hoverColor={hoverColor} />
    );
  }

  if (inNewWindow || pageUrl?.startsWith("http")) {
    return (
      <ExternalLink
        className="d-block px-2 px-lg-3 py-3 py-md-2 font-md text-14px"
        color={color}
        hoverColor={hoverColor}
        activeColor={activeColor}
        href={pageUrl}>
        {name}
      </ExternalLink>
    );
  }

  return (
    <NavMenuLink
      color={color}
      hoverColor={hoverColor}
      activeColor={activeColor}
      to={`/${pageUrl}`}>
      {name}
    </NavMenuLink>
  );
};

export default NavMenuLinkContainer;
