import React from "react";

import Styles from "./styles";

const CheckButton = (
  { name, id, label, checked, onChange, disabled, type = "radio" },
  ref
) => {
  const onChangeCheck = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <Styles>
      <div ref={ref} className="form-check my-2">
        <div className="form-check-input-container">
          <input
            className="form-check-input"
            type={type}
            name={name}
            id={id}
            disabled={disabled}
            checked={checked}
            onClick={onChangeCheck}
            onChange={() => null}
          />
        </div>

        <div className="form-check-label-container">
          <label className="form-check-label ms-3 text-14px" htmlFor={id}>
            {label}
          </label>
        </div>
      </div>
    </Styles>
  );
};

export default React.forwardRef(CheckButton);
