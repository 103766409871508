import { ExternalLink } from "@atoms";
import { CloseIcon } from "@icons";
import { HTMLComponent } from "@particles";
import React from "react";
import Styles from "./styles";

const CovidImpactCard = ({
  iconUrl,
  name,
  covidTitle,
  link,
  covidText,
  color,
  onClose
}) => {
  let urlHostName;
  if (link) {
    try {
      urlHostName = new URL(link).hostname;
    } catch (error) {
      urlHostName = "";
    }
  }

  return (
    <Styles iconColor={color}>
      <div className="covid-impact-card">
        {onClose && (
          <button className="covid-impact-card__close-btn" onClick={onClose}>
            <CloseIcon />
          </button>
        )}
        <div className="w-100 row h-100">
          <div className="col-2 col-lg-3 p-0">
            <div className="covid-impact-card__img">
              {iconUrl && (
                <img
                  src={iconUrl}
                  className="w-100 h-100"
                  alt={covidTitle || "SDG Covid Impact"}
                />
              )}

              {!iconUrl && (
                <div className="w-100 h-100 covid-impact-card__img-placeholder">
                  {name}
                </div>
              )}
            </div>
          </div>
          <div className="col-10 col-lg-9 h-100 p-3 py-lg-5">
            {covidTitle && (
              <h4 className="text-dark font-md text-25px mb-3">{covidTitle}</h4>
            )}
            {covidText && (
              <HTMLComponent
                className="covid-impact-card__desc text-dark text-15px font-light mb-3"
                html={covidText}
              />
            )}

            {link && <ExternalLink href={link}>{urlHostName}</ExternalLink>}
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default CovidImpactCard;
