import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  .impact-counters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -1rem;

    & > * {
      flex: 0 0 calc(50% - 2rem);
      margin: 1rem;

      ${respondTo.md`
          flex: 0 0 calc(25% - 2rem);
      `}
    }
  }
`;
export default Styles;
