import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function useTabNavigation(tabs) {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const tabFromUrl = searchParams.get("tab");
  const [tab, setTab] = useState(tabs[0]);
  const navigate = useNavigate();

  const handleTabClick = useCallback(
    (tab) => navigate(`${pathname}?tab=${tab}`),
    [navigate, pathname]
  );

  useLayoutEffect(() => {
    if (tabs?.includes(tabFromUrl)) {
      setTab(tabFromUrl);
    }
  }, [tabFromUrl, tabs]);

  useEffect(() => {
    if (!tabFromUrl || !tabs?.includes(tabFromUrl)) {
      handleTabClick(tabs[0]);
    }
  }, [tabFromUrl, tabs, handleTabClick]);

  return { tab, handleTabClick };
}

export default useTabNavigation;
