import React from "react";

import Styles from "./styles";

const Tag = ({ children }) => {
  return (
    <Styles>
      <span className="tag">{children}</span>
    </Styles>
  );
};

export default Tag;
