import React from "react";
import stylesFactory from "./styles";

import SnapshotTemplate from "../components/snapshot-template";
import Indicator from "../components/indicator";
import ThemeProvider from "../components/theme-provider";
import { Image, View } from "@particles";

const themeColor = "#E3233E";

const StatisticalSnapshot1 = ({ sdgInfo, indicators = [], sources, socialLinks }) => {
  const styles = stylesFactory(themeColor);
  return (
    <ThemeProvider themeColor={themeColor}>
      <SnapshotTemplate
        size={"A4"}
        {...sdgInfo}
        sources={sources}
        socialLinks={socialLinks}
        iconWidth="2.15cm">
        <>
          <View style={{ ...styles.section, padding: "0 10pt" }}>
            <Image
              src={sdgInfo?.snapshotImageURL}
              alt={sdgInfo?.name}
              style={{ width: "100%" }}
            />
          </View>
          <View style={styles.section}>
            {indicators[0] && <Indicator {...indicators[0]} stretch={false} />}
            {indicators[1] && (
              <Indicator {...indicators[1]} imgUrl={null} stretch={false} />
            )}
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[2] && <Indicator {...indicators[2]} />}
              {indicators[3] && <Indicator {...indicators[3]} />}
              {indicators[4] && <Indicator {...indicators[4]} />}
            </View>
          </View>

          <View
            style={{
              ...styles.section,
              ...styles.sectionWithBg
            }}>
            <Image
              style={{ width: "1.17cm", margin: "0 5pt", flexShrink: "0" }}
              src={indicators[5]?.imgUrl}
              alt={indicators[5]?.name}
            />
            <View style={{ flex: 1, padding: "0 10pt" }}>
              {indicators[5] && (
                <Indicator {...indicators[5]} imgUrl={null} stretch={false} />
              )}

              {indicators[6] && (
                <Indicator {...indicators[6]} imgUrl={null} stretch={false} />
              )}
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.indicatorsContainer}>
              {indicators[7] && <Indicator {...indicators[7]} />}
              {indicators[8] && <Indicator {...indicators[8]} />}
              {indicators[9] && <Indicator {...indicators[9]} />}
            </View>
          </View>

          <View
            style={{
              ...styles.section,
              ...styles.sectionWithBg
            }}>
            <View style={styles.indicatorsContainer}>
              {indicators[10] && <Indicator {...indicators[10]} />}
              {indicators[11] && <Indicator {...indicators[11]} />}
              {indicators[12] && <Indicator {...indicators[12]} />}
            </View>
          </View>

          <View style={styles.hr} />

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start"
            }}>
            {indicators[13] && <Indicator {...indicators[13]} iconWidth="2.34cm" />}
            {indicators[14] && <Indicator {...indicators[14]} iconWidth="2.34cm" />}
            {indicators[15] && <Indicator {...indicators[15]} iconWidth="2.34cm" />}
            {indicators[16] && <Indicator {...indicators[16]} iconWidth="2.34cm" />}
          </View>
        </>
      </SnapshotTemplate>
    </ThemeProvider>
  );
};

export default StatisticalSnapshot1;
