import { EdpLogo } from "@atoms";
import { CloseIcon, MenuIcon } from "@icons";
import { NavMenuLinks } from "@molecules";
import { LocalizedLink } from "@particles";
import React, { useState } from "react";

import Styles from "./styles";

const NavMenu = () => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const toggleMenu = () => setMenuExpanded(!menuExpanded);
  return (
    <Styles>
      <div className="nav-menu bg-light py-2">
        <div className="nav-menu__container d-flex justify-content-between align-items-stretch">
          <div className="d-flex align-items-center me-3 me-xl-5">
            <LocalizedLink className="nav-menu__brand me-xxl-5" to="/">
              <EdpLogo />
            </LocalizedLink>
          </div>
          <button className="nav-menu__btn btn d-block d-md-none" onClick={toggleMenu}>
            {menuExpanded ? <CloseIcon /> : <MenuIcon />}
          </button>
          <div className="d-none d-md-flex flex-1 align-items-center">
            <NavMenuLinks />
          </div>
        </div>
        <div
          className={`nav-menu__expandable ${
            menuExpanded ? "--expanded" : ""
          } d-block d-md-none mt-2`}>
          <NavMenuLinks className="justify-content-center" />
        </div>
      </div>
    </Styles>
  );
};

export default NavMenu;
