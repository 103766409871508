import { respondTo } from "helpers/style/respond-to";
import styled from "styled-components";

const Styles = styled.div`
  height: 100%;
  .publication-carousel-item {
    &__card {
      background-color: ${(props) => props.bgColor || `var(--primary)`};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__img-container {
      width: 100%;
      max-width: 250px;
      margin: 0 auto;

      ${respondTo.md`
        max-width: none;
      `}
    }

    &__img {
      position: relative;
      padding-top: 110%;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__index {
      opacity: 0.5;
      width: 100%;
      text-align: center;
      margin-top: -3.5rem;

      font-size: 2rem;
      ${respondTo.xl`
        font-size: 2.8rem;
      `};
    }
    &__read-more {
      color: white;
      font-weight: 500;
      padding-bottom: 0.4rem;
      border-bottom: solid 4px white;
    }

    &__title {
      font-size: 1.5rem;
      ${respondTo.md`
        font-size: 2rem;
      `};
      color: white;
      font-weight: 500;
    }
  }
`;
export default Styles;
