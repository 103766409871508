import { breakpoints } from "@constants";
import styled from "styled-components";

const Styles = styled.div`
  .sdg-reports {
    &__btn {
      appearance: none;
      border: none;
    }

    &__options {
      & > div {
        width: 100%; // make ouside click wrapper take the full width
      }
    }

    &__btns-panel {
      width: 100%;

      @media (min-width: ${breakpoints.lg}) {
        width: 70%;
      }

      pointer-events: all;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      > * + * {
        margin-top: 1rem;
      }
    }
  }
`;

export const ReportButton = styled.button`
  width: 100%;
  min-height: 40px;
  color: var(--secondary);
  border: solid 1px var(--secondary);
  background-color: white;
  appearance: none;
  padding: 0;
  transition: all 0.2s;

  a {
    color: var(--secondary);
  }

  &:hover {
    background-color: var(--secondary);
    color: white;

    a {
      color: white;
    }
  }

  &:disabled {
    opacity: 0.65;
    color: var(--secondary);
    background-color: white;

    a {
      color: var(--secondary);
    }
  }

  a {
    height: 100%;
    display: block;
    padding: 0.5rem 1rem;
    width: 100%;
  }
`;
export default Styles;
