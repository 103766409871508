import React from "react";
import { FormattedMessage } from "react-intl";

export const getChartData = (indicators) => {
  const data = [];

  const found = indicators.find((indicator) => !indicator?.data);

  if (!found) {
    for (let index = 0; index < indicators.length; index++) {
      const indicator = indicators[index];

      for (let index = 0; index < indicator.data.length; index++) {
        const element = indicator.data[index];

        const dataIndex = data.findIndex((e) => e.year == element.year);

        const isExist = dataIndex >= 0;

        const value = element.value || 0;

        if (isExist) {
          data[dataIndex][indicator.name] = value;
        } else {
          data.push({
            year: element.year.toString(),
            [indicator.name]: value
          });
        }
      }
    }

    const sortedData = data?.sort((a, b) => {
      if (a.year < b.year) return -1;
      if (a.year > b.year) return 1;

      return 0;
    });

    return sortedData;
  }

  return [];
};

export function loadChartsDataWithProp(
  prop,
  chartsData,
  series,
  getOutputPropName,
  isIncludeNullValues = true
) {
  const getNewPropName = getOutputPropName || ((serieName) => `${serieName}-${prop}`);

  const keys = Object.keys(chartsData[0]);
  const timeSeriesKey = keys[0];

  return chartsData.map((record) => {
    const newRecord = { ...record };
    series?.forEach((ind) => {
      const dataRecord = ind?.data?.find(
        (el) => el[timeSeriesKey]?.toString() === record[timeSeriesKey]?.toString()
      );

      if (isIncludeNullValues || (!isIncludeNullValues && dataRecord?.[prop])) {
        newRecord[getNewPropName(ind.name)] = dataRecord?.[prop];
      }
    });

    return {
      ...newRecord
    };
  });
}

export const indicatorTooltip = (indicator, goal) => {
  return (
    <div>
      <div className="border-bottom pt-2 pb-1 mb-2">{indicator.name}</div>
      <div className="goal pb-1">
        <b>
          <FormattedMessage defaultMessage="Goal" />:
        </b>{" "}
        {goal.name}
      </div>
      <div className="target">
        <b>
          <FormattedMessage defaultMessage="Target" />:
        </b>{" "}
        {indicator.target.name}
      </div>
    </div>
  );
};
