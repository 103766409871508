import { StyledButton } from "@atoms";
import { useDetectScroll } from "@hooks";
import { ArrowDropUpIcon } from "@icons";
import { Footer, Header, NavMenu } from "@organisms";
import { Seo } from "@particles";
import { scrollTop } from "@utils";
import React from "react";
import Styles from "./styles";

const AppTemplate = ({
  title,
  children,
  header = true,
  navMenu = true,
  footer = true,
  scrollBtn = true,
  searchBar = true
}) => {
  const isTooFarScrolled = useDetectScroll();

  return (
    <Styles showScrollBtn={isTooFarScrolled}>
      <div className="app-template">
        {title && <Seo title={title} />}
        <div className="app-template__header w-100">
          {header && <Header searchBar={searchBar} />}
          {navMenu && <NavMenu />}
        </div>
        <div className="app-template__content">{children}</div>
        {scrollBtn && (
          <div className="app-template__scroll-btn">
            <StyledButton variant="grey" className="text-18px" onClick={scrollTop}>
              <ArrowDropUpIcon />
            </StyledButton>
          </div>
        )}
        {footer && <Footer />}
      </div>
    </Styles>
  );
};

export default AppTemplate;
